import { useEffect, useMemo } from "react";
import throttle from "lodash/throttle";

const useThrottledOnScroll = (callback: any, delay: any) => {
  const throttledCallback = callback ? callback : noop;

  useEffect(() => {
    if (throttledCallback === noop) {
      return undefined;
    }

    document
      .getElementById("panel")!
      .addEventListener("scroll", throttledCallback);
    return () => {
      window.removeEventListener("scroll", throttledCallback);
    };
  }, [throttledCallback]);
};

const noop = () => {};

export default useThrottledOnScroll;

import { Box, PaginationItem, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledDateRangeContainer,
  StyledDownloadButton,
  StyledExportContainer,
  StyledPagination,
  StyledPhaseText,
} from "./styled";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../shared/Table/styled";
import DownloadButton from "../../../../../../assets/svg/download.svg";
import DownArrow from "../../../../../../assets/svg/dropdown_arrow.svg";
import { add, sub } from "date-fns";
import { DateRangePicker } from "react-date-range";
import CustomizedTable from "../../../../../shared/Table/CustomizedTable";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledArrowImage,
  StyledCustomMuiPagination,
  StyledDatePara,
  StyledTableHeightFix,
} from "../CreativeRotation/styled";
import moment from "moment";
import { textTruncateMore } from "../../../utils/text.utils";
import { ReactComponent as nextSvg } from "../../../../../../assets/svg/nextsvg.svg";
import { ReactComponent as prevSvg } from "../../../../../../assets/svg/prevsvg.svg";
import Loader from "../../../../../common/Loader";

const CreativeVariants: React.FC = () => {
  const { selectedAdAccount } = useSelector(
    (state: any) => state.aiGroupReducer
  );
  const { creativeVariantsLogs: data } = useSelector(
    (state: any) => state.activityLogsReducer
  );

  const { getActivityLogsState } = useSelector(
    (state: any) => state.activityLogsReducer
  );
  const { selectedAiGroup } = useSelector((state: any) => state.aiGroupReducer);
  const { creativeVariationDateRange } = useSelector(
    (state: any) => state.activityLogsReducer
  );
  const [showRange, setShowRange] = useState(false);
  const [dateSelection, setDateSelection] = React.useState([
    {
      startDate: !creativeVariationDateRange?.selection
        ? sub(new Date(), { days: 6 })
        : new Date(creativeVariationDateRange?.selection.startDate),
      endDate: !creativeVariationDateRange?.selection
        ? new Date()
        : new Date(creativeVariationDateRange?.selection.endDate),
      key: "selection",
    },
  ]);

  const initialParams = {
    searchField: "",
    search: "",
    order: "",
    orderBy: "",
    limit: 5,
    page: 1,
    start_date: !creativeVariationDateRange?.selection
      ? // ? sub(new Date(), { days: 6 }).toISOString()
        moment().subtract(6, "d").format("YYYY-MM-DDT00:00:00[Z]")
      : moment(creativeVariationDateRange?.selection.startDate).format(
          "YYYY-MM-DDT00:00:00[Z]"
        ),
    end_date: !creativeVariationDateRange?.selection
      ? moment(new Date()).format("YYYY-MM-DDT23:59:59[Z]")
      : moment(creativeVariationDateRange?.selection.endDate).format(
          "YYYY-MM-DDT23:59:59[Z]"
        ),
    page_size: "",
    download: false,
  };

  const paramsReducer = (state: any, action: any) => {
    switch (action.type) {
      case "download":
        return { ...state, download: true };
      case action.type:
        return { ...state, download: false, [action.type]: action.payload };
      default:
        return state;
    }
  };

  const [params, paramsDispatch] = React.useReducer(
    paramsReducer,
    initialParams
  );

  const handleSelect = (ranges: any) => {
    setDateSelection([
      {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      },
    ]);

    dispatch({
      type: "SET_CREATIVE_VARIATION_LOGS_DATE_RANGE",
      payload: ranges,
    });

    paramsDispatch({
      type: "start_date",
      payload: moment(ranges.selection.startDate).format(
        "YYYY-MM-DDTHH:mm:ss[Z]"
      ),
    });
    paramsDispatch({
      type: "end_date",
      payload: moment(ranges.selection.endDate).format(
        "YYYY-MM-DDT23:59:59[Z]"
      ),
    });
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: "GET_CREATIVE_VARIANTS_LOGS",
      payload: {
        elementId: selectedAiGroup,
        ad_account: selectedAdAccount.adAccountId,
        user: "",
        params,
      },
    });
  }, [params]);

  const handlePaginationChange = (e: any, page: any) => {
    paramsDispatch({ type: "page", payload: page });
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <StyledPhaseText></StyledPhaseText>
        <Box></Box>
        <StyledExportContainer>
          <StyledDateRangeContainer>
            <StyledDatePara
              onClick={() => setShowRange(!showRange)}
              variant="body1"
            >
              Custom date |{" "}
              {!creativeVariationDateRange?.selection
                ? dateSelection[0].startDate.toDateString()
                : creativeVariationDateRange?.selection?.startDate.toDateString()}{" "}
              -{" "}
              {!creativeVariationDateRange?.selection
                ? dateSelection[0].endDate.toDateString()
                : creativeVariationDateRange?.selection?.endDate.toDateString()}
              <StyledArrowImage src={DownArrow} />{" "}
            </StyledDatePara>
            {showRange && (
              <div className="daterangeSelector">
                <DateRangePicker
                  ranges={[...dateSelection]}
                  onChange={(e) => handleSelect(e)}
                  showSelectionPreview
                  direction="horizontal"
                  editableDateInputs={true}
                />
                <Button
                  className="addRange"
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowRange(false)}
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </div>
            )}
          </StyledDateRangeContainer>
          <StyledDownloadButton
            onClick={() => {
              paramsDispatch({ type: "download" });
            }}
            src={DownloadButton}
          />
        </StyledExportContainer>
      </Stack>
      <StyledTableHeightFix>
        <CustomizedTable
          tableHeadValues={[
            { name: "Variant Ad Name", align: "left" },
            { name: "Reference Ad Name", align: "left" },
            { name: "Ad Set Name", align: "left" },
            { name: "Cohort", align: "left" },
            { name: "Published at", align: "left" },
            { name: "Status", align: "left" },
          ]}
          tableWidth="100%"
          tableType="evenColoredTable"
          tableHeadCellStyle={{ width: "250px" }}
        >
          {getActivityLogsState?.loading === false ? (
            data?.rows?.map((v: any, i: number) => {
              return (
                <StyledTableRow key={i} className="tableDataRow">
                  <StyledTableCell title={v?.data?.name}>
                    {v?.data?.name ? textTruncateMore(v?.data?.name, 17) : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left" title={v?.data?.adName}>
                    {v?.data?.adName
                      ? textTruncateMore(v?.data?.adName, 17)
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left" title={v?.data?.adsetName}>
                    {v?.data?.adsetName
                      ? textTruncateMore(v?.data?.adsetName, 17)
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left" title={v?.data?.cohortName}>
                    {v?.data?.cohortName
                      ? textTruncateMore(v?.data?.cohortName, 17)
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(v.createdAt).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="left">{v.status}</StyledTableCell>
                </StyledTableRow>
              );
            })
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={7}>
                <Loader />
              </StyledTableCell>
            </StyledTableRow>
          )}
        </CustomizedTable>
      </StyledTableHeightFix>

      <StyledCustomMuiPagination>
        <StyledPagination
          onChange={(e, value) => handlePaginationChange(e, value)}
          count={Math.ceil((data?.count ? data?.count : 0) / params.limit)}
          variant="outlined"
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              components={{ previous: prevSvg, next: nextSvg }}
              {...item}
            />
          )}
        />
      </StyledCustomMuiPagination>
    </Box>
  );
};

export default CreativeVariants;

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsRefreshTokenExpired } from "../../store/actions/auth";
import { initialState } from "../../store/types/auth";
import { user_session_key } from "../../utils/constants";

const AuthWrapper:React.FC<any> = ({ children }) => {
    const auth = useSelector((state: { auth:initialState }) => state.auth);
    const { logout } = useAuth0();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleMessgae = async (event:any) => {
            if(event.data.from && event.data.from === "PYXIS_EXTENSION"){
                logout();
                localStorage.removeItem(user_session_key);
                dispatch(setIsRefreshTokenExpired(true));
            }
        }
        window.addEventListener("message", handleMessgae , false);

        return () => window.removeEventListener("message",handleMessgae)
    },[])

    if(!auth?.isRefreshTokenExpired)
    return children;

    return <div className="d-flex flex-column p-3 bg-white flex-grow-1 message-container vh-100">
        <div
        className="d-flex flex-column align-items-center p-4 flex-grow-1 overflow-hidden h-100"
        style={{ background: "#f2f2f2" }}
        >
            <h4 className="font-weight-bold fs-10 m-0 w-auto mt-5">Login From Extension</h4>
            <p className="text-center mt-4">
                <small>
                    Seems like your session timed out please login again from extension 
                </small>
            </p>
            <button
                className="text-white px-4 py-2 rounded border-0"
                style={{ background: "#0869FB" }}
                onClick={() => window.close()}
            >
                Close Tab
            </button>
        </div>
    </div>

    return null;
}

export default AuthWrapper;
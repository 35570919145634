import {
  GetBusinessUnitWithId,
  SET_BUSINESS_UNIT_WITH_ID,
  SetBusinessUnitWithId,
  SET_ELEMENT_CREATIVE_AI,
  SetElementCreativeAi,
  SET_AI_GROUP_LIST,
  SetAiGroupListing,
  NAVIGATE_VARIANT,
  NavigateVariant,
  GET_ELEMENT_CREATIVE_AI,
  GetElementCreativeAi,
  GENERATE_LEVERS,
  GenerateLevers,
  SetGenerateLevers,
  SET_GENERATE_LEVERS,
  SetAllBusinessUnitList,
  SET_ALL_BUSINESS_UNIT_LIST,
  SET_AD_ADSET_FIELD_LIST,
  SetAdAdSetFieldList,
} from "../../../../types/CreativeAI/Facebook/Main/Main";

const initialState = {
  variantWithId: null,
  elementCreativeAi: { loading: false },
  AIGroupListing: [],
  navigateVariant: false,
  defaultLevers: [],
  AllBusinessUnitList: [],
  adAdSetFieldList: {},
};

const MainReducer = (
  state = initialState,
  action:
    | GetBusinessUnitWithId
    | SetBusinessUnitWithId
    | SetElementCreativeAi
    | SetAiGroupListing
    | NavigateVariant
    | GetElementCreativeAi
    | SetGenerateLevers
    | SetAllBusinessUnitList
    | SetAdAdSetFieldList
) => {
  switch (action.type) {
    case SET_BUSINESS_UNIT_WITH_ID: {
      return {
        ...state,
        variantWithId: action.payload,
      };
    }
    case GET_ELEMENT_CREATIVE_AI: {
      return {
        ...state,
        elementCreativeAi: { loading: true },
      };
    }
    case SET_ELEMENT_CREATIVE_AI: {
      return {
        ...state,
        loading: false,
        elementCreativeAi: { loading: false, ...action.payload },
      };
    }

    case SET_AD_ADSET_FIELD_LIST: {
      return {
        ...state,
        adAdSetFieldList: action.payload,
      };
    }
    case SET_AI_GROUP_LIST: {
      return {
        ...state,
        AIGroupListing: action.payload,
      };
    }
    case NAVIGATE_VARIANT: {
      return {
        ...state,
        navigateVariant: action.payload,
      };
    }
    case SET_GENERATE_LEVERS: {
      return {
        ...state,
        defaultLevers: action.payload,
      };
    }
    case SET_ALL_BUSINESS_UNIT_LIST: {
      return {
        ...state,
        AllBusinessUnitList: action.payload,
        readyToUseBUs: action.payload.filter(
          (bu: any) => bu.status === "readyToUse"
        ),
      };
    }

    default:
      return state;
  }
};
export default MainReducer;

import styled from "styled-components";

export const StyledDrawerDiv = styled.div`
  display: flex;
  flex-grow: 1;
  max-height: calc(100% - 135px);
  padding-top: 24px;
  .drawer-panel {
    // border: 1px solid #dadce0;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    /* width: 100%; */
    padding: 24px;
    overflow: scroll;
  }
  .drawer-right-panel {
    padding: 0px 24px 24px;
    margin-left: 24px;
    background: #f5f6f7;
  }
`;

export const StyledEditorGifContainer = styled.div`
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const StyledEditorGif = styled.img`
  width: 350px;
  height: 350px;
`;
export const StyledGreatBestTxt = styled.p`
  margin-bottom: 10px;
  font-family: Lato;
  font-style: normal;
  // font-weight: 500;
  font-size: 20px;
  width: 414px;
`;
export const StyledAISettingTxt = styled.p`
  margin-bottom: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #a5a5a5;
  width: 328px;
`;
export const StyledAIProvideTxt = styled.p`
  margin-bottom: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #a5a5a5;
  width: 201px;
`;
export const StyledDrawer = styled.div`
  position: fixed !important;
`;

import { InputProps } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledErrorMessage } from "../../Typography/Heading/styled";
import { StyledInputContainer } from "../Input/styled";
import TextArea from "./TextArea";

interface TextAreaWithLabelProps extends InputProps {
  label: string;
  required?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  isBorder?: boolean;
  tooltipText?: string;
  registeredName?: any;
  control?: any;
}

export function TextAreaFormControl({
  required,
  label,
  tooltipText,
  registeredName,
  control,
  ...props
}: TextAreaWithLabelProps) {
  return (
    <Controller
      control={control}
      name={registeredName}
      rules={{
        required,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <StyledInputContainer>
          <InputLabel
            required={required}
            label={label}
            tooltipText={tooltipText}
          />
          <TextArea
            inputProps={{
              maxlength: 250,
            }}
            onChange={onChange}
            value={value}
            {...props}
            disableUnderline
          />
          {error && (
            <StyledErrorMessage>This field is required *</StyledErrorMessage>
          )}
        </StyledInputContainer>
      )}
    />
  );
}

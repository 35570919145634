import { Box, SxProps } from "@mui/material";
import {
  SwitchFormControl,
  InputFormControl,
  MultiSelectFormControl,
  RadioGroupForm,
  SelectFormControl,
  CheckBoxFormControl,
} from "../../../../shared";
import { TextAreaFormControl } from "../../../../shared/Input/TextArea/TextAreaFormControl";
import ListicleFormControl from "../Listicle/ListicleFormControl";
import { FieldType } from "../SharedTypes";

export default function LeverField({
  field,
  isConfigure,
  registeredName,
  control,
  setValue,
  getValues,
  formType,
  helperFunction,
  errors, // let it be there for future use
  setError, // let it be there for future use
  clearErrors, // let it be there for future use
}: {
  field: FieldType;
  isConfigure?: boolean;
  registeredName: any;
  control: any;
  setValue: any;
  getValues: any;
  formType?: string;
  errors: any;
  setError: any;
  clearErrors: any;
  helperFunction?: (registeredName: string) => void;
}) {
  const globalStyle: SxProps = {
    height: "40px",
    fontSize: "12px",
  };

  const globalBackgroundColor = isConfigure
    ? "rgba(242, 242, 242, 0.5)"
    : "#fffffff";

  // TODO: value prop to be added in inputs

  const getFinalRegisteredName = () => {
    const fieldName = registeredName + "." + field.field_name;
    if (field.is_dependent) {
      // TODO: generalize for multiple parent fields
      const dependentOn = field.dependent_on[0];
      const parentValue = getValues(registeredName + "." + dependentOn);
      return fieldName + "." + dependentOn + "." + parentValue;
    } else {
      return fieldName;
    }
  };

  const getField = ({ isRequired }: { isRequired: boolean }) => {
    const finalRegisteredName = getFinalRegisteredName();
    switch (field.field_type) {
      case "select":
        return (
          <SelectFormControl
            fullWidth
            label={field.field_label}
            helperFunction={helperFunction}
            tooltipText={field.helper_text}
            required={isRequired}
            sx={globalStyle}
            options={field.choices}
            placeholder={field.field_label}
            control={control}
            registeredName={finalRegisteredName}
            defaultValue={field.default_value}
            isBorder={!isConfigure}
            backgroundColor={globalBackgroundColor}
          />
        );
      case "multiselect":
        return (
          <MultiSelectFormControl
            fullWidth
            sx={globalStyle}
            options={field.choices}
            label={field.field_label}
            tooltipText={field.helper_text}
            placeholder={field.field_label}
            required={isRequired}
            control={control}
            registeredName={finalRegisteredName}
            defaultValue={field.default_value}
            isBorder={!isConfigure}
            backgroundColor={globalBackgroundColor}
            helperFunction={helperFunction}
          />
        );
      case "string":
        return (
          <InputFormControl
            disableUnderline
            fullWidth
            label={field.field_label}
            tooltipText={field.helper_text}
            required={isRequired}
            sx={globalStyle}
            placeholder={field.field_label}
            inputProps={{
              maxLength: field.max_value,
              minLength: field.min_value,
            }}
            control={control}
            registeredName={finalRegisteredName}
            defaultValue={field.default_value}
            value={getValues(finalRegisteredName) || ""}
            isBorder={!isConfigure}
            backgroundColor={globalBackgroundColor}
            helperFunction={helperFunction}
          />
        );
      case "integer":
        return (
          <InputFormControl
            disableUnderline
            inputType="int"
            fullWidth
            required={isRequired}
            label={field.field_label}
            tooltipText={field.helper_text}
            sx={globalStyle}
            placeholder={field.field_label}
            inputProps={{
              max: field.max_value,
              min: field.min_value,
            }}
            control={control}
            registeredName={finalRegisteredName}
            defaultValue={field.default_value}
            isBorder={!isConfigure}
            backgroundColor={globalBackgroundColor}
            helperFunction={helperFunction}
          />
        );
      case "float":
        return (
          <InputFormControl
            disableUnderline
            inputType="float"
            fullWidth
            required={isRequired}
            label={field.field_label}
            tooltipText={field.helper_text}
            sx={globalStyle}
            placeholder={field.field_label}
            inputProps={{
              max: field.max_value,
              min: field.min_value,
            }}
            control={control}
            registeredName={finalRegisteredName}
            defaultValue={field.default_value}
            isBorder={!isConfigure}
            backgroundColor={globalBackgroundColor}
            helperFunction={helperFunction}
          />
        );

      case "textarea":
        return (
          <TextAreaFormControl
            fullWidth
            required={isRequired}
            label={field.field_label}
            tooltipText={field.helper_text}
            sx={{
              fontSize: "12px",
            }}
            placeholder={field.field_label}
            inputProps={{
              max: field.max_value,
              min: field.min_value,
            }}
            control={control}
            registeredName={finalRegisteredName}
            defaultValue={field.default_value}
            isBorder={!isConfigure}
            backgroundColor={globalBackgroundColor}
            rows={6}
          />
        );
      case "radiobutton":
        return (
          <RadioGroupForm
            field={field}
            control={control}
            registeredName={finalRegisteredName}
            required={isRequired}
            defaultValue={field.default_value}
            helperFunction={helperFunction}
          />
        );
      case "boolean":
        return (
          <SwitchFormControl
            control={control}
            tooltipText={field.helper_text}
            registeredName={finalRegisteredName}
            label={field.field_label}
            sx={globalStyle}
            defaultValue={field.default_value}
            helperFunction={helperFunction}
          />
        );
      case "listicle":
        return (
          <ListicleFormControl
            maxValue={field.max_value as unknown as number}
            minValue={field.min_value as unknown as number}
            registeredName={finalRegisteredName}
            field={field}
            control={control}
            required={isRequired}
            helperFunction={helperFunction}
          />
        );
      case "checkbox":
        return (
          <CheckBoxFormControl
            checkedFieldName={finalRegisteredName}
            setValue={setValue}
            getValues={getValues}
            tooltipText={field.helper_text}
            label={field.field_label}
            sx={globalStyle}
            defaultValue={field.default_value}
            helperFunction={helperFunction}
          />
        );
      default:
        return <div />;
    }
  };
  return field.is_visible_in_UI || field.fetch_value_from === "super_user" ? (
    <Box width={isConfigure ? "50%" : "100%"} pr="12px" pt="5px">
      {getField({
        isRequired:
          formType === "BU"
            ? field.fetch_value_from === "super_user"
              ? field.is_required
              : false
            : field.is_required,
      })}
    </Box>
  ) : (
    <div />
  );
}

import { Box, Divider } from "@mui/material";
import React, { useState } from "react";
import { StyledHeading2 } from "../../../../../shared/Typography/Heading/styled";
import { StyledTab, StyledTabs } from "../../../../../shared/Tabs/styled";
import AdSettings from "../AdSettings/AdSettings";
import Preview from "../Preview/Preview";
import { RecommendationTab } from "./RecommendationTab";
import { StyledPreviewAndAdSettings } from "./styled";
import { AdSettingsTypes } from "../Editor.types";
import { StyledDrawerDiv } from "../styled";

interface RecommendationsProps {
  textFields: AdSettingsTypes[];
  onAdSettingChange: (e: any, index: number) => void;
}

export default function Recommendations({
  textFields,
  onAdSettingChange,
}: RecommendationsProps) {
  const [value, setValue] = useState("preview");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    // will be needed future for handling changes of settings

    // if (newValue === "preview") {
    //   dispatch(
    //     generateAdPreview({
    //       creativeSpec: {
    //         ...createSpec,
    //         object_story_spec: {
    //           ...createSpec.object_story_spec,
    //           link_data: {
    //             ...createSpec["object_story_spec"]["link_data"],
    //             link: textFields[1].value,
    //             // call_to_action: {
    //             //   type: textFields[1].value,
    //             // },
    //           },
    //         },
    //       },
    //       adAccountId: "2112918278956259",
    //       recommendationSelectionState: {
    //         variantName: textFields[0].value,
    //       },
    //     })
    //   );
    // }
  };
  return (
    <StyledDrawerDiv>
      <Box
        width="58%"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <StyledHeading2>Recommendations</StyledHeading2>
        <RecommendationTab />
      </Box>
      <Divider orientation="vertical" className="h-100"></Divider>
      <Box
        width="42%"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <StyledPreviewAndAdSettings>
          <StyledTabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ children: <div /> }}
          >
            <StyledTab label="Preview" value="preview" />
            <StyledTab label="Ad Settings" value="ad-settings" />
          </StyledTabs>
          <Preview value={value} textFields={textFields} />
          <AdSettings
            value={value}
            textFields={textFields}
            onAdSettingChange={onAdSettingChange}
          />
        </StyledPreviewAndAdSettings>
      </Box>
    </StyledDrawerDiv>
  );
}

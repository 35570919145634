import {
  SELECTED_AIGROUP_OBJECT,
  SetAigroupList_CreativeAi,
  SetSelectedAdaccountAndPlatform_CreativeAi,
  SetSelectedAigroup,
  SetSelectedAigroupObject,
  SET_AIGROUP_LIST,
  SET_SELECTED_ADACCOUNT_AND_PLATFORM,
  SET_SELECTED_AIGROUP,
} from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariantType";

const initialState = {
  selectedAiGroupObject: {},
  selectedAiGroup: "",
  aiGroups: { loading: false, error: "", data: [] },
  aiGroupsWithToggle: { loading: false, error: "", data: [] },
  selectedAdAccount: { adAccountId: "", platformId: "" },
};

const aiGroupReducer = (
  state = initialState,
  action:
    | SetSelectedAigroupObject
    | SetAigroupList_CreativeAi
    | SetSelectedAdaccountAndPlatform_CreativeAi
    | SetSelectedAigroup
    | any
) => {
  switch (action.type) {
    case SET_AIGROUP_LIST: {
      return {
        ...state,
        aiGroups: action.payload,
      };
    }
    case SET_SELECTED_AIGROUP: {
      return {
        ...state,
        selectedAiGroup: action.payload,
      };
    }
    case "SET_SELECTED_AIGROUP_WITH_TOGGLE": {
      return {
        ...state,
        aiGroupsWithToggle: action.payload,
      };
    }
    case SELECTED_AIGROUP_OBJECT: {
      return {
        ...state,
        selectedAiGroupObject: action.payload,
      };
    }
    case SET_SELECTED_ADACCOUNT_AND_PLATFORM: {
      return {
        ...state,
        selectedAdAccount: action.payload,
      };
    }

    default:
      return state;
  }
};
export default aiGroupReducer;

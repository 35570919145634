import {
  GET_BUSINESS_UNIT_WITH_ID,
  SET_BUSINESS_UNIT_WITH_ID,
  SET_ELEMENT_CREATIVE_AI,
  GET_ELEMENT_CREATIVE_AI,
  ADD_ELEMEMT_CREATIVE_AI,
  SET_AI_GROUP_LIST,
  GET_AI_GROUP_LIST,
  NAVIGATE_VARIANT,
  GENERATE_LEVERS,
  SET_GENERATE_LEVERS,
  SET_ALL_BUSINESS_UNIT_LIST,
  GET_ALL_BUSINESS_UNIT_LIST
} from "../../../../types/CreativeAI/Facebook/Main/Main";

export const getBusinessUintById = (payload?: any) => {
  return {
    type: GET_BUSINESS_UNIT_WITH_ID,
    payload,
  };
};
export const setBusinessUintById = (payload?: any) => {
  return {
    type: SET_BUSINESS_UNIT_WITH_ID,
    payload,
  };
};

export const setElementCreativeAi = (payload?: any) => {
  return {
    type: SET_ELEMENT_CREATIVE_AI,
    payload,
  };
};
export const getElementCreativeAi = (payload?: any) => {
  return {
    type: GET_ELEMENT_CREATIVE_AI,
    payload,
  };
};
export const SetAiGroupList = (payload?: any) => {
  return {
    type: SET_AI_GROUP_LIST,
    payload,
  };
};
export const getAiGroupList = (payload?: any) => {
  return {
    type: GET_AI_GROUP_LIST,
    payload,
  };
};
export const addElementCReativeAi = (payload?: any) => {
  return {
    type: ADD_ELEMEMT_CREATIVE_AI,
    payload,
  };
};
export const navigateCreateVariant = (payload?: any) => {
  return {
    type: NAVIGATE_VARIANT,
    payload,
  };
};
export const generateLevers = () => {
  return {
    type: GENERATE_LEVERS,
  };
};
export const setLevers = (payload?: any) => {
  return {
    type: SET_GENERATE_LEVERS,
    payload,
  };
};
export const setAllBusinessUnitList = (payload?: any) => {
  return {
    type: SET_ALL_BUSINESS_UNIT_LIST,
    payload,
  };
};
export const getAllBusinessUnitList = (payload?: any) => {
  return {
    type: GET_ALL_BUSINESS_UNIT_LIST,
  };
};

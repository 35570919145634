export const GET_ADS_LIST = "GET_ADS_LIST";
export const GET_VARIANT_LIST = "GET_VARIANT_LIST";
export const SET_VARIANT_LIST = "SET_VARIANT_LIST";
export const GET_RECOMMENDATION_METADATA = "GET_RECOMMENDATION_METADATA";
export const SET_RECOMMENDATION_METADATA = "SET_RECOMMENDATION_METADATA";
export const UPDATE_RECOMMENDATION_METADATA = "UPDATE_RECOMMENDATION_METADATA";
export const ADD_VARIANT = "ADD_VARIANT";
export const ADD_VARIANT_BUTTON = "ADD_VARIANT_BUTTON";
export const SET_ADD_VARIANT_RESPONSE = "SET_ADD_VARIANT_RESPONSE";
export const GET_CREATE_SPEC = "GET_CREATE_SPEC";
export const SET_CREATE_SPEC = "SET_CREATE_SPEC";
export const SET_ADS = "SET_ADS";
export const SET__SELECTED_ADS = "SET__SELECTED_ADS";
export const GET_AD_PREVIEW_LINK = "GET_AD_PREVIEW_LINK";
export const SET_AD_PREVIEW_LINK = "SET_AD_PREVIEW_LINK";
export const DELETE_VARIANT_API_CALL = "DELETE_VARIANT_API_CALL";
export const DELETE_VARIANT_STATE_UPDATE = "DELETE_VARIANT_STATE_UPDATE";
export const UPDATE_EXTRA_FIELD = "UPDATE_EXTRA_FIELD";
export const UPDATE_VARIANT = "UPDATE_VARIANT";
export const UPDATE_VARIANT_STATE = "UPDATE_VARIANT_STATE";
export const ADD_UPDATE_VARIANT_LOADING_STATE =
  "ADD_UPDATE_VARIANT_LOADING_STATE";
export const UPDATE_RECOMMENDATION_SELECTION_STATE =
  "UPDATE_RECOMMENDATION_SELECTION_STATE";
export const GENERATE_AD_PREVIEW = "GENERATE_AD_PREVIEW";
export const COPY_VARIANT = "COPY_VARIANT";
export const COPY_VARIANT_STATE = "COPY_VARIANT_STATE";

export const UPDATE_ADS = "UPDATE_ADS";
export const UPDATE_TOTAL_VARIANT_SELECTED = "UPDATE_TOTAL_VARIANT_SELECTED";
export const DISCARD_DRAFT_VARIANT = "DISCARD_DRAFT_VARIANT";
export const PUBLISH_VARIANT = "PUBLISH_VARIANT";
export const PUBLISH_VARIANT_STATE = "PUBLISH_VARIANT_STATE";
export const GENERATE_VARIANT_METADATA = "GENERATE_VARIANT_METADATA";
export const RESET_STATE = "RESET_STATE";
export const UPDATE_CREATE_SPEC = "UPDATE_CREATE_SPEC";
export const DISCARD_DRAFT_VARIANT_STATE = "DISCARD_DRAFT_VARIANT_STATE";
export const SET_RECOMMENDATION_LOADING = "SET_RECOMMENDATION_LOADING";
export const GENERATE_VARIANT_METADATA_STATE =
  "GENERATE_VARIANT_METADATA_STATE";
export const IS_CONFIGURING_STATE = "IS_CONFIGURING_STATE";
export const Is_Show_SaveVariant_Prompt = "Is_Show_SaveVariant_Prompt";

export const UPDATE_AI_GROUP = "UPDATE_AI_GROUP";
export const UPDATE_AI_GROUP_STATE = "UPDATE_AI_GROUP_STATE";
export const SET_UPDATE_AI_GROUP_PROMPT = "SET_UPDATE_AI_GROUP_PROMPT";
export const GENERATE_VARIANT_METADATA_STATE_VARIANT =
  "GENERATE_VARIANT_METADATA_STATE_VARIANT";
export const SET_RECOMMENDATION_METADATA_ERROR =
  "SET_RECOMMENDATION_METADATA_ERROR";

export const SET_TIMESTAMP = "SET_TIMESTAMP";
export const UPDATE_CONFIGURE_STATE = "UPDATE_CONFIGURE_STATE";
export const GENERATE_AD_PREVIEW_VARIANT = "GENERATE_AD_PREVIEW_VARIANT";
export const GENERATE_AD_PREVIEW_VARIANT_STATE =
  "GENERATE_AD_PREVIEW_VARIANT_STATE";

export const SET_CHANGED_FIELD_LIST = "SET_CHANGED_FIELD_LIST";
export const GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE =
  "GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE";

export interface CreateVariantsState {
  adsList: any;
  variantList: VariantItem[];
  recommendationMetaData: any;
  recommendationMetaDataTemplate: any;
  addVariantResponse?: AddVariantResponse;
  createSpec?: any;
  totalSelectedVariant: string[];
  isPublishingVariant: boolean;
  isUpdatingVariant: boolean;
  isDeletingVariant: boolean;
  isDiscardingDraft: boolean;
  fetchingAds: boolean;
  isUpdateAds: boolean;
  isConfiguringMetaData: boolean;
  isGeneratingMetaData: boolean;
  loadingRecommendation: boolean;
  isShowSaveVariantPrompt: boolean;
  showUpdateAISuccessMsg: boolean;
  ads?: any;
  selectedAd?: any;
  adPreviewLink?: any;
  extraFields: {
    isVariantEditing?: boolean;
    selectedVariantIndex?: number;
    showEditor?: boolean;
  };
  addUpdateVariantLoading: boolean;
  recommendationSelectionState: {
    primary_text: string;
    headline: string;
    description: string;
    adPreviewLink: any;
    variantName: string;
    isChanged: boolean;
    isConfigureChanged: boolean;
  };
  generateMetaData: any;
}

export type ResetState = {
  type: typeof RESET_STATE;
  payload: any;
};

export type SetChangedFieldList = {
  type: typeof SET_CHANGED_FIELD_LIST;
  payload: any;
};

export type GetAdsListValues = {
  type: typeof GET_ADS_LIST;
  payload: any;
};
export type AddVarianButton = {
  type: typeof ADD_VARIANT_BUTTON;
  payload: any;
};

export type GetVariantList = {
  type: typeof GET_VARIANT_LIST;
  payload: {
    adId: string;
  };
};

export type SetVariantList = {
  type: typeof SET_VARIANT_LIST;
  payload: {
    data: VariantItem[];
  };
};

export type GetRecommendationMetadata = {
  type: typeof GET_RECOMMENDATION_METADATA;
  payload: any;
};

export type SetRecommendationMetadata = {
  type: typeof SET_RECOMMENDATION_METADATA;
  payload: {
    data: RecommendationMetaData;
  };
};
export type UpdateRecommendationMetadata = {
  type: typeof UPDATE_RECOMMENDATION_METADATA;
  payload: {
    data: RecommendationMetaData;
  };
};
export type AddVariant = {
  type: typeof ADD_VARIANT;
  payload: {
    user: any;
    params: AddVariantPayload;
    adAccountId: string;
    creativeSpec: any;
    finalAction: typeof SET_ADD_VARIANT_RESPONSE | typeof COPY_VARIANT;
  };
};

export type SetAddVariantResponse = {
  type: typeof SET_ADD_VARIANT_RESPONSE;
  payload: AddVariantResponse;
};

export type GetCreateSpec = {
  type: typeof GET_CREATE_SPEC;
  payload: any;
};
export type SetCreateSpec = {
  type: typeof SET_CREATE_SPEC;
  payload: any;
};

export type SetAds = {
  type: typeof SET_ADS;
  payload: any;
};

export type DiscardDraftVariantState = {
  type: typeof DISCARD_DRAFT_VARIANT_STATE;
  payload: any;
};

export type UpdateAds = {
  type: typeof UPDATE_ADS;
  payload: any;
};

export type SetSelectedAds = {
  type: typeof SET__SELECTED_ADS;
  payload: any;
};

export type GetAdPreviewLink = {
  type: typeof GET_AD_PREVIEW_LINK;
  payload: any;
};

export type SetAdPreviewLink = {
  type: typeof SET_AD_PREVIEW_LINK;
  payload: any;
};

export type DeleteVariantAPICall = {
  type: typeof DELETE_VARIANT_API_CALL;
  payload: any;
};

export type DeleteVariantStateUpdate = {
  type: typeof DELETE_VARIANT_STATE_UPDATE;
  payload: any;
};

export type UpdateExtraField = {
  type: typeof UPDATE_EXTRA_FIELD;
  payload: any;
};

export type UpdateVariant = {
  type: typeof UPDATE_VARIANT;
  payload: any;
};
export type CopyVariant = {
  type: typeof COPY_VARIANT;
  payload: any;
};

export type UpdateVariantState = {
  type: typeof UPDATE_VARIANT_STATE;
  payload: any;
};
export type AddUpdateVariantLoadingState = {
  type: typeof ADD_UPDATE_VARIANT_LOADING_STATE;
  payload: any;
};

export type UpdateRecommendationSelectionState = {
  type: typeof UPDATE_RECOMMENDATION_SELECTION_STATE;
  payload: any;
};

export type GenerateAdPreview = {
  type: typeof GENERATE_AD_PREVIEW;
  payload: any;
};
export type UpdateTotalVariantSelected = {
  type: typeof UPDATE_TOTAL_VARIANT_SELECTED;
  payload: any;
};
export type DiscardDraftVariant = {
  type: typeof DISCARD_DRAFT_VARIANT;
  payload: any;
};

export type PublishVariant = {
  type: typeof PUBLISH_VARIANT;
  payload: any;
};
export type PublishVariantState = {
  type: typeof PUBLISH_VARIANT_STATE;
  payload: any;
};
export type UpdateCreateSpec = {
  type: typeof UPDATE_CREATE_SPEC;
  payload: any;
};

// response types
export interface VariantItem {
  id: string;
  adId: string;
  creativeAiId: string;
  status: string;
  payload: any;
  createdAt: string;
  updatedAt: string;
  checked?: boolean;
  CreativeAiData: {
    id: string;
    primaryText: {
      name: string;
    };
    headline: {
      name: string;
    };
    description: {
      name: string;
    };
    createdAt: string;
    updatedAt: string;
  };
}
export interface RecommendationMetaData {
  headline: RecommendationMetaDataItem;
  description: RecommendationMetaDataItem;
  primary_text: RecommendationMetaDataItem;
}
export interface RecommendationMetaDataItem {
  data: Array<string>;
  selected: string;
  noOfElementToShow: number;
  showMore: boolean;
}

export interface AddVariantPayload {
  headline: { name: string };
  description: { name: string };
  primaryText: { name: string };
  adId: string;
  payload: Object;
}

export interface AddVariantResponse {
  id: string;
  status: string;
  adId: string;
  payload: Object;
  creativeAiId: string;
  updatedAt: string;
  createdAt: string;
}

export type GenerateVariantMatadata = {
  type: typeof GENERATE_VARIANT_METADATA;
  payload: any;
  callback: any;
};


import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ActionImpactHeader from "../../components/ActionImpactHeader/ActionImpactHeader";
import AllActionImpactTable from "../../components/AllActionImpactTable/AllActionImpactTable";
import AllPlatformGraph from "../../components/AllPlatformGraph/AllPlatformGraph";
import Loader from "../../components/common/Loader";
import { setAllPlatformLinkClicks } from "../../store/actions/actionImpact";
import { ActionImpactState } from "../../store/types/actionImpact";
import "./AllPlatform.scss";
import mixpanel from 'mixpanel-browser';
import { mixPanelAdEvent } from "../../utils/mixpanel";
import { AdAccountState } from "../../store/types/adaccount";

const AllPlatform: React.FC<unknown> = () => {
  const actionImpact = useSelector((state: { actionImpact: ActionImpactState }) => state.actionImpact);
  const { allPlatformLinkClicks, allPlatformGraphSpinner, pieSpinner } = actionImpact;
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedBrand } = adaccount;

  useEffect(() => {
    mixPanelAdEvent('Page View - Dashboard', {
      'module_name': "Action Impact Analysis - All platforms",
      'brand_name': selectedBrand?.name,
    });
  }, []);

  const platformTooltip = (props?: any) => (
    <div {...props} className="custom-tooltip-layout tooltip">
      <div className="tootltip-wrapper">
        <div className="tooltip-header">Cross Platform Spends Analysis</div>
        This shows AI-recommended actions to be taken on individual elements like ad sets and
        campaigns to optimize their performance.
      </div>
    </div>
  );


  
  return (
    <>
      <div className="performance_analysis" style={{ flexGrow: 1 }}>
        <ActionImpactHeader title="Cross Platform Spends Analysis" tooltip={platformTooltip}
          localStorageKey="all-platform-graph-date" linkClicks={allPlatformLinkClicks} setLinkClicks={setAllPlatformLinkClicks}/>
        {(allPlatformGraphSpinner || pieSpinner) && <div className="text-center"><Loader /></div>}
        <AllPlatformGraph/>
      </div>
      <AllActionImpactTable />
    </>
  );
};

export default AllPlatform;
import axios from 'axios';
import { refreshToken } from '../store/actions/auth';
import { commonAuth, user_session_key } from './constants';
import store from '../store/store';
import { getLocalStorageData } from './commonFunctions';

// axios instance for making requests 
const axiosInstance = axios.create();
let refreashCall: any = null;

const fetchToken = () => {
    if (!refreashCall) {
        refreashCall = new Promise(res => {
            store.dispatch(refreshToken((session?: any) => {
                if (session) {
                    res(session.accessToken);
                } else {
                    res(null);
                }
                refreashCall = null;
            }))
        })
        return refreashCall;
    }
    return refreashCall;
};


axiosInstance.interceptors.request.use(async (config) => {
    const session = getLocalStorageData(user_session_key);
    const tokenIsExpired = parseInt(session?.expiresAt || 0) < new Date().getTime();
    if (tokenIsExpired) {
        const token = await fetchToken();
        config.headers.auth = token;
        return config;
    } else {
        config.headers.auth = session.accessToken;
    }

    // config.headers.auth = commonAuth;
    return config;
});


export default axiosInstance;
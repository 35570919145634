import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  size?: number;
  fontWeight?: number;
}
interface SubHeadingProps {
  size?: number;
  fontWeight?: number;
  color?: String;
}

export const CrossIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
`;

export const MainTitle = styled.span<Props>`
  font-size: ${({ size }) => size}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: "Lato";
  font-style: normal;
  line-height: 24px;
  color: #1c1e21;
`;
export const SubHeading = styled.p<SubHeadingProps>`
  font-size: ${({ size }) => size}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  margin-bottom: 0px;
  font-family: "Lato";
  font-style: normal;
  line-height: 19px;
`;
export const DialogContainer = styled.div`
  .MuiDialog-root {
    background: red !important;
  }
  .MuiDialogTitle-root {
    padding: 10px 16px !important;
  }
`;

export const DialogTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-family: Inter;
  font-style: normal;
`;

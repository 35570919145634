import React from "react";
import useScrollSpy from "../hooks/useScrollspy";
import OptionsComponent from "./OptionsComponent";
import { StyledMainDiv, StyledTabPanelMainDiv } from "./styled";
import { HashType, ItemType } from "../Editor.types";
import { StyledTab, StyledTabs } from "../../../../../shared/Tabs/styled";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateVariantsState,
  RecommendationMetaData,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  generateAdPreview,
  updateRecommendationMetadata,
} from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  checkAdType,
  getCreativeSPecFieldName,
} from "../../../utils/common.utils";

const items: ItemType[] = [
  {
    hash: "primary_text",
    label: "Primary Text",
    titleText: "Select Primary Text:",
  },
  {
    hash: "headline",
    label: "Headline",
    titleText: "Select Headline:",
  },
  {
    hash: "description",
    label: "Description",
    titleText: "Select Description:",
  },
];

export const RecommendationTab = (props: any) => {
  const { activeState, handleClick } = useScrollSpy({ items });

  const { recommendationMetaData, recommendationSelectionState, createSpec } =
    useSelector(
      (state: { createVariantReducer: CreateVariantsState }) =>
        state.createVariantReducer
    );
  const dispatch = useDispatch();

  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);

  const tabs = items.map(({ label, hash }) => (
    <StyledTab
      key={hash}
      value={hash}
      label={label}
      onClick={handleClick(hash)}
    />
  ));

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: HashType
  ) => {
    const value = (event.target as HTMLInputElement).value;
    dispatch(
      generateAdPreview({
        creativeSpec: {
          ...createSpec,
          object_story_spec: {
            ...createSpec.object_story_spec,
            [checkAdType(createSpec)]: {
              ...createSpec["object_story_spec"][checkAdType(createSpec)],
              [getCreativeSPecFieldName({
                type: checkAdType(createSpec),
                field: "primary_text",
              })]: recommendationSelectionState.primary_text,
              [getCreativeSPecFieldName({
                type: checkAdType(createSpec),
                field: "headline",
              })]: recommendationSelectionState.headline,
              [getCreativeSPecFieldName({
                type: checkAdType(createSpec),
                field: "description",
              })]: recommendationSelectionState.description,
              [getCreativeSPecFieldName({
                type: checkAdType(createSpec),
                field: type,
              })]: value,
            },
          },
        },
        adAccountId: adAccountId,
        recommendationSelectionState: {
          [type]: value,
          isChanged: true,
        },
      })
    );
  };

  const showMoreData = (hash: HashType) => {
    const localRecommendationData: RecommendationMetaData = {
      ...recommendationMetaData,
      isEditing: true,
    };
    if (
      localRecommendationData[hash].noOfElementToShow + 4 <
      localRecommendationData[hash].data.length
    ) {
      document
        .getElementById(
          hash + (localRecommendationData[hash].noOfElementToShow - 1)
        )!
        .scrollIntoView({
          behavior: "smooth",
        });
      localRecommendationData[hash].noOfElementToShow += 4;
    } else {
      document
        .getElementById(
          hash + (localRecommendationData[hash].noOfElementToShow - 1)
        )!
        .scrollIntoView({
          behavior: "smooth",
        });
      localRecommendationData[hash].noOfElementToShow =
        localRecommendationData[hash].data.length;
      localRecommendationData[hash].showMore = false;
    }
    dispatch(
      updateRecommendationMetadata({
        data: localRecommendationData,
      })
    );
  };

  return (
    <StyledMainDiv>
      <StyledTabs
        value={activeState ? activeState : items[0].hash}
        TabIndicatorProps={{ children: <div /> }}
      >
        {tabs}
      </StyledTabs>
      <StyledTabPanelMainDiv id="panel">
        {items.map((item) => (
          <OptionsComponent
            value={recommendationSelectionState[item.hash]}
            options={
              recommendationMetaData[item.hash] ?? {
                data: [],
                selected: "",
                noOfElementToShow: 0,
                showMore: false,
              }
            }
            handleChange={handleChange}
            titleText={item.titleText}
            hash={item.hash}
            key={item.hash}
            showMoreData={showMoreData}
          />
        ))}
      </StyledTabPanelMainDiv>
    </StyledMainDiv>
  );
};

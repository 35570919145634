import React, { useCallback, useEffect, useMemo } from "react";
import "./index.scss";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export type MultiSelectedWithIconLabel = {
  label: string;
};

const MultiSelectWithIcon: React.FC<{
  labels: MultiSelectedWithIconLabel[];
  handelOnChange: (value: any, action: string) => void;
  onActiveInterests: any;
}> = ({ labels, handelOnChange, onActiveInterests }) => {
  const [items, setItems] = React.useState<any>([]);

  const handleChange = useCallback(
    (value: string) => {
      let arr = items;
      if (arr.filter((temp: string) => temp == value).length <= 0) {
        arr.push(value);
        setItems(arr);
        handelOnChange(value, "add");
      } else if (arr.filter((data: any) => data == value)) {
        const temp = arr.filter((data: string) => data != value);
        arr = temp;
        handelOnChange(value, "remove");
        setItems(arr);
      }
    },
    [items, handelOnChange]
  );

  return (
    <div className="multi-select-with-icon-container">
      <Row>
        {labels &&
          labels?.length !== 0 &&
          labels.map((item, index) => {
            return (
              <Col
                lg={3}
                className="mb-3"
                onClick={() => handleChange(item?.label)}
                key={index}
              >
                <div
                  className={
                    onActiveInterests?.find(
                      (data: string) => data == item?.label
                    )
                      ? "multi-select-with-icon-active"
                      : "multi-select-with-icon-normal"
                  }
                >
                  <p className="mb-0 multi-select-with-icon-title">
                    {item?.label}
                  </p>

                  <span
                    className={
                      onActiveInterests?.find(
                        (data: string) => data == item?.label
                      )
                        ? "multi-select-circle-check"
                        : "multi-select-circle"
                    }
                  >
                    {onActiveInterests?.find(
                      (data: string) => data == item?.label
                    ) && <FontAwesomeIcon icon={faCheck} className="check" />}
                  </span>
                </div>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default MultiSelectWithIcon;

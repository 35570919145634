import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTableCell,
  StyledTableContainer,
  StyledColoredTableCell,
} from "./styled";

interface HeadProps {
  name: string;
  align: string;
  subHeading?: string;
}

const CustomizedTable: React.FC<{
  tableHeadValues: HeadProps[];
  children: React.ReactNode;
  tableWidth?: any;
  tableType?: "evenColoredTable" | "plainTable";
  tableHeadCellStyle?: any;
  subHeadingStyle?: any;
  extraHeadAlignStyle?: any;
}> = ({
  tableHeadValues,
  children,
  tableWidth,
  tableType,
  tableHeadCellStyle,
  subHeadingStyle,
  extraHeadAlignStyle,
}) => {
  return (
    <>
      <StyledTableContainer>
        <Table sx={{ minWidth: tableWidth }} aria-label="customized table">
          <TableHead>
            <TableRow >
              {tableHeadValues?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    {tableType === "evenColoredTable" ? (
                      <StyledTableCell
                        align={item?.align}
                        key={index}
                        style={tableHeadCellStyle}
                        
                      >
                        {item?.name}
                        {/* <div>{item?.subHeading}</div> */}
                        {item?.subHeading ? (
                          <div style={subHeadingStyle}>{item?.subHeading}</div>
                        ) : (
                          <div style={extraHeadAlignStyle} />
                        )}
                      </StyledTableCell>
                    ) : tableType === "plainTable" ? (
                      <StyledColoredTableCell
                        align={item?.align}
                        key={index}
                        style={tableHeadCellStyle}
                      >
                        {item?.name}
                      </StyledColoredTableCell>
                    ) : null}
                  </Fragment>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export default CustomizedTable;

import { all } from "redux-saga/effects";
import { clusterWatcher } from "./cluster";
import { commonWatcher } from "./common";
import { actionImpactWatcher } from "./actionImpact";
import { formWatcher } from "./form";
import { campaignWatcher } from "./campaign";
import { authWatcher } from "./auth";
import { adAccountWatcher } from "./adaccount";
import { creativeAIWatcher } from "./creativeAi";
import { MontageWatcher } from "./montageAds";
import { createVariantWatcher } from "./CreativeAI/Facebook/CreateVariant/createVariant";
import { selectCohortWatcher } from "./CreativeAI/Facebook/Select_Cohort/selectCohort";
import { MainWatcher } from "./CreativeAI/Facebook/Main/Main";

export function* rootSaga() {
  yield all([
    commonWatcher(),
    actionImpactWatcher(),
    formWatcher(),
    clusterWatcher(),
    campaignWatcher(),
    authWatcher(),
    adAccountWatcher(),
    creativeAIWatcher(),
    MontageWatcher(),
    createVariantWatcher(),
    selectCohortWatcher(),
    MainWatcher(),
  ]);
}

//*******DO NOT REFER THIS FILE FOR SAGA IMPLEMENTATION*******//

export const SET_USER = "SET_USER";
export const SET_PHASE = "SET_PHASE";
export const SET_ACTIVE_PHASE = "SET_ACTIVE_PHASE";
export const SET_ACCOUNT_ID = "SET_ACCOUNT_ID";
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const SET_LEARNING_PHASE = "SET_LEARNING_PHASE";
export const SET_ALLOW_REJECT_RECOMMENDATION = "SET_ALLOW_REJECT_RECOMMENDATION";
export const FETCH_PHASE = "FETCH_PHASE";
export const FETCH_LEARNING_PHASE = "FETCH_LEARNING_PHASE";
export const FETCH_ALLOW_REJECT_RECOMMENDATION = "FETCH_ALLOW_REJECT_RECOMMENDATION";
export const FETCH_ACCOUNT_DETAILS = "FETCH_ACCOUNT_DETAILS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_SESSION = "SET_SESSION";
export const SET_IS_REFRESH_TOKEN_EXPIRED = "SET_IS_REFRESH_TOKEN_EXPIRED";

export type initialState = {
    user: any;
    phase: any;
    activePhase: unknown;
    accountIdVal: string;
    learningPhase: any;
    allowRejectRecommendation: boolean;
    accountDetails: any;
    session: Session | null;
    isRefreshTokenExpired: boolean;
}

export type Session = {
    accessToken: string;
    expiresAt: string;
    refreshToken: string;
}

export type SetUser = {
    type: typeof SET_USER,
    payload: any;
}

export type SetPhase = {
    type: typeof SET_PHASE,
    payload: any;
}

export type SetActivePhase = {
    type: typeof SET_ACTIVE_PHASE,
    payload: number;
}

export type SetAccountDetails = {
    type: typeof SET_ACCOUNT_DETAILS,
    payload: any;
}

export type SetLearningPhase = {
    type: typeof SET_LEARNING_PHASE,
    payload: any
}

export type SetAllowRejectRecommendation = {
    type: typeof SET_ALLOW_REJECT_RECOMMENDATION,
    payload: boolean
}

export type SetAccountId = {
    type: typeof SET_ACCOUNT_ID,
    payload: string
}

export type FetchPhase = {
    type: typeof FETCH_PHASE,
    payload: {
        accountId: string;
    }
}

export type RefreashToken = {
    type: typeof REFRESH_TOKEN,
    callback?: (token?: Session) => void;
}

export type SetSession = {
    type: typeof SET_SESSION,
    payload: Session
}

export type SetIsRefreshTokenExpired = {
    type: typeof SET_IS_REFRESH_TOKEN_EXPIRED,
    payload: boolean;
}
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { CheckBox, SelectFormControl, InputFormControl } from "../../../shared";
import CustomizedButton from "../../../shared/Button/CustomizedButton";
import CustomizedDialogs from "../../../shared/Modal/CustomizedDialogs";
import { Link, useHistory } from "react-router-dom";
import SelectableTable from "../../../shared/Table/SelectableTable";
import { selectableTableHead } from "../../../../utils/constants/creativeAI";
import {
  StyledCheckboxItem,
  StyledPlaceholderContainer,
  StyledCreateVariantModalContainer,
  StyledRestoredDefault,
  StyledCVButton,
  StyledButtonFooter,
  StyledCreateVariantSettingTxt,
  StyleAiGroupDuplicatePara,
} from "./styled";
import BusinessUnitPlaceHolder from "../../../../assets/svg/businessUnitPlaceholder.svg";
import { APIGenerationType } from "../shared/SharedTypes";
import { useForm } from "react-hook-form";
import FormLevers from "../shared/FormLevers/FormLevers";
import {
  expandData,
  getBUFormValues,
  getFormValues,
  mergeData,
} from "../utils/form.utils";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessUintById } from "../../../../store/actions/CreativeAI/Facebook/Main/Main";
import { initialState } from "../../../../store/types/auth";
import { cloneDeep } from "lodash";
import Loader from "../../../common/Loader";
import { AlertConfirm } from "../../../shared/Modal/AlertConfiem";
import TooltipWithInfo from "../shared/TooltipWithInfo/TooltipWithInfo";
import { filterLevers } from "../utils/filterLevers.utils";
import { styled } from "@mui/material/styles";
import { AdAccountState } from "../../../../store/types/adaccount";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // // hide last border
  "&:first-child td, &:first-child th": {
    // border: "none",
  },
  border: "1px solid #DDDDDD",
  borderTop: "none",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #E5E5E5",
  fontWeight: 400,
  fontSize: "14px",
  fontFamily: "Inter",
  color: "#000000",
  lineHeight: "17px",
  fontStyle: "normal",
}));

const CreateVariantModal: React.FC<{
  onHide: () => void;
  selectedAiGroup: string;
  variantModalType?: string;
  setShowUniteForm?: any;
}> = ({ onHide, selectedAiGroup, variantModalType, setShowUniteForm }) => {
  const {
    LEVERS,
    ALL_AI_GROUPS,
    BUSINESS_UNIT_List,
    BUSINESS_UNIT_BY_ID_DATA,
    CREATIVE_ELEMENT_AI,
    NAVIGATE_VARIANT,
  } = useSelector((state: any) => ({
    LEVERS: state.MainReducer.defaultLevers,
    ALL_AI_GROUPS: state.aiGroupReducer.aiGroups.data,
    BUSINESS_UNIT_List: state.MainReducer.AllBusinessUnitList,
    BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
    CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
    NAVIGATE_VARIANT: state.MainReducer.navigateVariant,
  }));

  const auth = useSelector((state: { auth: initialState }) => state.auth);

  const [mandatoryCheck, setMandatoryCheck] = useState(
    CREATIVE_ELEMENT_AI.data === null ? false : true
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  const [AiGroupList, setAiGroupList] = useState([]);
  const [masterCheck, setMasterCheck] = useState(false);
  const [businessUnitNameList, setBusinessUnitNameList] = useState<any>([]);
  const [showAIGroupUi, setShowUiGroupAi] = useState(false);
  const [show, setShow] = useState(false);
  const [showFields, setShowFields] = useState(true);
  const [alertConfirmEvent, setAlertConfirmEvent] = useState(false);

  const handleMasterCheckbox = (e: any) => {
    let tempList = AiGroupList;
    tempList.map((user: any) => (user.isChecked = !masterCheck));
    setMasterCheck(!masterCheck);
    setAiGroupList(tempList);
  };

  const handleSingleCheckbox = (e: any, item: any) => {
    let tempList: any = AiGroupList;
    tempList = tempList.map((user: any) => {
      if (user.id === item.id) {
        user.isChecked = e.target.checked;
      }
      return user;
    });

    const totalItems = AiGroupList.length;
    const totalCheckedItems = tempList.filter((e: any) => e.isChecked).length;
    setMasterCheck(totalItems === totalCheckedItems);
    setAiGroupList(tempList);
  };

  const data: APIGenerationType[] = useMemo(
    () => filterLevers(LEVERS, ["user"]),
    [LEVERS]
  );

  const {
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm();

  useEffect(() => {
    watch();
    dispatch({
      type: "GET_ALL_BUSINESS_UNIT_LIST",
      payload: {
        brandId: selectedBrand.id,
      },
    });

    CREATIVE_ELEMENT_AI.data == null
      ? setMandatoryCheck(false)
      : setMandatoryCheck(true);
  }, []);

  const headlineDescriptionLevers = useMemo(() => {
    return mergeData(cloneDeep(data));
  }, []);

  const generationLevers = useMemo(() => {
    return mergeData(cloneDeep(LEVERS));
  }, []);

  const onGenerate = (data: any) => {
    const { headlineDescription, ...payload } = data;

    const aiGroupIds: any = [selectedAiGroup];
    AiGroupList?.filter((item: any) => {
      if (item?.isChecked) {
        aiGroupIds.push(item?.elementId);
      }
    });
    payload["elementId"] = aiGroupIds;
    const finalData = {
      brandName: payload.brandName,
      interestKeyword: payload.interestKeyword,
      businessUnitId: payload.businessUnitId,
      elementId: payload.elementId,
      primaryText: payload.primaryText,
    };
    dispatch({
      type: "ADD_ELEMEMT_CREATIVE_AI",
      payload: {
        user: auth.user,
        elementData: {
          ...finalData,
          ...expandData(headlineDescription, generationLevers),
        },
      },
    });
    if (variantModalType === "Setting") {
      setShowUniteForm(false);
    }
  };

  const businessUnitID = watch("businessUnitId");

  useEffect(() => {
    if (businessUnitID) {
      setShowFields(false);

      dispatch(
        getBusinessUintById({
          businessUnitId: businessUnitID,
        })
      );

      setMandatoryCheck(true);
    }
  }, [businessUnitID]);

  useEffect(() => {
    if (variantModalType) {
      if (BUSINESS_UNIT_BY_ID_DATA) {
        if (CREATIVE_ELEMENT_AI?.data?.BusniessUnit?.id !== businessUnitID) {
          reset(getBUFormValues(BUSINESS_UNIT_BY_ID_DATA));
        } else {
          reset(getFormValues(CREATIVE_ELEMENT_AI.data));
        }
        watch();
        setShowFields(true);
      }
    }
  }, [BUSINESS_UNIT_BY_ID_DATA, CREATIVE_ELEMENT_AI?.data]);

  useEffect(() => {
    const myArr = ALL_AI_GROUPS.map((item: any) => {
      return { ...item, isChecked: false };
    });
    setAiGroupList(myArr);
  }, [ALL_AI_GROUPS.length !== 0]);

  useEffect(() => {
    const temp: any = [];
    BUSINESS_UNIT_List?.map((item: any) => {
      if (item.status === "readyToUse") {
        temp.push({ value: item?.id, name: item?.businessUnit });
      }
    });
    setBusinessUnitNameList(temp);
  }, [BUSINESS_UNIT_List.length !== 0]);

  useEffect(() => {
    if (NAVIGATE_VARIANT == true) {
      if (variantModalType === "createVariant") {
        history.push(`/creative-ai/variants/cohort${history.location.search}`);
        setShow(false);
      } else {
        setShow(false);
      }
    }
  }, [NAVIGATE_VARIANT]);

  const toggleAiGroupAi = () => {
    setShowUiGroupAi(!showAIGroupUi);
  };

  useEffect(() => {
    dispatch({ type: "NAVIGATE_VARIANT", payload: false });
  }, []);

  useEffect(() => {
    if (!CREATIVE_ELEMENT_AI.loading) {
      if (CREATIVE_ELEMENT_AI.data) {
        if (variantModalType === "createVariant") {
          history.push(
            `/creative-ai/variants/cohort${history.location.search}`
          );
        } else {
          reset(getFormValues(CREATIVE_ELEMENT_AI.data));
          watch();
          setShow(true);
        }
      } else {
        setShow(true);
      }
    }
  }, [CREATIVE_ELEMENT_AI]);

  const restoreDefaultSetting = () => {
    setShowFields(false);
    if (BUSINESS_UNIT_BY_ID_DATA) {
      setTimeout(() => {
        reset(getBUFormValues(BUSINESS_UNIT_BY_ID_DATA));
        watch();
        setShowFields(true);
      }, 0);
    }
  };

  const handelAlertConfirm = (event: any) => {
    setAlertConfirmEvent(event);
  };

  return (
    <CustomizedDialogs
      type="modal"
      title="Creative AI Setup for AI Group"
      maxWidth="lg"
      subTitle="Performance (USA)"
      show={show}
      handleClose={() => {
        setValue("brandName", "");
        setValue("interestKeyword", "");
        setValue("businessUnitId", "");
        onHide();
      }}
      titleSize={20}
      subTitleSize={16}
      buttons={
        <StyledButtonFooter
          content={
            !showFields || !businessUnitID ? "flex-end" : "space-between"
          }
        >
          {showFields && businessUnitID && (
            // <StyledRestoredDefault
            //   to="#"
            //   onClick={() => {
            //     restoreDefaultSetting();
            //   }}
            // >

            // </StyledRestoredDefault>
            <StyledRestoredDefault
              onClick={() => {
                restoreDefaultSetting();
              }}
            >
              Restore BU Default Settings
            </StyledRestoredDefault>
          )}

          <StyledCVButton>
            <CustomizedButton
              buttonText={
                variantModalType === "Setting" ? "Save" : "Get Started"
              }
              buttonClass="primary save-btn"
              onClick={handleSubmit(onGenerate)}
              variant="contained"
              style={
                !showFields || !businessUnitID
                  ? { backgroundColor: "#B5D2FE", color: "#ffffff" }
                  : {}
              }
              disabled={!showFields || !businessUnitID}
            />
          </StyledCVButton>
        </StyledButtonFooter>
      }
    >
      <div id="businessForm">
        <StyledCreateVariantModalContainer>
          <StyledCreateVariantSettingTxt>
            Creative variants recommendation will depend on these settings:
          </StyledCreateVariantSettingTxt>
          {BUSINESS_UNIT_List.length !== 0 && (
            <SelectFormControl
              options={businessUnitNameList}
              field="name"
              valueKey="value"
              tooltipText="Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit."
              placeholder="Select Business Unit"
              required
              fullWidth
              control={control}
              registeredName="businessUnitId"
              label="Business Unit"
              height="40px"
              sx={{
                fontSize: "12px",
              }}
              isBorder
              backgroundColor="#fffffff"
            />
          )}

          {showFields ? (
            <>
              {mandatoryCheck ? (
                <>
                  <InputFormControl
                    label="Display Brand Name"
                    tooltipText="Display brand name refers to how you want the brand name to be displayed in the ad copy."
                    disableUnderline
                    control={control}
                    registeredName="brandName"
                    required
                    fullWidth
                    placeholder="Enter Brand Name"
                    sx={{ height: "40px", fontSize: "12px" }}
                    isBorder
                    backgroundColor="#fffffff"
                  />
                  <InputFormControl
                    label="Interest Keyword"
                    tooltipText="Interest keyword refers to the crux of the ad generations. It indicates what the ad should deliver based on the product/service category and what the ad generations should be based on."
                    disableUnderline
                    control={control}
                    registeredName="interestKeyword"
                    required
                    fullWidth
                    placeholder="Enter Interest Keyword"
                    sx={{ height: "40px", fontSize: "12px" }}
                    isBorder
                    backgroundColor="#fffffff"
                  />

                  <div className="d-flex justify-content-between align-item-center">
                    {/* <Link
                      to="#"
                      onClick={() => {
                        restoreDefaultSetting();
                      }}
                    >
                      Restore BU Default Settings
                    </Link> */}
                  </div>
                  <FormLevers
                    data={data}
                    headlineDescriptionLevers={headlineDescriptionLevers}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    errors={cloneDeep(errors)}
                    setError={setError}
                  ></FormLevers>
                  <Stack
                    marginTop={2}
                    direction="row"
                    display="flex"
                    alignItems="center"
                  >
                    <Box>
                      <CheckBox
                        checked={showAIGroupUi}
                        onClick={() => toggleAiGroupAi()}
                      />
                    </Box>
                    <Box>
                      <StyleAiGroupDuplicatePara>
                        Select AI Groups to duplicate this setup{" "}
                        <TooltipWithInfo
                          top={!showAIGroupUi ? "-90px" : ""}
                          info="All the settings will be duplicated for the selected AI Groups. You can still edit them individually by clicking on the setup icon against each AI Group."
                        />
                      </StyleAiGroupDuplicatePara>
                    </Box>
                  </Stack>
                  {showAIGroupUi && (
                    <div className="">
                      <SelectableTable
                        tableHead={selectableTableHead}
                        checkboxVisible={true}
                        masterCheck={masterCheck}
                        handleMasterCheckbox={handleMasterCheckbox}
                      >
                        {AiGroupList &&
                          AiGroupList.length !== 0 &&
                          AiGroupList.filter(
                            (item: any) => item?.elementId !== selectedAiGroup
                          ).map((item: any) => {
                            return (
                              <Fragment key={item?.id}>
                                <StyledTableRow key={item?.id}>
                                  <StyledTableCell>
                                    <StyledCheckboxItem
                                      onChange={(e) =>
                                        handleSingleCheckbox(e, item)
                                      }
                                      checked={item?.isChecked}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item?.name ?? "-"}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item?.totalAdsets ?? "-"}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </Fragment>
                            );
                          })}
                      </SelectableTable>
                    </div>
                  )}
                </>
              ) : (
                <StyledPlaceholderContainer>
                  <img src={BusinessUnitPlaceHolder} alt="" />
                  <Typography fontWeight={900} variant="body1">
                    {BUSINESS_UNIT_List.length !== 0
                      ? "Select a business unit to view all options"
                      : "Please contact support to add a business unit"}
                  </Typography>
                </StyledPlaceholderContainer>
              )}
            </>
          ) : (
            <Loader />
          )}
        </StyledCreateVariantModalContainer>
      </div>
      {alertConfirmEvent && (
        <AlertConfirm
          bodyText="Failed please try again"
          dialogType="alert" //"confirm" || "alert" || dialog
          headerText="Failed"
          completeButtonText="Ok"
          onHandel={handelAlertConfirm}
        />
      )}
    </CustomizedDialogs>
  );
};

export default CreateVariantModal;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import info from "../../assets/svg/smallinfo.svg";
import { publishClusterData, setClusterTable, setTargetKeyword, getPublishedClusterDetails, setClusterDetails, setSelectedTabIndex, setSaveCluster, setCreativeReplacement } from "../../store/actions/cluster";
import { setModal } from "../../store/actions/form";
import { ClusterState } from "../../store/types/cluster";
import { FormState } from "../../store/types/form";
import { initialState } from "../../store/types/auth";
import ButtonUI from "../../ui/ButtonUI/ButtonUI";
import CheckBox from "../../ui/CheckBox/CheckBox";
import Table from "../../ui/Table/Table";
import TableElement from "../../ui/Table/TableElement";
import ViewMore from "../common/ViewMore/ViewMore";
import CreateAdDrawer from "../CreateAd/CreateAdDrawer";
import ModalContainer from "../Modal/ModalContainer";
import ModalWrapper from "../Modal/ModalWrapper";
import StepwiseFormContainer from "../StepwiseForm/StepwiseFormContainer";
import StepwiseFormWrapper from "../StepwiseForm/StepwiseFormWrapper";
import CreateAdGroup from "./CreateAdGroup/CreateAdGroup";
import "./index.scss";
import { setLocalStorageData } from "../../utils/commonFunctions";
import { AdAccountState } from "../../store/types/adaccount";
import CreateAdGroupDropDown from "./CreateAdGroup/CreateAdGroupDropDown";
import { mixPanelAdEvent } from "../../utils/mixpanel";

const headers = ["Cluster", "Key Words", "Ad group", "Reference ad group", "AI Group", "Ad"];
const CreateAdAndPublish: React.FC = (): JSX.Element => {
  const target = useRef(null);
  const auth = useSelector((state: { auth:initialState }) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [currentCluster, setCurrentCluster] = useState<any>({});
  const [currentClusterIndex, setCurrentClusterIndex] = useState<number>(0);
  const [selectedClusters,setSelectedClusters] = React.useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);
  const [dontAskAgain, setDontAskAgain] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<string[]>([]);
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { modal } = formState;
  const clusterState = useSelector((state: { cluster: ClusterState }) => state.cluster);
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedBrand, selectedPlatform, selectedGSAIAdaccount, selectedAdAccount } = adaccount;
  const { clusterTable, targetKeyword, editCluster, clusterDetails } = clusterState;

  const handleModalSubmit = () => {};
  const resetCreativeReplacement = () => {
    const cr = {
      creative_replacement:false,
      headline: [
        {
          selected: false,
          label: 'Best Performance',
          performance: "BEST",
          interval: ""
        },
        {
          selected: false,
          label: 'Good Performance',
          performance: "GOOD",
          interval: ""
        },
        {
          selected: false,
          label: 'Low Performance',
          performance: "LOW",
          interval: ""
        }
      ],
      description: [
        {
          selected: false,
          label: 'Best Performance',
          performance: "BEST",
          interval: ""
        },
        {
          selected: false,
          label: 'Good Performance',
          performance: "GOOD",
          interval: ""
        },
        {
          selected: false,
          label: 'Low Performance',
          performance: "LOW",
          interval: ""
        }
      ]
    }
    dispatch(setCreativeReplacement(cr));
  }

  const handleDeleteAd = () => {
    const clusterData: any[] = [...clusterTable!];
    delete clusterData[modal.props.index].ad;
    dispatch(setSaveCluster({ext_id:null}));//remove ad saved flag
    resetCreativeReplacement();
    dispatch(setClusterTable(clusterData));
    dispatch(setModal(null));
  };



  const checkAdsCreated = (clusterSelected:any) => {
    const saveAdError:any = [];
    clusterSelected.map((tableData:any) => {
      if (!tableData.ad) {
        saveAdError.push("Ad should be created for the cluster "+ tableData.cluster_label +" to publish.");
      }
      if (!tableData.ref_ad_id) {
        saveAdError.push("Create new Ad group for the cluster "+ tableData.cluster_label +" to publish.");
      }
    });
    if (saveAdError.length > 0) {
      setShowError(saveAdError);
      return false;
    } else {
      return true;
    }
    
  }

  const handlePublishCluster = (setLoading:any) => {
    setLoading(true);
    if ((selectedClusters.length > 0 || isAllSelected) && clusterDetails.group_id) {
      let clusterSelected = clusterTable.filter((_: any,index:number) => (selectedClusters.includes(index)||isAllSelected));
      if (checkAdsCreated(clusterSelected)) {
        clusterSelected = clusterSelected.map((cluster:any,index:number) => {
          cluster.elementId = clusterDetails.group_id;
          cluster.ad_account_id = selectedGSAIAdaccount.id;
          const headlines:any = []
          const descriptions:any = []
          try {
            cluster.ad.responsiveSearchAd.descriptions.map((desc: any) => {
              desc.isPublished = Boolean(desc.isLocked)
              delete desc.isLocked
              descriptions.push(desc)
            })
            cluster.ad.responsiveSearchAd.headlines.map((headline: any) => {
              headline.isPublished = Boolean(headline.isLocked)
              delete headline.isLocked
              headlines.push(headline)
            })
            cluster.ad.responsiveSearchAd.headlines = headlines
            cluster.ad.responsiveSearchAd.descriptions = descriptions
          } catch(error) {
            console.warn(error)
          }
          return cluster; 
        });
        
        dispatch(publishClusterData(
          {accountID: selectedGSAIAdaccount?.id,params:clusterSelected,user: auth?.user},
          (response: any, error: boolean) => {
            if (response && !error) {
              mixPanelAdEvent('Create_publish_ad_screen_publish_click_gsai', {
                'cluster_names': clusterSelected?.map((cluster:any)=> cluster.cluster_label),
                'ai_groups': clusterSelected?.map((cluster:any)=> cluster.ai_group),
                'ad_groups': clusterSelected?.map((cluster:any)=> cluster.ad_group.update_name),
                'reference_ad_groups': clusterSelected?.map((cluster:any)=> cluster.ref_ad_group.name),
              });
              setSelectedClusters([])
              dispatch(setClusterTable([]));
              dispatch(setModal(null));
              setLoading(false);
              setLocalStorageData('selectedTabIndex',1);//save active tab index in local storage
              dispatch(setSelectedTabIndex(1));//set active tab to 'Cluster manager'
              history.push(`/googlesearchai${history.location.search}`);
            }
          }));
          dispatch(setClusterDetails({url:null,group_id:null}));
       }
    }
  };

  const tooltip = (props: any) => (
    <div className="tooltip-container">
      <i ref={target}>
        <img className="ml-2" src={info} alt="info_style" />
      </i>
      <div className="custom-tooltip-layout tooltip">
        <div className="tootltip-wrapper">
          <div className="tooltip-header">{props.title}</div>
          <span>{props.subtitle}</span>
        </div>
      </div>
    </div>
  );

  const selectAdGroup = (eKey: any, cluster: any, index: number) => {
    setCurrentCluster(cluster);
    setCurrentClusterIndex(index);
    if (eKey === "existingAdGroup") {
      dispatch(setModal({
        key: eKey,
        title: `Add to existing ad group : ${cluster.cluster_label}`,
      }));
    } else if (eKey === "updateAdGroup") {
      dispatch(setModal({
        key: eKey,
        title: `Update ad group : ${cluster.cluster_label}`,
      }));
    } else {
      dispatch(setModal({
        key: eKey,
        title: `Create New Ad Group : ${cluster.cluster_label}`,
      }));
    }
  };

  const handleClose = useCallback(() => {
    dispatch(setModal({ key: "closeCluster", title: "Close cluster creator" }));
  },[]);

  const handleClusterSelect = useCallback((index:number) => {
    
    if(selectedClusters.includes(index)){
      setSelectedClusters((clusters:any[]) => clusters.filter((v) => v !== index));
      setIsAllSelected(false);
    } else {
      let selected = [...selectedClusters,index];
      if(selected.length == clusterTable.length){
        setIsAllSelected(true);
      }
      setSelectedClusters(selected);
    }
  }, [selectedClusters]);

  useEffect(() => {
    if(editCluster.ext_id){
      //console.log(clusterTable);
      setOpen(true);
      // clusterTable.map((cluster:any,index:number)=>{
      //   setOpen(true);
      // dispatch(setTargetKeyword({
      //   ...targetKeyword,
      //   title: cluster?.clusterLabel,
      //   activeIndex: index,
      //   keywords: cluster?.clusterKeywords?.join(" , "),
      // }));
      // });
    }
  },[editCluster]);
  
  useEffect(() => {
    return () => {
      dispatch(setClusterTable([]));
    }
  }, [])

  return (
    <>
      <StepwiseFormWrapper>
        <StepwiseFormContainer
          initialActive={2}
          disabled={(!isAllSelected) && (selectedClusters.length === 0)}
          steps={["Keyword Clustering", "Create Ad & Publish"]}
          handleSubmit={() => {
            let clusterSelected:any = [];
            if (selectedClusters.length > 0) {
              clusterSelected = clusterTable.filter((_: any,index: number) => (selectedClusters.includes(index)||isAllSelected));
            } else if (isAllSelected) {
              clusterSelected = clusterTable;
            }
            if (checkAdsCreated(clusterSelected)) {
              dispatch(setModal({ key: "publishAdGroup", title: "Publish Ad Group" }));
            }
          }}
          handleClose={handleClose}
        >
          <div
            className="d-flex flex-column p-3 bg-white message-container flex-grow-1 mt-2 publish-cluster-table-container"
            style={{ height: "calc(100vh - 257px)" }}
          >
            <div className="d-flex flex-column m-3 bg-white message-container flex-grow-1 mt-2 overflow-auto">
            <Table headers={headers} allowSelection isSelected={isAllSelected} stickyTop={true} topPosition={"0"} handleSelection={() => {
              if (isAllSelected) {
                setSelectedClusters([]);
                setIsAllSelected(false);
              }else{
                let selected = [...Array(clusterTable.length).keys()];
                setSelectedClusters(selected);
                setIsAllSelected(true);
              }
              }} >
              {clusterTable?.map((clusterObj: any, index: number) => (
                <TableElement type="tr" className="position-relative">
                  <TableElement type="td">
                    <CheckBox value={selectedClusters.includes(index)} onChange={() => handleClusterSelect(index)}/>
                  </TableElement>
                  <TableElement type="td" className="publish-cluster-td">
                    {clusterObj?.cluster_label}
                  </TableElement>
                  <TableElement type="td" className="text-truncate publish-cluster-td">
                    {clusterObj?.cluster_keywords?.join(" , ")}
                    {clusterObj?.cluster_keywords && <ViewMore popupTitle={"Keywords:"} popupData={clusterObj?.cluster_keywords?.join(" , ")} />}
                    
                  </TableElement>
                  <TableElement type="td">
                    <CreateAdGroupDropDown clusterObj={clusterObj} index={index} selectAdGroup={selectAdGroup}/>
                    <div className="ad-group-detail">
                      <div className="adgroup-selected-content">
                        <div className="ad-group-name text-truncate">{clusterObj?.ad_group?.update_name}</div>
                        <div className="campaign-name text-truncate">{clusterObj?.ref_campaign?.name}</div>
                        {clusterObj?.ad_group?.update_name && clusterObj?.ad_group?.update_name.length > 30 && ( <div className="keywords-popup keywords">
                          <b>Ad Group Name: </b>{clusterObj?.ad_group?.update_name} 
                          <br /> <b>Campaign Name: </b>{clusterObj?.ref_campaign?.name}
                        </div>)}
                      </div>
                      
                      {clusterObj?.ref_campaign?.name && (
                        <div
                          onClick={() => {
                            selectAdGroup("updateAdGroup", clusterObj, index);
                          }}
                          className="edit-campaign"
                        >
                          Edit
                        </div>
                      )}
                    </div>
                  </TableElement>
                  <TableElement type="td" className="ad-group-detail">
                    {clusterObj?.ref_ad_group?.name ? (
                      <>
                        {/* <div className="ad-group-name">{clusterObj?.ref_ad_group?.name}</div> */}
                        <div className="campaign-name">{clusterObj?.ref_campaign?.name}</div>
                      </>
                    ) : (
                      "-"
                    )}
                  </TableElement>
                  <TableElement type="td">
                    {clusterObj?.ref_campaign?.aiGroupAvailable
                      ? clusterObj?.ref_campaign?.aiGroupName
                      : "-"}
                  </TableElement>
                  <TableElement type="td" style={{minWidth: "215px"}}>
                    {clusterObj.ad && clusterObj.ad.responsiveSearchAd ? (
                      <>
                        {clusterObj.ad.responsiveSearchAd.headlines && (
                          <div
                            className="text-truncate mb-2 ml-2"
                            title={clusterObj.ad.responsiveSearchAd.headlines[0].text}
                          >
                            {clusterObj.ad.responsiveSearchAd.headlines[0].text}
                          </div>
                        )}
                        {clusterObj.ad.responsiveSearchAd.descriptions && (
                          <div
                            className="text-truncate ml-2"
                            title={clusterObj.ad.responsiveSearchAd.descriptions[0].text}
                          >
                            {clusterObj.ad.responsiveSearchAd.descriptions[0].text}
                          </div>
                        )}
                        <div className="ml-2 mt-3">
                          <a
                            className="mr-3 edit-links"
                            onClick={() => {
                              setOpen((v) => !v);
                              dispatch(setTargetKeyword({
                                ...targetKeyword,
                                title: clusterObj?.cluster_label,
                                activeIndex: index,
                                keywords: clusterObj?.cluster_keywords?.join(" , "),
                                selectedReferenceDescription:parseInt(clusterObj?.selectedReferenceDescription) ?? 0,
                                selectedReferenceHeadline:parseInt(clusterObj?.selectedReferenceHeadline) ?? 0,
                                generationType:clusterObj?.selectedGenerationType ?? targetKeyword.generationType,
                              }));
                            }}
                          >
                            Edit
                          </a>
                          <a
                            className="edit-links"
                            onClick={() => {
                              if(!dontAskAgain)
                              dispatch(setModal({
                                key: "deleteKeyword",
                                title: "Delete Keyword",
                                props: { index },
                              }));
                            }}
                          >
                            Delete
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                      {clusterObj?.cluster_label && (
                        <ButtonUI
                        type="button"
                        className="btn secondary width-secondary create-ad-btn"
                        disabled={false}
                        onClick={() => {
                          setOpen(!open);
                          dispatch(setTargetKeyword({
                            ...targetKeyword,
                            title: clusterObj?.cluster_label,
                            activeIndex: index,
                            keywords: clusterObj?.cluster_keywords?.join(" , "),
                          }));
                        }}
                      >
                        Create responsive search Ad
                      </ButtonUI>
                      )}
                      </>
                    )}
                  </TableElement>
                </TableElement>
              ))}
            </Table>
            {/* <button
              type="button"
              className="btn primary width-secondary mt-4"
              disabled={false}
              onClick={() => form?.setModal({ key: "deleteKeyword", title: "Delete Keyword" })}
            >
              Delete Keyword
            </button>
            <button
              type="button"
              className="btn primary width-secondary mt-4"
              disabled={false}
              onClick={() => form?.setModal({ key: "saveAd", title: "Save Ad" })}
            >
              Save Ad
            </button>
            <button
              type="button"
              className="btn primary width-secondary mt-4"
              disabled={false}
              onClick={() => form?.setModal({ key: "publishAdGroup", title: "Publish Ad Group" })}
            >
              Publish AI Group
            </button>
           */}
           </div>
          </div>
        </StepwiseFormContainer>
      </StepwiseFormWrapper>
      {open && <CreateAdDrawer setOpen={setOpen} />}
      {modal && modal.key === "deleteKeyword" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Delete Keyword"}
            dontAsk={(value) => setDontAskAgain(value)}
            formDisable={false}
            submitText={"Delete"}
            handleSubmit={handleDeleteAd}
            width={"25vw"}
            handleClose={() => dispatch(setModal(null))}
          >
            <p className="text-lg">Are you sure you want to delete this keyword ?</p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal && modal.key === "saveAd" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Save Ad"}
            formDisable={false}
            submitText={"OK"}
            handleSubmit={handleModalSubmit}
            width={"25vw"}
            handleClose={() => dispatch(setModal(null))}
          >
            <p className="text-lg">
              The ad will be saved and when published it will be published in active state
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {/* Modal opens on error */}
      {showError.length > 0 && (
        <ModalWrapper>
          <ModalContainer
            title="Error"
            submitText="Ok"
            handleSubmit={() => setShowError([])}
            handleClose={() => setShowError([])}
          >
            <ul className="create-ad-drawer-error">
              {showError.map((err: string) => (
                <li>{err}</li>
              ))}
            </ul>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal && modal.key === "publishAdGroup" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Publish Ads"}
            formDisable={false}
            submitText={"Publish"}
            handleSubmit={handlePublishCluster}
            width={"25vw"}
            handleClose={() => dispatch(setModal(null))}
            showLoader
          >
           <p className="text-lg">
             System will publish new ads as per the selected clusters.
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal && modal.key === "closeCluster" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Close cluster creator"}
            formDisable={false}
            submitText={"Close and Exit"}
            handleSubmit={() => {
              dispatch(setClusterTable([]));
              history.push(`/googlesearchai${history.location.search}`);
              dispatch(setModal(null));
            }}
            handleClose={() => {
              dispatch(setModal(null));
            }}
          >
            <p>
              All data in the current cluster flow will be deleted. Are you sure you want to close
              the flow?
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal &&
        (modal.key === "createAdGroup" ||
        modal.key === "existingAdGroup" ||
        modal.key === "updateAdGroup") && (
          <CreateAdGroup
            tooltip={tooltip}
            currentCluster={currentCluster}
            currentClusterIndex={currentClusterIndex}
          />
        )}
    </>
  );
};

export default CreateAdAndPublish;

import { styled as muiStyled } from "@mui/material/styles";
import { Input } from "@mui/material";
import styled from "styled-components";
import { TextInputProps } from "./TextInput";

export const StyledTextInput = muiStyled(Input)`
font-family: Inter;
  background: ${(props: TextInputProps) =>
    props.backgroundColor ?? "rgba(242, 242, 242, 0.5)"};
  border-radius: 8px;
  padding: 12px 16px;
  border: ${(props: TextInputProps) =>
    props.isBorder ? `1px solid ${props.borderColor ?? "#E5E5E5"}` : "white"} ;
  height: ${(props: TextInputProps) => props.height ?? undefined};

`;

export const StyledTextInputWithLabel = muiStyled(StyledTextInput)`
height:43px;
// padding-top:8px;
font-style: normal;
font-weight: 400;
font-size: 12px; 
line-height: 17px;
color: #333333;
font-family:"Inter";
`;

export const StyledInputContainer = styled.div`
  margin-bottom: 20px;
`;


import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { ReactComponent as GoogleAIIcon } from "../assets/GoogleNew.svg";
import { ReactComponent as TargetIcon } from "../assets/targetingAiNew.svg";
import { ReactComponent as SinglePlatform } from "../assets/SinglePlatformNew.svg";
import { ReactComponent as AllPlatform } from "../assets/AllPlatformNew.svg";
import { ReactComponent as GroupSettingsIcon } from "../assets/CrossPlatformSettingsNew.svg";
import { ReactComponent as MontageIcon } from "../assets/MontageAdsNew.svg";
import { ReactComponent as ActionImpact } from "../assets/ActionImpactNew.svg";
import { ReactComponent as AIModelCalibrationNew } from "../assets/AIModelCalibrationNew.svg";
import { ReactComponent as FacebookSvg } from "../assets/fbNew.svg";
import { ReactComponent as GovernanceAINew } from "../assets/GovernanceAINew.svg";
import { ReactComponent as NewText } from "../assets/NewText.svg";

import userImg from "../assets/svg/user.svg";
import { ReactComponent as CreativeAI } from "../assets/CreativeAiNew.svg";
import { getPhase } from "../store/actions/auth";
import { initialState } from "../store/types/auth";
import Loader from "./common/Loader";
import Journey from "./Journey";
import { AdAccountState } from "../store/types/adaccount";
import InputText from "../ui/InputText/InputText";
import { user_session_key } from "../utils/constants";
import { postMessage } from "../utils/commonFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as DownIcon } from "../assets/svg/down.svg";
import MontageAds from "../Pages/MontageAds";
import "./SideBar.scss"
import mixpanel from "mixpanel-browser";
type SideBarProps = unknown;

const SideBar: React.FC<SideBarProps> = () => {
  const [accordionSwitchIcon,setAccordionSwitchIcon] = React.useState(false);
  const [accordion2SwitchIcon,setAccordion2SwitchIcon] = React.useState(true);
  const [governanceSwitchIcon,setGovernanceSwitchIcon] = React.useState(false);
  const [adminControlIcon,setAdminControlIcon] = React.useState(true);
  const [targetingAISwitchIcon,setTargetingAISwitchIcon] = React.useState(true);
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { groups, selectedGroup, selectedBrand } = adaccount;
  const authState = useSelector((state: { auth:initialState }) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const { logout, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((data) => {
      dispatch({ type: "GET_USER_INFO", payload: { user, data } });
    });
  }, []);

  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  const accordion = useRef<any>(null);
  const accordion2 = useRef<any>(null);
  const governanceAccordion = useRef<any>(null);
  const targetingAIAccordion = useRef<any>(null);
  const adminControlAccordion = useRef<any>(null);

  const isEnabled: boolean = useMemo(
    () => (selectedBrand ? selectedBrand.enabled : false),
    [selectedBrand]
  );

  const isAdmin = userInfo?.roles?.includes("super-admin");
  
  const label = React.useMemo(
    () => (selectedGroup?.platform === "google" ? "Campaigns" : "Ad Sets"),
    [selectedGroup]
  );

  useEffect(() => {
    if (user?.name) {
      mixpanel.identify(user?.name);
      mixpanel.people.set({ "$name": user?.name, "$email": user?.email });
    }
  }, [user])

  useEffect(() => {
    var userId = user?.sub || null; 
    (window as any)?.hj('identify', userId, user || {});
  }, [user]);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(getPhase());
    }
  }, [selectedBrand?.id]);

  const handleLogout = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/auth/logout");
  }

  const closeAccordion = () => {
    if (accordion?.current?.getAttribute("aria-expanded") === "true") {
      setAccordionSwitchIcon(false)
      accordion.current.click();
    } else {
      setAccordionSwitchIcon(true)
    }
  }
  const closeAccordion2 = () => {
    if (accordion2?.current?.getAttribute("aria-expanded") === "true") {
      setAccordion2SwitchIcon(false)
      accordion2.current.click();
    }else {
      setAccordion2SwitchIcon(true)
    }
  }

  const closeGovernanceAccordion=()=>{
    if (governanceAccordion?.current?.getAttribute("aria-expanded") === "true") {
      setGovernanceSwitchIcon(false)
      governanceAccordion.current.click();
    } else {
      setGovernanceSwitchIcon(true)
    }
  }

  const closeTargetingAi=()=>{
    if (targetingAIAccordion?.current?.getAttribute("aria-expanded") === "true") {
      setTargetingAISwitchIcon(false)
      targetingAIAccordion.current.click();
    } else {
      setTargetingAISwitchIcon(true)
    }
  }
  const closeAdminControlAccordion=()=>{
    if (adminControlAccordion?.current?.getAttribute("aria-expanded") === "true") {
      setAdminControlIcon(false)
      adminControlAccordion.current.click();
    } else {
      setAdminControlIcon(true)
    }
  }



  return (
    <>
      <div className="flex-column vh-100 pyxis-nav-bar">
        <div className="flex-row">
          <h3 className="fs-5 p-3 font-weight-bold w-auto">AI Optimizer</h3>
        </div>
        <div className="flex-row mb-4">
          <div className="form-group">
            <input value={selectedBrand?.name} disabled={true} className={"form-control form-control-md p-3 text-xl bg-white"}/>
          </div>
        </div>
        <div className="flex-row sidebar-item-content">
          <ul className="nav nav-pills flex-column">
          <li className="nav-item accordion" id="accordionExample1">
              <NavLink
                className={`nav-link accordion-item ${governanceSwitchIcon == true ? 'up-arrow-icon' : 'down-arrow-icon'}`} 
                isActive={(match, location) => {
                  return location.pathname === "/" || location.pathname === "/actionImpactAnalysis" || location.pathname === "/singlePlatform"|| location.pathname === "/group-settings"|| location.pathname === "/aiModelConfidence";
                }}
                to={{ pathname: "/actionImpactAnalysis", search: history.location.search }}
                data-bs-toggle="collapse" data-bs-target="#collapseOneGovernance " aria-expanded="false" aria-controls="collapseOneGovernance" ref={governanceAccordion}
                onClick={closeGovernanceAccordion}
              >
                <div className="flex-center justify-content-between fw-bold">
                  <div>
                  <GovernanceAINew className="svg-container" />
                    <span className="sidebar-svg-name">Governance AI</span>
                  </div>
                  <DownIcon className="sidebar-dropdown" />
                </div>
                </NavLink>
            <div className={`accordion-collapse collapse ${(history.location.pathname==="/"||history.location.pathname==="/actionImpactAnalysis"||history.location.pathname==="/singlePlatform"||history.location.pathname==="/group-settings"||history.location.pathname==="/aiModelConfidence")?"show":""}`} 
            id="collapseOneGovernance"
             aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
            <li className="nav-item accordion ml-4" id="accordionExample">
              <NavLink
                className={`nav-link accordion-item ${accordionSwitchIcon === true ? 'up-arrow-icon' : 'down-arrow-icon'}`} 
                isActive={(match, location) => {
                  return location.pathname === "/" || location.pathname === "/actionImpactAnalysis" || location.pathname === "/singlePlatform";
                }}
                to={{ pathname: "/actionImpactAnalysis", search: history.location.search }}
                data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" ref={accordion}
                onClick={closeAccordion}
              >
                <div className="flex-center justify-content-between">
                  <div>
                  
                     <ActionImpact className="svg-content-item" />
                    Action Impact Analysis 
                  </div>
                  <DownIcon className="sidebar-dropdown" />
                </div>
                </NavLink>
                <div className={`accordion-collapse collapse ${(history.location.pathname==="/"||history.location.pathname==="/actionImpactAnalysis"||history.location.pathname==="/singlePlatform")?"show":""}`} id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  
                <li className="nav-item ml-4">
                    <NavLink
                      className="nav-link nav-bar-platform"
                      exact
                      to={{ pathname: '/singlePlatform', search: history.location.search }}
                    >
                      <SinglePlatform className="svg-content-item" />
                      Single Platform
                    </NavLink>
                  </li>
                  <li className="nav-item ml-4">
                    <NavLink
                      className="nav-link nav-bar-platform"
                      exact
                      to={{ pathname: '/actionImpactAnalysis', search: history.location.search }}
                      isActive={(match, location) => {
                        return location.pathname === "/" || location.pathname === "/actionImpactAnalysis" ;
                      }}
                    >
                      <AllPlatform className="svg-content-item" />
                      All Platform
                    </NavLink>
                  </li>
               
                </div>
            </li>
            <li className="nav-item ml-4"> 
              <NavLink
                className="nav-link"
                exact
                to={{ pathname: '/group-settings', search: history.location.search }}
                onClick={()=>{ closeAccordion()}}
               
              >
                <GroupSettingsIcon  className="svg-content-item"/>
               Cross Platform Settings
              </NavLink>
            </li>
            <li className="nav-item ml-4">
              <NavLink
                className="nav-link"
                exact
                to={{ pathname: "/aiModelConfidence", search: history.location.search }}
                onClick={()=>{ closeAccordion()}}
              >
                
                <AIModelCalibrationNew className="svg-content-item" />
                 AI Model Calibration
              </NavLink>
            </li>
            </div>
            </li>
              <li className="nav-item accordion" id="accordionTargetingAi">
              <NavLink
                className={`nav-link accordion-item ${targetingAISwitchIcon === true ? 'up-arrow-icon' : 'down-arrow-icon'}`} 
                isActive={(match, location) => {
                  return  location.pathname === "/targeting-ai"|| location.pathname === "/googleSearchTargetAi";
                }}
                to={{ pathname: "/actionImpactAnalysis", search: history.location.search }}
                data-bs-toggle="collapse" data-bs-target="#collapseTargetingAi" aria-expanded={'false'}  aria-controls="collapseTargetingAi" ref={targetingAIAccordion}
                onClick={closeTargetingAi}
              >
                <div className="flex-center justify-content-between fw-bold">
                  <div>
                  <TargetIcon className="svg-content-item" />
                  <span className="sidebar-svg-name">Targeting AI </span>
                  </div>
                  <DownIcon className="sidebar-dropdown" />
                </div>
                </NavLink>
                <div className={`accordion-collapse collapse${(history.location.pathname==="/targeting-ai"||history.location.pathname==="/googleSearchTargetAi")?"show":""}`} id="collapseTargetingAi" aria-labelledby="headingTwo1" data-bs-parent="#accordionTargetingAi">
                  <li className="nav-item ml-4">
                    <NavLink
                      className="nav-link nav-bar-platform"
                      exact
                      to={{ pathname: '/targeting-ai', search: history.location.search }}
                      onClick={()=>{ 
                        closeGovernanceAccordion();
                        closeAccordion();}}
                    >
                     <FacebookSvg className="svg-content-item"/>
                      Facebook 
                    </NavLink>
                  </li>
                  <li className="nav-item ml-4">
              <NavLink
                className="nav-link nav-bar-platform"
                exact
                to={{ pathname: "/googleSearchTargetAi", search: history.location.search }}
                onClick={()=>{ 
                  closeGovernanceAccordion();
                  closeAccordion();
                }}
              >
               <GoogleAIIcon className="svg-content-item" />
                Google Search  
              </NavLink>
            </li>               
                </div>
            </li>

            <li className="nav-item accordion" id="accordionMontage">
              <NavLink
                className={`nav-link accordion-item ${accordion2SwitchIcon === true ? 'up-arrow-icon' : 'down-arrow-icon'}`} 
                isActive={(match, location) => {
                  return location.pathname === "/montage-ads" || location.pathname === "/outline-screen" || location.pathname === "/creative-ai"|| location.pathname === "/googlesearchai";
                }}
                to={{ pathname: "/creative-ai", search: history.location.search }}
                data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded={'false'}  aria-controls="collapseTwo" ref={accordion2}
                onClick={closeAccordion2}
              >
                <div className="flex-center justify-content-between fw-bold">
                  <div>
                    <CreativeAI className="svg-content-item"/>
                    <span className="sidebar-svg-name">Creative AI</span>
                  </div>
                  <DownIcon className="sidebar-dropdown" />
                </div>
                </NavLink>
                <div className={`accordion-collapse collapse ${(history.location.pathname==="/montage-ads"||history.location.pathname==="/outline-screen"||history.location.pathname==="/creative-ai"||history.location.pathname==="/googlesearchai")?"show":""}`} id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionMontage">
                  <li className="nav-item ml-4">
                    <NavLink
                      className="nav-link nav-bar-platform"
                      exact
                      to={{ pathname: '/creative-ai', search: history.location.search }}
                      onClick={()=>{ closeGovernanceAccordion();}}
                    >
                      <FacebookSvg className="svg-content-item"/>
                      Facebook
                    </NavLink>
                  </li>
                  <li className="nav-item ml-4">
              <NavLink
                className="nav-link nav-bar-platform"
                exact
                to={{ pathname: "/googlesearchai", search: history.location.search }}
               onClick={()=>{ closeGovernanceAccordion();}}
              >
               <GoogleAIIcon className="svg-content-item" />
                Google Search 
              </NavLink>
            </li>
                  <li className="nav-item ml-4">
                    <NavLink
                      className="nav-link nav-bar-platform"
                      exact
                      isActive={(match, location)=>{
                        return location.pathname==="/montage-ads" || location.pathname==="/outline-screen"} }
                      to={{ pathname: '/montage-ads', search: history.location.search }}
                      onClick={()=>{ closeGovernanceAccordion();}}
                    >
                      <MontageIcon className="svg-content-item"/>
                      Montage Ads
                    </NavLink>
                  </li>
                 
                  {isAdmin && (<li className="nav-item accordion ml-4" id="accordionExampleAdmin">
              <NavLink
                className={`nav-link accordion-item ${adminControlIcon === true ? 'up-arrow-icon' : 'down-arrow-icon'}`} 
                isActive={(match, location) => {
                  return location.pathname === "/adminControl" ;
                }}
                to={{ pathname: "/adminControl", search: history.location.search }}
                data-bs-toggle="collapse" data-bs-target="#collapseAdmin" aria-expanded="false" aria-controls="collapseAdmin" ref={adminControlAccordion}
                onClick={closeAdminControlAccordion}
              >
                <div className="flex-center justify-content-between">
                  <div>
                  
                     <ActionImpact className="svg-content-item" />
                     Admin Control
                  </div>
                  <DownIcon className="sidebar-dropdown" />
                </div>
                </NavLink>
                <div className={`accordion-collapse collapse ${(history.location.pathname==="/adminControl")?"show":""}`} id="collapseAdmin" aria-labelledby="headingOne" data-bs-parent="#accordionExampleAdmin">
                  
                <li className="nav-item ml-4">
                    <NavLink
                      className="nav-link nav-bar-platform"
                      exact
                      to={{ pathname: '/brand-setting', search: history.location.search }}
                    >
                      <SinglePlatform className="svg-content-item" />
                      Brand Settings
                    </NavLink>
                  </li>
                  {/* <li className="nav-item ml-4">
                    <NavLink
                      className="nav-link nav-bar-platform"
                      exact
                      to="#"
                     
                    >
                      <AllPlatform className="svg-content-item" />
                      AI Group Settings
                    </NavLink>
                  </li> */}
               
                </div>
            </li>)}
           
                </div>
            </li>
           
            
           
            {/* )} */}
          </ul>
        </div>
      </div>
      <div className="fixed-bottom col-3 mb-4 sidebar-phase-container">
        {authState?.phase ? (
          <div>
            <Journey enabled={isEnabled} phases={authState.phase} />
            <hr />
            <div>
            <div style={authState && {cursor: "pointer"}} onClick={() => {
              if (selectedBrand?.agreementType) {
                history.push(`/user-details${history.location.search}`);
              }
            }} className="d-flex align-items-center" >
                <span>
                  <img
                    src={userImg}
                    width="30"
                    height="30"
                    className="rounded-circle bg-white profile-image"
                    alt="profile"
                  />
                </span>
                <div className="d-flex flex-column ml-4">
                  <span>
                    {user?.name ||"User"}
                  </span>
                  <span className="p-0 link-primary text-md" role="button" onClick={handleLogout}>Log out</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader height="20px" />
        )}
      </div>
    </>
  );
};

export default SideBar;
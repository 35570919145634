import { phasesV1API, groupV1API, crossPlatform } from './../../MappedAPI/MappedAPI';
import { call, takeLatest } from "@redux-saga/core/effects";
import httpService from "../../HttpService/HttpService";
import { groupV2API } from "../../MappedAPI/MappedAPI";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import { GetCampaignsDetails, GetCampaignFactors, GetPhaseTimeline, GET_CAMPAIGNS_DETAILS, GET_CAMPAIGN_METRICS, GET_PHASE_TIMELINE, GET_CAMPAIGNS_FACTORS, GET_METRICS_LIST, GetMetricsList, POST_CAMPAIGN_FACTORS, PostCampaignFactors, DELETE_CAMPAIGN_FACTORS, DeleteCampaignFactors, GET_MODEL_CONFIDENCE_SCORE, GetModelConfidenceScore, GET_GROUP_METRICS, GetGroupMetrics, GetCampaignMetrics, GET_METRICS_CUMULATIVE, GetMetricsCumulative, UPDATE_METRIC, UpdateMetric, GET_RECOMMENDATION, GetRecommendation, REJECT_RECOMMENDATION, RejectRecommendation, GET_STORY, POST_STORY, PUT_STORY, PostStoryData, PutStoryData, GetStoryData, GET_ALL_PLATFORM_CUMMULATIVE_DATA, GET_ALL_PLATFORM_METRICS_DATA, GetAllPlatformCummulativeData, GetAllPlatformMetricsData,GetOptimizationEventDetails,GET_OPTIMIZATION_EVENT } from "../types/campaign";
import moment from 'moment';

function* getCampaignsDetails(action: GetCampaignsDetails): any {
  let {
    payload: { groupId, user },
    callback,
  } = action;
  try {
    const urlParams = `${groupV2API}/${groupId}/adsets`;
    const response = yield call(httpService.get, BASE_URL, urlParams, user);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getCampaignsDetails ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getPhaseTimeline(action: GetPhaseTimeline): any {
  let {
    payload: { brandId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/phases/${brandId}/allmetadata`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getPhaseTimeline ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getCampaignFactors(action: GetCampaignFactors): any {
  let {
    payload: { elementId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/factor/${elementId}/factors`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getCampaignFactors ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getMetricsList(action: GetMetricsList): any {
  let {
    payload: { elementId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/global-metrics/${elementId}/filter`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);

    if (!response.data.error) {
      callback && callback(response.data, false);

    } else {
      callback && callback(response, true);

    }
  } catch (error: any) {
    console.log("function*getMetricsList ~ error", error)
    callback && callback(error.response, true);
  }
}


function* postCampaignFactors(action: PostCampaignFactors): any {
  let {
    payload: { elementId, user, payload },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/factor/${elementId}/factors`;
    const response = yield call(httpService.post, CROSS_PLATFORM_URL, urlParams, user, payload);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*postCampaignFactors ~ error", error)
    callback && callback(error.response, true);
  }
}

function* deleteCampaignFactors(action: DeleteCampaignFactors): any {
  let {
    payload: { factorId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/factor/factor/${factorId}`;
    const response = yield call(httpService.delete, CROSS_PLATFORM_URL, urlParams, user);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*deleteCampaignFactors ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getModelConfidenceScore(action: GetModelConfidenceScore): any {
  let {
    payload: { elementId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/element-model/${elementId}`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getModelConfidenceScore ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getGroupMetrics(action: GetGroupMetrics): any {
  let {
    payload: { elementId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/metrics/elements/${elementId}`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getGroupMetrics ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getCampaignMetrics(action: GetCampaignMetrics): any {
  let {
    payload: { elementId, dates, user, selectedAdSet, selectedCampaign },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/metrics/elements/${elementId}/data?start_date=${moment(
      dates.start
    ).format("YYYY-MM-DD")}&end_date=${moment(dates.end).format("YYYY-MM-DD")}${selectedAdSet && selectedAdSet.length > 0 ? `&adsetIds=` + selectedAdSet : ``}${selectedCampaign && selectedCampaign.length > 0 ? "&campaignIds=" + selectedCampaign : ``}`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getCampaignMetrics ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getMetricsCumulative(action: GetMetricsCumulative): any {
  let {
    payload: { elementId, dates, user, selectedAdSet, selectedCampaign },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/metrics/elements/${elementId}/cumulativedata?start_date=${moment(
      dates.start
    ).format("YYYY-MM-DD")}&end_date=${moment(dates.end).format("YYYY-MM-DD")}${selectedAdSet && selectedAdSet.length > 0 ? `&adsetIds=` + selectedAdSet : ``}${selectedCampaign && selectedCampaign.length > 0 ? "&campaignIds=" + selectedCampaign : ``}`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getMetricsCumulative ~ error", error)
    callback && callback(error.response, true);
  }
}

function* updateMetric(action: UpdateMetric): any {
  let {
    payload: { metricId, user, payload },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/metrics/${metricId}`;
    const response = yield call(httpService.put, CROSS_PLATFORM_URL, urlParams, user, payload);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*updateMetric ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getActionRecommendataionRecommendation(action: GetRecommendation): any {
  let {
    payload: { elementId, dates, user, activeHeader },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/recommendation/${elementId}?startDate=${moment(
      dates.start
    ).format("YYYY-MM-DD")}&endDate=${moment(dates.end).format(
      "YYYY-MM-DD"
    )}${!!activeHeader ? `&fields=${activeHeader},id,concept_type` : ''}`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getRecommendation ~ error", error)
    callback && callback(error.response, true);
  }
}



function* rejectRecommendation(action: RejectRecommendation): any {
  let {
    payload: { id, concept_type, user },
    callback,
  } = action;
  try {
    const urlParams = `${groupV2API}/${id}/${concept_type}s/recommendations/reject/`;
    const response = yield call(httpService.post, BASE_URL, urlParams, user, undefined);
    if (response.data.code === "101") {
      return;
    }
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*rejectRecommendation ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getStoryData(action: GetStoryData): any {
  let {
    payload: { elementId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/story/${elementId}`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getStoryData ~ error", error)
    callback && callback(error.response, true);
  }
}

function* putStoryData(action: PutStoryData): any {
  let {
    payload: { elementId, user, payload },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/story/${elementId}/story`;
    const response = yield call(httpService.put, CROSS_PLATFORM_URL, urlParams, user, payload);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*putStoryData ~ error", error)
    callback && callback(error.response, true);
  }
}


function* postStoryData(action: PostStoryData): any {
  let {
    payload: { elementId, user, payload },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/story/${elementId}/story`;
    const response = yield call(httpService.post, CROSS_PLATFORM_URL, urlParams, user, payload);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*postStoryData ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getAllPlatformCummulativeData(action: GetAllPlatformCummulativeData): any {
  let {
    payload: { groupId, dates, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/metrics/groups/${groupId}/cumulativedata?start_date=${moment(
      dates.start
    ).format("YYYY-MM-DD")}&end_date=${moment(dates.end).format(
      "YYYY-MM-DD")}`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getStoryData ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getAllPlatformMetricsData(action: GetAllPlatformMetricsData): any {
  let {
    payload: { groupId, dates, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/metrics/groups/${groupId}/data?start_date=${moment(
      dates.start
    ).format("YYYY-MM-DD")}&end_date=${moment(dates.end).format(
      "YYYY-MM-DD")}`;
    const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getStoryData ~ error", error)
    callback && callback(error.response, true);
  }
}

function* getAllOptimizationEventDetails(action:GetOptimizationEventDetails):any{
  let {
    payload: { adAccountId, platformId,user },
    callback,
  } = action;
  try{
const urlParams =`${crossPlatform}/global-metrics/factor-metrics/adAccount/${adAccountId}/platform/${platformId}`
const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
  if (!response.data.error) {
    callback && callback(response.data, false);
  } else {
    callback && callback(response, true);
  }
  }catch(error:any){
    console.log("OPTIMIZATION EVENT ERROR", error)
    callback && callback(error.response, true);
  }
}

export function* campaignWatcher() {
  yield takeLatest(GET_CAMPAIGNS_DETAILS, getCampaignsDetails);
  yield takeLatest(GET_PHASE_TIMELINE, getPhaseTimeline);
  yield takeLatest(GET_CAMPAIGNS_FACTORS, getCampaignFactors);
  yield takeLatest(GET_METRICS_LIST, getMetricsList);
  yield takeLatest(POST_CAMPAIGN_FACTORS, postCampaignFactors);
  yield takeLatest(DELETE_CAMPAIGN_FACTORS, deleteCampaignFactors);
  yield takeLatest(GET_MODEL_CONFIDENCE_SCORE, getModelConfidenceScore);
  yield takeLatest(GET_GROUP_METRICS, getGroupMetrics);
  yield takeLatest(GET_CAMPAIGN_METRICS, getCampaignMetrics);
  yield takeLatest(GET_METRICS_CUMULATIVE, getMetricsCumulative);
  yield takeLatest(UPDATE_METRIC, updateMetric);
  yield takeLatest(GET_RECOMMENDATION, getActionRecommendataionRecommendation);
  yield takeLatest(REJECT_RECOMMENDATION, rejectRecommendation);
  yield takeLatest(GET_STORY, getStoryData);
  yield takeLatest(POST_STORY, postStoryData);
  yield takeLatest(PUT_STORY, putStoryData);
  yield takeLatest(GET_ALL_PLATFORM_CUMMULATIVE_DATA, getAllPlatformCummulativeData);
  yield takeLatest(GET_ALL_PLATFORM_METRICS_DATA, getAllPlatformMetricsData);
  yield takeLatest(GET_OPTIMIZATION_EVENT,getAllOptimizationEventDetails)
}
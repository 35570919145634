import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCohort } from "../../../../../store/actions/CreativeAI/Facebook/SelectCohort/selectCohort";
import { textTruncateMore } from "../../utils/text.utils";
import TooltipWithInfo from "../TooltipWithInfo/TooltipWithInfo";
import {
  StyledVariantsRows,
  StyledVariantItem,
  StyledVariantItemParaTitle,
  StyledVariantItemPara,
  StyledCreateVariantRecommended,
  StyledLinkViewMore,
  StyledCreateVariantButton,
  StyledVariantItemRecommended,
  StyledVariantItemParaRecommended,
  StyledVariantItemCreate,
  StyledVariantFirstItem,
  StyledVariantItemParaTitlePV,
  StyledVariantItemParaDT,
  StyledDivider,
  StyledAnchorSA,
  StyledVariantItemParaBigFont,
  StyledVariantItemParaTitleInDraft,
  StyledVariantItemParaTitleAdSet,
} from "./styled";

const AdSets: React.FC<{
  toggleViewMoreModal?: any;
  type?: string;
  item?: any;
}> = ({ toggleViewMoreModal, type, item }) => {
  const getComaSeparatedDate = (fields: any) => {
    const value = fields.map((element: any) => element.name);
    return value.slice(0, 3).join(",");
  };
  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const isRecommended = item?.adset?.variantsRecommended;
  const adManagerLink = `https://business.facebook.com/adsmanager/manage/adsets?act=${adAccountId}&filter_set=SEARCH_BY_CAMPAIGN_IDS-STRING_SET%1EANY%1E[%22${item?.adset?.adsetId}%22]`;
  return (
    <StyledVariantsRows>
      {!item?.adset?.cohortName.length ? (
        <StyledVariantFirstItem>
          <StyledVariantItemParaTitle>Ad-set:</StyledVariantItemParaTitle>
          <StyledVariantItemPara title={item?.adset?.name}>
            {item?.adset?.name ?? "-"}
          </StyledVariantItemPara>
          <StyledAnchorSA target="_blank" href={adManagerLink} style={{}}>
            {" "}
            View in Ads Manager
          </StyledAnchorSA>
        </StyledVariantFirstItem>
      ) : (
        <StyledVariantItemCreate>
          <StyledVariantItemParaTitle>Ad-set:</StyledVariantItemParaTitle>
          <StyledVariantItemPara title={item?.adset?.name}>
            {item?.adset?.name ?? "-"}
          </StyledVariantItemPara>
          <StyledAnchorSA target="_blank" href={adManagerLink}>
            {" "}
            View in Ads Manager
          </StyledAnchorSA>
          <StyledVariantItemParaTitle>cohort:</StyledVariantItemParaTitle>
          <StyledVariantItemPara title={item?.adset?.cohortName}>
            {item?.adset?.cohortName}
          </StyledVariantItemPara>
        </StyledVariantItemCreate>
      )}
      <StyledDivider orientation="vertical" flexItem className="h-100" />
      {isRecommended ? (
        <StyledVariantItemRecommended>
          <StyledCreateVariantRecommended
            className="mb-0"
            style={{ alignItems: "center" }}
          >
            Creative Variation Recommended
            <TooltipWithInfo info="This represents the basis of Creative Variation Recommendation." />
          </StyledCreateVariantRecommended>
          {/* // */}
          <StyledVariantItemParaRecommended
            title={item?.adset?.recommendationText}
          >
            {textTruncateMore(item?.adset?.recommendationText, 48)}
          </StyledVariantItemParaRecommended>
        </StyledVariantItemRecommended>
      ) : (
        <StyledVariantItemParaRecommended>
          No Recommendation
        </StyledVariantItemParaRecommended>
      )}
      <StyledVariantItem style={{ width: "70px" }}>
        <StyledVariantItemParaTitle>
          Age
          <TooltipWithInfo info="This represents the age set for this Ad Set using Facebook’s Targeting configuration." />
        </StyledVariantItemParaTitle>
        <StyledVariantItemParaBigFont style={{ width: "70px" }}>{`${
          item?.facebookData?.targeting?.age_min ?? ""
        } - ${
          item?.facebookData?.targeting?.age_max ?? ""
        }`}</StyledVariantItemParaBigFont>
      </StyledVariantItem>
      <StyledVariantItem style={{ width: "64px" }}>
        <StyledVariantItemParaTitle>
          Gender
          <TooltipWithInfo info="This represents the gender set for this Ad Set using Facebook’s Targeting configuration." />
        </StyledVariantItemParaTitle>
        <StyledVariantItemParaBigFont style={{ width: "64px" }}>
          {item?.facebookData?.targeting?.genders &&
          item?.facebookData?.targeting?.genders[0] === 1
            ? "Male"
            : item?.facebookData?.targeting?.genders &&
              item?.facebookData?.targeting?.genders[0] === 2
            ? "Female"
            : "All"}
        </StyledVariantItemParaBigFont>
      </StyledVariantItem>
      <StyledVariantItem>
        <StyledVariantItemParaTitle>
          Detailed Targeting
          <TooltipWithInfo
            info="This represents the detailed targeting keywords(Interests, Demographics and Behaviours) for this Ad Set using Facebook’s Targeting configuration."
            // iconTop={true}
          />
        </StyledVariantItemParaTitle>
        {item?.facebookData?.targeting?.flexible_spec ? (
          <span className="d-flex">
            <StyledVariantItemParaDT>
              {item?.facebookData?.targeting?.flexible_spec[0]?.interests &&
                getComaSeparatedDate(
                  item?.facebookData?.targeting?.flexible_spec[0]?.interests
                )}
            </StyledVariantItemParaDT>
            <StyledLinkViewMore
              to="#"
              onClick={() => toggleViewMoreModal(item)}
            >
              {" "}
              View more
            </StyledLinkViewMore>
            {/* <span style={{ width: "50px" }}></span> */}
          </span>
        ) : (
          <span className="d-flex">
            <StyledVariantItemParaBigFont>-</StyledVariantItemParaBigFont>
            <span style={{ width: "50px" }}></span>
          </span>
        )}
      </StyledVariantItem>
      <StyledVariantItem style={{ width: "134px" }}>
        <StyledVariantItemParaTitlePV>
          Published Variants
          <TooltipWithInfo info="This number represents the number of Ad Variants published for this Ad Set using Pixis Creative AI system" />
        </StyledVariantItemParaTitlePV>
        <StyledVariantItemParaBigFont>
          {item?._id}
          {item?.publishedVariants ?? "-"}
        </StyledVariantItemParaBigFont>
      </StyledVariantItem>{" "}
      <div>
        {type === "cohort" && (
          <>
            <StyledCreateVariantButton
              onClick={() => {
                dispatch(getCohort(item));
              }}
              to={{
                pathname: `/creative-ai/variants/create`,
                state: item,
                search: history.location.search,
              }}
              style={
                item.adsCount === 50 ||
                item.adsCount === 0 ||
                item?.facebookData?.campaign_objective !== "CONVERSIONS"
                  ? {
                      background: "#EDF3FE",
                      opacity: 0.3,
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              Create Variants
            </StyledCreateVariantButton>

            <StyledVariantItemParaTitleInDraft>
              ({item.draftVariants} variants in draft)
            </StyledVariantItemParaTitleInDraft>
          </>
        )}
        {/* {item.adsCount} */}
        <StyledVariantItemParaTitleAdSet
          color={
            item.adsCount !== 50 && item.adsCount !== 0 ? "#7A7A7A" : "#E84B47"
          }
          componentType={type}
        >
          Total ads in ad set {item.adsCount}/50
        </StyledVariantItemParaTitleAdSet>
      </div>
    </StyledVariantsRows>
  );
};

export default AdSets;

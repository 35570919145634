import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import GoogleSearchAIDashboard from "./components/GoogleSearchAIDashboard";
import MessageContainer from "../../components/MessageContainer/MessageContainer";
import {
  setGoogleOnboarding,
  updateGoogleOnBoarding,
} from "../../store/actions/form";
import { getLearningPhaseFlag } from "../../store/actions/common";
import { FormState } from "../../store/types/form";
import { initialState } from "../../store/types/auth";
import { onBoardingDataGoogle } from "../../utils/constants";
import Notification from "../../components/Notification";
import { AdAccountState } from "../../store/types/adaccount";
import { mixPanelAdEvent } from "../../utils/mixpanel";

const GoogleSearchAI: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { googleOnboarding, googleOnboardingLoading, fetchedGoolgeOnboardingStatus } = formState;
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [learningPhaseEnabled, setLearningPhaseEnabled] = React.useState(false);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand, selectedGSAIAdaccount } = adaccount;

  useEffect(() => {
    mixPanelAdEvent('Page View - Dashboard', {
      'module_name': "Google Search AI",
      'brand_name': selectedBrand?.name,
    });
  }, []);

  useEffect(() => {
    if (auth && selectedGSAIAdaccount && selectedBrand) {
      dispatch(
        getLearningPhaseFlag(
          {
            brandId: selectedBrand?.id,
            accountId: selectedGSAIAdaccount?.id,
            user: auth.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              setLearningPhaseEnabled(response.data.googleLearningPhase);
            }
          }
        )
      );
    }
  }, [selectedGSAIAdaccount]);

  return (
    <>
      {learningPhaseEnabled ? (
        <Notification
          bgColor="#000"
          color="#fff"
          boldText="Google Search AI is in learning phase."
          text="Keyword recommendations will start getting generated after the learning phase ends."
        />
      ) : (
        ""
      )}
      <div className="d-flex flex-column p-3 bg-white flex-grow-1 message-container">
        {fetchedGoolgeOnboardingStatus ? <>
          {!googleOnboarding ? (
            <MessageContainer
              data={onBoardingDataGoogle}
              submitText="Unlock Google Search AI"
              handleNext={() => {
                dispatch(
                  updateGoogleOnBoarding(
                    {
                      brandId: selectedBrand.id,
                      user: auth.user,
                      enable: true,
                    },
                    (response: any, error: boolean) => {
                      if (!error) {
                        dispatch(setGoogleOnboarding(true));
                      }
                    }
                  )
                );
              }}
            />
          ) : (
            <GoogleSearchAIDashboard />
          )}
        </> : <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100">
          <Loader />
        </div>}
      </div>
    </>
  );
};

export default GoogleSearchAI;

import { Box } from "@mui/material";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseDrawer } from "../../../../../assets/svg/closeDrawer.svg";
import {
  resetState,
  setRecommendationLoading,
  updateRecommendationMetadata,
  isShowSaveVariantPromptAction,
  showUpdateAISuccessMsgAction,
  setRecommendationError,
  setTimeStamp,
  addUpdateVariantLoadingState,
  updateRecommendationSelectionState,
  generateAdPreview,
} from "../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  getRecommendationMetadataTemplateAPICall,
  setRecommendationTimer,
} from "../../../../../store/actions/CreativeAI/Facebook/SelectCohort/selectCohort";
import { initialState } from "../../../../../store/types/auth";
import {
  CreateVariantsState,
  GENERATE_VARIANT_METADATA_STATE_VARIANT,
} from "../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { ConfirmDialog, AlertDialog, Button } from "../../../../shared";
import { CustomizedSnackBars } from "../../../../shared/AlertSnackBar/AlertSnackBar";
import {
  checkAdType,
  getCreativeSPecFieldName,
} from "../../utils/common.utils";
import {
  generateConfigurePayload,
  getFormValues,
} from "../../utils/form.utils";
import Configure from "./Configure/Configure";
import ConfigureFooter from "./EditorFooter/ConfigureFooter";
import RecommendationFooter from "./EditorFooter/RecommendationFooter";
import EditorHeader from "./EditorHeader/EditorHeader";
import { useEditor } from "./hooks/useEditor";
import Recommendations from "./Recommendations/Recommendations";
import EditorGif from "../../../../../assets/Gif/EditorGif.gif";
import {
  StyledEditorGifContainer,
  StyledEditorGif,
  StyledGreatBestTxt,
  StyledAISettingTxt,
  StyledAIProvideTxt,
  StyledDrawer,
} from "./styled";

export default function Editor() {
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const {
    variantList,
    loadingRecommendation,
    extraFields: { showEditor, selectedVariantIndex },
    addUpdateVariantLoading,
    isConfiguringMetaData,
    isShowSaveVariantPrompt,
    executionIdVariant,
    recommendationSelectionState: { isChanged, isConfigureChanged },
    showUpdateAISuccessMsg,
    generateRecommendationError,
    configureMetaData,
    createSpec,
    recommendationMetaData,
    isGeneratingMetaData,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { BUSINESS_UNIT_BY_ID_DATA } = useSelector((state: any) => ({
    BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
  }));
  const {
    textFields,
    onAdSettingChange,
    handleSubmit,
    onGenerate,
    control,
    getValues,
    setValue,
    reset,
    watch,
    isUpdateAISettingChecked,
    onUpdateAISettingCheckClick,
    errors,
    setError,
    clearErrors,
  } = useEditor();
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [updateInitialRecommendation, setUpdateInitialRecommendation] =
    useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { CREATIVE_ELEMENT_AI, LEVERS } = useSelector((state: any) => ({
    CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi.data,
    LEVERS: state.MainReducer.defaultLevers,
  }));
  const timerRef = useRef<any>(null);
  const { recommendationTimer } = useSelector(
    (state: { selectCohortReducer: any }) => state.selectCohortReducer
  );

  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);

  const onCrossClick = () => {
    if (isChanged || isConfigureChanged) setShowDiscardModal(true);
    else {
      onDiscard();
    }
  };
  const onDiscard = () => {
    setShowDiscardModal(false);
    const localVariantList = variantList.filter(
      (element: any) => element.id !== null
    );
    let initState: any = {
      variantList: localVariantList,
      loadingRecommendation: true,
      isConfiguringMetaData: false,
      extraFields: {
        isVariantEditing: undefined,
        selectedVariantIndex: undefined,
        showEditor: undefined,
      },
      generateRecommendationError: false,
      configureMetaData: undefined,
      generateRecommendationTimestamp: undefined,
      recommendationMetaData: {},
      recommendationSelectionState: {
        primary_text: "",
        headline: "",
        description: "",
        adPreviewLink: "",
        variantName: "",
        isChanged: false,
        isConfigureChanged: false,
      },
    };
    dispatch(resetState(initState));
  };

  const getInitialRecommendationState = (recommendationMetaData: any) => {
    return {
      creativeSpec: {
        ...createSpec,
        object_story_spec: {
          ...createSpec.object_story_spec,
          [checkAdType(createSpec)]: {
            ...createSpec.object_story_spec[checkAdType(createSpec)],
            [getCreativeSPecFieldName({
              type: checkAdType(createSpec),
              field: "primary_text",
            })]: recommendationMetaData?.primary_text?.data[0],
            [getCreativeSPecFieldName({
              type: checkAdType(createSpec),
              field: "headline",
            })]: recommendationMetaData?.headline?.data[0],
            [getCreativeSPecFieldName({
              type: checkAdType(createSpec),
              field: "description",
            })]: recommendationMetaData?.description?.data[0],
          },
        },
      },
      adAccountId: adAccountId,
      recommendationSelectionState: {
        primary_text: recommendationMetaData?.primary_text?.data[0],
        headline: recommendationMetaData?.headline?.data[0],
        description: recommendationMetaData?.description?.data[0],
        isChanged: true,
      },
    };
  };

  useEffect(() => {
    if (selectedVariantIndex !== undefined)
      if (
        variantList[selectedVariantIndex].id === null ||
        isConfiguringMetaData
      ) {
        dispatch(setTimeStamp({}));
        getRecommendationMetDataTemplate({
          executionId: executionIdVariant,
          generateRecommendationTimestamp: +new Date(),
          configureMetaData,
          recommendationMetaData,
        });
      } else {
        dispatch(addUpdateVariantLoadingState(false));
        dispatch(setRecommendationLoading(false));
        dispatch(
          updateRecommendationMetadata({
            data: cloneDeep(
              variantList[selectedVariantIndex].payload?.recommendationMetaData
            ),
            configureMetaData:
              variantList[selectedVariantIndex].payload?.configureMetaData,
          })
        );
      }
  }, [executionIdVariant]);

  const getRecommendationMetDataTemplate = useCallback(
    ({
      executionId,
      generateRecommendationTimestamp,
      configureMetaData,
      recommendationMetaData,
    }) => {
      dispatch(
        getRecommendationMetadataTemplateAPICall(
          { executionId },
          ({ response, error }: { response: any; error: boolean }) => {
            const { status, data } = response;

            if (!error) {
              if (status === "failed") {
                dispatch(setRecommendationError(true));
              } else if (status === "pending") {
                if (+new Date() - generateRecommendationTimestamp < 60000) {
                  let timer = setTimeout(() => {
                    getRecommendationMetDataTemplate({
                      executionId,
                      generateRecommendationTimestamp,
                      configureMetaData,
                      recommendationMetaData,
                    });
                  }, 8000);
                  dispatch(setRecommendationTimer(timer));
                } else {
                  dispatch(setRecommendationError(true));
                }
              } else {
                const formattedData = formateRecommendationData(data);
                if (variantList[selectedVariantIndex].id) {
                  setUpdateInitialRecommendation(true);
                }
                dispatch(
                  updateRecommendationMetadata({
                    data: {
                      ...recommendationMetaData,
                      ...formattedData,
                      isEditing: undefined,
                    },
                    configureMetaData,
                  })
                );
              }
            } else {
              dispatch(setRecommendationError(true));
            }
          }
        )
      );
    },
    []
  );

  useEffect(() => {
    timerRef.current = recommendationTimer;
  }, [recommendationTimer]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        dispatch(setRecommendationTimer(null));
      }
    },
    []
  );

  useEffect(() => {
    if (
      recommendationMetaData &&
      Object.keys(recommendationMetaData).length &&
      !recommendationMetaData?.isEditing
    ) {
      if (
        selectedVariantIndex !== undefined &&
        variantList[selectedVariantIndex].id &&
        !updateInitialRecommendation
      ) {
        dispatch(
          updateRecommendationSelectionState({
            primary_text:
              variantList[selectedVariantIndex]?.CreativeAiData?.primaryText
                ?.name,
            headline:
              variantList[selectedVariantIndex]?.CreativeAiData?.headline?.name,
            description:
              variantList[selectedVariantIndex]?.CreativeAiData?.description
                ?.name,
            adPreviewLink:
              variantList[selectedVariantIndex]?.payload?.adPreviewLink,
          })
        );
      } else {
        setUpdateInitialRecommendation(false);
        dispatch(
          generateAdPreview(
            getInitialRecommendationState(recommendationMetaData)
          )
        );
      }
    }
  }, [selectedVariantIndex, variantList, recommendationMetaData]);

  const handleRetryClick = () => {
    dispatch(setRecommendationLoading(true));
    const data = configureMetaData ?? getFormValues(CREATIVE_ELEMENT_AI, true);
    dispatch({
      type: "GENERATE_VARIANT_METADATA",
      payload: {
        user: auth.user,
        data: generateConfigurePayload(
          {
            ...data,
            businessUnit: BUSINESS_UNIT_BY_ID_DATA.businessUnit,
            businessUnitDetail: BUSINESS_UNIT_BY_ID_DATA.businessUnitDetail,
          },
          LEVERS
        ),
        finalAction: GENERATE_VARIANT_METADATA_STATE_VARIANT,
      },
      callback: () => {
        setIsConfiguring(false);
      },
    });
  };
  const formateRecommendationData = (data: any) => {
    const output: any = {};
    Object.keys(data).forEach((item: string) => {
      let generations: Array<string> = [];
      Object.keys(data[item]).forEach((element: any) => {
        if (data[item][element]?.generations)
          generations = [...generations, ...data[item][element]?.generations];
      });
      if (generations.length) {
        output[item] = {
          data: generations,
          selected: generations[0],
          noOfElementToShow: generations.length > 4 ? 4 : generations.length,
          showMore: generations.length > 4,
        };
      }
    });
    return output;
  };

  const handleSnackBarClose = () => {
    dispatch(showUpdateAISuccessMsgAction(false));
  };

  return (
    <>
      <StyledDrawer
        id="drawer"
        className="drawer"
        aria-hidden={showEditor ? "false" : "true"}
      >
        <div className="drawer-container">
          <div className="drawer-container--close-container">
            <div className="drawer-container--close" onClick={onCrossClick}>
              <CloseDrawer />
            </div>
          </div>

          {!loadingRecommendation &&
          !addUpdateVariantLoading &&
          selectedVariantIndex !== undefined &&
          !generateRecommendationError ? (
            <div className="drawer-container--body">
              <EditorHeader variantItem={variantList[selectedVariantIndex]} />
              {isConfiguring ? (
                <Configure
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  reset={reset}
                  watch={watch}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              ) : (
                <Recommendations
                  textFields={textFields}
                  onAdSettingChange={onAdSettingChange}
                />
              )}
              {isConfiguring ? (
                <ConfigureFooter
                  setIsConfiguring={setIsConfiguring}
                  handleSubmit={handleSubmit}
                  onGenerate={onGenerate}
                  getValues={getValues}
                  isUpdateAISettingChecked={isUpdateAISettingChecked}
                  onUpdateAISettingCheckClick={onUpdateAISettingCheckClick}
                  watch={watch}
                />
              ) : (
                <RecommendationFooter
                  setIsConfiguring={setIsConfiguring}
                  textFields={textFields}
                />
              )}
            </div>
          ) : (
            <Box
              width="100%"
              height="85vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
              alignContent="center"
            >
              {generateRecommendationError ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <h6>Error while generating Recommendation</h6>
                  <Button
                    sx={{
                      marginTop: "10px",
                    }}
                    buttonVariant="contained"
                    onClick={handleRetryClick}
                  >
                    {" "}
                    Tap to retry
                  </Button>
                </Box>
              ) : (
                <StyledEditorGifContainer>
                  {/* // <CircularProgress thickness={5} /> */}
                  <StyledEditorGif src={EditorGif} alt="" />
                  <StyledGreatBestTxt>
                    Generating Best Recommendations for your Ad
                  </StyledGreatBestTxt>
                  <StyledAISettingTxt>
                    The AI system takes a few seconds to
                  </StyledAISettingTxt>
                  <StyledAIProvideTxt>
                    provide relevant output
                  </StyledAIProvideTxt>
                </StyledEditorGifContainer>
              )}
            </Box>
          )}
        </div>
      </StyledDrawer>

      <ConfirmDialog
        show={showDiscardModal}
        onClose={() => setShowDiscardModal(false)}
        title="You have unsaved changes, do you want to discard them?"
        onComplete={onDiscard}
      />
      <AlertDialog
        show={isShowSaveVariantPrompt}
        onClose={() => {
          dispatch(isShowSaveVariantPromptAction(false));
        }}
        title="You need to first save this variant to perform any action."
      />
      <CustomizedSnackBars
        handleClose={handleSnackBarClose}
        open={showUpdateAISuccessMsg && !isGeneratingMetaData}
        msg="Successfully update AI group level setting"
      />
    </>
  );
}

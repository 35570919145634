import { crossPlatform } from './../../MappedAPI/MappedAPI';
import { call, takeEvery, takeLatest } from "@redux-saga/core/effects";
import httpService from "../../HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "../../utils/path";
import moment from 'moment';
import { GetAllPlatfromRecommendation, GetCurrencyDetails, GET_ALL_PLATFROM_RECOMMENDATION, GET_CURRENCY_DETAILS } from '../types/actionImpact';
import { currencyUrl } from '../../utils/constants/motageAds';
import axios from 'axios';

function* getAllPlatformRecommendation(action: GetAllPlatfromRecommendation): any {
	let {
		payload: { groupId, dates, user, download },
		callback,
	} = action;
	try {
		const urlParams = `${crossPlatform}/cross-platform-recommendation?ai_group=${groupId}&start_date=${moment(
			dates.start
		).format("YYYY-MM-DD")}&end_date=${moment(dates.end).format(
			"YYYY-MM-DD"
		)}&download=${download}`;
		const response = yield call(httpService.get, CROSS_PLATFORM_URL, urlParams, user);
		if (!response.data.error) {
			callback && callback(response.data, false);
		} else {
			callback && callback(response, true);
		}
	} catch (error: any) {
		console.log("function*getAllPlatformRecommendation ~ error", error)
		callback && callback(error.response, true);
	}
}

function* getCurrencyDetails(action: GetCurrencyDetails): any {
	let {
		payload: { currencyName },
		callback,
	} = action;
	try {
		const urlParams = `${currencyUrl + currencyName}.json`;
		const response = yield call(axios.get, urlParams);
		if (!response.data.error) {
			callback && callback(response.data, false);
		} else {
			callback && callback(response, true);
		}
	} catch (error: any) {
		console.log("function*getCurrencyDetails ~ error", error)
		callback && callback(error.response, true);
	}
}

export function* actionImpactWatcher() {
	yield takeLatest(GET_ALL_PLATFROM_RECOMMENDATION, getAllPlatformRecommendation);
	yield takeEvery(GET_CURRENCY_DETAILS, getCurrencyDetails);
}
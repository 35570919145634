import React from "react";
import CheckBox from "../../ui/CheckBox/CheckBox";
import Tooltip from "../../ui/Tooltip/Tooltip";
import Loader from "../common/Loader";
import "./index.scss";

const ModalContainer: React.FC<{
  title: string;
  tagline?: string;
  handleClose: (value:any) => void;
  width?: string;
  height?: string;
  submitText?: string;
  cancelText?: string;
  formDisable?: boolean;
  dontAsk?: (value:boolean) => void;
  error?: string;
  showLoader?:boolean;
  handleSubmit?: (setLoading: any) => void;
}> = ({
  title,
  tagline,
  handleClose,
  width,
  height,
  children,
  submitText,
  cancelText,
  handleSubmit,
  formDisable,
  dontAsk,
  error,
  showLoader=false,
}): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <div
      className="modal fade show d-block"
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style={{ background: "rgb(0,0,0,0.4)" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: width || "40vw", height }}
      >
        <div className="modal-content rounded border-0 w-100 h-100">
          <div className="d-flex items-align-center justify-content-between px-3 py-2 modal-title">
            <h5
              title={title}
              className="fs-6 font-weight-bold my-2 text-truncate"
              id="exampleModalLabel"
            >
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body overflow-auto h-inherit">
            {tagline && <div className="body-tagline">{tagline}</div>}
            {children}
          </div>
          {(submitText || cancelText) && (
            <div className="modal-footer">
              {dontAsk && (
                <div className="dont-ask position-relative">
                  <CheckBox onChange={(value) => dontAsk(value)} />
                  <span className="align-middle">Dont Ask me again</span>
                </div>
              )}
              {cancelText && (
                <button
                  type="button"
                  className="btn secondary"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  {cancelText}
                </button>
              )}
              {handleSubmit && (error ? (
                  <Tooltip tooltipContainer={
                    <button
                      type="button"
                      className="btn primary width-secondary"
                      disabled={loading || formDisable}
                      onClick={() => handleSubmit(setLoading)}
                    >
                      {submitText}
                    </button> 
                  }>
                    {error}
                  </Tooltip>
                ) : (
                  <button
                  type="button"
                  className="btn primary width-secondary"
                  disabled={loading || formDisable}
                  onClick={() => handleSubmit(setLoading)}
                  >
                    {showLoader && loading?<Loader height="18px" width="57px" size="sm"/>:submitText}
                  </button>
                ))
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSession } from '../store/actions/auth';
import { getSearchParams, getSessionData, postMessage, setLocalStorageData } from '../utils/commonFunctions';
import { user_session_key } from '../utils/constants';
import { useAuth0,  } from "@auth0/auth0-react";
import { setActiveBrand } from '../store/actions/adaccount';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';
let interval:any;
const Authenticate:React.FC = () => {
    const dispatch = useDispatch();
    const { loginWithRedirect, isAuthenticated, user, logout, isLoading } = useAuth0();
    const [timer, setTimer] = useState(3);
    const history = useHistory();
    const params = useParams<any>();
    const action = useMemo(() => params?.action||"login",[params?.action]);
    useEffect(() => {
        dispatch(setActiveBrand({ id: getSearchParams("brand_id") } as any))
    }, []);
    useEffect(() => {
        if(!isLoading){
            if(isAuthenticated && action === "login"){
                const data = getSessionData();
                if(data){
                    interval = setInterval(() => {
                        setTimer((t) => {
                            if(t === 1){ 
                                clearInterval(interval); 
                                window.close()
                            }
                            return t-1;
                        });
                    }, 1000);
    
                    let session:any = {
                        refreshToken: data.body.refresh_token,
                        accessToken: data.body.access_token,
                        expiresAt: data.expiresAt * 1000
                    }
                    setLocalStorageData(user_session_key, session);
                    postMessage({ from: "PYXIS_DASHBOARD", data: {session,user } });
                    dispatch(setSession(session));
                }
            } else if (action === "login" && !isAuthenticated){
                loginWithRedirect();
            } else if (action === "logout" && isAuthenticated){
                localStorage.removeItem(user_session_key); 
                postMessage({ from: "PYXIS_DASHBOARD", data: {} });
                logout({ returnTo: `${window.location.origin}/auth/login` });
            } else {
                window.location.pathname = "/auth/login";
            }
        }
    }, [isAuthenticated,isLoading]);
    if(isAuthenticated && action === "login")
    return <div className="d-flex flex-column p-3 bg-white flex-grow-1 message-container vh-100">
        <div
        className="d-flex flex-column align-items-center p-4 flex-grow-1 overflow-hidden h-100"
        style={{ background: "#f2f2f2" }}
        >
            <h4 className="font-weight-bold fs-10 m-0 w-auto mt-5">Successfully Logged In</h4>
            <p className="text-center mt-4">
                <small>
                    You have Logged In Successfully! 
                </small>
            </p>
            <div className="d-flex align-items-center">
                <button
                    className="text-white px-4 py-2 rounded border-0 ml-4"
                    style={{ background: "#0869FB" }}
                    onClick={() => window.close()}
                >
                    Close Tab (closing in {timer} seconds) 
                </button>
            </div>
        </div>
    </div>
    return <span>Redirecting...</span>;
}
export default Authenticate;
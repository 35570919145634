import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledAdVariantPreview,
  StyledAdVariantListCard,
  StyledMainCreativeHead,
  StyledVariantItemPara,
  StyledAdVariantBottomBtn,
  StyledAdVariantListButton,
  StyledHeading4New,
  StyledFrame,
} from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVariantAPICall,
  setVariants,
  updateExtraField,
  updateTotalVariantSelected,
} from "../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialState } from "../../../../../store/types/auth";
import { ConfirmDialog } from "../../../../shared";
import {
  CreateVariantsState,
  GENERATE_AD_PREVIEW_VARIANT,
} from "../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import Loader from "../../../../common/Loader";
import {
  checkAdType,
  getCreativeSPecFieldName,
} from "../../utils/common.utils";

const AdPreview: React.FC<{ index?: any; item: any }> = ({ index, item }) => {
  const { id } = item; // will be needed for future use
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { variantList, isDeletingVariant, totalSelectedVariant, createSpec } =
    useSelector(
      (state: { createVariantReducer: CreateVariantsState }) =>
        state.createVariantReducer
    );

  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);

  const deleteVariant = () => {
    dispatch(
      deleteVariantAPICall({ item: { ...item, index }, user: auth.user })
    );
    setShowDeletePopUp(false);
  };

  const onEdit = () => {
    dispatch(
      updateExtraField({
        isVariantEditing: true,
        selectedVariantIndex: index,
        showEditor: true,
      })
    );
  };

  const onCheckBocClick = () => {
    const localVariantList = [...variantList];

    localVariantList[index] = {
      ...localVariantList[index],
      checked: !isChecked,
    };
    dispatch(setVariants({ data: localVariantList }));
    dispatch(updateTotalVariantSelected(id));
    setIsChecked(!isChecked);
  };

  const iframe = () => {
    return {
      __html: item?.payload?.adPreviewLink,
    };
  };

  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    var doc: any = new DOMParser().parseFromString(
      item?.payload?.adPreviewLink,
      "text/xml"
    );
    const iframSrc = doc?.firstChild.getAttribute("src");
    setIframeSrc(iframSrc);
  }, [item?.payload?.adPreviewLink]);

  function resizeIframe(obj: any) {
    obj.style.height =
      obj.contentWindow.document.documentElement.scrollHeight + "px";
  }

  useEffect(() => {
    setIsChecked(totalSelectedVariant.includes(id));
  }, [id, totalSelectedVariant]);

  const [previewDelay, setPreviewDelay] = useState(false);

  useEffect(() => {
    loadingFunction();
    const expiryDifference = 1000 * 60 * 60 * 20; // 20 hours
    if (
      item?.id &&
      (!item?.payload?.previewExpiry ||
        +new Date() - item?.payload?.previewExpiry >= expiryDifference)
    ) {
      const newCreateSpec = {
        ...createSpec,
        object_story_spec: {
          ...createSpec.object_story_spec,
          [checkAdType(createSpec)]: {
            ...createSpec.object_story_spec[checkAdType(createSpec)],
            [getCreativeSPecFieldName({
              type: checkAdType(createSpec),
              field: "primary_text",
            })]: item?.CreativeAiData?.primaryText?.name,
            [getCreativeSPecFieldName({
              type: checkAdType(createSpec),
              field: "headline",
            })]: item?.CreativeAiData?.headline?.name,
            [getCreativeSPecFieldName({
              type: checkAdType(createSpec),
              field: "description",
            })]: item?.CreativeAiData?.description?.name,
          },
        },
      };
      dispatch({
        type: GENERATE_AD_PREVIEW_VARIANT,
        payload: {
          adAccountId,
          creativeSpec: newCreateSpec,
          adId: item?.adId,
          auth,
          variantId: item?.id,
          index,
          payload: item?.payload,
          recommendationSelectionState: {
            headline: item?.CreativeAiData?.headline?.name,
            description: item?.CreativeAiData?.description?.name,
            primaryText: item?.CreativeAiData?.primaryText?.name,
          },
        },
      });
    }
  }, []);

  const loadingFunction = () => {
    setTimeout(() => {
      setPreviewDelay(true);
    }, 2500);
  };

  return (
    <>
      <StyledAdVariantPreview key={id}>
        <StyledAdVariantListCard>
          <StyledMainCreativeHead>
            <span>
              <StyledHeading4New className="fw-bold">
                {`Variant #${index + 1}`}
              </StyledHeading4New>
              <StyledVariantItemPara
                title={item?.payload?.settings?.variantName}
              >
                {item?.payload?.settings?.variantName ?? "-"}
              </StyledVariantItemPara>
            </span>
            <Checkbox checked={isChecked} onClick={onCheckBocClick} />
          </StyledMainCreativeHead>
          {
            <>
              <StyledFrame
                style={{ display: previewDelay ? "block" : "none" }}
                className="iframScroll"
                src={iframeSrc}
              ></StyledFrame>
              {!previewDelay && (
                <>
                  <div
                    style={{
                      width: "100%",
                      height: "450px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                </>
              )}
            </>
          }
        </StyledAdVariantListCard>
        <StyledAdVariantBottomBtn>
          <StyledAdVariantListButton className="mr-4" onClick={onEdit}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </StyledAdVariantListButton>
          <StyledAdVariantListButton onClick={() => setShowDeletePopUp(true)}>
            <FontAwesomeIcon icon={faTrash} className="text-danger" />
          </StyledAdVariantListButton>
        </StyledAdVariantBottomBtn>
      </StyledAdVariantPreview>
      <ConfirmDialog
        show={showDeletePopUp}
        onClose={() => setShowDeletePopUp(false)}
        title="Are you sure you wanted to delete this variant"
        onComplete={deleteVariant}
        completeButtonText="Confirm"
        loading={isDeletingVariant}
      />
    </>
  );
};

export default AdPreview;

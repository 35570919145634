import { getSearchParams } from "../../utils/commonFunctions";
import { SetActiveBrand, SetActivePlatform, SetBrandLoading, SetGroupId, SetGroups, SetGroupsLoading, SetPlatform, SetSelectedAdaccounts, SetSelectedAdSet, SetSelectedCampaign, SetSelectedGroup, SetSelectedBrandAdaccounts, SET_ACTIVE_BRAND, SET_ACTIVE_PLATFORM, SET_BRAND_LOADING, SET_GROUPS, SET_GROUPS_LOADING, SET_GROUP_ID, SET_PLATFORM, SET_SELECTED_ADACCOUNT, SET_SELECTED_AD_SET, SET_SELECTED_CAMPAIGN, SET_SELECTED_GROUP, SET_SELECTED_BRAND_ADACCOUNTS, SET_SELECTED_GROUP_ADACCOUNTS, SetSelectedGroupAdaccounts, SetPlatformId, SET_PLATFORM_ID, SetTargetingGroups, SET_TARGETING_GROUPS, SetAdAccountLoading, SET_ADACCOUNT_LOADING, SetSinglePlatformAdaccounts, SET_SINGLE_PLATFORM_ADACCOUNTS, SET_SAVINGCALCULATOR_DATA, SetSavingCalculatorData, GET_SAVINGCALCULATOR_DATA, SetSelectedElementId, SET_SELECTED_ELEMENTID, SET_SELECTED_GROUP_CAMPAIGN, SetSelectedGroupCampaign, SetSelectedGroupAdset, SET_SELECTED_GROUP_ADSET, SET_SELECTED_TARGETING_ADACCOUNT, SET_SELECTED_GSAI_ADACCOUNT, SetSelectedTargetingAdaccount, SetSelectedGSAIAdaccount, SET_SELECTED_BRAND_GSAI_ADACCOUNTS, SetSelectedBrandGSAIAdaccounts, SET_GSAI_PLATFORM, SetGSAIPlatform, SetSelectedBrandTargetingAdaccounts, SET_SELECTED_BRAND_TARGETING_ADACCOUNTS } from "../types/adaccount";

const initialState = {
    groups: [],
    groupsLoading: false,
    selectedGroup: {},
    groupId: getSearchParams("group_id"),
    selectedAdSet: [],
    selectedBrand: {
        id: getSearchParams("brand_id")
    },
    brandLoading: false,
    platforms: [],
    selectedPlatform: {},
    selectedCampaign: {},
    selectedBrandAdaccounts: [],
    selectedBrandTargetingAdaccounts: [],
    selectedAdAccount: {},
    selectedGroupAdaccounts: [],
    platformId: {},
    targetingGroups: null,
    adaccountLoading: false,
    singlePlatformAdaccounts: {},
    savingCalculator: {},
    selectedElementId: null,
    selecteGroupCamp: [],
    selecteGroupAdset: [],
    selectedTargetingAdaccount: {},
    selectedGSAIAdaccount: {}
};

const commonReducer = (state = initialState, action: SetGroups | SetGroupsLoading | SetSelectedGroup
    | SetGroupId | SetSavingCalculatorData | SetSelectedAdSet | SetActiveBrand | SetBrandLoading | SetPlatform | SetActivePlatform | SetGSAIPlatform | SetSelectedCampaign | SetSelectedBrandAdaccounts | SetSelectedBrandGSAIAdaccounts
    | SetSelectedAdaccounts | SetSelectedGroupAdaccounts | SetPlatformId | SetTargetingGroups | SetAdAccountLoading | SetSinglePlatformAdaccounts | SetSelectedElementId
    | SetSelectedGroupCampaign | SetSelectedGroupAdset | SetSelectedTargetingAdaccount | SetSelectedGSAIAdaccount | SetSelectedBrandTargetingAdaccounts
) => {
    switch (action.type) {
        case SET_GROUPS: {
            return {
                ...state,
                groups: action.payload
            }
        }
        case SET_GROUPS_LOADING: {
            return {
                ...state,
                groupsLoading: action.payload
            }
        }
        case SET_SELECTED_GROUP: {
            return {
                ...state,
                selectedGroup: action.payload
            }
        }
        case SET_GROUP_ID: {
            return {
                ...state,
                groupId: action.payload
            }
        }
        case SET_SELECTED_AD_SET: {
            return {
                ...state,
                selectedAdSet: action.payload
            }
        }
        case SET_ACTIVE_BRAND: {
            return {
                ...state,
                selectedBrand: action.payload
            }
        }
        case SET_BRAND_LOADING: {
            return {
                ...state,
                brandLoading: action.payload
            }
        }
        case SET_PLATFORM: {
            return {
                ...state,
                platforms: action.payload
            }
        }
        case SET_ACTIVE_PLATFORM: {
            return {
                ...state,
                selectedPlatform: action.payload
            }
        }
        case SET_GSAI_PLATFORM: {
            return {
                ...state,
                selectedGSAIPlatform: action.payload
            }
        }
        case SET_SELECTED_CAMPAIGN: {
            return {
                ...state,
                selectedCampaign: action.payload
            }
        }
        case SET_SELECTED_BRAND_ADACCOUNTS: {
            return {
                ...state,
                selectedBrandAdaccounts: action.payload
            }
        }
        case SET_SELECTED_BRAND_GSAI_ADACCOUNTS: {
            return {
                ...state,
                selectedBrandGSAIAdaccounts: action.payload
            }
        }
        case SET_SELECTED_BRAND_TARGETING_ADACCOUNTS: {
            return {
                ...state,
                selectedBrandTargetingAdaccounts: action.payload
            }
        }
        case SET_SELECTED_ADACCOUNT: {
            return {
                ...state,
                selectedAdAccount: action.payload
            }
        }
        case SET_SELECTED_GROUP_ADACCOUNTS: {
            return {
                ...state,
                selectedGroupAdaccounts: action.payload
            }
        }
        case SET_PLATFORM_ID: {
            return {
                ...state,
                platformId: action.payload
            }
        }
        case SET_TARGETING_GROUPS: {
            return {
                ...state,
                targetingGroups: action.payload
            }
        }
        case SET_ADACCOUNT_LOADING: {
            return {
                ...state,
                adaccountLoading: action.payload
            }
        }
        case SET_SINGLE_PLATFORM_ADACCOUNTS: {
            return {
                ...state,
                singlePlatformAdaccounts: action.payload
            }
        }
        case SET_SAVINGCALCULATOR_DATA: {
            return {
                ...state,
                savingCalculator: action.payload
            }
        }
        case SET_SELECTED_ELEMENTID: {
            return {
                ...state,
                selectedElementId: action.payload
            }
        }
        case SET_SELECTED_GROUP_CAMPAIGN: {
            return {
                ...state,
                selecteGroupCamp: action.payload
            }
        }
        case SET_SELECTED_GROUP_ADSET: {
            return {
                ...state,
                selecteGroupAdset: action.payload
            }
        }
        case SET_SELECTED_TARGETING_ADACCOUNT: {
            return {
                ...state,
                selectedTargetingAdaccount: action.payload
            }
        }
        case SET_SELECTED_GSAI_ADACCOUNT: {
            return {
                ...state,
                selectedGSAIAdaccount: action.payload
            }
        }
        default:
            return state;
    }
};

export default commonReducer;
import React, { useState } from "react";
import CustomizedTable from "../../shared/Table/CustomizedTable";
import {
  StyledPlainTableCell,
  StyledPlainTableRow,
} from "../../shared/Table/styled";
import { businessTableHead } from "../../../utils/constants/creativeAI";
import { StyledAdVariantListButton } from "../Facebook/CreateVariants/AdPreview/styled";
import { useDispatch } from "react-redux";
import BuPlaceHolder from "./BuPlaceHolder/BuPlaceHolder";
import EditIcon from "../../../assets/svg/buEditIcon.svg";
import DeleteIcon from "../../../assets/svg/buDeleteIcon.svg";
import { AlertConfirm } from "../../shared/Modal/AlertConfiem";

const BrandSettingTable: React.FC<{
  businessUnits: any;
  handleBusinessEdit: any;
  handleBusinessUnitDelete: any;
}> = ({ businessUnits, handleBusinessEdit, handleBusinessUnitDelete }) => {
  // if (!businessUnits.length) {
  //   return <BuPlaceHolder />;
  // }
  const [alertConfirmEvent, setAlertConfirmEvent] = useState(false);
  const [activeDeleteId, setActiveDeleteId] = useState("");

  const handelAlertConfirm = (event: any, id: any) => {
    setActiveDeleteId(id);
    setAlertConfirmEvent(event);
    if (event?.sText === "ok" && id) {
      handleBusinessUnitDelete(id);
      setAlertConfirmEvent(false);
    }
    if (event?.sText === "cancel" && id) {
      setAlertConfirmEvent(false);
    }
  };

  return (
    <CustomizedTable
      tableHeadValues={businessTableHead}
      tableWidth="100%"
      tableType="plainTable"
    >
      {businessUnits.length === 0 ? (
        <StyledPlainTableRow>
          <StyledPlainTableCell align="center" colSpan={7} width="90%">
            <BuPlaceHolder />
          </StyledPlainTableCell>
        </StyledPlainTableRow>
      ) : (
        <>
          {businessUnits?.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <StyledPlainTableRow>
                  <StyledPlainTableCell component="th" scope="row" align="left">
                    {index + 1}
                  </StyledPlainTableCell>
                  <StyledPlainTableCell align="left">
                    {item?.businessUnit}
                  </StyledPlainTableCell>
                  <StyledPlainTableCell align="left">
                    {item?.status === "readyToUse"
                      ? "Ready To Use"
                      : "In Review"}
                  </StyledPlainTableCell>
                  <StyledPlainTableCell align="left">
                    {item?.updatedBy ?? "-"}
                  </StyledPlainTableCell>
                  <StyledPlainTableCell align="left">
                    <StyledAdVariantListButton
                      className="mr-4"
                      onClick={() => handleBusinessEdit(item.id)}
                    >
                      <img src={EditIcon} alt="" />
                    </StyledAdVariantListButton>
                    <StyledAdVariantListButton
                      // onClick={() => handleBusinessUnitDelete(item?.id)}
                      onClick={() => handelAlertConfirm(true, item?.id)}
                    >
                      <img src={DeleteIcon} alt="" />
                    </StyledAdVariantListButton>
                  </StyledPlainTableCell>
                </StyledPlainTableRow>
                {alertConfirmEvent && item.id === activeDeleteId && (
                  <AlertConfirm
                    bodyText="Are you sure you want to delete"
                    dialogType="confirm" //"confirm" || "alert" || dialog
                    // headerText="Want to Delete id" // optional
                    completeButtonText="Ok"
                    // loading={true}
                    id={item?.id}
                    onHandel={handelAlertConfirm}
                  />
                )}
              </React.Fragment>
            );
          })}
        </>
      )}
    </CustomizedTable>
  );
};

export default BrandSettingTable;

import React, { ReactElement } from "react";
import HandleClickOutside from "../../components/common/HandleClickOutside";
import "./Dropdown.scss";

const Dropdown: React.FC<{
  className?: string;
  toggelButton?: ReactElement;
  isDropDownVisible: boolean;
  setIsDropDownVisible: any;
  style?:any;
  disabled?:boolean,
}> = ({
  children,
  className,
  toggelButton,
  isDropDownVisible,
  setIsDropDownVisible,
  style,
  disabled=false,
}) => {
  const wrapperRef = React.useRef(null);
  HandleClickOutside(wrapperRef, [setIsDropDownVisible]);

  return (
    <div className={`${className} dropdown-container`} ref={wrapperRef} style={style}>
      <div
        className="dropdow-btn"
        onClick={() => {
          setIsDropDownVisible(!isDropDownVisible);
        }}
      >
        {toggelButton}
      </div>
      {!disabled && isDropDownVisible && (
        <div className="dropdown-item-container">{children}</div>
      )}
    </div>
  );
};

export default Dropdown;

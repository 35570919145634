import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 62,
  height: 32,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 3,
    padding: 0,
    transform: "translateX(2px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(27px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#DAE3F3",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#507AD7",
        width: 23,
        height: 24,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#ffffff",
    width: 25,
    height: 25,
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#A1A1A1",
    borderRadius: 20 / 2,
  },
}));

interface CustomizedButtonProps extends SwitchProps {}

const CustomizedSwitch: React.FC<CustomizedButtonProps> = ({ ...props }) => {
  return <StyledSwitch {...props} />;
};

export default CustomizedSwitch;

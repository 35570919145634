import { SetBarChartData, SetChartLoading, SetCPRDetails, SetCPRGraphDetails, SetCPRRecommendation, SetCPRRecommendationLoading, SetGroupRecommendationDetailLoading, SetGroupRecommendationLoading, SetIndustryType, SetModelText, SetRecommendationDetails, SetRecommendations, SET_BAR_CHART_DATA, SET_CHART_LOADING, SET_CPR_DETAILS, SET_CPR_GRAPH_DETAILS, SET_CPR_RECOMMENDATION, SET_CPR_RECOMMENDATION_LOADING, SET_GROUP_RECOMMENDATION_DETAIL_LOADING, SET_GROUP_RECOMMENDATION_LOADING, SET_INDUSTRY_TYPE, SET_MODEL_TEXT, SET_RECOMMENDATIONS, SET_RECOMMENDATION_DETAILS } from "../types/common";

const initialState = {
  modelText: {},
  industryType: [],
  recommendations: {},
  recommendationDetails: {},
  cprDetails: {},
  cprGraphDetails: {},
  barChartData: null,
  cprRecommendation: null,
  chartLoading: false,
  cprRecommendationLoading: false,
  groupRecommendationLoading: false,
  groupRecommendationDetailLoading: false,
};

const commonReducer = (state = initialState, action: SetModelText | SetIndustryType | SetRecommendations | SetRecommendationDetails
  | SetCPRDetails | SetCPRGraphDetails | SetBarChartData | SetCPRRecommendation | SetChartLoading
  | SetCPRRecommendationLoading | SetGroupRecommendationLoading | SetGroupRecommendationDetailLoading
) => {
  switch (action.type) {
    case SET_MODEL_TEXT: {
      return {
        ...state,
        modelText: action.payload
      }
    }
    case SET_INDUSTRY_TYPE: {
      return {
        ...state,
        industryType: action.payload
      }
    }
    case SET_RECOMMENDATIONS: {
      return {
        ...state,
        recommendations: action.payload
      }
    }
    case SET_RECOMMENDATION_DETAILS: {
      return {
        ...state,
        recommendationDetails: action.payload
      }
    }
    case SET_CPR_DETAILS: {
      return {
        ...state,
        cprDetails: action.payload
      }
    }
    case SET_CPR_GRAPH_DETAILS: {
      return {
        ...state,
        cprGraphDetails: action.payload
      }
    }
    case SET_BAR_CHART_DATA: {
      return {
        ...state,
        barChartData: action.payload
      }
    }
    case SET_CPR_RECOMMENDATION: {
      return {
        ...state,
        cprRecommendation: action.payload
      }
    }
    case SET_CHART_LOADING: {
      return {
        ...state,
        chartLoading: action.payload
      }
    }
    case SET_CPR_RECOMMENDATION_LOADING: {
      return {
        ...state,
        cprRecommendationLoading: action.payload
      }
    }
    case SET_GROUP_RECOMMENDATION_LOADING: {
      return {
        ...state,
        groupRecommendationLoading: action.payload
      }
    }
    case SET_GROUP_RECOMMENDATION_DETAIL_LOADING: {
      return {
        ...state,
        groupRecommendationDetailLoading: action.payload
      }
    }
    default:
      return state;
  }
};

export default commonReducer;
import React, { useEffect, useState } from "react";
import HorizontalLinearStepper from "../../../../components/shared/Stepper/Stepper";
import VariantsListing from "../../../../components/CreativeAI/Facebook/SelectCohort/VariantsListing";
import {
  StyledContainer,
  StyledTitle,
  StyledBottomContainer,
  StyledMiddleContainer,
} from "./styled";
import { createVariantsStepper } from "../../../../utils/constants/creativeAI";
import { useHistory, useLocation } from "react-router-dom";
import CreateVariants from "../../../../components/CreativeAI/Facebook/CreateVariants/CreateVariants";
import DiscardDraftModal from "../../../../components/CreativeAI/Facebook/CreateVariants/DiscardDraftModal";
import ReviewModal from "../../../../components/CreativeAI/Facebook/CreateVariants/ReviewModal";
import { Button } from "../../../../components/shared";
import { useDispatch, useSelector } from "react-redux";
import { CreateVariantsState } from "../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { resetState } from "../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialStateCreateVariant } from "../../../../store/reducers/CreativeAI/Facebook/CreateVariant/createVariantReducer";
import { Box } from "@mui/material";

const CreateVariantsLayout: React.FC = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  // All State
  const [activeStep, setActiveStep] = useState(0);
  const [showDiscardDraftModal, setShowDiscardDraftModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const dispatch = useDispatch();
  const { totalSelectedVariant, isPublishingVariant } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );

  const { selectedAiGroupObject } = useSelector(
    (state: any) => state.aiGroupReducer
  );

  useEffect(() => {
    if (location?.pathname === "/creative-ai/variants/cohort") {
      setActiveStep(0);
    }
    if (location?.pathname === "/creative-ai/variants/create") {
      setActiveStep(1);
    }
  }, [location?.pathname]);

  const toggleDiscardDraftModal = () => {
    setShowDiscardDraftModal(!showDiscardDraftModal);
  };

  const toggleReviewModal = (event?: string) => {
    setShowReviewModal(!showReviewModal);
    setActiveStep(event === "close" ? 1 : 2);
  };

  useEffect(() => {
    dispatch(resetState(initialStateCreateVariant));
  }, []);

  return (
    <>
      {activeStep === 0 && (
        <StyledTitle>{`AI Group - ${selectedAiGroupObject?.name} -> Create Variants`}</StyledTitle>
      )}
      <StyledContainer>
        <HorizontalLinearStepper
          orientation={"horizontal"}
          steps={createVariantsStepper}
          stepperWidthWithPercent={80}
          activeStep={activeStep}
        />
      </StyledContainer>
      <StyledMiddleContainer height={activeStep === 0 ? 204 : 166}>
        {activeStep === 0 ? <VariantsListing /> : <CreateVariants />}
      </StyledMiddleContainer>
      <StyledBottomContainer>
        {activeStep === 0 && (
          <Button
            onClick={() =>
              history.push(`/creative-ai${history.location.search}`)
            }
            buttonVariant="outlined"
            sx={{
              padding: ".75rem 1.5rem .75rem 1.5rem",
              fontFamily: "Inter",
              // fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Go Back
          </Button>
        )}

        {activeStep === 1 && (
          <>
            <Button
              onClick={() =>
                history.push(
                  `/creative-ai/variants/cohort${history.location.search}`
                )
              }
              buttonVariant="outlined"
              sx={{
                padding: ".75rem 1.5rem .75rem 1.5rem",
                fontFamily: "Inter",
                // fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Go Back
            </Button>
            <Box height="73">
              <Button
                onClick={() => toggleDiscardDraftModal()}
                buttonVariant="outlined"
                sx={{
                  padding: ".75rem 1.5rem .75rem 1.5rem",
                  fontFamily: "Inter",
                  // fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Discard Drafts
              </Button>

              <Button
                sx={{
                  marginLeft: "20px",
                  padding: ".75rem 1.5rem .75rem 1.5rem",
                  color: "#ffffff",
                  fontFamily: "Inter",
                  // fontSize: "16px",
                  fontWeight: "600",
                }}
                buttonVariant="contained"
                disabled={totalSelectedVariant.length === 0}
                onClick={() => toggleReviewModal()}
              >
                Review ({totalSelectedVariant.length})
              </Button>
            </Box>
          </>
        )}
      </StyledBottomContainer>
      {showDiscardDraftModal && (
        <DiscardDraftModal
          show={showDiscardDraftModal}
          onHide={toggleDiscardDraftModal}
        />
      )}

      {(showReviewModal || isPublishingVariant) && (
        <ReviewModal show={showReviewModal} onHide={toggleReviewModal} />
      )}
    </>
  );
};

export default CreateVariantsLayout;

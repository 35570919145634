import { IconButton } from "@mui/material";
import styled from "styled-components";

interface Para {
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
}
export const StyledVariantContainerCreateVariant = styled.div`
  // height: 510px;
  // height: 75vh;
  height: 100%;
`;
export const StyledMainWidth = styled.div`
  width: 100%;
  // min-width: 1017px;
  min-width: 1280px;
`;
export const StyledMainWidthCV = styled.div`
  width: 100%;
  min-width: 1280px;
  height: calc(100% - 147px);
`;

export const StyledAds = styled.div`
  overflow: auto;
  overflow-x: hidden;
  // height: 60vh;
  height: 100%;
  margin-top: 10px;
  width: 318px;

  &::-webkit-scrollbar {
    //**** Will search  alternate in future ***//
    height: 0;
    width: 0;
  }
`;

export const StyledNotSupportText = styled.p`
  padding-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  text-transform: capitalize;
  color: #7a7a7a;
`;

export const StyledHorizontalScrollMainCreativeCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 8px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    background-color: #e0e0e0;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
  }
`;

export const StyledAddVariantCard = styled.div`
  width: 172px;
  height: 204px;
  background: #fbfcff;
  border: 1px dashed #0869fb;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAddVariantCardDisabled = styled.div`
  width: 172px;
  height: 204px;
  background: #fbfcff;
  border: 1px dashed #0869fb;
  border-radius: 8px;
  opacity: 0.35;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAddVariantSpan = styled.span`
  color: #0869fb;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;
export const StyledAddVariant = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  /* width: 20%; */
`;

export const StyledReviewModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
`;
export const StyledPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledVariantItemPara = styled.p<Para>`
  margin-bottom: 0px;
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-family: ${(props) => props.fontFamily};
`;

export const StyledReviewSpan = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledReviewBtn = styled.span`
  & .close-button {
    background: #e5e1e1;
    width: 106px;
    border: 1px solid #e5e1e1;
    color: #000000;
    font-weight: bold;
    margin-right: 20px;
    padding: 16px 24px;
    font-family: Inter;
    font-size: 16px !important;
  }
  & .close-button:hover {
    background: #ddd6d6;
    width: 106px;
    border: 1px solid #ddd6d6;
    color: #000000;
    font-weight: bold;
    margin-right: 20px;
    font-family: Inter;
    font-size: 16px !important;
    padding: 16px 24px;
  }
  & .publish-button {
    background: #0869fb;
    border: 1px solid #0869fb;
    color: #ffffff;
    font-weight: 500;
    margin-right: 20px;
    font-family: Inter;
    font-size: 16px !important;
    padding: 16px 24px;
  }
  & .publish-button:hover {
    background: #0869fb;
    border: 1px solid #0869fb;
    color: #ffffff;
    font-weight: 500;
    margin-right: 20px;
    font-family: Inter;
    font-size: 16px !important;
    padding: 16px 24px;
  }
`;
export const StyledMainCreativeContainer = styled.div`
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* position: fixed;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999999999999999999; */
`;
export const StyledCloseButton = styled.div`
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* position: fixed;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999999999999999999; */
`;
export const StyledOverFlow = styled.div`
  overflow: auto;
  // overflow-y: hidden;
`;
export const StyledOverFlowNew = styled.div`
  overflow: auto;
  height: 100%;
`;
export const StyledNotSupportTextAVDIS = styled.p`
  margin-bottom: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: 7A7A7A;
  opacity: 0.5;
  margin-top: 15px;
`;

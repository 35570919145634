import React, { useEffect } from 'react';
import { userDetailForm, UserDetailsFormValidationFields, viewInvoices } from '../../utils/constants/userForm';
import UserDetailsFormInput from './UserDetailsFormInput';
import './index.scss';
import InputText from '../../ui/InputText/InputText';
import CheckBox from '../../ui/CheckBox/CheckBox';
import ModalWrapper from '../Modal/ModalWrapper';
import ModalContainer from '../Modal/ModalContainer';
import { updateUserDetails, getAgreement, getClientPDF, setModal} from '../../store/actions/form';
import { useDispatch, useSelector } from "react-redux";
import { FormState } from '../../store/types/form';
import { initialState } from '../../store/types/auth';
import { AdAccountState } from '../../store/types/adaccount';
import Loader from '../common/Loader';

type formDataProp = {
    userType: string,
    userEmail: string,
    userName?: string,
    createdAt?: any,
}

const UserDetails: React.FC<any> = () => {
    const [userDetailFormData, setUserDetailFormData] = React.useState(userDetailForm);
    const [checkStatus, setCheckStatus] = React.useState(false);
    const [formDisable, setFormDisable] = React.useState(false);
    const [loader, setLoader] = React.useState(true);
    const [pdfFileData, setPdfFileData] = React.useState("");
    const dispatch = useDispatch();
    const formState = useSelector((state: { form: FormState }) => state.form);
    const { modal } = formState;
    const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
    const { selectedBrand } = adaccount;
    const [formData, setFormData] = React.useState<formDataProp>({
        userType:"Client",
        userEmail:"",
    });
    
    const auth = useSelector((state: { auth:initialState }) => state.auth);
    const [formUpdated, setFormUpdated] = React.useState(false);
    const handleChange = (key: string, value:string | number) => {
        if (key === "userType" && selectedBrand) {
            dispatch(getClientPDF(
                {brandId:selectedBrand.id,user:auth?.user,userType:value},
                (response:any,error:boolean) => {
                    if (!error) {
                        setPdfFileData(response);
                    }
                }
            ));
            let newFormField:any;
            if (value === "Agency") {
               
                newFormField = userDetailFormData.map((val) => {
                    if(val.key === "gstin" || val.key === "pan") {
                        return {...val,show: false }
                    }
                    return val;
                }) 
            } else if (value === "Client") {
                newFormField = userDetailFormData.map((val) => {
                    if(val.key === "gstin" || val.key === "pan") {
                        return {...val,show: true }
                    }
                    return val;
                })
            }
           
            setUserDetailFormData(newFormField);
        }
        setFormData({...formData,[key]:value});
    };

    const validateForm = (data:any) => {
        for (let index = 0; index < UserDetailsFormValidationFields.length; index++) {
            if(!(UserDetailsFormValidationFields[index] in data) || !data[UserDetailsFormValidationFields[index]]) {
                return false;
            }
        }
        return true;
    }

    const submitDetails = () => {
        if (checkStatus) {
            if (validateForm(formData) && auth) {
                setLoader(true);
                dispatch(updateUserDetails(
                    {brandId: selectedBrand.id, user: auth.user, param:formData},
                    (response: any, error: boolean) => {
                        setLoader(false);
                        if (!error) {
                            dispatch(setModal({key:"confirmation"}));
                        } else {
                            dispatch(setModal({key:"error"}));
                        }
                    }
                ));
            }
        } else {
            dispatch(setModal({key:"termsAndConditionWarning"}));
        }
        
    }

    const getAgreementData = () => {
        setLoader(true);
        dispatch(getAgreement(
            {brandId:selectedBrand.id,user:auth?.user},
            (response:any, error:boolean) => {
                setLoader(false);
                if (response && response.data && response.data.userType && !error) {
                    setFormData(response.data);
                    setFormUpdated(true);
                    let newFormField:any;
                    if (response.data.userType === "Agency") {
                        newFormField = userDetailFormData.map((val) => {
                            if(val.key === "gstin" || val.key === "pan") {
                                return {...val,show: false }
                            }
                            return val;
                        }) 
                    } else if (response.data.userType === "Client") {
                        newFormField = userDetailFormData.map((val) => {
                            if(val.key === "gstin" || val.key === "pan") {
                                return {...val,show: true }
                            }
                            return val;
                        })
                    }
                    setUserDetailFormData(newFormField);
                    dispatch(getClientPDF(
                        {brandId:selectedBrand?.id,user:auth?.user,userType:response.data.userType},
                        (response:any,error:boolean) => {
                            if (!error) {
                                setPdfFileData(response);
                            }
                        }
                    ));
                }
            }
        ));
    }

    const openFile = () => {
        // Create a Blob from the PDF Stream
        const file = new Blob([pdfFileData], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        // const pdfWindow = window.open();
        // if (pdfWindow) {
        //     pdfWindow.location.href = fileURL; 
        // }
    }

    useEffect(() => {
        if (auth && selectedBrand && selectedBrand.id) {
            dispatch(getClientPDF(
                {brandId:selectedBrand.id,user:auth?.user,userType:"client"},
                (response:any,error:boolean) => {
                   
                    if (!error) {
                        setPdfFileData(response);
                    }
                }
            ));
            getAgreementData();
        }
    }, [selectedBrand])

    useEffect(() => {
        if (formData  && validateForm(formData)) {
            setFormDisable(false);
        } else {
            setFormDisable(true);
        }
    }, [formData])

    return <div className="d-flex flex-column p-4 bg-white flex-grow-1 box-shadow rounded user-details" style={{ height: "20px",overflow: "scroll" }}>
            {loader ? <Loader  />  : 
            (<><div className="user-details d-flex justify-content-between">
                <div key="user_email" className="form-group width-40">
                    <label className="text-md form-title">User Email ID *</label>
                    <InputText name="user_email" disable={formUpdated} value={formData.userEmail} onChange={(e) => handleChange("userEmail",e.target.value)}/>
                </div>
                <div><button onClick={() => window.open(viewInvoices,"_blank")} className="btn secondary" >View My Invoices</button></div>
            </div>
            <div className="user-details-form d-flex flex-wrap">
            {userDetailFormData.map((value:any)=> {
                return (<UserDetailsFormInput disable={formUpdated} formData={formData} formKey={value.key} data={value} handleChanage={handleChange} />)
            })}
            </div>
            <div className="footer-user-details">
                <div className="terms pb-2"><CheckBox disabled={formUpdated} value={formUpdated} onChange={(value) => {
                    if(value){
                        setCheckStatus(true);
                    } else {
                        setCheckStatus(false);
                    }
                }}/> I agree to the <a className="link-click" onClick={openFile} rel="noreferrer">terms and condition</a></div>
                <div className="footer-button pt-3">
                    <div className={"approved-by"}>{auth && formUpdated && (`User agreement Approved by `+ formData.userName +` at `) + new Date(formData.createdAt).toLocaleString()} </div>
                    <div>
                        <button onClick={openFile} className="btn secondary">View User Agreement</button>
                        <button onClick={submitDetails} disabled={formDisable || formUpdated} className="btn primary">Confirm</button>
                    </div>
                </div>
            </div>
            {modal && (
                <ModalWrapper>
                    {modal.key === "termsAndConditionWarning" || modal.key === "error" && ( 
                        <ModalContainer title={"Warning"} submitText={"OK"} handleClose={()=>dispatch(setModal(null))} handleSubmit={()=> {
                            dispatch(setModal(null));
                        }} tagline={""}>
                            {modal.key === "error" ? (<p>Form not subimitted successfully. Please connect with user admin.</p>) : (<p>Please read the terms and conditions and agree the same to proceed further.</p>)}
                        </ModalContainer>
                    )}
                    {modal.key === "confirmation" && ( 
                        <ModalContainer title={"Updated Successfully"} submitText={"Continue"} handleClose={()=>{
                            setFormUpdated(true);
                            getAgreementData();
                            dispatch(setModal(null));}} 
                        handleSubmit={()=> {
                            setFormUpdated(true);
                            getAgreementData();
                            dispatch(setModal(null));
                        }} tagline={""}>
                            <p>Your User Agreement has been successfully Updated.</p>
                        </ModalContainer>
                    )}
                </ModalWrapper>)
            }
        </>)}
        </div>;
}

export default UserDetails;

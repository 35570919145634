//@ts-nocheck
import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import ActionImpactHeader from "../components/ActionImpactHeader/ActionImpactHeader";
import ActionImpaceMetrics from "../components/ActionImpactMetrics/ActionImpaceMetrics";
import ActionImpactTable from "../components/ActionImpactTable/ActionImpactTable";
import Loader from "../components/common/Loader";
import NoData from "../components/common/NoData";
import { getCampaignMetrics, getGroupMetrics, getMetricsCumulative, getRecommendation, setChartCordsData, setChartData, setChartOption, setChartTabs, setMetricCumulative, setRecommendation, setSelectedOption, setSettingPostspinner, setSpinner } from "../store/actions/campaign";
import { ActionImpactState } from "../store/types/actionImpact";
import { CampaignState } from "../store/types/campaign";
import { formatChartInfo, formatChartOptions, getCurrencySymbol, getRandomColor } from "../utils/commonFunctions";
import { getAllowRejectRecommendation } from "../store/actions/auth";
import { initialState } from "../store/types/auth";
import { FACEBOOK_PLATFORM_NAME } from "../utils/constants";
import "./actionImpact.scss";
import { AdAccountState } from "../store/types/adaccount";
import { getAdsetDetails, getCampaignDetails, setGroups, setGroupsLoading, setSelectedGroupAdset, setSelectedGroupCampaign } from "../store/actions/adaccount";
import { useHistory } from "react-router-dom";
import { setLinkClicks } from "../store/actions/actionImpact";
import { commonOptions } from "../utils/constants/actionImpact";
import { mixPanelAdEvent } from "../utils/mixpanel";

const ActionImpactAnalysis: React.FC<unknown> = () => {

  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedAdSet , selectedBrand, selectedPlatform, selectedAdAccount, selectedGroup, groups, selectedCampaign } = adaccount;
  const campaign = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { chartTabs, chartData, chartOption, spinner, selectedOption, chartCordsData } = campaign;
  const actionImpact = useSelector((state: { actionImpact: ActionImpactState }) => state.actionImpact);
  const { linkClicks } = actionImpact;
  const { user,...authState} = useSelector((state: { auth:initialState }) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedBrand?.name) {
      mixPanelAdEvent('Page View - Dashboard', {
        'module_name': "Action Impact Analysis - Single platforms",
        'brand_name': selectedBrand?.name,
      });
    }
  }, [selectedBrand]);

  useEffect(() => {
    dispatch(setChartCordsData([]));
    dispatch(setChartData([]));
    if (linkClicks && selectedBrand && selectedAdAccount && selectedAdAccount.elementId) {
      dispatch(getMetricsCumulative({ elementId:selectedAdAccount.elementId, dates: linkClicks, user, selectedAdSet: selectedPlatform?.name && selectedPlatform.name === FACEBOOK_PLATFORM_NAME ?  selectedAdSet : [] , selectedCampaign: selectedPlatform?.name && selectedPlatform.name !== FACEBOOK_PLATFORM_NAME ? selectedCampaign : [] },
        (response: any, error: boolean) => {
          if (!error) {
            dispatch(setMetricCumulative(selectedPlatform?.name && selectedPlatform.name?.toLowerCase().includes("facebook") ? response.data.metrics[0] : response.data.metrics));
          } else {
            dispatch(setMetricCumulative([]));
            console.log(error);
          }
          }
        ));
        dispatch(setSpinner(true));
        dispatch(getCampaignMetrics({ elementId:selectedAdAccount.elementId, dates: linkClicks, user, selectedAdSet: selectedPlatform?.name && selectedPlatform.name === FACEBOOK_PLATFORM_NAME ?  selectedAdSet : [], selectedCampaign: selectedPlatform?.name && selectedPlatform.name !== FACEBOOK_PLATFORM_NAME ? selectedCampaign : []  },
          (response: any, error: boolean) => {
          dispatch(setSpinner(false));
          if (!error) {
            if (
              response.data.metrics && Object.keys(response.data.metrics).length > 0
            ) {
              dispatch(setChartData(
                formatChartInfo(
                  Object.keys(response.data.metrics).map((key) => ({
                    "Date Stop": key,
                    ...response.data.metrics[key],
                  })),linkClicks
                )
              ))
              dispatch(setChartOption(
                formatChartOptions(
                  Object.keys(response.data.metrics).map((key) => ({
                    "Date Stop": key,
                    ...response.data.metrics[key],
                  }))
                )
              ))
            } else {
              dispatch(setChartData(formatChartInfo(response.data.metrics[0])))
              dispatch(setChartOption(formatChartOptions(response.data.metrics[0])))
            }
          } else {
            dispatch(setSpinner(false));
            console.log(error);
            dispatch(setChartData({}));
            dispatch(setChartOption([])); 
          }
        }
      ));
  } else {
    dispatch(setMetricCumulative([]));
    dispatch(setChartData({}));
    dispatch(setChartOption([])); 
  }
}, [linkClicks, selectedPlatform?.id, selectedAdAccount?.elementId, selectedCampaign,selectedAdSet]);

  useEffect(() => {
    if (selectedAdAccount?.elementId) {
      dispatch(getGroupMetrics({ elementId:selectedAdAccount.elementId , user },
      (response: any, error: boolean) => {
        if (!error) {
          const data = response.data.map((res: any) => ({
            ...res,
            isSelected: res.label === "Spends" ? true : res.uiVisible,
            uiVisible: res.label === "Spends" ? true : res.uiVisible,
            color: getRandomColor(),
          }));
          dispatch(setChartTabs(data));
        } else {
          dispatch(setChartTabs([]));
          console.log(error);
        }
      }
      ));
    } else {
      dispatch(setChartTabs([]));
    }
  }, [selectedAdAccount])

  useEffect(() => {
    dispatch(getAllowRejectRecommendation());
  }, []);

  useEffect(() => {
    if (chartData && chartTabs ) {
      const selectMetric: [] = chartTabs.reduce((selected, { isSelected, label }) => {
        if (isSelected) {
          selected.push(label);
        }
        return selected;
      }, []);
      let datasets = chartData?.datasets?.map((d: any) => {
        const metric = chartTabs.find((tab: any) => tab.label === d.label);
        // Currency is used in tooltip (currency is added to tooltip from constant file -> commonOptions -> tooltips)
        const currency =
          metric?.showCurrency && authState?.accountDetails?.currency
            ?  getCurrencySymbol(authState?.accountDetails?.currency)
            : "";
        const data =
        selectedPlatform?.name && selectedPlatform.name?.toLowerCase().includes("google")
            ? metric?.showCurrency
              ? d.data.map((d: any) => d / 1_000_000)
              : metric?.filter === "interaction_rate"
              ? d.data.map((d: any) => d * 100)
              : d.data
            : d.data;
        return {
          ...d,
          data,
          backgroundColor: metric?.color,
          borderColor: metric?.color,
          currency,
          hidden: !selectMetric.includes(d.label),
        };
      });
      dispatch(setChartCordsData({ ...chartData, datasets }));
    } else {
      dispatch(setChartCordsData({ ...chartData, datasets: [] }));
    }
  }, [chartData, chartTabs, authState?.accountDetails]);

  useEffect(() => {
    if (chartOption && chartTabs) {
      const selectMetric: [] = chartTabs.reduce((selected, { isSelected, label }) => {
        if (isSelected) {
          selected.push(label);
        }
        return selected;
      }, []);
      let selectedOptions = chartOption?.filter((o: any) => selectMetric.includes(o.id));
      let options = chartOption?.map((o: any, index: number) => ({
        ...o,
        position: selectedOptions.length === 1 ? "left" : "right",
        display: selectedOptions.length === 1 ? selectedOptions[0].id === o.id : index === 0,
        ticks: {
          display: selectedOptions.length === 1 ? selectedOptions[0].id === o.id : false,
          beginAtZero: true,
        },
        gridLines: {
          display: selectedOptions.length === 1 ? true : index === 0,
        },
      }));
      dispatch(setSelectedOption({
        ...commonOptions,
        maintainAspectRatio: false,
        scales: {
          yAxes: options,
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
                unitStepSize: 1,
                displayFormats: {
                  day: "MM-DD",
                },
              },
            },
          ],
        },
      }));
    }
  }, [chartOption, chartTabs]);

  useEffect(() => {
    if (selectedPlatform && selectedPlatform.id && selectedGroup?.id && selectedAdAccount?.id) {
      dispatch(setSelectedGroupCampaign([]));
      dispatch(getCampaignDetails({ group_id: selectedGroup.id, user: user }, (campRes: any, campErr: boolean) => {
        if (!campErr && campRes.data.length > 0) {
          let filteredData:any = campRes.data.filter((campaign: any) => campaign.platformId === selectedPlatform.id && campaign.adAccountId === selectedAdAccount.id);
          dispatch(setSelectedGroupCampaign(filteredData));
        }
      }))
    }
  }, [selectedAdAccount])

  useEffect(() => {
    if (selectedGroup && selectedAdAccount.id && groups.length > 0) {
        if (selectedGroup?.id) {
          dispatch(getAdsetDetails({ group_id: selectedGroup.id, user: user }, (adsetRes: any, adsetErr: boolean) => {
            if (!adsetErr && adsetRes.data.length > 0) {
              let newAdSets = adsetRes.data.filter((adset:any) => adset.adAccountId === selectedAdAccount.id  )
              dispatch(setSelectedGroupAdset(newAdSets));
            }  else {
              console.log(adsetErr);
            }
          }));
        }
    }
  }, [selectedAdAccount])

  const performanceTooltip = (props?: any) => (
    <div {...props} className="custom-tooltip-layout tooltip">
      <div className="tootltip-wrapper">
        <div className="tooltip-header">Performance Analysis</div>
        This shows AI-recommended actions to be taken on individual elements like ad sets and
        campaigns to optimize their performance.
      </div>
    </div>
  );

  return (
    <>
      <div className="performance_analysis" style={{ flexGrow: 1 }}>
        <ActionImpactHeader title="Performance Analysis" tooltip={performanceTooltip} 
          localStorageKey="graph-date" linkClicks={linkClicks} setLinkClicks={setLinkClicks}/>
        {!spinner && <ActionImpaceMetrics />}
        <div className="chartbox">
          {spinner && <Loader />}
          {!spinner && selectedGroup && chartCordsData?.datasets?.length > 0 && chartTabs.length > 0 && (
            <div className="chartbox-container">
              <div className="ml-4 mt-2"></div>
              {selectedOption &&
                chartData?.datasets?.length === selectedOption?.scales?.yAxes?.length && (
                  <Line data={chartCordsData} options={selectedOption} />
                )}
            </div>
          )}
          {!spinner && (!chartData?.datasets?.length > 0) && (
            <NoData />
          )}
        </div>
      </div>
      <ActionImpactTable />
    </>
  );
};

export default ActionImpactAnalysis;

import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";
export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #0869fb;
    /* need to find another way instead of putting !important */
    padding-top: -20px !important;
    height: 3px;
  }
  .Mui-selected {
    opacity: 1;
    color: #000000 !important;
    font-family: Lato !important;
  }
`;
export const StyledTab = styled(Tab)`
  text-transform: none;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
  opacity: 0.3;
  margin-left: 6px;
  font-family: Lato !important;
  :focus {
    opacity: 1;
    color: #000000;
    font-family: Lato !important;
  }
`;

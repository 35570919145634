//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import info from "../../assets/svg/smallinfo.svg";
import PyxisTable from "../../components/common/PyxisTable/PyxisTable";
import ModalContainer from "../../components/Modal/ModalContainer";
import ModalWrapper from "../../components/Modal/ModalWrapper";
import { getRecommendation, rejectRecommendation, setRecommendation, setSettingPostspinner } from "../../store/actions/campaign";
import { ActionImpactState } from "../../store/types/actionImpact";
import { initialState } from "../../store/types/auth";
import { getCurrencySymbol } from "../../utils/commonFunctions";
import useSortableData from "../../utils/useSortableData";
import "./index.scss";
import { CampaignState } from "../../store/types/campaign";
import { AdAccountState } from "../../store/types/adaccount";

const TableTitle = () => {
  const target2 = React.useRef(null);
  
  const recommendationsTooltip = (props?: any) => (
    <div {...props} className="custom-tooltip-layout tooltip">
      <div className="tootltip-wrapper">
        <div className="tooltip-header">Action Recommendations</div>
        This shows AI-recommended actions to be taken on individual elements like ad sets and
        campaigns to optimize their performance.
      </div>
    </div>
  );

  return <h4 className="campaigns_header fs-6 p-0 pt-4 ml-4 d-flex">
  Action Recommendations
  <div className="position-relative tooltip-container">
    <i ref={target2}>
      <img className="ml-2" src={info} alt="info_style" />
    </i>
    {recommendationsTooltip()}
  </div>
</h4>
}

const ActionImpactTable: React.FC = () => {
  
  const actionImpact = useSelector((state: { actionImpact: ActionImpactState}) => state.actionImpact);
  const { linkClicks } = actionImpact;
  const [modal, setModal] = React.useState(null);
  const [activeCustomHeader, setActiveCustomHeader] = React.useState('');
  const [dateTime, _] = React.useState(linkClicks);
  const campaign = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { settingPostspinner, recommendation  } = campaign;
  const {  accountDetails, allowRejectRecommendation, user } = useSelector((state: { auth:initialState }) => state.auth);
  const { items, requestSort } = useSortableData(recommendation || []);
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedAdSet, selectedAdAccount } = adaccount;

  const actionImpactTableHeader = React.useMemo(
    () => [
      {
        name: "Recommendation",
        isSortable: true,
        sortKey: "recommendation_time",
        isEnabled: true,
        tableData:
          !!accountDetails?.timezone_offset_hours_utc && "(Date and Time UTC " +
          (accountDetails?.timezone_offset_hours_utc > 0 ? "+" : "-") +
          accountDetails?.timezone_offset_hours_utc +
          ")",
      },
      {
        name: "Item to Change",
        isSortable: true,
        isEnabled: true,
        sortKey: "item_to_change",
      },
      {
        name: "Recommended Action",
        isSortable: true,
        sortKey: "recommended_action",
        isEnabled: true,
        type: "analytics",
        tableData: !!accountDetails?.currency && "(in " + (getCurrencySymbol(accountDetails?.currency) || "") + ")",
      },
      {
        name: "Action Basis",
        isSortable: true,
        isEnabled: true,
        type: "analytics",
        sortKey: "action_basis",
      },
      {
        name: "Confidence Score",
        isSortable: true,
        isEnabled: true,
        type: "analytics",
        sortKey: "confidence_score",
      },
      {
        name: "Action Execution Time",
        isSortable: true,
        isEnabled: true,
        sortKey: "action_execution_time",
        type: "setup",
        tableData:
        !!accountDetails?.timezone_offset_hours_utc && "(Date and Time UTC " +
          (accountDetails?.timezone_offset_hours_utc > 0 ? "+" : "-") +
          accountDetails?.timezone_offset_hours_utc +
          ")",
      },
      {
        name: "Phase",
        isSortable: true,
        isEnabled: true,
        sortKey: "phase",
      },
      {
        name: "Executed Action",
        isSortable: true,
        isEnabled: true,
        sortKey: "executed_action",
        type: "analytics",
        tableData: !!accountDetails?.currency && "(in " + (getCurrencySymbol(accountDetails?.currency) || "") + ")",
      },
      {
        name: "Status",
        isSortable: true,
        isEnabled: true,
        sortKey: "status",
      },
      {
        name: "Message",
        isSortable: true,
        isEnabled: false,
        sortKey: "message",
      },
      {
        name: "Campaign Name",
        isSortable: true,
        isEnabled: false,
        sortKey: "campaign_name",
      },
      {
        name: "Campaign Id",
        isSortable: true,
        isEnabled: false,
        sortKey: "campaign_id",
      },
      {
        name: "Concept Name",
        isSortable: true,
        isEnabled: false,
        sortKey: "concept_name",
      },
      {
        name: "Concept Type",
        isSortable: true,
        isEnabled: false,
        sortKey: "concept_type",
      },
      {
        name: "FB Ad Account ID",
        isSortable: true,
        isEnabled: false,
        sortKey: "fb_ad_account_id",
      },
      {
        name: "CPR",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "cpr",
      },
      {
        name: "CPR Lookback",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "cpr_lookback",
      },
      {
        name: "Spend",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "spend",
      },
      {
        name: "Spend Lookback",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "spend_lookback",
      },
      {
        name: "Results",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "results",
      },
      {
        name: "Results Lookback",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "results_lookback",
      },
      {
        name: "Lookback Period",
        isSortable: true,
        isEnabled: false,
        sortKey: "lookback_period",
      },
      {
        name: "Aggregation Concept Name",
        isSortable: true,
        isEnabled: false,
        sortKey: "aggregation_concept_name",
      },
      {
        name: "Updated Daily Budget",
        isSortable: true,
        isEnabled: false,
        sortKey: "updated_daily_budget",
      },
      {
        name: "Updated Bid Amount",
        isSortable: true,
        isEnabled: false,
        sortKey: "updated_bid_amount",
      },
    ],
    [accountDetails?.currency, accountDetails?.timezone_offset_hours_utc]
  );

  const [updatedTableHeader, setUpdatedTableHeader] = React.useState(actionImpactTableHeader);
  const [dateRange, setDateRange] = React.useState({});
  const dispatch = useDispatch();

  const isGooglePlatform = React.useMemo(
    () => (accountDetails ? (accountDetails.platform === "google" ? true : false) : false),
    [accountDetails]
  );


  useEffect(() => {
    if (dateRange.end) {
      const activeHeader = !!activeCustomHeader ? activeCustomHeader : actionImpactTableHeader
        .filter((elem: any) => elem.isEnabled)
        .map((element: any) => element.sortKey)
        .toString();
        dispatch(setSettingPostspinner(true))
        dispatch(getRecommendation({ elementId: selectedAdAccount.elementId, dates: dateRange, user, activeHeader },
          (response: any, error: boolean) => {
          if (!error) {
            dispatch(setRecommendation(response.data))
          } else {
            console.log(error);
          }
          dispatch(setSettingPostspinner(false));
        }
      ));
    }
  }, [dateRange]);

  useEffect(() => {
    if (linkClicks  && selectedAdAccount.elementId) {
      setDateRange({});
      const activeHeader = actionImpactTableHeader
        .filter((elem: any) => elem.isEnabled)
        .map((element: any) => element.sortKey)
        .toString();
      if (!isGooglePlatform) {
        if (linkClicks.start === dateTime.start && linkClicks.end === dateTime.end)
        dispatch(setSettingPostspinner(true))
        dispatch(getRecommendation({ elementId: selectedAdAccount.elementId, dates: dateTime, user, activeHeader },
          (response: any, error: boolean) => {
          if (!error) {
            dispatch(setRecommendation(response.data))
          } else {
            console.log(error);
          }
          dispatch(setSettingPostspinner(false));
        }
      ));
      } else {
        dispatch(setSettingPostspinner(true))
        dispatch(getRecommendation({ elementId: selectedAdAccount.elementId, dates: linkClicks, user, activeHeader },
          (response: any, error: boolean) => {
          if (!error) {
            dispatch(setRecommendation(response.data))
          } else {
            console.log(error);
          }
          dispatch(setSettingPostspinner(false));
        }
      ));
      }
    }
  }, [selectedAdAccount, linkClicks]);

  const handleRejectRecommendation = React.useCallback(
    (setLoading) => {
      setLoading(true);
      const data = items[modal?.index];
      dispatch(rejectRecommendation({ id: data.id, concept_type: data.concept_type, user },
        (response: any, error: boolean) => {
        if (!error) {
          dispatch(setRecommendation(recommendation.map((r) => {
            if (data.id === r.id) {
              r.status = "Rejected";
            }
            return r;
          })));
          setLoading(false);
        } else {
          console.log(error);
        }
        setModal(null);
      }
    ));
    },
    [items, modal?.index]
  );

  const updateTableData = (activeHeader) => {
    setActiveCustomHeader(activeHeader)
    linkClicks && dispatch(setSettingPostspinner(true))
    linkClicks && dispatch(getRecommendation({ elementId: selectedAdAccount.elementId, dates: dateRange.end ? dateRange : linkClicks, user, activeHeader },
      (response: any, error: boolean) => {
      if (!error) {
        dispatch(setRecommendation(response.data))
      } else {
        console.log(error);
      }
      dispatch(setSettingPostspinner(false));
    }
    ));
  };

  useEffect(() => {
    setUpdatedTableHeader(actionImpactTableHeader);
  }, [actionImpactTableHeader]);

  return (
    <div className="action_recommendations" style={{ flexGrow: 1 }}>
      
      <PyxisTable
        tableTitle = {TableTitle}
        type={"actionImpact"}
        responsiveType={"md"}
        showTableTopBar={isGooglePlatform}
        maxHeight={"300px"}
        linkClicks={dateRange.end ? dateRange : linkClicks}
        setDateRange={setDateRange}
        tableContainerClass = {accountDetails?.platform === 'facebook' ? 'facebook-action-recommendation-table' : 'google-action-recommendation-table'}
        tableClass={`mt-4 ml-4 overflow-scroll pyxis-table`}
        tableHeader={accountDetails?.platform === 'facebook' ? updatedTableHeader : updatedTableHeader.filter((h:any)=>h.name!=="Phase")}
        data={(recommendation && items && items.length > 0 && items) || []}
        updateTableData={updateTableData}
        setUpdatedTableHeader={setUpdatedTableHeader}
        requestSort={requestSort}
        loading={settingPostspinner}
        handleAction={!allowRejectRecommendation ? (index: number) => setModal({ index }) : null}
      />
      {modal && (
        <ModalWrapper>
          <ModalContainer
            title="Reject Recommendation"
            cancelText="Close"
            submitText={"Reject"}
            handleSubmit={handleRejectRecommendation}
            handleClose={() => setModal(false)}
          >
            <p>
              Are you sure you want to cancel this recommendation from execution on ad account?
              Rejecting this recommendation might impact your performance.
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
    </div>
  );
};

export default ActionImpactTable;

export const selectableTableHead = [
  { name: "AI Group Name", align: "left" },
  { name: "No.of Ad Sets", align: "left" },
];

export const createVariantsStepper = [
  "Select Ad-set/Cohort",
  "Create Variants",
  "Review & Publish",
];

export const reviewVariantTableHead = [
  { name: "Ad Name", align: "left" },
  { name: "Variants to publish", align: "center" },
];

export const businessTableHead = [
  { name: "S.No", align: "left" },
  { name: "Business Unit", align: "left" },
  { name: "Status", align: "left" },
  { name: "Last Updated By", align: "left" },
  { name: "Action", align: "left" },
];

import styled from "styled-components";

export const StepperContainer = styled.div`
  display: flex;
  justify-content: center;

  .MuiStepIcon-text {
    fill: #fff !important;
  }
  .MuiStepLabel-label {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #000000 !important;
  }
  .Mui-active {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    color: #0869fb !important;
  }
  .stepper-step {
    .Mui-active {
      circle {
        color: #0869fb;
      }
      text {
        fill: #fff !important;
      }
    }
    circle {
      color: #edf3fe;
    }
    text {
      fill: #247afb !important;
    }
  }
`;

import ConfigureKeywordInfoItem from "./ConfigureKeywordInfoItem";

const configureKeywordInfoItems = [
  {
    title: "What is Business Unit?",
    desc: "Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit. ",
  },
  {
    title: "What is Display Brand Name?",
    desc: "Display brand name refers to how you want the brand name to be displayed in the ad copy. You can define how the brand is represented at a business unit level too.",
  },

  {
    title: "What is Interest Keyword?",
    desc: "Interest keyword refers to the crux of the ad generations. It indicates what the ad should deliver based on the product/service category and what the ad generations should be based on.",
    items: [],
  },
];

export default function ConfigureKeywordInfo() {
  return (
    <div className="target-keyword-info">
      {configureKeywordInfoItems.map(({ title, desc, items }) => (
        <ConfigureKeywordInfoItem title={title} desc={desc} items={items} />
      ))}
    </div>
  );
}

export const uploadKeywordPath = "upload-keywords";
export const keywordsClustering = "keywords-clustering";
export const CreateAdAndPublish = "create-ad-and-publish";
export const gooleSearchAi = "googlesearchai";


export const creativeReplacement = {
    creative_replacement: false,
    headline: [
      {
        selected: false,
        label: "Best Performance",
        performance: "BEST",
        interval: "",
      },
      {
        selected: false,
        label: "Good Performance",
        performance: "GOOD",
        interval: "",
      },
      {
        selected: false,
        label: "Low Performance",
        performance: "LOW",
        interval: "",
      },
    ],
    description: [
      {
        selected: false,
        label: "Best Performance",
        performance: "BEST",
        interval: "",
      },
      {
        selected: false,
        label: "Good Performance",
        performance: "GOOD",
        interval: "",
      },
      {
        selected: false,
        label: "Low Performance",
        performance: "LOW",
        interval: "",
      },
    ],
  };
export const editCluster = {
    ext_id: null,
    adDetails: {},
  };
  
export const saveCluster = {
    ext_id: null,
    adDetails: {},
  };
  
  export const loadHeadlineDescription = {
    headline: false,
    description: false,
  };
  
export const staticTargetKeyword = {
    title: "",
    generationType: [
      {
        label: "Interest Specific",
        value: "interest",
        selected: true,
      },
      {
        label: "Brand Specific",
        value: "brand",
        selected: true,
      },
      {
        label: "Generic",
        value: "generic",
        selected: true,
      },
    ],
    offer: "",
    cta: "",
};


export const maxTimeLimit = 60000;

export const clusterManagerTableHeaders = [
  {
    value: "Cluster Name",
    tooltipValue:
      "Cluster name is a unique identification property for a collection of similar or related keywords.",
  },
  {
    value: "Keywords",
    tooltipValue:
      "Keywords are words or phrases that describe a user's search intent. Keywords are used in placement of your ads in Google's display network and rank search results.",
  },
  {
    value: "Ad Group Name",
    tooltipValue:
      "Ad Group Name is a unique identification property for a group of ads with similar or related properties.",
  },
  {
    value: "Campaign Name",
    tooltipValue:
      " Campaign Name is the unique identification property for a collection of Ad Groups. A single campaign includes multiple Ad Groups.",
  },
  {
    value: "Type of Ad Group",
    tooltipValue:
      "GS AI (Google Search AI) are those ad groups which were newly published from our system. ",
  },
  {
    value: "AI Group",
    tooltipValue: "AI Group is a collection of similar or related campaigns. ",
  },
  {
    value: "Ad",
    tooltipValue:
      "Ad is a promotional piece of communication that provides information or motivates buying behavior based on the user's intent.  ",
  },
  {
    value: "Date",
    tooltipValue: "The ad creation date.",
  },
  {
    value: "Creative Replacement",
    tooltipValue:
      "If the toggle is on,creative replacement was activated during ad creation.Turn this off to stop creative replacement. ",
  },
  {
    value: "Status",
    tooltipValue:
      "Gives the status of the ad - processing, published, replacement triggered or replacement ",
  },
  {
    value: "Replacement Log",
    tooltipValue:
      "A log of all the creative replacements that have occurred for the ad. ",
  },
];

export const gSAILogsTableHeader = [
  "Date",
  "Asset Type",
  "Original Assets",
  "Position Pinning",
  "Performance",
  "New Assets",
];
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";

interface totalAds {
  color?: string;
  componentType?: string;
}

export const StyledVariantsRows = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  height: 139px;
  padding: 10px 25px;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const StyledVariantItem = styled.div`
  // height: 4rem;
`;
export const StyledVariantFirstItem = styled.div`
  // height: 4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
  width: 160px;
`;
export const StyledVariantItemParaTitle = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 500;
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;
export const StyledVariantItemParaTitleInDraft = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 500;
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: Inter;
  margin-top: 8px;
  font-style: normal;
`;
export const StyledVariantItemParaTitleAdSet = styled.p<totalAds>`
  margin-bottom: 0px;
  font-size: 14px;
  color: ${(props) => props.color};
  font-weight: 500;
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: Inter;
  margin-top: ${(props) => (props.componentType == "cohort" ? "15px" : "0px")};
`;
export const StyledVariantItemParaTitlePV = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 500;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 155px;
  font-family: Inter;
`;
export const StyledVariantItemPara = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
  width: 160px;
  font-family: Inter;
  font-style: normal;
  text-transform: capitalize;
`;
export const StyledVariantItemParaBigFont = styled.p`
  margin-bottom: 0px;
  font-size: 18px;
  color: #333333;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
  width: 160px;
  font-family: Inter;
`;
export const StyledVariantItemParaDT = styled.p`
  margin-bottom: 0px;
  font-size: 18px;
  color: #333333;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
  width: 160px;
`;

export const StyledLinkItem = styled(Link)`
  font-size: 14px;
  color: #0869fb;
`;

export const StyledCreateVariantRecommended = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
  color: #89b84f;
  display: flex;
  justify-content: start;
  align-item: center !important;
  font-family: Inter;
`;
export const StyledCreateVariantSpan = styled.p`
  background: #b2b2b2;
  width: 9px;
  height: 9px;
  color: #fff;
  border-radius: 5px;
  font-size: 7px;
  text-align: center;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 0px;
`;

export const StyledLinkViewMore = styled(Link)`
  font-size: 11px;
  color: #0869fb;
  margin-left: 5px;
  font-weight: 400;
  font-family: Inter;
  text-decoration-line: underline !important;
  font-style: normal;
`;

export const StyledCreateVariantButton = styled(Link)`
  display: block;
  padding: 10px 24px;
  border-radius: 10px;
  background: #edf3fe;
  border: 1px solid #edf3fe;
  color: #0869fb;
  margin-bottom: 5px;
  font-size: 16px;
  text-decoration: none !important;
  font-weight: 500;
  font-family: Inter;
`;

export const StyledVariantItemRecommended = styled.div`
  // height: 4rem;
  width: 221px;
  margin-bottom: 5px;
  // width: 193px;
  // overflow: hidden;
`;

export const StyledVariantItemParaRecommended = styled.div`
  margin-bottom: 0px;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  width: 210px;
  // width: 193px;
  font-family: Inter;
  font-style: normal;
`;

export const StyledVariantItemCreate = styled.div`
  height: 7rem;
`;
//we will use on hover
export const StyledHoverText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #e84b47;
  display: none;
`;

export const StyledAnchor = styled.a`
  font-size: 14px;
`;
export const StyledAnchorSA = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #0869fb;
`;
export const StyledDivider = styled(Divider)`
  background: #6a6969;
`;

import { sub } from "date-fns";
import moment from "moment/moment";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DropDownArrow } from "../../assets/svg/dropdownarrow.svg";
import info from "../../assets/svg/smallinfo.svg";
import { setLinkClicks } from "../../store/actions/actionImpact";
import { getPhaseTimeline, setPhaseTimeline } from "../../store/actions/campaign";
import { ActionImpactState, LinkClicks } from "../../store/types/actionImpact";
import { CampaignState } from "../../store/types/campaign";
import { initialState } from "../../store/types/auth";
import DateRangePicker from "../../ui/DateRangePicker/DateRangePicker";
import PhaseTimeline from "../PhaseTimeline/PhaseTimeline";
import "./index.scss";
import { AdAccountState } from "../../store/types/adaccount";
import HandleClickOutside from "../common/HandleClickOutside";
import { getModuleName, mixPanelAdEvent } from "../../utils/mixpanel";
import { useHistory } from "react-router-dom";

const ActionImpactHeader: React.FC<{
    title:string,
    tooltip:any,
    localStorageKey?:string,
    linkClicks: LinkClicks,
    setLinkClicks: any
  }> = ({title, tooltip, localStorageKey, linkClicks, setLinkClicks}) => {
  const campaign = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { phaseTimeline } = campaign;
  const authState = useSelector((state: { auth:initialState }) => state.auth);
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedBrand, selectedAdAccount } = adaccount;
  const target = useRef(null);
  const dispatch = useDispatch();
  const [showPhaseTimline, setShowPhaseTimline] = React.useState<boolean>(false);
  const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
  const [dateSelection, setDateSelection] = React.useState([
    {
      startDate: new Date(),
      endDate: sub(new Date(), { days: 6 }),
      key: "selection",
    },
  ]);
  const history = useHistory();

  React.useEffect(() => {
    if (selectedBrand?.id) {
      dispatch(getPhaseTimeline({ brandId: selectedBrand.id, user:authState?.user },
        (response: any, error: boolean) => {
        if (!error) {
          dispatch(setPhaseTimeline(response));
        } else {
          dispatch(setPhaseTimeline({}));
          console.log(error);
        }
      }
      ));
      if (localStorageKey && localStorage.getItem(localStorageKey)) {
        const date = JSON.parse(localStorage.getItem(localStorageKey)!);
        if (date) {
          setDateSelection([
            {
              startDate: moment(new Date(date?.start)).isBefore(sub(new Date(), { days: 24 }))
                ? sub(new Date(), { days: 24 })
                : new Date(date?.start),
              endDate: new Date(date?.end),
              key: "selection",
            },
          ]);
        }
      }
    }
  }, [selectedBrand]);

  const onDateRangeChange = (value:any) => {
    const date = {
      start: moment(value.start).format("YYYY-MM-DD"),
      end: moment(value.end).format("YYYY-MM-DD"),
    };
    if(localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(date));
    }
    dispatch(setLinkClicks(value));
  }
      
  const onDateSelect = () => {
    onDateRangeChange({
      start: dateSelection[0].startDate,
      end: dateSelection[0].endDate,
    });
    setShowCalendar(false);
    mixPanelAdEvent('Date Range Selected', {
      'module_name': getModuleName(history.location.pathname),
      'feature_name': 'Graph - Action Impact',
      'brand_name': selectedBrand?.name,
      'ad_account_id': history.location.pathname === "/singlePlatform" ? selectedAdAccount?.id || null : 'ALL' ,
      'ad_account_name': history.location.pathname === "/singlePlatform" ? selectedAdAccount?.name || null : 'ALL'
    });
  };

  const wrapperRef = React.useRef(null);
  HandleClickOutside(wrapperRef,[setShowCalendar, setShowPhaseTimline]);

  return (
    <div className="header-container">
      <h4 className="campaigns_header fs-6 p-0 pt-3 ml-4 d-flex">
        {title}
        <div className="position-relative tooltip-container">
          <i ref={target}>
            <img className="ml-2" src={info} alt="info_style" />
          </i>
          {tooltip()}
        </div>
      </h4>
      <div ref={wrapperRef} className="phase-container" >
        <div className="date-range-picker">
          <button
            className="btn secondary"
            onClick={() => {
              setShowPhaseTimline(false);
              setShowCalendar(!showCalendar);
            }}
          >
            {`${moment(linkClicks.start).format("ll")} - ${moment(linkClicks.end).format("ll")}`}
            <DropDownArrow style={{ marginLeft: 8, paddingLeft: 3, marginBottom: 3 }} />
          </button>
          {showCalendar && (
            <DateRangePicker
              className="performance-calendar"
              dateSelection={dateSelection}
              setDateSelection={setDateSelection}
              maxLimit={0}
              minLimit={24}
              onSelect={onDateSelect}
            />
          )}
        </div>
        <div className="phase-container" ref={wrapperRef}>
          <button
            className="btn secondary btn-relative"
            onClick={() => {
              setShowCalendar(false); 
              setShowPhaseTimline(!showPhaseTimline);
            }}
          >
            Phase timeline
            {showPhaseTimline && <PhaseTimeline phaseData={phaseTimeline} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionImpactHeader;

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./outlineScreen.scss";
import ButtonUI from "../../ui/ButtonUI/ButtonUI";
import { useDispatch } from "react-redux";
import {
  getMontageCountry,
  getMontageValues,
  postMontageAds,
} from "../../store/actions/montageAds";
import { productsCategory, currency } from "../../utils/constants/motageAds";
import { ReactComponent as LeftArrow } from "../../assets/svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/svg/rightArrow.svg";
import { MontageCampaigns } from "../../store/types/montageAds";
import { initialState } from "../../store/types/auth";
import { AdAccountState } from "../../store/types/adaccount";
import { NavLink, useHistory } from "react-router-dom";
import OutlineModal from "./OutlineModal";
import MontageSetup from "./MontageSetup";
import MontageCreatorTargeting from "./CreatorTargeting";
import MontageCreativeBrief from "./CreativeBrief";

const OutlineScreen: React.FC = (): JSX.Element => {
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const { user } = authState;
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const [montageGuidelines, setMontageGuidelines] = useState<any[]>([]);
  const [montageCurrency, setMontageCurrency] = React.useState<any>([]);
  const [montageCountry, setMontageCountry] = React.useState<any>([]);
  const [formError, setFormError] = React.useState<boolean>(false);
  const [submitCampaign, setSubmitCampaign] = React.useState(false);
  const [showModal, setShowModal] = React.useState<boolean>(true);
  
  const [montageAds, setMontageAds] = React.useState<MontageCampaigns>({
    name: "",
    productName: "",
    productCategory: "",
    productCurrency: "",
    productUrl: "",
    productFiles: [],
    targetGender: "All",
    targetAgeMin: 16,
    targetAgeMax: 16,
    country: "",
    language:"",
    interests: "",
    videoLayout: "",
    videoLength: "",
    videoStyle: "",
    contentGuidelinesDos: [],
    contentGuidelinesDonts: [],
    styleGuide: "",
  });
  const [montageValidation, setMontageValidation] = React.useState<any>({
    name: false,
    creativesCount: false,
    productName: false,
    productCategory: false,
    productValue: false,
    productCurrency: false,
    productUrl: false,
    targetGender: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getMontageValues((montageRes: any, montageErr: boolean) => {
        if (!montageErr && montageRes.data) {
          setMontageGuidelines(montageRes.data.guidelines);
          setMontageCurrency(montageRes.data.currencies);
        }
      })
    );
    dispatch(
      getMontageCountry((countryRes: any, montageErr: boolean) => {
        if (!montageErr) {
          const resCountry = countryRes.data.sort((a:any, b:any) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase(); 
            if(nameA === nameB) return 0; 
            return nameA > nameB ? 1 : -1
          });
          setMontageCountry(resCountry);
          setMontageAds((mAds: any) => ({
            ...mAds,
            targetGender: montageAds.targetGender,
            productCategory: productsCategory[0].value,
            country: resCountry[resCountry.length-1].name,
            productCurrency: currency[0].value,
          }));
        } else {
          setMontageAds((mAds: any) => ({
            ...mAds,
            targetGender: montageAds.targetGender,
            productCategory: productsCategory[0].value,
            productCurrency: currency[0].value,
          }));
        }
      })
    );
  }, []);

  const handleChange = (e: any) => {
    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCreative=(value:any,name:any)=>{
    if(name==="targetGender"){ 
    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      targetGender: value,
    }));
  }
  else if(name==="videoLayout"){
    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      videoLayout: value,
    }));
  }
  else if(name==="videoLength"){
    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      videoLength: value,
    }));
  }
  else if(name==="videoStyle"){
    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      videoStyle: value,
    }));
  }
  }

  //launch campaign
  const handleInputChange = (e: any) => {
    if (e.target.value.trim() == "") {
      if (montageValidation[e.target.name] === false) {
        setMontageValidation({ ...montageValidation, [e.target.name]: true });
      }
    } else {
      if (montageValidation[e.target.name] === true) {
        setMontageValidation({ ...montageValidation, [e.target.name]: false });
      }
    }
    setMontageAds((montageAds: MontageCampaigns) => ({
      ...montageAds,
      [e.target.name]: e.target.value,
    }));
  };

  

  const handleOpenModal = () => {
    setShowModal(true)
    setSubmitCampaign(true);
  };

  const handleSubmitCampaign = () => {
    setShowModal(true)
    let errors = {};
    let isError = false;
    {
      if (montageAds.name.trim() == "") {
        errors = { ...errors, name: true };
        isError = true;
      }
      if(montageAds.creativesCount && montageAds.creativesCount <= 0){
        errors = { ...errors, creativesCountNegative: true };
        isError = true;
      }
      if(montageAds.creativesCount && (montageAds.creativesCount-Math.floor(montageAds.creativesCount)>0 && montageAds.creativesCount > 0)){
        errors = { ...errors, creativesCountDecimal: true };
        isError = true;
      }
      if (
        montageAds.creativesCount == "" ||
        montageAds.creativesCount == null ||
        montageAds.creativesCount == undefined
      ) {
        errors = { ...errors, creativesCountEmpty: true };
        isError = true;
      }
      if (montageAds.productName.trim() == "") {
        errors = { ...errors, productName: true };
        isError = true;
      }
      if(montageAds.productValue && montageAds.productValue <= 0){
        errors = { ...errors, productValueNegative: true };
        isError = true;
      }
      if (
        montageAds.productValue == "" ||
        montageAds.productValue == null ||
        montageAds.productValue == undefined
      ) {
        errors = { ...errors, productValueEmpty: true };
        isError = true;
      }
      if (montageValidation.name){isError = true}
      if (montageValidation.creativesCountEmpty){isError = true}
      if (montageValidation.creativesCountNegative){isError = true}
      if (montageValidation.creativesCountDecimal){isError = true}
      if (montageValidation.productName){isError = true}
      if (montageValidation.productValueEmpty){isError = true}
      if (montageValidation.productValueNegative){isError = true}
      if (montageValidation.productCategory){isError = true}
      if (montageValidation.productUrl){isError = true}
      if (montageValidation.productCurrency){isError = true}
      if (montageValidation.targetGender){isError = true}

      if (montageAds.productUrl.trim() == "") {
        errors = { ...errors, productUrl: true };
        isError = true;
      }
      if (montageAds.language.trim() == "") {
        errors = { ...errors, language: true };
        isError = true;
      }
      if (montageAds.interests.trim() == "") {
        errors = { ...errors, interests: true };
        isError = true;
      }

      if (isError) {
        alert("Please fill mandatory field");
        setSubmitCampaign(false);
        setMontageValidation(errors);
        setFormError(true);
        return;
      }
    }
    if (!isError) {
      setMontageAds((montageAds: MontageCampaigns) => ({
        ...montageAds,
        videoLayout: montageAds.videoLayout,
      }));

      dispatch(
        postMontageAds(
          { selectedBrandId: selectedBrand.id, user, payload: montageAds },
          (response: any, error: boolean) => {
            if (!error) {
              history.push("/montage-ads");
            } else {
              console.log(error);
            }
          }
        )
      );
    }
  };


  return (
    <>
      <p className="montage-heading">Montage Ads</p>
      <div className="outline-screen">
        <div className="outline-heading">Outline</div>
        <MontageSetup handleInputChange={(e)=>handleInputChange(e)} handleChange={handleChange} montageAds={montageAds} setMontageAds={setMontageAds} montageValidation={montageValidation} setMontageValidation={setMontageValidation}showModal={showModal} setShowModal={setShowModal}/>
        <MontageCreatorTargeting handleChange={handleChange}montageAds={montageAds} setMontageAds={setMontageAds} montageCountry={montageCountry}montageValidation={montageValidation}handleCreative={handleCreative}/>
        <MontageCreativeBrief handleInputChange={handleInputChange} montageAds={montageAds} setMontageAds={setMontageAds} montageGuidelines={montageGuidelines}setMontageGuidelines={setMontageGuidelines} montageCurrency={montageCurrency}handleCreative={handleCreative}showModal={showModal} setShowModal={setShowModal}/>
        
        <div className="outline-footer">
          <div>
            <></>
            <span>
              <NavLink className="back-btn" to={"/montage-ads"}>
                <LeftArrow className="leftArrow"/> Back
              </NavLink>
            </span>
          </div>
          <ButtonUI className="launch_button" onClick={handleOpenModal}>
            Launch Campaign
            <RightArrow className="rightArrow"/>
          </ButtonUI>
          {submitCampaign && <OutlineModal text="Click on Continue to launch the campaign" title="Launch Campaign" handleSubmit={()=>handleSubmitCampaign()}handleClose={()=>{setSubmitCampaign(false);setShowModal(false)}} showModal={showModal} />}
        </div>
      </div>
    </>
  );
};

export default OutlineScreen;
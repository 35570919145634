import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CreateVariantsState } from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import Loader from "../../../../../common/Loader";
import { StyledHeading4 } from "../../../../../shared/Typography/Heading/styled";
import { StyledParagraph1 } from "../../../../../shared/Typography/Paragraph/styled";
import { StyledPreviewDiv, StyledPreviewHeadingCard } from "./styled";

export interface PreviewProps {
  value: string;
  textFields: any;
}

export default function Preview({ value, textFields }: PreviewProps) {
  const {
    extraFields: { selectedVariantIndex },
    recommendationSelectionState,
    isGeneratingPreview,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );

  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    var doc: any = new DOMParser().parseFromString(
      recommendationSelectionState.adPreviewLink,
      "text/xml"
    );
    const iframSrc = doc?.firstChild.getAttribute("src");
    setIframeSrc(iframSrc);
  }, [recommendationSelectionState.adPreviewLink]);

  const currentPage =
    selectedVariantIndex !== undefined && selectedVariantIndex + 1;

  const [previewDelay, setPreviewDelay] = useState(false);

  useEffect(() => {
    loadingFunction();
  }, []);

  const loadingFunction = () => {
    setTimeout(() => {
      setPreviewDelay(true);
    }, 2000);
  };
  return (
    <StyledPreviewDiv hidden={value !== "preview"} id="preview">
      <StyledPreviewHeadingCard>
        <StyledHeading4>Variant #{currentPage}</StyledHeading4>
        <StyledParagraph1>{textFields[0]?.value}</StyledParagraph1>
      </StyledPreviewHeadingCard>
      {isGeneratingPreview ? (
        <Box
          width="100%"
          height="50vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <CircularProgress
            size={25}
            sx={{
              color: "white",
            }}
            thickness={5}
          />
        </Box>
      ) : (
        <>
          <iframe
            style={{
              display: previewDelay ? "block" : "none",
              width: "100%",
              minHeight: "450px",
            }}
            src={iframeSrc}
          ></iframe>
          {!previewDelay && (
            <>
              <div
                style={{
                  width: "100%",
                  height: "450px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            </>
          )}
        </>
      )}
    </StyledPreviewDiv>
  );
}

import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router";
import { ReactComponent as Download } from "../../../assets/svg/download.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import sortOrder from "../../../assets/svg/sort_order.svg";
import CheckBox from "../../../ui/CheckBox/CheckBox";
import {
  exportToCsv,
  filterConfidenceData,
  generateChipText,
} from "../../../utils/commonFunctions";
import {
  adsetFirst,
  campaignFirst,
  confRadioInput,
  phaseList,
  searchHead,
  statusList,
} from "../../../utils/constants/actionImpact";
import Chip from "../../Chip/Chip";
import DatePickerDropdown from "../../DatePickerDropdown/DatePickerDropdown";
import HandleClickOutside from "../HandleClickOutside";
import Loader from "../Loader";
import NoData from "../NoData";
import "./index.scss";
import "./styles.scss";
import DropdownUI from "../../../ui/Dropdown/Dropdown";
import ButtonUI from "../../../ui/ButtonUI/ButtonUI";
import { getModuleName, mixPanelAdEvent } from "../../../utils/mixpanel";
import { AdAccountState } from "../../../store/types/adaccount";
import { useSelector } from "react-redux";


const PyxisTable: React.FC<{
  type: "actionImpact" | "campaign";
  tableHeader: any;
  tableTitle?: any;
  data: any;
  responsiveType?: string;
  showTableTopBar?: boolean;
  showFilterAndSearch?: boolean;
  showCustomiseColumn?: boolean;
  maxHeight?: any;
  loading?: boolean;
  tableClass?: string;
  tableContainerClass?: string;
  tableHeaderClass? :string;
  linkClicks?: any;
  setDateRange: (value: any) => void;
  requestSort: (value: any) => void;
  updateTableData: (activeHeader: any) => void;
  setUpdatedTableHeader: (tableData: any) => void;
  handleAction: null | ((index: number) => void);
}> = ({
  type,
  tableHeader,
  tableTitle,
  data,
  responsiveType,
  maxHeight,
  tableClass,
  tableContainerClass,
  tableHeaderClass,
  showTableTopBar,
  showCustomiseColumn = true,
  showFilterAndSearch = true,
  linkClicks,
  loading,
  setDateRange,
  requestSort,
  updateTableData,
  setUpdatedTableHeader,
  handleAction,
}) => {
  const history = useHistory();
  const [tableData, setTableData] = useState(data);
  const [actionImpactTableDetails, setActionImpactTableDetails] = useState(tableHeader);
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedBrand, selectedAdAccount, selectedGroup, selectedPlatform } = adaccount;
  const [searchState, setSearchState] = useState(0);
  const [confState, setConfState] = useState("gt");
  const [rangeState, setRangeState] = useState([
    {
      startDate: linkClicks ? linkClicks?.start : new Date(),
      endDate: linkClicks ? linkClicks?.end : new Date(),
      key: "selection",
    },
  ]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showCustomise, setShowCustomise] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [isConfView, SetIsConfView] = useState(false);
  const [searchValue, setSearchValue] = useState(searchHead);
  const [finalFilter, setFinalFilter] = useState<{ firstStep: string; secondStep: string }[]>([]);
  const [confStateValue, setConfStateValue] = useState<{
    from: number;
    to: number;
  }>({
    from: -1.0,
    to: -1.0,
  });
  const [searchFilter, setSearchFilter] = useState({
    firstStep: "",
    secondStep: "",
  });

  const tableKey = React.useMemo(
    () =>
      tableHeader
        .filter((element: any) => element.isEnabled)
        .map((element: any) => element.sortKey),
    [tableHeader]
  );

  const analyticsKey = React.useMemo(
    () =>
      tableHeader
        .filter((element: any) => element.type === "analytics")
        .map((element: any) => {
          return {
            key: element.sortKey,
            value: element.name,
            isEnabled: element.isEnabled,
          };
        }),
    [tableHeader]
  );

  const setupKey = React.useMemo(
    () =>
      tableHeader
        .filter((element: any) => element.type === "setup")
        .map((element: any) => {
          return {
            key: element.sortKey,
            value: element.name,
            isEnabled: element.isEnabled,
          };
        }),
    [tableHeader]
  );

  useEffect(() => {
    if (!loading) {
      if (!finalFilter.length) {
        setTableData(data);
      } else {
        setFilteredData();
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if (searchFilter.secondStep) {
      if (searchFilter.firstStep !== "confidence_score") {
        setDropdownOpen(!dropdownOpen);
      }
      addToFinalFilter();
    }
  }, [searchFilter.secondStep]);

  useEffect(() => {
    setFilteredData();
  }, [finalFilter]);

  useEffect(() => {
    setRangeState([
      {
        startDate: linkClicks ? new Date(linkClicks?.start) : new Date(),
        endDate: linkClicks ? new Date(linkClicks?.end) : new Date(),
        key: "selection",
      },
    ]);
  }, [linkClicks]);

  const handleSelect = (ranges: any) => {
    setRangeState([ranges.selection]);
  };

  const setFilteredData = () => {
    let updatedTableData = data;
    if (!!finalFilter.length) {
      finalFilter.map((elem: any) => {
        if (elem.firstStep !== "confidence_score") {
          updatedTableData = updatedTableData.filter(
            (element: any) =>
              element[elem.firstStep] &&
              element[elem.firstStep].toLowerCase().includes(elem.secondStep)
          );
        } else {
          updatedTableData = filterConfidenceData(elem, updatedTableData);
        }
      });
    }
    setTableData(updatedTableData);
  };

  const updateDate = () => {
    setDefault();
    setDateRange({
      start: rangeState[0].startDate,
      end: rangeState[0].endDate,
    });
    const date = {
      start: moment(rangeState[0].startDate).format("YYYY-MM-DD"),
      end: moment(rangeState[0].endDate).format("YYYY-MM-DD"),
    };
    mixPanelAdEvent('Date Range Selected', {
      'module_name': getModuleName(history.location.pathname),
      'feature_name': 'Recommendations Table - Action Impact',
      'brand_name': selectedBrand?.name,
      'ad_account_id': history.location.pathname === "/singlePlatform" ? selectedAdAccount?.id || null : 'ALL' ,
      'ad_account_name': history.location.pathname === "/singlePlatform" ? selectedAdAccount?.name || null : 'ALL'
    });
  };

  const setDefault = () => {
    setSearchFilter({
      firstStep: "",
      secondStep: "",
    });
    setConfStateValue({
      from: -1,
      to: -1,
    });
    setSearchState(0);
    SetIsConfView(false);
    setSearchValue(searchHead);
  };

  const addToFinalFilter = () => {
    setFinalFilter((finalFilter) => {
      return [
        ...finalFilter,
        {
          ...searchFilter,
          text: generateChipText(searchFilter),
        },
      ];
    });
  };

  const downloadCSV = () => {
    const result = [
      [...tableKey],
      ...tableData.map((element: any) => tableKey.map((keyword: any) => element[keyword])),
    ];

    exportToCsv("recommendation_result.csv", result);
  };

  const handleChange = (key: any) => {
    setActionImpactTableDetails((actionImpactTableDetails: any) => {
      const updatedTable = actionImpactTableDetails.map((element: any) => {
        if (element.sortKey === key) {
          return {
            ...element,
            isEnabled: !element.isEnabled,
          };
        } else return element;
      });

      return updatedTable;
    });
  };

  const updateRecommendationList = () => {
    const selectedKey = actionImpactTableDetails
      .filter((elem: any) => elem.isEnabled)
      .map((element: any) => element.sortKey);
    setDefault();
    setShowCustomise(false);
    updateTableData(selectedKey);
    setUpdatedTableHeader(actionImpactTableDetails);
  };

  const applySearchNextState = (key: string) => {
    if (searchState === 0) {
      if (key === "status") {
        setSearchValue(statusList);
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            firstStep: key,
          };
        });
      } else if (key === "phase") {
        setSearchValue(phaseList);
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            firstStep: key,
          };
        });
      } else if (key === "item_to_change") {
        const adsets = data.map((elem: any) => {
          const adset = elem.item_to_change.split(" ");
          return {
            type: adset[0],
            value: adset[adset.length - 1],
          };
        });

        const uniqueList = [...new Map(adsets.map((item: any) => [item["value"], item])).values()];
        const campaignsList = uniqueList.filter((item: any) => item.type.includes("Campaign"));
        const adsetsList = uniqueList.filter((item: any) => item.type === "Adset");

        let campaignDropdown = campaignsList.map((elem: any) => {
          return {
            key: elem.value,
            value: elem.value,
            disabled: false,
          };
        });

        let adsetsDropdown = adsetsList.map((elem: any) => {
          return {
            key: elem.value,
            value: elem.value,
            disabled: false,
          };
        });

        campaignDropdown = campaignDropdown.length ? [campaignFirst, ...campaignDropdown] : [];
        adsetsDropdown = adsetsDropdown.length ? [adsetFirst, ...adsetsDropdown] : [];

        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            firstStep: key,
          };
        });
        setSearchValue([...adsetsDropdown, ...campaignDropdown]);
      } else if (key === "confidence_score") {
        SetIsConfView(true);
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            firstStep: key,
          };
        });
      }
    } else if (searchState === 1) {
      if (searchFilter.firstStep === "confidence_score") {
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            secondStep: `${key} ${confStateValue.from} ${confStateValue.to}`,
          };
        });
      } else {
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            secondStep: key,
          };
        });
      }
    }
  };

  const handleRadioChange = (value: string) => {
    setTimeout(() => {
      setConfState(value);
    }, 0);
    setConfStateValue({
      from: -1,
      to: -1,
    });
  };

  const deleteChip = (index: number) => {
    setFinalFilter((finalFilter) => {
      return finalFilter.filter((elem, i) => i !== index);
    });
  };

  const updateState = (key: string, searchState: number) => {
    switch (searchState) {
      case 0:
        applySearchNextState(key);
        setSearchState(1);
        break;
      case 1:
        applySearchNextState(key);
        break;
      default:
        break;
    }
  };

  const wrapperRef = useRef(null);
  HandleClickOutside(wrapperRef,[setShowCustomise]);

  return (
    <>
      <div className={`pyxis-table-header-container ${tableHeaderClass}`}>
        {tableTitle && tableTitle()}
        {!showTableTopBar &&
          (history.location.pathname === "/singlePlatform" ||
          history.location.pathname === "/actionImpactAnalysis" || history.location.pathname === "/") && (
            <div className="search-text fs-6 p-0 ml-4 d-flex">
              {showFilterAndSearch && <div className="d-flex position-relative w-100">
                  <Dropdown
                    onSelect={(target: any) => {}}
                    show={dropdownOpen}
                    className="hoverable mr-3 position-set"
                    id="search-and-filter"
                    style={{ marginLeft: "auto" }}
                  >
                    <Dropdown.Toggle className="search-dropdown" disabled={loading}>
                      <SearchIcon className="search-icon" />
                      {finalFilter?.map((element: any, index: number) => {
                        return (
                          <Chip
                            index={index}
                            chip={element.text}
                            deleteChip={deleteChip}
                            key={element?.id}
                          />
                        );
                      })}
                      <input
                        type="text"
                        placeholder="Search and filter"
                        className="text-md form-control form-control-md"
                        id="dropdown-toggle"
                        onClick={() => {
                          if (!dropdownOpen) {
                            setDefault();
                          }
                          setDropdownOpen(!dropdownOpen);
                        }}
                        onChange={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Dropdown.Toggle>
                    {isConfView ? (
                      <Dropdown.Menu className="conf-dropdown">
                        <Dropdown.Item
                          className="text-truncate conf-view border-bottom py-2"
                          eventKey={"conf"}
                          key={"conf"}
                          style={{
                            backgroundColor: "#E9E9E9",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="confidence-score-container">
                            <Card>
                              <Card.Body>
                                <Card.Title>Confidence score</Card.Title>
                                <div className="conf-body">
                                  <div className="radio">
                                    {confRadioInput.map((value: any) => {
                                      return (
                                        <label className="display-block text-md" key={value?.id}>
                                          <input
                                            type="radio"
                                            checked={confState === value.value}
                                            id={value.value}
                                            name={value.value}
                                            value={value.value}
                                            className="radio-input"
                                            onChange={(e) => {
                                              e.stopPropagation();
                                              handleRadioChange(e.target.value);
                                            }}
                                          />
                                          <span>{value.name}</span>
                                        </label>
                                      );
                                    })}
                                  </div>
                                  {confState === "gt" || confState === "lt" ? (
                                    <input
                                      type="number"
                                      className="text-md form-control-md"
                                      value={confStateValue.from != -1 ? confStateValue.from : ""}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        setConfStateValue((confStateValue) => {
                                          return {
                                            ...confStateValue,
                                            from: parseFloat(e.target.value),
                                          };
                                        });
                                      }}
                                    />
                                  ) : (
                                    <div className="input-container">
                                    <input
                                      type="number"
                                      value={confStateValue.from != -1 ? confStateValue.from : ""}
                                      className="text-mdform-control-md"
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        setConfStateValue((confStateValue) => {
                                          return {
                                            ...confStateValue,
                                            from: parseFloat(e.target.value),
                                          };
                                        });
                                      }}
                                    />
                                      <span>and</span>
                                      <input
                                      type="number"
                                      value={confStateValue.to != -1 ? confStateValue.to : ""}
                                      className="text-md form-control-md"
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        setConfStateValue((confStateValue) => {
                                          return {
                                            ...confStateValue,
                                            to: parseFloat(e.target.value),
                                          };
                                        });
                                      }}
                                    />
                                    </div>
                                  )}
                                </div>
                                <div className="conf-footer">
                                  <button
                                    type="button"
                                    className="btn primary p-1 btn-cancel"
                                    onSelect={function () {}}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDefault();
                                      setDropdownOpen(!dropdownOpen);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn primary p-1"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateState(confState, searchState);
                                      setDropdownOpen(!dropdownOpen);
                                    }}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu>
                        {searchValue.map((element: any) => (
                          <Dropdown.Item
                            key={element?.id}
                            className="text-truncate border-bottom py-2 dropdown-recommendation"
                            eventKey={element.key}
                            disabled={element.disabled}
                            style={{
                              backgroundColor: "#E9E9E9",
                              color: "#000",
                              fontWeight: "bold",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              updateState(element.key, searchState);
                            }}
                          >
                            {element.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </div>}
              <div className="right-panel" ref={wrapperRef}>
                <DatePickerDropdown
                  showDatePicker={showDatePicker}
                  loading={loading}
                  updateDate={updateDate}
                  setShowDatePicker={setShowDatePicker}
                  setShowCustomise={setShowCustomise}
                  handleSelect={handleSelect}
                  linkClicks={linkClicks}
                  rangeState={rangeState}
                />
                {showCustomiseColumn && (
                  <DropdownUI
                    className="dropdown-up-ui hoverable  mr-3 customise-column ml-2"
                    isDropDownVisible={showCustomise}
                    setIsDropDownVisible={() => {}}
                    toggelButton={
                      <ButtonUI
                        className="customize-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowCustomise((v) => !v);
                          setShowDatePicker(false);
                        }}
                        disabled={loading}
                      >
                        <div className="text-dark text-left text-truncate pr-3">
                          Customise Column
                        </div>

                        <span className="dropdown_arrow">
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </ButtonUI>
                    }
                  >
                    <div
                      className="dropdown-item text-truncate border-bottom py-2 pt-3 pr-3 "
                      key={"date"}
                      style={{
                        backgroundColor: "#E9E9E9",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      Setup
                    </div>
                    {setupKey?.map((key: any) => {
                      return (
                        <div
                          className="dropdown-item text-truncate border-bottom py-2"
                          key={key.key}
                          style={{ backgroundColor: "#E9E9E9" }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <CheckBox
                            value={key.isEnabled}
                            onChange={() => handleChange(key.key)}
                          />{" "}
                          {key.value}
                        </div>
                      );
                    })}
                    <div
                      className=" dropdown-item text-truncate border-bottom border-top py-2"
                      key={"date"}
                      style={{
                        backgroundColor: "#E9E9E9",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      Analytics
                    </div>
                    {analyticsKey &&
                      analyticsKey.map((key: any) => {
                        return (
                          <div
                            className=" dropdown-item text-truncate"
                            key={key.key}
                            style={{ backgroundColor: "#E9E9E9" }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <CheckBox
                              value={key.isEnabled}
                              onChange={() => handleChange(key.key)}
                            />{" "}
                            {key.value}
                          </div>
                        );
                      })}
                       <div
                      className="pb-2 pt-2"
                      key={"date"}
                      style={{
                        backgroundColor: "#E9E9E9",
                      }}
                    >
                    <button
                      type="button"
                      className="btn primary mx-3 my-1"
                      onClick={updateRecommendationList}
                    >
                      Apply
                    </button>
                    </div>
                    
                  </DropdownUI>
                )}
                <Dropdown
                  onSelect={(target: any) => {}}
                  show={showDownload}
                  id="download-dropdown"
                  className="hoverable dropleft position-relative mr-3 dropdown-download-parent"
                  align={{ lg: "end" }}
                >
                  <Dropdown.Toggle
                    className="dropdown-download"
                    disabled={loading}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDownload(!showDownload);
                    }}
                  >
                    <Download />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="text-truncate border-bottom py-2"
                      eventKey={"date"}
                      key={"date"}
                      style={{
                        backgroundColor: "#E9E9E9",
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "12px !important",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        downloadCSV();
                        console.log("Check", history.location.pathname);
                        mixPanelAdEvent('Export Recommendations Clicked', {
                          'module_name': getModuleName(history.location.pathname),
                          'brand_name': selectedBrand?.name,
                          'ai_group_id': selectedGroup?.id,
                          'ai_group_name': selectedGroup?.name,
                          'ad_account_id': history.location.pathname === "/singlePlatform" ? selectedAdAccount?.id || null : 'ALL' ,
                          'ad_account_name':history.location.pathname === "/singlePlatform" ? selectedAdAccount?.name || null : 'ALL',
                          'platform_name': history.location.pathname === "/singlePlatform" ? selectedPlatform?.name || null : 'ALL',
                        });
                      }}
                    >
                      Export Excel sheet as .CSV
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div
          style={{ overflowX: "scroll" }}
          className={`pyxis-table-container ${tableContainerClass}`}
        >
          <Table
            responsive={responsiveType ? "md" : ""}
            className={tableClass}
            style={{ maxHeight: maxHeight }}
          >
            <thead>
              <tr>
                {type === "actionImpact"
                  ? tableHeader?.map((element: any) => {
                      if (element.isEnabled) {
                        return (
                          <th className="t_header10" key={element?.id}>
                            {element.name}
                            {element.isSortable && element.sortKey && (
                              <img
                                src={sortOrder}
                                className="sort_style"
                                alt="sort_order"
                                onClick={() => requestSort(element.sortKey)}
                              />
                            )}
                              {element.tableData && <p className="tableData">{element.tableData}</p>}
                          </th>
                        );
                      }
                    })
                  : tableHeader?.map((element: any) => (
                      <th className="t_header10" key={element?.id}>
                        {element.name}
                        {element.isSortable && element.sortKey && (
                          <img
                            src={sortOrder}
                            className="sort_style"
                            alt="sort_order"
                            onClick={() => requestSort(element.sortKey)}
                          />
                        )}
                       {element.tableData && <p className="tableData">{element.tableData}</p>}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {type === "actionImpact" &&
                 (history.location.pathname === "/singlePlatform" ||
                 history.location.pathname === "/actionImpactAnalysis" || history.location.pathname === "/"
               ) &&
                tableData &&
                tableData.map((element: any, index: number) => (
                  <tr key={element?.id}>
                    {tableKey?.map((keyword: any) =>
                      keyword === "status" ? (
                        <td key={element?.id}>
                          {element.status}
                          {/* {handleAction && element.status && element.status.toLowerCase() === "recommended" && (
                          <button
                            type="button"
                            className="btn primary p-1 btn-reject"
                            onClick={() => handleAction(index)}
                          >
                            Reject Recommendation
                          </button>
                        )}{" "} */}
                        </td>
                      ) : (
                        <td>{element[keyword] ? element[keyword] : "-"}</td>
                      )
                    )}
                  </tr>
                ))}
              {type === "campaign" &&
                data &&
                data.map((element: any, index: number) => (
                  <tr className="tableData" key={element?.id}>
                    <td>{index + 1}</td>
                    <td>{element.id}</td>
                    <td>{element.name}</td>
                  </tr>
                ))}
              {(showTableTopBar && tableData && tableData.length === 0) ||
              (data && data.length === 0) ? (
                <tr>
                  <td colSpan={8} className="borderless-table">
                    <NoData />
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default PyxisTable;

import { Box, IconButton } from "@mui/material";
import CustomizedDialogs from "../../../../../shared/Modal/CustomizedDialogs";
import AdSetDetailsModalItem from "./AdSetDetailsModalItem";
import { ReactComponent as CrossIcon } from "../../../../../../assets/svg/close.svg";
import { useSelector } from "react-redux";
import {
  StyledCreateVariantSpan,
  StyledLinkViewMore,
  StyledVariantItem,
  StyledVariantItemPara,
  StyledVariantItemParaTitle,
} from "../../../shared/AdSets/styled";
import { getCohortName } from "../../../utils/common.utils";

export default function AdSetDetailsModal({
  show,
  onHide,
  toggleDetailedTargetModal,
}: {
  show: boolean;
  onHide: () => void;
  toggleDetailedTargetModal: any;
}) {
  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state.selectCohortReducer.cohortData,
      ADS_LIST: state.createVariantReducer.adsList,
    })
  );

  const getComaSeparatedDate = (fields: any) => {
    const value = fields.map((element: any) => element.name);
    return value.splice(0, 3).join(",");
  };
  return (
    <CustomizedDialogs
      show={show}
      handleClose={onHide}
      type="ConfirmDialog"
      footer={false}
      maxWidth="sm"
    >
      <Box display="flex" flexDirection="column" mt="-8px" width="450px">
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={onHide}
            color="primary"
            aria-label="cross"
            className="ml-3"
          >
            <CrossIcon />
          </IconButton>
        </Box>
        <AdSetDetailsModalItem
          heading="Ad-set"
          tooltipText="Testing again"
          subHeading={[COHORT_DATA?.adset?.name]}
        />
        <Box display="flex" mb="20px" mt="20px">
          <AdSetDetailsModalItem
            heading="Cohort"
            tooltipText="Testing again"
            subHeading={[
              COHORT_DATA?.adset?.cohortName.length
                ? COHORT_DATA?.adset?.cohortName
                : "-",
            ]}
          />
          <AdSetDetailsModalItem
            heading="Age"
            tooltipText="Testing again"
            subHeading={[
              `${COHORT_DATA?.facebookData?.targeting?.age_min ?? ""} - ${
                COHORT_DATA?.facebookData?.targeting?.age_max ?? ""
              }`,
            ]}
          />
        </Box>
        <Box display="flex">
          <AdSetDetailsModalItem
            heading="Gender"
            tooltipText="Testing again"
            subHeading={[
              COHORT_DATA?.facebookData?.targeting?.genders &&
              COHORT_DATA?.facebookData?.targeting?.genders[0] === 1
                ? "Male"
                : COHORT_DATA?.facebookData?.targeting?.genders &&
                  COHORT_DATA?.facebookData?.targeting?.genders[0] === 2
                ? "Female"
                : "All",
            ]}
          />
          <StyledVariantItem>
            <StyledVariantItemParaTitle>
              Detailed Targeting
              <StyledCreateVariantSpan>i</StyledCreateVariantSpan>
            </StyledVariantItemParaTitle>
            {COHORT_DATA?.facebookData?.targeting?.flexible_spec ? (
              <span className="d-flex">
                <StyledVariantItemPara>
                  {COHORT_DATA?.facebookData?.targeting?.flexible_spec[0]
                    ?.interests &&
                    getComaSeparatedDate(
                      COHORT_DATA?.facebookData?.targeting?.flexible_spec[0]
                        ?.interests
                    )}
                </StyledVariantItemPara>
                <StyledLinkViewMore
                  to="#"
                  onClick={() => toggleDetailedTargetModal()}
                >
                  {" "}
                  View more
                </StyledLinkViewMore>
              </span>
            ) : (
              "-"
            )}
          </StyledVariantItem>
        </Box>
      </Box>
    </CustomizedDialogs>
  );
}

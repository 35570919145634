
export const userDetailForm = [
    {
      type: "radio",
      label: "User Type",
      show: true,
      fullWidth: true,
      dependentField: false,
      key: "userType",
      options: [
        {
          name: "Client",
          value: "Client",
          defaultSelected: true,
        },
        {
          name: "Agency",
          value: "Agency",
          defaultSelected: false,
        },
      ],
  
      initialvalue: "",
    },
    {
      type: "text",
      show: true,
      label: "Client Name",
      key: "clientName",
    },
    {
      type: "text",
      label: "Legal Name",
      show: true,
      key: "legalName",
      initialvalue: "",
    },
    {
      type: "text",
      label: "GSTIN",
      show: true,
      key: "gstin",
      initialvalue: "",
    },
    {
      type: "text",
      label: "PAN No.",
      show: true,
      key: "pan",
      initialvalue: "",
    },
    {
      type: "text",
      label: "Address",
      show: true,
      key: "address",
      initialvalue: "",
    },
    {
      type: "text",
      label: "Authorised reprensentative full name",
      key: "authorisedFullName",
      show: true,
      initialvalue: "",
    },
];

export const viewInvoices = "https://agreement-dashboard.web.app/login";

export const UserDetailsFormValidationFields = [
    "userEmail",
    "userType",
    "clientName",
    "legalName",
    "address",
    "authorisedFullName",
];
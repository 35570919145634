import { Stack, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import {
  StyledDateRangeContainer,
  StyledDownloadButton,
  StyledExportContainer,
  StyledGreenOnline,
  StyledPagination,
  StyledPhaseText,
  StyledTableHeightFix,
  StyledOptimizationPhase,
  StyledDatePara,
  StyledCustomMuiPagination,
  StyledArrowImage,
} from "./styled";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../shared/Table/styled";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Box } from "@mui/system";
import { add, sub } from "date-fns";
import DownloadButton from "../../../../../../assets/svg/download.svg";
import DownArrow from "../../../../../../assets/svg/dropdown_arrow.svg";
import CustomizedTable from "../../../../../shared/Table/CustomizedTable";
import { useDispatch, useSelector } from "react-redux";
import PaginationItem from "@mui/material/PaginationItem";
import { ReactComponent as nextSvg } from "../../../../../../assets/svg/nextsvg.svg";
import { ReactComponent as prevSvg } from "../../../../../../assets/svg/prevsvg.svg";
import moment from "moment";
import Loader from "../../../../../common/Loader";

import { textTruncateMore } from "../../../utils/text.utils";

const CreativeRotation: React.FC = () => {
  const { selectedAdAccount } = useSelector(
    (state: any) => state.aiGroupReducer
  );

  const { getActivityLogsState } = useSelector(
    (state: any) => state.activityLogsReducer
  );

  const { selectedAiGroup, selectedAiGroupObject } = useSelector(
    (state: any) => state.aiGroupReducer
  );

  const { creativeRotations: data } = useSelector(
    (state: any) => state.activityLogsReducer
  );

  const { creativeRotationDateRange } = useSelector(
    (state: any) => state.activityLogsReducer
  );
  const [showRange, setShowRange] = useState(false);
  const [dateSelection, setDateSelection] = React.useState([
    {
      startDate: !creativeRotationDateRange?.selection
        ? sub(new Date(), { days: 6 })
        : new Date(creativeRotationDateRange?.selection.startDate),
      endDate: !creativeRotationDateRange?.selection
        ? new Date()
        : new Date(creativeRotationDateRange?.selection.endDate),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges: any) => {
    setDateSelection([
      {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      },
    ]);
    dispatch({
      type: "SET_CREATIVE_ROTATION_LOGS_DATE_RANGE",
      payload: ranges,
    });

    paramsDispatch({
      type: "start_date",
      payload: moment(ranges.selection.startDate).format("YYYY-MM-DD"),
    });
    paramsDispatch({
      type: "end_date",
      payload: moment(ranges.selection.endDate).format("YYYY-MM-DD"),
    });
  };

  const dispatch = useDispatch();

  const initialParams = {
    searchField: "",
    search: "",
    order: "",
    orderBy: "",
    limit: "",
    page: 1,
    start_date: !creativeRotationDateRange?.selection
      ? // ? moment(new Date()).format("YYYY-MM-DD")
        moment().subtract(6, "d").format("YYYY-MM-DD")
      : moment(creativeRotationDateRange?.selection.startDate).format(
          "YYYY-MM-DD"
        ),
    end_date: !creativeRotationDateRange?.selection
      ? moment(new Date()).format("YYYY-MM-DD")
      : moment(creativeRotationDateRange?.selection.endDate).format(
          "YYYY-MM-DD"
        ),
    page_size: 5,

    download: false,
  };

  const paramsReducer = (state: any, action: any) => {
    switch (action.type) {
      case "download":
        return { ...state, download: true };
      case action.type:
        return { ...state, download: false, [action.type]: action.payload };
      default:
        return state;
    }
  };

  const [params, paramsDispatch] = React.useReducer(
    paramsReducer,
    initialParams
  );

  React.useEffect(() => {
    dispatch({
      type: "GET_CREATIVE_ROTATION_LOGS",
      payload: {
        elementId: selectedAiGroup,
        ad_account: selectedAdAccount.adAccountId,
        params,
      },
    });
  }, [params]);

  const handlePaginationChange = (e: any, page: any) => {
    paramsDispatch({ type: "page", payload: page });
  };

  return (
    <>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <StyledPhaseText>
            {/* {selectedAiGroupObject?.phase && <StyledGreenOnline />}{" "}
            {selectedAiGroupObject?.phase} */}
          </StyledPhaseText>
          <StyledExportContainer>
            <StyledDateRangeContainer>
              <StyledDatePara
                onClick={() => setShowRange(true)}
                variant="body1"
              >
                Custom date |{" "}
                {!creativeRotationDateRange?.selection
                  ? dateSelection[0].startDate.toDateString()
                  : creativeRotationDateRange?.selection?.startDate.toDateString()}{" "}
                -{" "}
                {!creativeRotationDateRange?.selection
                  ? dateSelection[0].endDate.toDateString()
                  : creativeRotationDateRange?.selection?.endDate.toDateString()}
                <StyledArrowImage src={DownArrow} alt="" />{" "}
              </StyledDatePara>
              {showRange && (
                <div className="daterangeSelector">
                  <DateRangePicker
                    ranges={[...dateSelection]}
                    onChange={(e) => handleSelect(e)}
                    // onChange={(item) => handleSelect([item.selection])}
                    showSelectionPreview
                    direction="horizontal"
                    editableDateInputs={true}
                  />
                  <Button
                    className="addRange"
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowRange(false)}
                    style={{ float: "right" }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </StyledDateRangeContainer>
            <StyledDownloadButton
              onClick={() => {
                paramsDispatch({ type: "download" });
              }}
              src={DownloadButton}
            />
          </StyledExportContainer>
        </Stack>
        <StyledTableHeightFix>
          <CustomizedTable
            tableHeadValues={[
              { name: "Action", align: "left" },
              { name: "Action Basis", align: "left" },
              {
                name: "Phase",
                align: "left",
                subHeading: "(At the time of Recommendation)",
              },
              { name: "Ad Creative Performance Score", align: "left" },
              { name: "Creative Type", align: "left" },
              { name: "Ad Name", align: "left" },
              {
                name: "Cohort",
                align: "left",
                subHeading: "(Ad set)",
              },
              { name: "Date & Time", align: "left" },
              { name: "Status", align: "left" },
            ]}
            tableType="evenColoredTable"
            subHeadingStyle={{
              fontSize: "10px",
              fontWeight: 600,
              fontFamily: "Inter",
              lineHeight: "17px",
              color: "#000000",
              fontStyle: "normal",
            }}
            extraHeadAlignStyle={{ width: "100%", height: "20px" }}
            tableHeadCellStyle={{
              verticalAlign: "baseline",
              // height: "20px",
              width: "250px",
            }}
          >
            {getActivityLogsState?.loading === false ? (
              data?.results?.map((v: any, i: number) => {
                return (
                  <StyledTableRow className="tableDataRow" key={i}>
                    <StyledTableCell>{v.action}</StyledTableCell>
                    <StyledTableCell align="left">
                      {v.action_basis
                        ? v.action_basis
                        : v.action_basis == ""
                        ? "-"
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {v.creative_phase ?? "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {v.ad_creative_performance_score ?? "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left">-</StyledTableCell>
                    <StyledTableCell align="left" title={v.ad_name}>
                      {textTruncateMore(v.ad_name ?? "-", 10)}
                    </StyledTableCell>
                    <StyledTableCell align="left" title={v.adset_name}>
                      {/* {v.adset_name ?? "-"} */}
                      {textTruncateMore(v.adset_name ?? "-", 12)}
                      {/* <span>TAI Cohort - Kitchen</span>
                  <span>kitchen_male_20-..</span> */}
                    </StyledTableCell>
                    <StyledTableCell align="left" width="185px">
                      {/* {new Date(v.datetime).toDateString()} */}
                      {moment(v.datetime).format("lll")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {v.status ?? "-"}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={9}>
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            )}
          </CustomizedTable>
        </StyledTableHeightFix>
        <StyledCustomMuiPagination>
          <StyledPagination
            // count={1}
            // count={Math.ceil(data?.count / params.limit)}
            count={Math.ceil(
              (data?.count ? data?.count : 0) / params?.page_size
            )}
            onChange={(e, value) => handlePaginationChange(e, value)}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: prevSvg, next: nextSvg }}
                {...item}
              />
            )}
          />
        </StyledCustomMuiPagination>
      </Box>
    </>
  );
};

export default CreativeRotation;

import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Authenticate from "./components/Authenticate";
import AuthWrapper from "./components/Wrapper/AuthWrapper";
import Navigation from "./components/Navigation/Navigation";
import SideBar from "./components/SideBar";
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./utils/path";


type AppProps = unknown;
const App: React.FC<AppProps> = () => (
  <Router>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={window.location.origin + "/auth/login"}
        useRefreshTokens={true}
        scope="offline_access"
        cacheLocation="localstorage"
        audience={AUTH0_AUDIENCE}
      >
        <Switch>
          <Route path="/auth/:action" exact component={Authenticate} />
          <Route path="*"  render={() => (
            <AuthWrapper>
              <div className="wrapper container-fluid h-100">
                <div className="row gx-5">
                  <div className="col-3 bg-white left-container ">
                    <SideBar />
                  </div>
                  <div className="col-9 px-4 pb-4 d-flex flex-column app-container right-container">
                    <Navigation />
                  </div>
                </div>
              </div>
            </AuthWrapper>
          )} />
        </Switch>
      </Auth0Provider>
    </Router>
);

export default App;

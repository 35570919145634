import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepperContainer } from "./styled";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#784af4",
    border: "1px  dashed #A1A1A1",
  },
}));

const HorizontalLinearStepper: React.FC<{
  steps: any;
  stepperWidthWithPercent?: number;
  activeStep: number;
  orientation?: any;
}> = ({ steps, stepperWidthWithPercent, activeStep, orientation }) => {
  return (
    <StepperContainer>
      <Box
        sx={{
          width: `${stepperWidthWithPercent ? stepperWidthWithPercent : "80"}%`,
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation={orientation}
          connector={<QontoConnector />}
        >
          {steps &&
            steps?.length !== 0 &&
            steps?.map((label: any, index: any) => {
              return (
                <Step key={index} completed={false} className="stepper-step">
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
        </Stepper>
      </Box>
    </StepperContainer>
  );
};
export default HorizontalLinearStepper;

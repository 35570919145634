import { Box } from "@mui/material";
import { StyledHeading2 } from "../../../../../shared";
import { StyledDrawerDiv } from "../styled";
import ConfigureFields from "./ConfigureFields";
import ConfigureKeywordInfo from "./ConfigureKeywordInfo";
interface ConfigureProps {
  control: any;
  getValues: any;
  setValue: any;
  reset: any;
  watch: any;
  errors: any;
  setError: any;
  clearErrors: any;
}

export default function Configure({
  control,
  getValues,
  setValue,
  reset,
  watch,
  errors,
  setError,
  clearErrors,
}: ConfigureProps) {
  return (
    <StyledDrawerDiv>
      <Box
        width="54%"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Box height="8%">
          <StyledHeading2>Configure</StyledHeading2>
        </Box>
        <ConfigureFields
          control={control}
          getValues={getValues}
          setValue={setValue}
          reset={reset}
          watch={watch}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
        />
      </Box>

      <Box
        width="46%"
        alignItems="center"
        display="flex"
        flexDirection="column"
        // height="92%"
      >
        <Box height="8%" mb="10px">
          <StyledHeading2></StyledHeading2>
        </Box>
        <div
          className="drawer-panel drawer-right-panel"
          style={{ height: "100%" }}
        >
          <ConfigureKeywordInfo />
        </div>
      </Box>
    </StyledDrawerDiv>
  );
}


export const SET_MODEL_TEXT = 'SET_MODEL_TEXT';
export const SET_INDUSTRY_TYPE = 'SET_INDUSTRY_TYPE';
export const SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS';
export const SET_RECOMMENDATION_DETAILS = 'SET_RECOMMENDATION_DETAILS';
export const SET_CPR_DETAILS = 'SET_CPR_DETAILS';
export const SET_CPR_GRAPH_DETAILS = 'SET_CPR_GRAPH_DETAILS';
export const SET_BAR_CHART_DATA = 'SET_BAR_CHART_DATA';
export const SET_CPR_RECOMMENDATION = 'SET_CPR_RECOMMENDATION';
export const SET_CHART_LOADING = 'SET_CHART_LOADING';
export const SET_CPR_RECOMMENDATION_LOADING = 'SET_CPR_RECOMMENDATION_LOADING';
export const SET_GROUP_RECOMMENDATION_LOADING = 'SET_GROUP_RECOMMENDATION_LOADING';
export const SET_GROUP_RECOMMENDATION_DETAIL_LOADING = 'SET_GROUP_RECOMMENDATION_DETAIL_LOADING';

export const GET_MODEL_TEXT = 'GET_MODEL_TEXT';
export const GET_INDUSTRY = 'GET_INDUSTRY';
export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const GET_KEYWORD_RECOMMENDATIONS = 'GET_KEYWORD_RECOMMENDATIONS';
export const GET_RECOMMENDATION_DETAILS = 'GET_RECOMMENDATION_DETAILS';
export const ACTION_RECOMMENDATIONS = 'ACTION_RECOMMENDATIONS';
export const GET_CPR_DETAILS = 'GET_CPR_DETAILS';
export const GET_CPR_RECOMMENDATIONS = 'GET_CPR_RECOMMENDATIONS';

export const GET_LEARNING_PHASE_FLAG = 'GET_LEARNING_PHASE_FLAG';

// State
export type CommonState = {
  modelText: {},
  industryType: industry_type[],
  recommendations: any,
  recommendationDetails: any,
  cprDetails: any,
  cprGraphDetails: any,
  barChartData: any,
  cprRecommendation: any,
  chartLoading: boolean,
  cprRecommendationLoading: boolean,
  groupRecommendationLoading: boolean,
  groupRecommendationDetailLoading: boolean,
}

export type adset = {
  id: string,
  name: string
}

export type sub_industries = {
  name: string,
  value: string
}

export type industry_type = {
  name: string,
  subIndustries: sub_industries[],
  value: string
}

export type SetModelText = {
  type: typeof SET_MODEL_TEXT;
  payload: {};
};

export type SetIndustryType = {
  type: typeof SET_INDUSTRY_TYPE;
  payload: industry_type[];
};

export type SetRecommendations = {
  type: typeof SET_RECOMMENDATIONS;
  payload: any;
};

export type SetRecommendationDetails = {
  type: typeof SET_RECOMMENDATION_DETAILS;
  payload: any;
};

export type SetCPRDetails = {
  type: typeof SET_CPR_DETAILS;
  payload: any;
};

export type SetCPRGraphDetails = {
  type: typeof SET_CPR_GRAPH_DETAILS;
  payload: any;
};

export type SetBarChartData = {
  type: typeof SET_BAR_CHART_DATA;
  payload: any;
};

export type SetCPRRecommendation = {
  type: typeof SET_CPR_RECOMMENDATION;
  payload: any;
};

export type SetChartLoading = {
  type: typeof SET_CHART_LOADING;
  payload: boolean;
};

export type SetCPRRecommendationLoading = {
  type: typeof SET_CPR_RECOMMENDATION_LOADING;
  payload: boolean;
};

export type SetGroupRecommendationLoading = {
  type: typeof SET_GROUP_RECOMMENDATION_LOADING;
  payload: boolean;
};

export type SetGroupRecommendationDetailLoading = {
  type: typeof SET_GROUP_RECOMMENDATION_DETAIL_LOADING;
  payload: boolean;
};

// API

export type GetModelText = {
  type: typeof GET_MODEL_TEXT;
  payload: {
    user: any;
  };
  callback?: Function;
}

export type GetIndustry = {
  type: typeof GET_INDUSTRY;
  payload: {
    user: any;
  };
  callback?: Function;
}

export type GetRecommendations = {
  type: typeof GET_RECOMMENDATIONS;
  payload: {
    elementId: string;
    user: any;
  };
  callback?: Function;
}

export type GetKeywordRecommendations = {
  type: typeof GET_KEYWORD_RECOMMENDATIONS;
  payload: {
    adAccountId: string;
    user: any;
  };
  callback?: Function;
}

export type GetRecommendationDetails = {
  type: typeof GET_RECOMMENDATION_DETAILS;
  payload: {
    elementId: string;
    recommendation_id: string;
    user: any;
  };
  callback?: Function;
}

export type ActionRecommendations = {
  type: typeof ACTION_RECOMMENDATIONS;
  payload: {
    elementId: string;
    recommendation_ids: any;
    user: any;
    recommendation_action: "approve" | "deny",
  };
  callback?: Function;
}

export type GetCPRDetails = {
  type: typeof GET_CPR_DETAILS;
  payload: {
    elementId: string;
    start_date: any;
    end_date: any;
    user: any;
  };
  callback?: Function;
}

export type GetCPRRecommendations = {
  type: typeof GET_CPR_RECOMMENDATIONS;
  payload: {
    elementId: string;
    start_date: any;
    end_date: any;
    user: any;
  };
  callback?: Function;
}

export type GetLearningPhaseFlag = {
  type: typeof GET_LEARNING_PHASE_FLAG;
  payload: {
    brandId: string;
    accountId: string;
    user: any;
  };
  callback?: Function;
};




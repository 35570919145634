import { takeLatest, call, takeEvery } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import HttpService from "../../HttpService/HttpService";
import { GET_FORMS, GetFormData, UpdateFormData, UPDATE_FORMS, StoreFormData, STORE_FORM, UpdateOnBoarding, UPDATE_ONBOARDING, GET_ONBOARDING, GetOnBoarding, UpdateTargetingAI, GetTargetingAI, GET_TARGETING_AI, UPDATE_TARGETING_AI, GetClientPDF, GetAgreement, UpdateUserDetails, GET_CLIENT_PDF, GET_AGREEMENT, UPDATE_USER_DETAILS, GET_GOOGLE_ONBOARDING, GetGoogleOnBoarding, UpdateCreativeAI, UPDATE_CREATIVE_AI, GetCreativeAI, GetBrandData, GET_BRAND_DATA, GetPlatformAdaccounts, GET_PLATFORM_ADACCOUNTS, EnableCreativeRotation, DisableCreativeRotation, ENABLE_CREATIVE_ROTATION, DISABLE_CREATIVE_ROTATION, GetCreativeRotationToggle, GET_CREATIVE_ROTATION_TOGGLE, GET_ACTIVITY_LOGS, GetActivityLogs, UPDATE_GOOGLE_ONBOARDING, UpdateGoogleOnBoarding } from "../types/form";
import { crossPlatform } from "../../MappedAPI/MappedAPI";

function* getFormData(action: GetFormData): any {
    let {
        payload: { elementId, user },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/targeting-ai-form/${elementId}`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updateFormData(action: UpdateFormData): any {
    let {
        payload: { elementId, formId, user, params },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/targeting-ai-form/${elementId}/${formId}`;
        const response = yield call(HttpService.put, CROSS_PLATFORM_URL, `${urlParams}`, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* storeFormData(action: StoreFormData): any {
    let {
        payload: { elementId, user, params },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/targeting-ai-form${elementId ? `/` + elementId : ``}`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, `${urlParams}`, user, params);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updateTargetingAI(action: UpdateTargetingAI): any {
    let {
        payload: { brandId, user, enable },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/brand-ad-account-settings/${brandId}/tai-onboarding/${enable}`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, `${urlParams}`, user, {});
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updateCreativeAI(action: UpdateCreativeAI): any {
    let {
        payload: { brandId, user, enable },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/brand/${brandId}/creative-ai-onboarding/${enable}`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, `${urlParams}`, user, {});
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}
function* getBrandData(action: GetBrandData): any {
    let {
        payload: { brandId, user },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/brand/${brandId}`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, `${urlParams}`, user, {});
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getTargetingAI(action: GetTargetingAI): any {
    let {
        payload: { brandId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/brand-ad-account-settings/${brandId}/tai-onboarding`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getCreativeAI(action: GetCreativeAI): any {
    let {
        payload: { brandId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/brand/${brandId}`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getPlatformAdaccounts(action: GetPlatformAdaccounts): any {
    let {
        payload: { adaccountId, platformId, user },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/ai-group/${adaccountId}/${platformId}/get-aigroups`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* enableCreativeRotation(action: EnableCreativeRotation): any {
    let {
        payload: { elementId, user },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/element/${elementId}/enable-creative-rotation`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, urlParams, user,{});
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* disableCreativeRotation(action: DisableCreativeRotation): any {
    let {
        payload: { elementId, user },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/element/${elementId}/disable-creative-rotation`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, urlParams, user,{});
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getCreativeRotationToggle(action: GetCreativeRotationToggle): any {
    let {
        payload: { elementId, user },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/element/${elementId}/creative-rotation`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getActivityLogs(action: GetActivityLogs): any {
    let {
        payload: { elementId, original_ad_account_id,start_date, end_date,download,page,page_size,user},
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/creative-rotation/${elementId}/activity-logs?original_ad_account_id=${original_ad_account_id}&end_date=${end_date}&download=${download}&page=${page}&page_size=${page_size}&start_date=${start_date}`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getGoogleOnBoarding(action: GetGoogleOnBoarding): any {
    let {
        payload: { brandId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/brand-ad-account-settings/${brandId}/google-onboarding`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updateGoogleOnBoarding(action: UpdateGoogleOnBoarding): any {
    let {
        payload: { brandId, user, enable },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/brand-ad-account-settings/${brandId}/google-onboarding/${enable}`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, `${urlParams}`, user, {});
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getOnBoarding(action: GetOnBoarding): any {
    let {
        payload: { brandId, accountId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/brand-ad-account-settings/${brandId}/${accountId}/tai`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* updateOnBoarding(action: UpdateOnBoarding): any {
    let {
        payload: { brandId, accountId, user, enable },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/brand-ad-account-settings/${brandId}/${accountId}/tai/${enable}`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, `${urlParams}`, user, {});
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getClientPDF(action: GetClientPDF): any {
    let {
        payload: { brandId, userType },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/brand-agreement/${brandId}/pdf?userType=${userType}`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, '', 'arraybuffer');
        if (response.data) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* getAgreement(action: GetAgreement): any {
    let {
        payload: { brandId, user },
        callback,
    } = action;
    try {
        const urlParams = `/v1/api/brand-agreement/${brandId}`;
        const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams, user);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

function* userDetails(action: UpdateUserDetails): any {
    let {
        payload: { brandId, user, param },
        callback,
    } = action;
    try {
        const urlParams = `${crossPlatform}/brand-agreement/${brandId}?userType=${param.userType}`;
        const response = yield call(HttpService.post, CROSS_PLATFORM_URL, `${urlParams}`, user, param);
        if (!response.data.error && response.data.success) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error: any) {
        callback && callback(error.response, true);
    }
}

export function* formWatcher() {
    yield takeEvery(GET_FORMS, getFormData);
    yield takeLatest(UPDATE_FORMS, updateFormData);
    yield takeLatest(STORE_FORM, storeFormData);
    yield takeLatest(GET_ONBOARDING, getOnBoarding);
    yield takeLatest(GET_GOOGLE_ONBOARDING, getGoogleOnBoarding);
    yield takeLatest(UPDATE_ONBOARDING, updateOnBoarding);
    yield takeLatest(UPDATE_GOOGLE_ONBOARDING, updateGoogleOnBoarding);
    yield takeLatest(GET_TARGETING_AI, getTargetingAI);
    yield takeLatest(UPDATE_TARGETING_AI, updateTargetingAI);
    yield takeLatest(UPDATE_CREATIVE_AI, updateCreativeAI);
    yield takeLatest(GET_BRAND_DATA, getBrandData);
    yield takeLatest(GET_PLATFORM_ADACCOUNTS, getPlatformAdaccounts);
    yield takeLatest(GET_CLIENT_PDF, getClientPDF);
    yield takeLatest(GET_AGREEMENT, getAgreement);
    yield takeLatest(UPDATE_USER_DETAILS, userDetails);
    yield takeLatest(ENABLE_CREATIVE_ROTATION, enableCreativeRotation);
    yield takeLatest(DISABLE_CREATIVE_ROTATION, disableCreativeRotation);
    yield takeEvery(GET_CREATIVE_ROTATION_TOGGLE, getCreativeRotationToggle);
    yield takeLatest(GET_ACTIVITY_LOGS, getActivityLogs);
}
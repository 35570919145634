import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MessageContainer from "../../../components/MessageContainer/MessageContainer";
import {
  getBrandData,
  getCreativeAI,
  setCreativeAI,
  setCreativeAILoading,
  updateCreativeAI,
} from "../../../store/actions/form";
import { getLearningPhase } from "../../../store/actions/auth";
import { initialState } from "../../../store/types/auth";
import { CreativeAIOnBoarding } from "../../../utils/constants";
import { AdAccountState } from "../../../store/types/adaccount";
import CreativeAIListing from "../../../components/CreativeAIListing/CreativeAIListing";
import { FormState } from "../../../store/types/form";
import es from "date-fns/esm/locale/es/index.js";
import Loader from "../../../components/common/Loader";
import CreativeAIListingV2 from "../../../components/CreativeAI/Facebook/Main/AiGroupListing/CreativeAIListingV2";

const CreativeAI: React.FC = (): JSX.Element => {
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { creativeAILoading, creativeAI } = formState;
  const showListing = useMemo(() => creativeAI, [creativeAI]);
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCreativeAILoading(true));
    dispatch(
      getBrandData(
        { brandId: selectedBrand?.id, user: auth?.user },
        (response: any, error: boolean) => {
          if (!error) {
            dispatch(setCreativeAI(response.data.creativeAiOnboarding));
            dispatch(setCreativeAILoading(false));
          }
        }
      )
    );
  }, [selectedBrand]);
  return (
    <>
      {!creativeAILoading ? (
        !showListing && showListing !== null ? (
          <MessageContainer
            data={CreativeAIOnBoarding}
            submitText="Unlock Creative AI"
            handleNext={() => {
              dispatch(
                updateCreativeAI(
                  { brandId: selectedBrand.id, user: auth?.user, enable: true },
                  (response: any, error: boolean) => {
                    if (!error) {
                      dispatch(setCreativeAI(!creativeAI));
                    }
                  }
                )
              );
            }}
          />
        ) : (
          <CreativeAIListingV2 />
          // <CreativeAIListing />
        )
      ) : (
        <Loader />
      )}
    </>
  );
};
export default CreativeAI;

import { Select, InputWithLabel } from "../../../../../shared";
import { AdSettingsTypes } from "../Editor.types";
import { StyledAdSettingsDiv } from "./styled";

export interface AdSettingsProps {
  value: string;
  textFields: AdSettingsTypes[];
  onAdSettingChange: (e: any, index: number) => void;
}

export default function AdSettings({
  value,
  textFields,
  onAdSettingChange,
}: AdSettingsProps) {
  return (
    <StyledAdSettingsDiv hidden={value !== "ad-settings"} id="ad-settings">
      {textFields.map((element, index) => {
        const {
          label,
          value,
          tooltipText,
          readOnly,
          placeholder,
          type,
          options,
        } = element;
        if (type && type === "Select") {
          return (
            <Select
              label={label}
              readOnly={readOnly}
              backgroundColor="#E9EAEB"
              placeholder={placeholder}
              tooltipText={tooltipText}
              value={value}
              onChange={(e) => onAdSettingChange(e, index)}
              fullWidth
              options={options}
            />
          );
        }
        if (readOnly) {
          return (
            <InputWithLabel
              fullWidth
              label={label}
              value={value}
              tooltipText={tooltipText}
              backgroundColor="#E9EAEB"
              readOnly
              disableUnderline
              placeholder={placeholder}
            />
          );
        } else {
          return (
            <InputWithLabel
              backgroundColor="white"
              borderColor="#E9EAEB"
              fullWidth
              label={label}
              value={value}
              tooltipText={tooltipText}
              isBorder
              disableUnderline
              placeholder={placeholder}
              onChange={(e) => onAdSettingChange(e, index)}
            />
          );
        }
      })}
    </StyledAdSettingsDiv>
  );
}

import {
  FetchValueFormAdEnum,
  FetchValueFromAdSetType,
  FetchValueFromAdType,
  GenderEnum,
  GeneratePreviewImageType,
  GeneratePreviewVideoType,
  GenerationType,
  GenerationTypeConvertEnum,
} from "../shared/SharedTypes";

export const getCreativeSPecFieldName = ({
  type,
  field,
}: {
  type: any;
  field: GenerationType;
}) => {
  if (type === "link_data") {
    return GeneratePreviewImageType[field];
  } else {
    return GeneratePreviewVideoType[field];
  }
};

export const getCohortName = (adSetName: string) => {
  const adSetNameArray = adSetName.split("_");
  if (adSetName && adSetNameArray.length > 2)
    return adSetNameArray[adSetNameArray.length - 2];
  else {
    return undefined;
  }
};

export const checkAdType = (createSpec: any) => {
  const { object_story_spec } = createSpec;
  const { link_data } = object_story_spec;
  if (link_data) return "link_data";
  return "video_data";
};

export const getComaSeparatedDate = (fields: any) => {
  const value = fields.map((element: any) => element.name);
  return value.join(",");
};

//field mapping of fetch value form ad level

export const fetchAdTypeField = (
  createSpec: any,
  facebook_internal_key: FetchValueFromAdType
): any => {
  const fieldsOptionArray =
    FetchValueFormAdEnum[facebook_internal_key].split("|");
  let fetchAdTypeFieldValue = "";
  for (let fieldsString of fieldsOptionArray) {
    const fieldsArray = fieldsString.split(".");
    let currentValue = createSpec;
    for (let field of fieldsArray) {
      if (currentValue[field]) {
        currentValue = currentValue[field];
        currentValue = Array.isArray(currentValue)
          ? currentValue[0]
          : currentValue;
      } else {
        currentValue = undefined;
        break;
      }
    }
    if (currentValue) {
      fetchAdTypeFieldValue = currentValue;
      break;
    }
  }
  return fetchAdTypeFieldValue;
};

//field mapping of fetch value form adset level

export const fetchValueFromAdSet = (
  targeting: any,
  facebook_internal_key: FetchValueFromAdSetType
) => {
  switch (facebook_internal_key) {
    case "age_min": {
      return targeting?.age_min ?? "";
    }
    case "age_max": {
      return targeting?.age_max ?? "";
    }
    case "interests": {
      return targeting?.flexible_spec[0]?.interests
        ? getComaSeparatedDate(targeting?.flexible_spec[0]?.interests)
        : "";
    }
    case "behaviors": {
      return targeting?.flexible_spec[0]?.behaviors
        ? getComaSeparatedDate(targeting?.flexible_spec[0]?.behaviors)
        : "";
    }
    case "genders": {
      return targeting?.genders?.length
        ? targeting.genders
            .map((gender: number) =>
              gender <= 2 ? GenderEnum[gender - 1] : "All"
            )
            .join(",")
        : "";
    }
  }
};

// map element level data with ad and adset level of data

export const mapAdAdSetToElement = ({
  levers,
  createSpec,
  targeting,
  creativeElementAI,
}: any) => {
  const newCreativeElementAI = { ...creativeElementAI };
  const adAdSetFieldList: any = {};
  for (let leverField of levers) {
    const generationType: GenerationType = leverField.generation_type;
    const leversObject = leverField.levers;
    for (let lever of leversObject) {
      const leverName = lever.lever_name;
      const fields = lever.fields;
      for (let field of fields) {
        const fetchValueFrom = field.fetch_value_from;
        const fieldName = field.field_name;
        const facebook_internal_key = field.facebook_internal_key;
        if (fetchValueFrom === "ad") {
          const adValue = fetchAdTypeField(createSpec, facebook_internal_key);
          adAdSetFieldList[`${generationType}.${leverName}.${fieldName}`] =
            adValue;

          newCreativeElementAI.CreativeAiData[
            GenerationTypeConvertEnum[generationType]
          ][leverName] = {
            ...newCreativeElementAI.CreativeAiData[
              GenerationTypeConvertEnum[generationType]
            ][leverName],
            [fieldName]: adValue,
          };
        }
        if (fetchValueFrom === "adset") {
          const adSetValue = fetchAdTypeField(targeting, facebook_internal_key);
          adAdSetFieldList[`${generationType}.${leverName}.${fieldName}`] =
            adSetValue;

          newCreativeElementAI.CreativeAiData[
            GenerationTypeConvertEnum[generationType]
          ][leverName] = {
            ...newCreativeElementAI.CreativeAiData[
              GenerationTypeConvertEnum[generationType]
            ][leverName],
            [fieldName]: adSetValue,
          };
        }
      }
    }
  }
  return { newCreativeElementAI, adAdSetFieldList };
};

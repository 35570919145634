import { Box, RadioGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateRecommendationMetadata } from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  CreateVariantsState,
  RecommendationMetaDataItem,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import EditableTextRadio from "../EditableTextRadio/EditableTextRadio";
import { HashType } from "../Editor.types";
import {
  StyledButtonDiv,
  StyledIntroText,
  StyledOptionsContainer,
  StyledShowMore,
} from "./styled";

interface OptionsComponentProps {
  titleText: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: HashType
  ) => void;
  value?: string | number;
  options: RecommendationMetaDataItem;
  hash: HashType;
  showMoreData: (hash: HashType) => void;
}

export default function OptionsComponent({
  handleChange,
  value,
  titleText,
  options,
  hash,
  showMoreData,
}: OptionsComponentProps) {
  const optionToBeShown = options?.data?.slice(0, options?.noOfElementToShow);
  const dispatch = useDispatch();
  const { recommendationMetaData } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const onSave = (index: number, value: string) => {
    const localRecommendationData = {
      ...recommendationMetaData,
      isEditing: true,
    };
    localRecommendationData[hash].data[index] = value;
    dispatch(
      updateRecommendationMetadata({
        data: localRecommendationData,
      })
    );
    dispatch({
      type: UPDATE_RECOMMENDATION_SELECTION_STATE,
      payload: {
        isChanged: true,
      },
    });
  };

  return (
    <StyledOptionsContainer id={hash} key={hash}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => handleChange(e, hash)}
        className="p-0 m-0"
      >
        <StyledIntroText>{titleText}</StyledIntroText>
        {optionToBeShown && optionToBeShown.length ? (
          optionToBeShown.map((option, index) => (
            <EditableTextRadio
              value={option}
              radioValue={option}
              isSelected={option === value}
              key={index}
              hash={hash}
              index={index}
              onSave={onSave}
            />
          ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            color="red"
            sx={{
              paddingTop: "25px",
            }}
          >
            Sorry there is no recommendation to be shown
          </Box>
        )}

        <StyledButtonDiv>
          {options.showMore && (
            <StyledShowMore onClick={() => showMoreData(hash)}>
              Show More
            </StyledShowMore>
            // <Button
            //   buttonVariant="underline"
            //   onClick={() => showMoreData(hash)}
            // >
            //   Show More
            // </Button>
          )}
        </StyledButtonDiv>
      </RadioGroup>
    </StyledOptionsContainer>
  );
}

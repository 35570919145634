import React, { Fragment } from "react";
import CustomizedDialogs from "../../../shared/Modal/CustomizedDialogs";
import {
  StyledTitle,
  StyledButtonContainer,
  StyledTitleDM,
} from "../../../../Pages/CreativeAI/Facebook/CreateVariants/styled";
import CustomizedButton from "../../../shared/Button/CustomizedButton";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { discardDraftVariant } from "../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { Button } from "../../../shared";
import { Box, CircularProgress } from "@mui/material";
import { CreateVariantsState } from "../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";

const DiscardDraftModal: React.FC<{
  show: boolean;
  onHide: () => void;
}> = ({ show, onHide }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state?.selectCohortReducer?.cohortData,
      ADS_LIST: state?.createVariantReducer?.adsList,
    })
  );
  const { isDeletingVariant } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const onDiscard = () => {
    dispatch(discardDraftVariant({ adSetId: COHORT_DATA?.adset?.adsetId }));
    onHide();
    history.push(`/creative-ai/variants/cohort${history.location.search}`);
  };
  return (
    <CustomizedDialogs
      show={show}
      handleClose={() => onHide()}
      type="ConfirmDialog"
      fullScreen={false}
      maxWidth="sm"
    >
      <Fragment>
        <StyledTitleDM className="mb-0">
          All the new created variants along with their setup <br /> will be
          deleted{" "}
        </StyledTitleDM>

        <StyledButtonContainer>
          <CustomizedButton
            buttonText="Cancel"
            buttonClass="primary cancel-btn mr-3"
            onClick={() => onHide()}
            variant="text"
          />
          <Button
            buttonVariant="contained"
            className="button-base-mui"
            onClick={onDiscard}
          >
            {isDeletingVariant ? (
              <Box width="60px" height="22px">
                <CircularProgress
                  size={25}
                  sx={{
                    color: "white",
                  }}
                  thickness={5}
                />
              </Box>
            ) : (
              "Continue"
            )}
          </Button>
        </StyledButtonContainer>
      </Fragment>
    </CustomizedDialogs>
  );
};

export default DiscardDraftModal;

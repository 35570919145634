import React, { Fragment, useState } from "react";

import {
  StyledVariantItemParaTitle,
  StyledVariantItemParaADC,
  StyledAdsListContainer,
  StyledAdsImage,
  StyledAdsListContainerTitle,
  StyledAdsInDraft,
  StyledAdsValues,
  StyledAdsDrafFields,
  StyledBackDrop,
} from "./styled";

import Divider from "@mui/material/Divider";
import { Box, Typography } from "@mui/material";
import { StyledMainCreativeContainer } from "../styled";
import MainCreativeCard from "../AdPreview/MainCreativeCard";
import imagePlaceholder from "../../../../../assets/png/no_image_placeholder.png";

const AdCard: React.FC<{
  item?: any;
  handelSelect: any;
  activeAd: any;
  index: number;
  previewLoading: any;
  adPreviewLink: any;
}> = ({
  item,
  handelSelect,
  activeAd,
  index,
  // setShowMainCreativeAi,
  // showMainCreativeUI,
  previewLoading,
  adPreviewLink,
}) => {
  const [showMainCreativeUI, setShowMainCreativeAi] = useState(false);
  const [hoverDelay, setHoverDelay] = useState<any>("");

  if (!item) {
    return <div>Loading...</div>;
  }
  const selectedCount = item?.variantList?.filter(
    (element: any) => element.checked
  )?.length;

  return (
    <>
      <StyledAdsListContainer
        onClick={() => handelSelect(item, index)}
        active={activeAd?.id === item?.id ? true : false}
      >
        <div className="pointer"></div>
        {selectedCount ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: "8px",
              right: "14px",
              backgroundColor: "#0869FB",
              width: "18px",
              height: "18px",
              borderRadius: "20px",
            }}
          >
            <Typography color="white" fontSize="11px" fontWeight="700">
              {selectedCount}
            </Typography>
          </Box>
        ) : null}
        <div className="ads-list-text">
          <div
            onMouseOver={(e) => {
              if (item?.id) {
                setHoverDelay(
                  setTimeout(() => {
                    e.stopPropagation();
                    setShowMainCreativeAi(true);
                  }, 500)
                );
              }
            }}
            onMouseOut={(e) => {
              e.stopPropagation();
              setHoverDelay(clearTimeout(hoverDelay));
            }}
          >
            <StyledAdsImage
              src={item?.adcreatives?.data[0]?.image_url ?? imagePlaceholder}
              alt=""
            />
            {showMainCreativeUI && (
              <StyledBackDrop
                className="dropback"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMainCreativeAi(false);
                  setHoverDelay(clearTimeout(hoverDelay));
                }}
              >
                <StyledMainCreativeContainer key={activeAd.id}>
                  <div className="mainContainer">
                    <MainCreativeCard
                      item={item}
                      adPreviewLink={adPreviewLink}
                      selectedAd={activeAd}
                      previewLoading={previewLoading}
                    />
                  </div>
                </StyledMainCreativeContainer>
              </StyledBackDrop>
            )}
          </div>
          <StyledAdsListContainerTitle>
            <StyledVariantItemParaTitle className="fw-bold">
              Ad Name:
            </StyledVariantItemParaTitle>
            <StyledVariantItemParaADC>
              {item?.name ?? "-"}
            </StyledVariantItemParaADC>
            <StyledAdsDrafFields className="mt-2">
              <span>
                <StyledAdsInDraft>In Draft</StyledAdsInDraft>
                <StyledAdsValues>{item?.draftVariants}</StyledAdsValues>
              </span>
              <Divider
                orientation="vertical"
                flexItem
                style={{ height: "35px" }}
              />
              <span>
                <StyledAdsInDraft>Published Variants</StyledAdsInDraft>
                <StyledAdsValues>{item?.publishedVariants}</StyledAdsValues>
              </span>
            </StyledAdsDrafFields>
          </StyledAdsListContainerTitle>
        </div>
      </StyledAdsListContainer>
    </>
  );
};

export default React.memo(AdCard);

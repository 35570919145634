import React, { useEffect, useState } from "react";
import BrandSettingTable from "./BrandSettingTable";
import {
  StyledContainer,
  StyledTitle,
  StyledHeaderContainer,
  StyledHeadTitle,
  StyledLinkItem,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import BusinessUnitModal from "./BusinessUnitModal";
import { initialState } from "../../../store/types/auth";
import TooltipWithInfo from "../Facebook/shared/TooltipWithInfo/TooltipWithInfo";
import AddIcon from "../../../assets/svg/buAddIcon.svg";
import { Button } from "../../shared";
import CustomizedDialogs from "../../shared/Modal/CustomizedDialogs";
import { Stack } from "@mui/material";
import Loader from "../../common/Loader";
import { AdAccountState } from "../../../store/types/adaccount";

const BrandSetting: React.FC = () => {
  const dispatch = useDispatch();

  const { singleBusinessUnit, businessUnits, deleteBusinessUnitState } =
    useSelector((state: any) => state.businessUnitsReducer);

  const [userConfirmation, setUserConfirmation] = useState<{
    uiState: boolean;
    userInput: boolean;
  }>({
    uiState: false,
    userInput: false,
  });

  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  useEffect(() => {
    dispatch({ type: "GET_ALL_BUSINESS_UNITS", payload: params });
    dispatch({ type: "GENERATE_LEVERS" });
  }, []);

  const [showUnitForm, setShowUniteForm] = useState(false);

  const [editState, setEditState] = useState(false);

  const handelToggleModal = () => {
    setShowUniteForm(!showUnitForm);
    setEditState(false);
  };

  const initialParams = {
    searchField: "",
    search: "",
    order: "ASC",
    orderBy: "businessUnit",
    limit: "50",
    page: "",
    brandId: selectedBrand?.id,
  };

  const paramsReducer = (state: any, action: any) => {
    switch (action.type) {
      case action.type:
        return { ...state, [action.type]: action.payload };
      default:
        return state;
    }
  };

  const [params, paramsDispatch] = React.useReducer(
    paramsReducer,
    initialParams
  );

  const handleBusinessEdit = (id: string = "") => {
    setShowUniteForm(true);
    setEditState(true);
    dispatch({ type: "GET_BUSINESS_UNIT_BY_ID", payload: { id } });
  };

  const handleBusinessSave = (formData: any) => {
    dispatch({
      type: "ADD_BUSINESS_UNIT",
      payload: { user: auth.user, formData, brandId: selectedBrand?.id },
    });
  };
  const handleBusinessUpdate = (id: any, creativeAiId: any, formData: any) => {
    dispatch({
      type: "UPDATE_BUSINESS_UNIT",
      payload: {
        id,
        creativeAiId,
        user: auth.user,
        formData,
        brandId: selectedBrand?.id,
      },
    });
  };

  const handleBusinessUnitDelete = (id: string = "") => {
    // if (window.confirm("Are you sure you want to delete")) {

    dispatch({
      type: "DELETE_BUSINESS_UNIT_BY_ID",
      payload: { id, user: auth.user, brandId: selectedBrand?.id },
    });

    // }
  };

  return (
    <>
      {userConfirmation && (
        <CustomizedDialogs
          handleClose={() =>
            setUserConfirmation({ ...userConfirmation, uiState: false })
          }
          show={userConfirmation.uiState}
          type="ConfirmDialog"
        >
          <p>Are you sure you want to delete?</p>
          <Stack direction={"row"} justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() => {
                setUserConfirmation({
                  ...userConfirmation,
                  uiState: false,
                  userInput: false,
                });
              }}
            >
              NO
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setUserConfirmation({
                  ...userConfirmation,
                  uiState: false,
                  userInput: false,
                });
              }}
            >
              YES
            </Button>
          </Stack>
        </CustomizedDialogs>
      )}
      <StyledTitle>{`Creative AI Admin Control`}</StyledTitle>
      <StyledContainer className="rounded">
        <StyledHeaderContainer>
          <StyledHeadTitle>
            Business Units
            <TooltipWithInfo info="Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit." />
          </StyledHeadTitle>
          <StyledLinkItem onClick={() => setShowUniteForm(true)}>
            <img style={{ marginRight: "10px" }} src={AddIcon} /> Add Business
            Unit
          </StyledLinkItem>
        </StyledHeaderContainer>
        {businessUnits?.loading ? (
          <Loader />
        ) : (
          <div style={{ width: "100%", overflow: "scroll" }}>
            {businessUnits?.loading === false && (
              <BrandSettingTable
                handleBusinessEdit={handleBusinessEdit}
                handleBusinessUnitDelete={handleBusinessUnitDelete}
                businessUnits={businessUnits?.data}
              />
            )}
          </div>
        )}
      </StyledContainer>
      {showUnitForm && (
        <BusinessUnitModal
          editState={editState}
          handleBusinessSave={handleBusinessSave}
          handleBusinessUpdate={handleBusinessUpdate}
          onHide={() => handelToggleModal()}
          showUnitForm={showUnitForm}
          setShowUniteForm={setShowUniteForm}
          singleBusinessUnit={singleBusinessUnit}
        />
      )}
    </>
  );
};

export default BrandSetting;

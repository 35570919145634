import { has, isEmpty, merge } from "lodash";
import { APIGenerationType } from "../shared/SharedTypes";

// TODO: to be replaced with better algo

const hasLever = (levers: any, lever: any) => {
  if (!has(levers, lever)) {
    levers[lever] = {};
  }
};

// to merge headline and description levers into headline/description
export const mergeData = (data: APIGenerationType[]) => {
  const headlineLevers = data.find(
    (g) => g.generation_type === "headline"
  )?.levers;
  const descriptionLevers = data.find(
    (g) => g.generation_type === "description"
  )?.levers;

  descriptionLevers?.forEach((descriptionLever) => {
    if (headlineLevers) {
      const headlineLeverIndex = headlineLevers.findIndex(
        (h) => descriptionLever.lever_name === h.lever_name
      );

      if (headlineLeverIndex > -1) {
        descriptionLever.fields.forEach((descriptionField) => {
          const headlineFieldIndex = headlineLevers[
            headlineLeverIndex
          ].fields.findIndex(
            (headlineField) =>
              headlineField.field_name === descriptionField.field_name
          );
          if (headlineFieldIndex > -1) {
            headlineLevers[headlineLeverIndex].fields[headlineFieldIndex] = {
              ...headlineLevers[headlineLeverIndex].fields[headlineFieldIndex],
              origin: "both",
            };
          } else {
            headlineLevers[headlineLeverIndex].fields.push({
              ...descriptionField,
              origin: "description",
            });
          }
        });
      } else {
        headlineLevers?.push(descriptionLever);
      }
    }
  });

  return headlineLevers;
};

// TODO: merge expandData and expandGeneratedData
// to expand headline/description form values into headline and description form values
export const expandData = (
  headlineDescription: any,
  headlineDescriptionLevers: any,
  isBU: boolean = false
) => {
  let formattedData: any = {
    headline: {},
    description: {},
  };
  if (isBU) {
    formattedData["superUserSettings"] = {};
  }
  for (let lever in headlineDescription) {
    for (let field in headlineDescription[lever]) {
      const headlineDescriptionLever = headlineDescriptionLevers
        ?.find((l: any) => l.lever_name === lever)
        ?.fields.find((f: any) => f.field_name === field);
      const origin = headlineDescriptionLever?.origin;
      const is_dependent = headlineDescriptionLever?.is_dependent;
      const value = headlineDescription[lever][field];
      if (isBU && is_dependent) {
        formattedData.superUserSettings[field] = value;
      }
      switch (origin) {
        case "both":
          hasLever(formattedData.description, lever);
          hasLever(formattedData.headline, lever);
          formattedData.description[lever][field] = value;
          formattedData.headline[lever][field] = value;
          break;

        case "description":
          hasLever(formattedData.description, lever);
          formattedData.description[lever][field] = value;
          break;

        default:
          hasLever(formattedData.headline, lever);
          formattedData.headline[lever][field] = value;
          break;
      }
      if (field === "isChecked") {
        hasLever(formattedData.description, lever);
        formattedData.description[lever][field] = value;
      }
    }
  }
  return formattedData;
};

export const generateConfigurePayload = (
  data: any,
  generations: any,
  noOfRecommendations: number = 5
) => {
  const { headline, description, primary_text, primaryText, ...rest } = data;
  let generationsData: any = {
    primary_text: primaryText || primary_text,
    headline,
    description,
  };
  let formattedData: any = {};
  const bu = {
    bu_name: rest.businessUnit,
    bu_detail: rest.businessUnitDetail,
    brand_name: rest.brandName,
    interest_keyword: rest.interestKeyword,
  };
  const payload: any = { input_data: {} };
  for (let generationType of ["primary_text", "headline", "description"]) {
    const defaultLevers = generations.find(
      (generation: any) => generation.generation_type === generationType
    )?.levers;
    formattedData[generationType] = {};
    for (let lever in generationsData[generationType]) {
      const defaultFields = defaultLevers?.find(
        (defaultLever: any) => defaultLever.lever_name === lever
      )?.fields;
      if (generationsData[generationType][lever].isChecked) {
        formattedData[generationType][lever] = {};
        formattedData[generationType][lever]["business_unit"] = { default: bu };
        for (let field in generationsData[generationType][lever]) {
          if (
            field !== "isChecked" &&
            defaultFields?.find(
              (defaultField: any) => defaultField.field_name === field
            )
          ) {
            let value = generationsData[generationType][lever][field];
            if (value && typeof value === "object") {
              value = value.value;
            }
            if (field.startsWith("bu_")) {
              formattedData[generationType][lever]["business_unit"][field] =
                value;
            } else {
              formattedData[generationType][lever][field] = value;
            }
          }
        }
        formattedData[generationType]["total_no_outputs"] = noOfRecommendations;
      }
    }
    if (!isEmpty(formattedData[generationType])) {
      payload.input_data[generationType] = formattedData[generationType];
    }
  }
  return payload;
};

export const getFormValues = (data: any, isConfigure: boolean = false) => {
  const formValues: any = {
    brandName: data?.brandName,
    interestKeyword: data?.interestKeyword,
    businessUnitId: data?.businessUnitId,
  };
  if (isConfigure) {
    formValues["primary_text"] = data?.CreativeAiData?.primaryText;
    formValues["headline"] = data?.CreativeAiData?.headline;
    formValues["description"] = data?.CreativeAiData?.description;
  } else {
    formValues["primaryText"] = data?.CreativeAiData?.primaryText;
    formValues["headlineDescription"] = merge(
      data?.CreativeAiData?.description,
      data?.CreativeAiData?.headline
    );
  }
  return formValues;
};

export const getBUFormValues = (
  data: any,
  isBU: boolean = false,
  isConfigure: boolean = false
) => {
  const payload: any = {
    brandName: data?.brandName,
    interestKeyword: data?.interestKeyword,
    businessUnit: data?.businessUnit,
    businessUnitDetail: data?.businessUnitDetail,
    status: data?.status === "readyToUse",
  };
  if (isBU) {
    payload["businessUnit"] = data?.businessUnit;
    payload["businessUnitDetail"] = data?.businessUnitDetail;
  } else {
    payload["businessUnitId"] = data?.id;
  }
  if (isConfigure) {
    payload["headline"] = data?.headline;
    payload["description"] = data?.description;
    payload["primary_text"] = data?.primaryText;
  } else {
    payload["primaryText"] = data?.primaryText;
    payload["headlineDescription"] = merge(data?.description, data?.headline);
  }
  return payload;
};

import styled from "styled-components";

export const StyledAdSettingsDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 17% 30px 17%;
  overflow: scroll;

  & ${"label"} {
    font-family: "Inter";
  }
`;

import {
  GetAdAccountDetails,
  GetAdsetDetails,
  GetAllBrands,
  GetAllPlatforms,
  GetBrandAdAccounts,
  GetCampaignDetails,
  GetElementId,
  GetGroupAdAccounts,
  GetGroups,
  GetSavingCalculatorData,
  GET_ADACCOUNT_DETAIL,
  GET_ADSET_DETAILS,
  GET_ALL_BRANDS,
  GET_ALL_PLATFORMS,
  GET_BRAND_ADACCOUNTS,
  GET_CAMPAIGN_DETAILS,
  GET_ELEMENT_ID,
  GET_GROUPS,
  GET_GROUP_ADACCOUNTS,
  GET_SAVINGCALCULATOR_DATA,
  SET_SAVINGCALCULATOR_DATA,
} from "./../types/adaccount";
import { takeLatest, call, takeEvery, put } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import { crossPlatform, groupV2API } from "../../MappedAPI/MappedAPI";
import HttpService from "../../HttpService/HttpService";

function* getGroups(action: GetGroups): any {
  let {
    payload: { brandId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getGroups ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAdsetDetails(action: GetAdsetDetails): any {
  let {
    payload: { group_id, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ai-group/${group_id}/adsets`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAdsetDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getCampaignDetails(action: GetCampaignDetails): any {
  let {
    payload: { group_id, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ai-group/${group_id}/campaigns`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAdsetDetails ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAllBrands(action: GetAllBrands): any {
  let {
    payload: { user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/brand`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllBrands ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAllPlatforms(action: GetAllPlatforms): any {
  let {
    payload: { user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/platform`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getSavingCalculatorData(action: GetSavingCalculatorData): any {

  const {
    payload,
    callback,

  } = action;
  try {
    const urlParams = `${crossPlatform}/savings-calculator/${payload?.elementID}/${payload.platformType}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);

    if (!response.data.error) {
      yield put({ type: SET_SAVINGCALCULATOR_DATA, payload: response.data.data })
      // callback && callback(response.data, false);
    } else {             
      callback && callback(response, true);
    }
  } catch (error: any) {
    yield put({ type: SET_SAVINGCALCULATOR_DATA, payload: {} })
    console.log("function*getAllPlatforms ~ error", error.response.data.message);
    callback && callback(error.response, true);
  }
}

function* getElementId(action: GetElementId): any {
  let {
    payload: { adAccountId, aiGroupId, platformId, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/element/${platformId}/${aiGroupId}/${adAccountId}`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getBrandAdAccounts(action: GetBrandAdAccounts): any {
  let {
    payload: { brandId },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/brand/${brandId}/ad-account`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getGroupAdAccounts(action: GetGroupAdAccounts): any {
  let {
    payload: { groupId },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ai-group/${groupId}/ad-accounts`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

function* getAdAccountDetails(action: GetAdAccountDetails): any {
  let {
    payload: { adaccountId, platformType },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/ad-account/${adaccountId}/platform/${platformType}/details`;
    const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getAllPlatforms ~ error", error);
    callback && callback(error.response, true);
  }
}

export function* adAccountWatcher() {
  yield takeLatest(GET_GROUPS, getGroups);
  yield takeEvery(GET_ADSET_DETAILS, getAdsetDetails);
  yield takeEvery(GET_CAMPAIGN_DETAILS, getCampaignDetails);
  yield takeLatest(GET_ALL_BRANDS, getAllBrands);
  yield takeLatest(GET_ALL_PLATFORMS, getAllPlatforms);
  yield takeLatest(GET_SAVINGCALCULATOR_DATA, getSavingCalculatorData);
  yield takeEvery(GET_ELEMENT_ID, getElementId);
  yield takeLatest(GET_BRAND_ADACCOUNTS, getBrandAdAccounts);
  yield takeLatest(GET_GROUP_ADACCOUNTS, getGroupAdAccounts);
  yield takeEvery(GET_ADACCOUNT_DETAIL, getAdAccountDetails);
}

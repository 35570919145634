import styled from "styled-components";

//need to setup typography

export const StyledHeading2 = styled.h2`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

export const StyledHeading3 = styled.h2`
  /* font-family: "Lato"; */
  font-style: normal;
  // font-weight: 600;
  // font-size: 17.5px;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  font-family: "Lato";
`;

export const StyledHeading4 = styled.h4`
  /* font-family: "Lato"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  background-color: #f2f2f2;
  width: 90%;
  height: 100%;
  border-radius: 20px;
  z-index: 99999;
  color: #000000;
  max-lines: 1;
  font-family: Inter;
`;

export const StyledErrorMessage = styled.p`
  padding-top: 12px;
  padding-left: 5px;
  color: red;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

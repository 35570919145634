import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseDrawer } from "../../../assets/svg/closeDrawer.svg";
import {
  setCreateAdDesc,
  setCreateAdHeadline,
  setCreateDescError,
  setCreateHeadlineError,
  setCreativeReplacement,
  setGenerateDescriptionTimeStamp,
  setGenerateHeadlineTimestamp,
  setLoadHeadLineDesc,
  setTargetKeyword,
} from "../../../store/actions/cluster";
import { creativeReplacement, staticTargetKeyword } from "../../../utils/constants/GSAI";
import { ClusterState } from "../../../store/types/cluster";
import ModalContainer from "../../Modal/ModalContainer";
import ModalWrapper from "../../Modal/ModalWrapper";
import DrawerBody from "../DrawerBody";
import DrawerHeader from "../DrawerHeader";
import "./index.scss";

const CreateAdDrawer: React.FC<{
  setOpen: (value: boolean) => void;
}> = ({ setOpen }): JSX.Element => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const { showDrawer } = clusterState;
  const dispatch = useDispatch();

  const ShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const hideConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const closeDrawer = () => {
    dispatch(
      setLoadHeadLineDesc({ ...{ headline: false, description: false } })
    );
    dispatch(setTargetKeyword(staticTargetKeyword));
    dispatch(setCreativeReplacement(creativeReplacement)); 
    dispatch(setCreateAdHeadline([]));
    dispatch(setCreateAdDesc([]));
    setShowConfirmModal(false);
    setOpen(false);
    dispatch(setCreateHeadlineError(false));
    dispatch(setGenerateHeadlineTimestamp(null)); 
    dispatch(setCreateDescError(false));
    dispatch(setGenerateDescriptionTimeStamp(null));
  };

  return (
    <div>
      {showDrawer && (
        <div
          id="drawer"
          className="drawer"
          aria-hidden={showDrawer ? "false" : "true"}
        >
          <div className="drawer-container">
            <div className="drawer-container--close-container">
              <div
                className="drawer-container--close"
                onClick={ShowConfirmModal}
              >
                <CloseDrawer />
              </div>
            </div>
            <div className="drawer-container--body">
              <DrawerHeader />
              <DrawerBody setOpen={setOpen} />
            </div>
          </div>
        </div>
      )}

      {/* Modal opens on closing drawer */}
      {showConfirmModal && (
        <ModalWrapper>
          <ModalContainer
            title="Close ad creator"
            submitText="Confirm"
            cancelText="Cancel"
            handleSubmit={closeDrawer}
            handleClose={hideConfirmModal}
          >
            <p>
              All changes will be lost if the slider is closed without saving
              the ad
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
    </div>
  );
};

export default CreateAdDrawer;

import { Box, CircularProgress, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Delete } from "../../../../../../assets/svg/bin.svg";
import { ReactComponent as Copy } from "../../../../../../assets/svg/copy.svg";
import { ReactComponent as LeftArrow } from "../../../../../../assets/svg/left_arrow.svg";
import { ReactComponent as RightArrow } from "../../../../../../assets/svg/right_arrow.svg";
import {
  addVariant,
  copyVariant,
  deleteVariantAPICall,
  setConfigurationState,
  updateExtraField,
  updateVariant,
  updateRecommendationMetadata,
  isShowSaveVariantPromptAction,
} from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialState } from "../../../../../../store/types/auth";
import {
  COPY_VARIANT,
  CreateVariantsState,
  SET_ADD_VARIANT_RESPONSE,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Button, ConfirmDialog } from "../../../../../shared";
import { checkAdType } from "../../../utils/common.utils";

import { HashType } from "../Editor.types";
import {
  StyledFooterDiv,
  StyledMiddleElement,
  StyledPaginatedText,
  StyledRightElement,
} from "./styled";

interface RecommendationFooterProps {
  setIsConfiguring: any;
  textFields: any;
}

export default function RecommendationFooter({
  setIsConfiguring,
  textFields,
}: RecommendationFooterProps) {
  const {
    variantList,
    extraFields,
    recommendationSelectionState,
    selectedAd,
    recommendationMetaData: recommendationMetaDataRedux,
    isUpdatingVariant,
    isDeletingVariant,
    configureMetaData,
    isGeneratingPreview,
    createSpec,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const dispatch = useDispatch();
  const {
    selectedAiGroup,
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);
  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state?.selectCohortReducer?.cohortData,
      ADS_LIST: state?.createVariantReducer?.adsList,
    })
  );

  const formateRecommendationDate = (key: HashType) => {
    let data = recommendationMetaDataRedux[key]?.data;

    if (data && data.length) {
      data = data.filter(
        (item: string) => item !== recommendationSelectionState[key]
      );
      data.splice(0, 0, recommendationSelectionState[key]);
      return {
        data,
        selected: data[0],
        noOfElementToShow: data.length > 4 ? 4 : data.length,
        showMore: data.length > 4,
      };
    }
    return {};
  };
  const recommendationMetaData = {
    headline: formateRecommendationDate("headline"),
    primary_text: formateRecommendationDate("primary_text"),
    description: formateRecommendationDate("description"),
  };

  const settings = {
    variantName: textFields[0]?.value ?? "",
    displayUrl: textFields[1]?.value ?? "",
    url: textFields[2]?.value ?? "",
    fbPage: textFields[3]?.value ?? "",
    instagramPage: textFields[4]?.value ?? "",
    pixel: textFields[5]?.value ?? "",
    adType: createSpec?.object_story_spec?.link_data
      ? "SINGLE_IMAGE_AD"
      : createSpec?.object_story_spec?.video_data
      ? "VIDEO_AD"
      : "", // further discussion will going to happen on this
    adName: selectedAd?.name,
  };
  const currentPage =
    extraFields.selectedVariantIndex !== undefined
      ? extraFields.selectedVariantIndex + 1
      : 1;

  const updateSelectVariantIndex = (value: number) => {
    if (extraFields.selectedVariantIndex !== undefined)
      dispatch(
        updateExtraField({
          ...extraFields,
          selectedVariantIndex: extraFields.selectedVariantIndex + value,
        })
      );
  };

  const onSave = () => {
    if (extraFields.selectedVariantIndex !== undefined)
      dispatch(
        updateVariant({
          user: auth.user,
          params: {
            headline: { name: recommendationSelectionState.headline },
            description: { name: recommendationSelectionState.description },
            primaryText: { name: recommendationSelectionState.primary_text },
            adId: selectedAd?.id,
            payload: {
              adPreviewLink: recommendationSelectionState.adPreviewLink,
              recommendationMetaData,
              settings,
              configureMetaData,
              previewExpiry: +new Date(),
            },
          },
          variantId: variantList[extraFields.selectedVariantIndex].id,
          index: extraFields.selectedVariantIndex,
        })
      );
  };

  const addingVariant = () => {
    dispatch(
      addVariant({
        user: auth.user,
        params: {
          headline: { name: recommendationSelectionState.headline },
          description: { name: recommendationSelectionState.description },
          primaryText: { name: recommendationSelectionState.primary_text },
          adId: selectedAd?.id,
          adsetId: COHORT_DATA.adset.adsetId,
          elementId: selectedAiGroup,
          adAccountId: adAccountId,
          payload: {
            adPreviewLink: recommendationSelectionState.adPreviewLink,
            recommendationMetaData,
            settings,
            configureMetaData,
            previewExpiry: +new Date(),
          },
        },
        finalAction: SET_ADD_VARIANT_RESPONSE,
        adAccountId: adAccountId,
      })
    );
  };

  const onDelete = () => {
    if (extraFields.selectedVariantIndex !== undefined)
      dispatch(
        deleteVariantAPICall({
          item: {
            ...variantList[extraFields.selectedVariantIndex],
            index: extraFields.selectedVariantIndex,
          },
          user: auth.user,
        })
      );
    setShowDeletePopUp(false);
  };

  const onCopy = () => {
    if (extraFields.selectedVariantIndex !== undefined) {
      dispatch(
        copyVariant({
          user: auth.user,
          params: {
            headline:
              variantList[extraFields.selectedVariantIndex].CreativeAiData
                .headline,
            description:
              variantList[extraFields.selectedVariantIndex].CreativeAiData
                .description,
            primaryText:
              variantList[extraFields.selectedVariantIndex].CreativeAiData
                .primaryText,
            payload: variantList[extraFields.selectedVariantIndex].payload,
            adId: selectedAd?.id,
            adsetId: COHORT_DATA.adset.adsetId,
            elementId: selectedAiGroup,
            adAccountId: adAccountId,
          },
          adAccountId: adAccountId,
          finalAction: COPY_VARIANT,
        })
      );
    }
  };

  const showPrompt = () => {
    dispatch(isShowSaveVariantPromptAction(true));
  };

  return (
    <>
      <StyledFooterDiv>
        <Button
          buttonVariant="outlined"
          onClick={() => {
            setIsConfiguring(true);
            dispatch(setConfigurationState(true));
          }}
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            fontFamily: "Inter",
          }}
        >
          Configure
        </Button>
        <StyledMiddleElement>
          <IconButton
            disabled={extraFields.selectedVariantIndex === 0}
            color="primary"
            aria-label="left_arrow"
            className="mr-3"
            onClick={
              recommendationSelectionState.isChanged ||
              recommendationSelectionState.isConfigureChanged
                ? showPrompt
                : () => {
                    updateSelectVariantIndex(-1);
                    dispatch(setConfigurationState(false));
                    dispatch(
                      updateRecommendationMetadata({
                        data: variantList[currentPage - 2].payload
                          ?.recommendationMetaData,
                        configureMetaData:
                          variantList[currentPage - 2].payload
                            ?.configureMetaData,
                      })
                    );
                  }
            }
          >
            <LeftArrow />
          </IconButton>

          <StyledPaginatedText>
            {currentPage}/{variantList.length}
          </StyledPaginatedText>

          <IconButton
            disabled={
              extraFields.selectedVariantIndex === variantList.length - 1
            }
            color="primary"
            aria-label="right_arrow"
            className="ml-3"
            onClick={
              recommendationSelectionState.isChanged ||
              recommendationSelectionState.isConfigureChanged
                ? showPrompt
                : () => {
                    updateSelectVariantIndex(1);
                    dispatch(setConfigurationState(false));
                    dispatch(
                      updateRecommendationMetadata({
                        data: variantList[currentPage].payload
                          ?.recommendationMetaData,
                        configureMetaData:
                          variantList[currentPage].payload?.configureMetaData,
                      })
                    );
                  }
            }
          >
            <RightArrow />
          </IconButton>
        </StyledMiddleElement>
        <StyledRightElement>
          <IconButton
            color="primary"
            aria-label="copy"
            className="ml-2"
            onClick={
              recommendationSelectionState.isChanged ||
              recommendationSelectionState.isConfigureChanged
                ? showPrompt
                : onCopy
            }
          >
            <Copy />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="delete"
            className="mr-2"
            onClick={
              recommendationSelectionState.isChanged ||
              recommendationSelectionState.isConfigureChanged
                ? showPrompt
                : () => setShowDeletePopUp(true)
            }
          >
            <Delete />
          </IconButton>
          <Button
            buttonVariant="contained"
            disabled={
              !(
                recommendationSelectionState.isChanged ||
                recommendationSelectionState.isConfigureChanged
              ) || isGeneratingPreview
            }
            onClick={
              variantList[currentPage - 1].id === null ? addingVariant : onSave
            }
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Inter",
            }}
          >
            {isUpdatingVariant ? (
              <Box width="90px" height="25px">
                <CircularProgress
                  size={25}
                  sx={{
                    color: "white",
                  }}
                  thickness={5}
                />
              </Box>
            ) : (
              `${
                variantList[currentPage - 1].id === null ? "Save " : "Update "
              }Variant #${currentPage}`
            )}
          </Button>
        </StyledRightElement>
      </StyledFooterDiv>
      <ConfirmDialog
        show={showDeletePopUp}
        onClose={() => setShowDeletePopUp(false)}
        title="Are you sure you wanted to delete this variant"
        onComplete={onDelete}
        completeButtonText="Confirm"
        loading={isDeletingVariant}
      />
    </>
  );
}

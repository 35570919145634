import styled from "styled-components";

export const StyledVariantTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  font-family: "Inter";
  font-style: normal;
  // line-height: 19px;
`;

export const StyledVariantContainer = styled.div`
  overflow: auto;
  overflow-x: hidden;
  height: 70vh;
  width: 100%;
  // min-width: 1017px;
  min-width: 1280px;
  &::-webkit-scrollbar {
    //**** Will search  alternate in future ***//
    height: 0;
    width: 0;
  }
`;

export const StyledVariantItemPara = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
`;
export const StyledVariantItemParaViewMore = styled.p`
  margin-bottom: 25px;
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  font-family: "Inter";
`;

export const StyledDetailedTargetingContent = styled.div`
  margin-bottom: 14px;
  width: 400px;
`;
export const StyleViewMoreHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;
export const StyleViewMoreTitle = styled.p`
  margin-bottom: 0px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #000000;
`;
export const StyledViewModalSubHead = styled.p`
  margin-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`;
export const StyledCloseIconContainer = styled.p`
  cursor: pointer;
`;

import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import HttpService from "../../../../../HttpService/HttpService";
import {
  AddVariant,
  ADD_VARIANT,
  DeleteVariantAPICall,
  DELETE_VARIANT_API_CALL,
  DELETE_VARIANT_STATE_UPDATE,
  GetAdPreviewLink,
  GetCreateSpec,
  GetVariantList,
  GET_ADS_LIST,
  GET_AD_PREVIEW_LINK,
  GET_CREATE_SPEC,
  GET_VARIANT_LIST,
  SET_ADS,
  SET_AD_PREVIEW_LINK,
  SET_CREATE_SPEC,
  SET_VARIANT_LIST,
  UpdateVariant,
  COPY_VARIANT,
  UPDATE_VARIANT,
  UPDATE_VARIANT_STATE,
  GENERATE_AD_PREVIEW,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
  PUBLISH_VARIANT,
  PUBLISH_VARIANT_STATE,
  DISCARD_DRAFT_VARIANT,
  GenerateVariantMatadata,
  GENERATE_VARIANT_METADATA,
  UPDATE_CREATE_SPEC,
  DISCARD_DRAFT_VARIANT_STATE,
  COPY_VARIANT_STATE,
  GENERATE_VARIANT_METADATA_STATE,
  UPDATE_AI_GROUP,
  UPDATE_AI_GROUP_STATE,
  GENERATE_AD_PREVIEW_VARIANT,
  GENERATE_AD_PREVIEW_VARIANT_STATE,
  GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE,
} from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { BASE_URL } from "../../../../../utils/path";

function* getVariantList(action: GetVariantList): any {
  let {
    payload: { adId },
  } = action;

  try {
    const urlParams = `/v1/api/variant/ad-id/${adId}?status=draft`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);

    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: SET_VARIANT_LIST, payload: { data } });
    } else {
      console.log("got error in get variantlist");
    }
  } catch (error: any) {
    console.log("got error in get variantlist", error);
  }
}

function* addVariant(action: AddVariant): any {
  let {
    payload: { params, user, finalAction },
  } = action;
  try {
    const urlParams = `/v1/api/variant`;
    const response = yield call(HttpService.post, BASE_URL, urlParams, user, {
      ...params,
      payload: {
        ...params.payload,
      },
    });
    if (response.data.success) {
      yield put({ type: finalAction, payload: response.data.data });
    } else {
      console.log("error during adding variant");
    }
    // }
  } catch (error: any) {
    console.log("error during adding variant");
  }
}

function* copyVariant(action: AddVariant): any {
  let {
    payload: { params, user },
  } = action;

  try {
    const urlParams = `/v1/api/variant`;
    const response = yield call(HttpService.post, BASE_URL, urlParams, user, {
      ...params,
      payload: {
        ...params.payload,
      },
    });
    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: COPY_VARIANT_STATE, payload: data });
    } else {
      console.log("error during adding variant");
    }
    // }
  } catch (error: any) {
    console.log("error during adding variant");
  }
}

function* updateVariant(action: UpdateVariant): any {
  let {
    payload: { variantId, user, params, index },
  } = action;
  try {
    const urlParams = `/v1/api/variant/${variantId}`;
    const response = yield call(
      HttpService.put,
      BASE_URL,
      urlParams,
      user,
      params
    );

    if (response.data.success) {
      yield put({
        type: UPDATE_VARIANT_STATE,
        payload: { index, updatedValues: response.data.data },
      });
    } else {
      console.log("error during update variant");
    }
  } catch (error: any) {
    console.log("error during update variant", error);
  }
}

function* getCreateSpec(action: GetCreateSpec): any {
  let {
    payload: { creativeId, adId, adAccountId },
  } = action;

  try {
    const urlParams = `/v1/api/adset-creative-ai/ad/${creativeId}/creative-spec?adAccountId=${adAccountId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);

    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: SET_CREATE_SPEC, payload: data });
      yield put({ type: GET_AD_PREVIEW_LINK, payload: { adId, adAccountId } });
    } else {
      console.log("error getCreateSpec");
    }
  } catch (error: any) {
    console.log("error getCreateSpec", error);
  }
}

function* getAds(action: GetCreateSpec): any {
  let {
    payload: { adSetId, adAccountId },
  } = action;

  try {
    const urlParams = `/v1/api/adset-creative-ai/ads/${adSetId}?adFormat=MOBILE_FEED_STANDARD&adAccountId=${adAccountId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: SET_ADS, payload: data.data });
    } else {
      console.log("error on getting ads");
    }
  } catch (error: any) {
    console.log("error on getting ads", error);
  }
}

function* getAdPreviewLink(action: GetAdPreviewLink): any {
  let {
    payload: { adId, adAccountId },
  } = action;

  try {
    const urlParams = `/v1/api/adset-creative-ai/ad/${adId}/preview-link?adFormat=MOBILE_FEED_STANDARD&adAccountId=${adAccountId}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);

    if (response.data.success) {
      const data = response.data.data[0].body;
      yield put({ type: SET_AD_PREVIEW_LINK, payload: data });
    } else {
      console.log("error occured during get ad preview");
    }
  } catch (error: any) {
    console.log("error occured during get ad preview", error);
  }
}

function* deleteVariantAPICall(action: DeleteVariantAPICall): any {
  let {
    payload: { item, user },
  } = action;

  try {
    const urlParams = `/v1/api/variant/${item.id}`;
    const response = yield call(HttpService.delete, BASE_URL, urlParams, user);
    if (response.data.success) {
      yield put({ type: DELETE_VARIANT_STATE_UPDATE, payload: item });
    } else {
      // api error
      console.log("error occured during delete");
    }
  } catch (error: any) {
    //catch error
    console.log("error occured during delete", error);
  }
}

function* generateAdPreview(action: GetCreateSpec): any {
  let {
    payload: { adAccountId, creativeSpec, recommendationSelectionState },
  } = action;
  yield put({
    type: UPDATE_RECOMMENDATION_SELECTION_STATE,
    payload: recommendationSelectionState,
  });
  yield put({
    type: UPDATE_CREATE_SPEC,
    payload: creativeSpec,
  });
  try {
    const generatePreviewUrls = `/v1/api/adset-creative-ai/ad/${adAccountId}/generate-preview-link`;

    const responseGeneratedPreview = yield call(
      HttpService.get,
      BASE_URL,
      generatePreviewUrls,
      undefined,
      undefined,
      {
        adFormat: "MOBILE_FEED_STANDARD",
        creativeSpec: { object_story_spec: creativeSpec["object_story_spec"] },
      }
    );

    if (responseGeneratedPreview.data.success) {
      const adPreviewLink = responseGeneratedPreview.data.data[0].body;
      yield put({
        type: UPDATE_RECOMMENDATION_SELECTION_STATE,
        payload: { adPreviewLink },
      });
      yield put({
        type: GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE,
        payload: false,
      });
    } else {
      // api error
    }
  } catch (error: any) {
    //catch error
  }
}

function* generateAdPreviewVariantLevel(action: GetCreateSpec): any {
  let {
    payload: {
      adAccountId,
      creativeSpec,
      auth,
      variantId,
      index,
      adId,
      payload,
      recommendationSelectionState,
    },
  } = action;

  try {
    const generatePreviewUrls = `/v1/api/adset-creative-ai/ad/${adAccountId}/generate-preview-link`;

    const responseGeneratedPreview = yield call(
      HttpService.get,
      BASE_URL,
      generatePreviewUrls,
      undefined,
      undefined,
      {
        adFormat: "MOBILE_FEED_STANDARD",
        creativeSpec: { object_story_spec: creativeSpec["object_story_spec"] },
      }
    );

    if (responseGeneratedPreview.data.success) {
      const adPreviewLink = responseGeneratedPreview.data.data[0].body;
      yield put({
        type: UPDATE_VARIANT,
        payload: {
          user: auth.user,
          params: {
            headline: { name: recommendationSelectionState.headline },
            description: { name: recommendationSelectionState.description },
            primaryText: { name: recommendationSelectionState.primary_text },
            adId,
            payload: {
              ...payload,
              adPreviewLink,
              previewExpiry: +new Date(),
            },
          },
          variantId,
          index,
        },
      });
      yield put({
        type: GENERATE_AD_PREVIEW_VARIANT_STATE,
        payload: {},
      });
    } else {
      // api error
    }
  } catch (error: any) {
    //catch error
  }
}

function* publishVariant(action: GetCreateSpec): any {
  let {
    payload: { variantIds, user, adAccountId },
  } = action;
  yield put({
    type: PUBLISH_VARIANT_STATE,
    payload: true,
  });
  try {
    const url = `/v1/api/variant/publish-variant`;
    const response = yield call(HttpService.post, BASE_URL, url, user, {
      variantIds,
      adAccountId,
    });

    if (response.data.success) {
      yield put({
        type: PUBLISH_VARIANT_STATE,
        payload: false,
      });
    } else {
      // api error
    }
  } catch (error: any) {
    //catch error
  }
}

function* discardDraftVariant(action: GetCreateSpec): any {
  let {
    payload: { adSetId, user },
  } = action;

  try {
    const url = `/v1/api/variant/adset/${adSetId}`;
    const response = yield call(HttpService.delete, BASE_URL, url, user);

    if (response.data.success) {
      yield put({
        type: DISCARD_DRAFT_VARIANT_STATE,
        payload: false,
      });
    } else {
      // api error
    }
  } catch (error: any) {
    //catch error
  }
}

function* updateCreativeAIElement(action: any): any {
  let {
    payload: { user, elementData },
  } = action;
  try {
    const url = `/v1/api/element-creative-ai`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      url,
      user,
      elementData
    );
    if (response.data.success) {
      yield put({
        type: "GET_ELEMENT_CREATIVE_AI",
        payload: { elementId: response.data.data.successRecord[0].elementId },
      });
      yield put({
        type: UPDATE_AI_GROUP_STATE,
        payload: true,
      });
    } else {
      console.log("Element Creative  error");
      yield put({
        type: UPDATE_AI_GROUP_STATE,
        payload: false,
      });
    }
  } catch (error: any) {
    console.log("Element Creative", error);
    yield put({
      type: UPDATE_AI_GROUP_STATE,
      payload: false,
    });
  }
}

function* handelGenerateMatadata(action: GenerateVariantMatadata): any {
  let {
    payload: { user, data, finalAction },
    callback,
  } = action;
  try {
    const url = `/v1/api/variant/generate-variant-metadata`;
    const response = yield call(HttpService.post, BASE_URL, url, user, data);
    if (response.data.success) {
      yield put({
        type: finalAction ?? GENERATE_VARIANT_METADATA_STATE,
        payload: response.data.data,
      });
      callback && callback();
    } else {
      console.log("Element Creative  error");
    }
  } catch (error: any) {
    console.log("Element Creative", error);
  }
}

export function* createVariantWatcher() {
  yield takeLatest(GET_VARIANT_LIST, getVariantList);
  yield takeLatest(ADD_VARIANT, addVariant);
  yield takeLatest(GET_CREATE_SPEC, getCreateSpec);
  yield takeLatest(GET_ADS_LIST, getAds);
  yield takeLatest(GET_AD_PREVIEW_LINK, getAdPreviewLink);
  yield takeLatest(DELETE_VARIANT_API_CALL, deleteVariantAPICall);
  yield takeEvery(UPDATE_VARIANT, updateVariant);
  yield takeLatest(GENERATE_AD_PREVIEW, generateAdPreview);
  yield takeLatest(PUBLISH_VARIANT, publishVariant);
  yield takeLatest(DISCARD_DRAFT_VARIANT, discardDraftVariant);
  yield takeLatest(COPY_VARIANT, copyVariant);
  yield takeLatest(GENERATE_VARIANT_METADATA, handelGenerateMatadata);
  yield takeLatest(UPDATE_AI_GROUP, updateCreativeAIElement);
  yield takeEvery(GENERATE_AD_PREVIEW_VARIANT, generateAdPreviewVariantLevel);
}

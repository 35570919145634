/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import mixpanel from 'mixpanel-browser';

export const mixPanelAdEvent = (trackName: string, params: any) => {
    mixpanel.track(trackName, params);
}

export const getModuleName = (value:any) => {
    switch (value) {
      case "/actionImpactAnalysis":
        return "Action Impact Analysis - All platforms";
      case "/singlePlatform":
        return "Action Impact Analysis - Single platform";
      case "/group-settings":
        return "AI Settings";
      case "/aiModelConfidence":
        return "AI model confidence";
      case "/targeting-ai":
        return "Targeting AI";
      default:
        return "Action Impact Analysis - All platforms";
    }
  }

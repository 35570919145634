import styled from "styled-components";

export const StyledPreviewDiv = styled.div`
  width: 100%;
  max-width:550px;
  height: 100%;
  padding: 30px 15% 30px 15%;
  overflow: scroll;
`;

export const StyledPreviewHeadingCard = styled.div`
  background: #f2f2f2;
  border: 1px solid #dadcde;
  border-radius: 10px 10px 0px 0px;
  padding: 15px;
  width: 335;
`;

import moment from "moment";
import React, { useRef, useState } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { ReactComponent as DownIcon } from "../assets/svg/down.svg";
import info from "../assets/svg/smallinfo.svg";
import Loader from "./common/Loader";

const RecommendationAccordion: React.FC<{
  data: any;
  accordionKey: string;
  children?: any;
  index: number;
  onOpen: (callback: any) => void;
  groupData: any;
}> = ({ data, groupData, accordionKey, children, onOpen, index }): JSX.Element => {
  const [loading, setLoader] = useState(false);
  const decoratedOnClick = useAccordionButton(accordionKey);
  const target = useRef(null);
  const count = useRef(1);

  const handleOpen = (e: any) => {
    if (count.current % 2 !== 0) {
      onOpen(setLoader);
    }
    count.current += 1;

    decoratedOnClick(e);
  };
  return (
    <div className="mb-2">
      <div
        className="w-100 d-flex justify-content-between text-sm align-items-center bg-light px-4 py-3 rounded"
        onClick={handleOpen}
      >
        <div className="d-flex flex-column">
          <span className="fw-bold text-md mb-1">Recommendation {index + 1}</span>
        </div>
        <div className="d-flex flex-column align-items-between">
          <span className="text-xs mb-1">Generated on</span>
          <span className="fw-bold text-md">{moment(data?.created_at).format("MMM D-YYYY")}</span>
        </div>
        <div className="d-flex flex-column align-items-between">
          <span className="text-xs mb-1">
            Total ad sets in AI group
            <div className="tooltip-container">
              <i ref={target}>
                <img className="ml-2" src={info} alt="info_style" />
              </i>
              <div className="custom-tooltip-layout tooltip">
                <div className="tootltip-wrapper">
                  This refers to the number of Ad sets within a particular AI Group when the
                  targeting recommendations were generated.
                </div>
              </div>
            </div>
          </span>
          <span className="fw-bold text-md">{groupData?.adsets?.length}</span>
        </div>
        <div className="d-flex flex-column align-items-between">
          <span className="text-xs mb-1">
            Recommendation status
            {/* <div className="tooltip-container">
                        <i ref={target}>
                        <img className="ml-2" src={info} alt="info_style" />
                        </i>
                        <div className="custom-tooltip-layout tooltip">
                            <div className="tootltip-wrapper">
                                <div className="tooltip-header">
                                Performance Analysis
                                </div>
                                This shows AI-recommended actions to be taken on individual elements like ad sets and campaigns to optimize their performance.
                            </div>
                        </div>
                    </div> */}
          </span>
          <span className="fw-bold text-md" style={{ color: "#89B84F" }}>
            Generated
          </span>
        </div>
        <div className="cursor-pointer" role="button">
          <DownIcon />
        </div>
      </div>
      <Accordion.Collapse
        eventKey={accordionKey}
        className="overflow-scroll box-shadow rounded"
        style={{ maxHeight: "300px" }}
      >
        <>
          {loading && (
            <div className="w-100">
              <Loader />
            </div>
          )}
          {children}
          {!loading && !children && (
            <div
              className="w-100 text-md text-center font-weight-bold mt-auto d-flex align-items-center justify-content-center"
              style={{ height: "100px" }}
            >
              <span>No Details Found</span>
            </div>
          )}
        </>
      </Accordion.Collapse>
    </div>
  );
};

export default RecommendationAccordion;

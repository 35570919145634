import React from "react";
import { ACTIVE_COLOR, COMPLETED_COLOR, phaseDetail } from "../utils/commonFunctions";
import { useSelector } from "react-redux";
import { initialState } from "../store/types/auth";

function getColor(val: number, enabled: boolean): string {
  if (!enabled) return "";
  switch (val) {
    case 0:
      return "";
    case 1:
      return ACTIVE_COLOR;
    case 2:
      return COMPLETED_COLOR;
    default:
      return "";
  }
}

function getActivePhase(phases: any) {
  const { phase1, phase2, phase3, phase4, phase5 } = phases || {};
  if (phase1 === 1) return 1;
  if (phase2 === 1) return 2;
  if (phase3 === 1) return 3;
  if (phase4 === 1) return 4;
  if (phase5 === 1) return 5;
  return 0;
}

const Journey: React.FC<{ enabled: boolean; phases: any; }> = ({ enabled, phases }) => {
  const authState = useSelector((state: { auth:initialState }) => state.auth);
  const [phaseTimeLine,setPhaseTimeLine] = React.useState({} as any);
  const { phase1, phase2, phase3, phase4, phase5 } = phases || {};
  const activePhase = getActivePhase(phases);
  const activePhaseKey = `phase${activePhase}`;


  React.useEffect(()=>{
    setPhaseTimeLine(phaseDetail(authState?.accountDetails?.platform))
  },[authState?.accountDetails?.platform])


  return (
    <div className="journey">
      {!!activePhase && (
        <h5 className="fw-bold" style={{ fontSize: '0.7rem' }}>
            {enabled && activePhase
              ? `Phase ${activePhase}: ${phaseTimeLine[activePhaseKey]?.title}`
              : phaseTimeLine?.disabled?.title}
        </h5>
      )}
      {!!activePhase && (
        <p className="text-muted pr-2" style={{ fontSize: "0.7rem" }}>
          {enabled && activePhase
            ? phaseTimeLine[activePhaseKey]?.description
            : phaseTimeLine?.disabled?.description}
        </p>
      )}
      {!!enabled && (
        <div className="mt-10">
          <div className="progress">
            <div
              className="progress-chip"
              style={{ background: getColor(phase1, enabled) }}
            ></div>
            <div
              className="progress-chip"
              style={{ background: getColor(phase2, enabled) }}
            ></div>
            <div
              className="progress-chip"
              style={{ background: getColor(phase3, enabled) }}
            ></div>
            <div
              className="progress-chip"
              style={{ background: getColor(phase4, enabled) }}
            ></div>
            <div
              className="progress-chip"
              style={{ background: getColor(phase5, enabled) }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Journey;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, {useCallback, useEffect, useMemo,useRef} from "react";
import { ReactComponent as SettingsImg } from "../../assets/settingsImg.svg";
import { Accordion, Table } from 'react-bootstrap';
import Slider from 'react-custom-slider';
import AdAccountAccordion from "./AdAccountAccordion/AdAccountAccordion";
import AIGroupSettingForm from "./AIGroupSettingForm/AIGroupSettingForm";
import info from '../../assets/svg/smallinfo.svg';
import { useDispatch, useSelector } from "react-redux";
import { AdAccountState } from "../../store/types/adaccount";
import { getFBAdsLink, getPlatformName } from "../../utils/commonFunctions";
import { initialState } from "../../store/types/auth";
import { getCampaignsFactors, getMetricsList, getStoryData, postCampaignFactors, postStoryData,putStoryData,setCampaignsFactors, setMetricList, setSpinner, setSuccess } from "../../store/actions/campaign";
import Metrics from "../../components/Campaigns/Metrics";
import { CampaignState } from "../../store/types/campaign";
import { mapFactors, mapFactorToResponse, mapStoryPayload, mapStoryResponse } from "../../utils/mapFactors";
import "./style.scss";
import Loader from "./../common/Loader";
import NoData from '../../components/common/NoData';
import { getAdsetDetails,getCampaignDetails, getAdAccountDetails } from "../../store/actions/adaccount";
import ButtonUI from "../../ui/ButtonUI/ButtonUI";
import { mixPanelAdEvent } from "../../utils/mixpanel";

const AIGroupSettings: React.FC<unknown> = () => {
  const target = useRef(null);
  const dispatch = useDispatch();
  const [activeAdAccount, setActiveAdAccount] = React.useState([]);
  const [adAccountsList, setAdAccountsList] = React.useState({facebook:[],google:[]});
  const [adsets, setAdsets]=React.useState([]);
  const [campaigns, setCampaigns]=React.useState([]);
  const [currency, setCurrency]=React.useState({});
  const adAccount = useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const { selectedGroup, selectedGroupAdaccounts, platformId, adaccountLoading,platforms, selectedBrand } = adAccount;
  const campaign = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { campaignsFactors, metricList, success } = campaign;
  const [ROIMetrics, setROIMetrics] = React.useState<any>([]);
  const [QFMetrics, setQFMetrics] = React.useState<any>([]);
  const [ROIFactor, setROIFactor] = React.useState<any>(50);
  const [QFError, setQFError] = React.useState(false);
  const [ROIError, SetROIError] = React.useState(false);
  const { ROIResponse, QFResponse } = mapFactors((campaignsFactors?.metrics) || []);
  const authState = useSelector((state: { auth:initialState }) => state.auth);
  const [storyData,setStoryData]=React.useState({});
  const { user } = authState;
  const label = React.useMemo(
    () => ("Campaign"),
    []
  );
  
  useEffect(() => {
    mixPanelAdEvent('Page View - Dashboard', {
      'module_name': "AI Settings",
      'brand_name': selectedBrand?.name,
    });
  }, []);

  useEffect(() => {

    if (selectedGroupAdaccounts.length > 0) {
      let adsList:any = {
        google:[],
        facebook: []
    };
      selectedGroupAdaccounts.map((adAcc: any) => {
        if (adAcc.platformId === platformId.googleSearchId) {
          adsList.google.push(adAcc);
        }
        if (adAcc.platformId === platformId.facebookId) {
          adsList.facebook.push(adAcc);
        }
      });
      setAdAccountsList(adsList);
    } else {
      setAdAccountsList({
        google:[],
        facebook: []
      });
    }
  }, [selectedGroupAdaccounts]);

  useEffect(() => {
    const weightDistribuition = campaignsFactors?.weight_distribuition;
    setROIFactor((weightDistribuition?.roi || weightDistribuition?.quality) ? weightDistribuition?.roi : 50);
    setROIMetrics(ROIResponse);
    setQFMetrics(QFResponse);
  }, [campaignsFactors]);

  useEffect(() => {
    
    if (success) {
      alert("The data has been saved");
      dispatch(setSuccess(false));
    }
  }, [success]);

  const metricsList = React.useMemo(() => !!metricList && metricList, [metricList]);

  const addMetric = (isROI) => {
    removeAllErrors();

    if (isROI) {
      setROIMetrics((ROIMetrics) => {
        const newMetric = {
          metric: "",
          weight: 0,
          error: false,
          errorMessage: "",
        };

        return [...ROIMetrics, newMetric];
      });
    } else {
      setQFMetrics((QFMetrics) => {
        const newMetric = {
          metric: "",
          weight: 0,
          error: false,
          errorMessage: "",
        };

        return [...QFMetrics, newMetric];
      });
    }
  }

  const updateMetrics = (index, value, type, isROI, filter) => {
    removeAllErrors();

    if (isROI) {
      setROIMetrics((ROIMetrics) => {
        const updatedMetrics = [...ROIMetrics];
        updatedMetrics[index][type] = value;
        updatedMetrics[index]['filter'] = filter;
        return updatedMetrics;
      });
    } else {
      setQFMetrics((QFMetrics) => {
        const updatedMetrics = [...QFMetrics];
        updatedMetrics[index][type] = value;
        updatedMetrics[index]['filter'] = filter;
        return updatedMetrics;
      });
    }
  };

  const validateAllInput = (platform) => {
    let errorFound = false;
    
    
    if (platform.type===("Google Search Ad")&&!QFMetrics.length) {
      alert("Quality Factor Metric should not be empty");
      return;
    } else if (platform.type===("Google Search Ad")&&!ROIMetrics.length) {
      alert("ROI Factor Metric should not be empty");
      return;
    }

    const QFTestResult = QFMetrics.map((metrics, index) => {
      if (metrics.metric === "") {
        errorFound = true;
        return {
          ...metrics,
          error: true,
          errorMessage: "Metric Field Cannot be Left Empty",
        };
      }  else return metrics;
    });

    const ROITestResult = ROIMetrics.map((metrics, index) => {
      if (metrics.metric === "") {
        errorFound = true;
        return {
          ...metrics,
          error: true,
          errorMessage: "Metric Field Cannot be Left Empty",
        };
      } else return metrics;
    });

    if (errorFound && platform.type===("Google Search Ad")) {
      setQFMetrics(QFTestResult);
      setROIMetrics(ROITestResult);
      return false;
    }

    const QFTotalCount = QFMetrics.reduce((prev, current) => prev + parseInt(current.weight), 0);
    const ROITotalCount = ROIMetrics.reduce((prev, current) => prev + parseInt(current.weight), 0);
    if (QFTotalCount !== 100) setQFError(true);
    if (ROITotalCount !== 100) SetROIError(true);

    if (platform.type===("Google Search Ad") && (QFTotalCount !== 100 || ROITotalCount !== 100)){
      window.alert("Weight sum not 100");
      return false;
    }

    return true;
  };

  const setWeightError = (errorText, index, isROI) => {
    if (isROI) {
      setROIMetrics((ROIMetrics) => {
        const ErrorMetric = ROIMetrics.map((metric, i) => {
          if (index === i) {
            return {
              ...metric,
              error: true,
              errorMessage: errorText,
            };
          } else return metric;
        });

        return ErrorMetric;
      });
    } else {
      setQFMetrics((QFMetrics) => {
        
        return QFMetrics.map((metric, i) => {
          return index === i ? {
            ...metric,
            error: true,
            errorMessage: errorText,
          } : metric;
        });
      });
    }
  };

  const removeAllErrors = () => {
    setQFMetrics((QFMetrics) => {
      
      return QFMetrics.map((metrics) => {
        return {
          ...metrics,
          error: false,
          errorMessage: "",
        };
      });
    });

    setROIMetrics((ROIMetrics) => {
      
      return ROIMetrics.map((metrics) => {
        return {
          ...metrics,
          error: false,
          errorMessage: "",
        };
      });
    });

    setQFError(false);
    SetROIError(false);
  };

  const submitResult = (elementId:any, isFactor: boolean, params: any) => {
    dispatch(setSpinner(true));
    if (isFactor) {
      dispatch(postCampaignFactors({ elementId, user, payload: mapFactorToResponse(ROIMetrics, QFMetrics, ROIFactor)} ,
      (response: any, error: boolean) => {
      dispatch(setSpinner(false));
      if (!error) {
        dispatch(setCampaignsFactors(response.message))
      } else {
        dispatch(setSuccess(false));
        alert(response.data.message);
        dispatch(setCampaignsFactors({}));
      }}
    ));
    }
    if(Object.keys(storyData).length>0){
      dispatch(putStoryData(
        {elementId,user,payload: mapStoryPayload(activeAdAccount,elementId)},
        (response: any, error: boolean) => {
          if (!error) {
            dispatch(setSuccess(true));
          } else {
            dispatch(setSuccess(false));
            alert(response.data.message);
          }
        }
      ))
    }
    else{
    dispatch(postStoryData(
        {elementId,user,payload: mapStoryPayload(activeAdAccount,elementId)},
        (response: any, error: boolean) => {
          if (!error) {
            dispatch(setSuccess(true));
          } else {
            dispatch(setSuccess(false));
            alert(response.data.message);
          }
        }
      ))
    }

    mixPanelAdEvent('AI Group Settings Updated', {
      'brand_name': selectedBrand?.name,
      'ai_group_id': selectedGroup?.id,
      'ai_group_name': selectedGroup?.name,
      'minimum_budget': activeAdAccount[elementId]?.cross_budget_min,
      'maximum_budget': activeAdAccount[elementId]?.cross_budget_max,
      'optimization_events': activeAdAccount[elementId]?.result_metric_key,
      'target_cost_per_optimization_event': activeAdAccount[elementId]?.target_cpr,
      'target_daily_results': activeAdAccount[elementId]?.target_result,
      'metric_weight_array_roi': isFactor ? ROIMetrics : "",
      'metric_weight_array_quality_factor': isFactor ? QFMetrics: "",
      'roi_quality_weight_distribution_bar': isFactor ? { roi: ROIFactor, quality: 100 - ROIFactor}: "",
      'ad_set_ids': params.ad_set_ids,
      'ad_account_id': params.ad_account_id,
      'ad_account_name': params.ad_account_name,
      'facebook_google': isFactor ? "Google" : "Facebook",
      'campaign_ids': params.campaign_ids
    });
  };

  const campaignInfoTooltip = (props?:any) => (
    <div {...props} className="custom-tooltip-layout tooltip">
      <div className="tootltip-wrapper">
        <div className="tooltip_header">{`${label}s`}</div>
        This shows a list of {label}s grouped under a particular AI Group
      </div>
    </div>
  );


  const handleOpen = ( params:any, elementId: string, callback:any ) => {
    
    dispatch(setSpinner(true));
    dispatch(getAdAccountDetails(
      {adaccountId: params.adAccountId, platformType:params.platformType.toLowerCase()},
      (response:any, error: boolean) => {
        if ( !error && response.data && response.data.currency) {
          setCurrency({...currency,[elementId]:response.data.currency})
        } else {
          setCurrency({...currency,[elementId]:""})
        }
      } 
    ))
    dispatch(getMetricsList({ elementId:elementId , user},
      (response: any, error: boolean) => {
        dispatch(setSpinner(false));
        if (!error) {
          dispatch(setMetricList(response.data));
        } else {
          dispatch(setMetricList([]))
          console.log(error);
        }
      }
    ));
    dispatch(setSpinner(true));
    dispatch(getCampaignsFactors({ elementId:elementId, user },
      (response: any, error: boolean) => {
      dispatch(setSpinner(false));
      if (!error) {
        dispatch(setCampaignsFactors(response))
      } else {
        dispatch(setCampaignsFactors({}))
        console.log(error);
      }
      }
    ));
    dispatch(getStoryData(
      {elementId,user},
      (response:any, error: boolean) => {
        if (!error) {
          
          setActiveAdAccount({...activeAdAccount,[elementId]:mapStoryResponse(response)});
          setStoryData(mapStoryResponse(response));
        }
        else{
          
          setActiveAdAccount({});
          setStoryData({});
        }
      }
    ));
      if(params.platformType==="Facebook"){
        dispatch(getAdsetDetails({ group_id: selectedGroup.id, user: user }, (adsetRes: any, adsetErr: boolean) => {
          if (!adsetErr && adsetRes.data.length > 0) {
            const newAdSets = adsetRes.data.filter((adset:any) => adset.adAccountId === params.adAccountId  )
            setAdsets({...adsets,[elementId]:newAdSets})
            
          }  else {
            console.log(adsetErr);
            setAdsets([]);
          }
        })) 
      }
      else{
        dispatch(getCampaignDetails({ group_id: selectedGroup.id, user: user }, (campRes: any, campErr: boolean) => {
          if (!campErr && campRes.data.length > 0) {
            const filteredData:any = campRes.data.filter((campaign: any) => campaign.platformId === params.platformId && campaign.adAccountId=== params.adAccountId)
            setCampaigns({...campaigns,[elementId]:filteredData});
          }
          else{
            setCampaigns([]);
          }
        }))  
      }
  }
  const aiGroupPlatforms1 = useMemo(() => {
    
    const data= platforms.sort((a:any,b:any) => {
      if(a.name>b.name){ return 1}
      if(a.name<b.name){ return -1}
      if(a.name==b.name){ return 0}
    })
    const capitalize = function (type: string){
      const arr = type.split(' ');
      arr.forEach((word,index)=>arr[index]=word.charAt(0)+word.slice(1).toLowerCase())
      return arr.join(' ')
    }
    
    data.forEach((platform:any,index:number)=>{data[index].type=capitalize(platform.type)});
    return data
  },[platforms]);
  

  const handleChanage = (key:string, value: any,elementId:string) => {
    setActiveAdAccount({...activeAdAccount,[elementId]:{...activeAdAccount[elementId],[key]:value }});
  }
  const handleAdsView = useCallback((id:string,elementId:any) => {
    window.open(getFBAdsLink(id, adsets[elementId].map((item) => item.adsetId)));
  }, [adsets])
  
  return (<>
    <div className="d-flex flex-column bg-white box-shadow rounded">
      <Accordion>
        <div>
        <AdAccountAccordion data={{}} isAdvance={true} index={1} onOpen={(callback:any) => {}}>
          <div className="p-3 text-center border-top pt-5 d-flex flex-column align-items-center pb-5">
            <SettingsImg />
            <span className="mt-3">Third Party data Integrations like Google Analytics etc. are coming soon</span>
          </div>
        </AdAccountAccordion>
        </div>
      </Accordion>
    </div>
    <div className="d-flex flex-column bg-white box-shadow rounded mt-2">
      <div className="border-bottom">
        <div className="d-flex p-3">
          <div className="pr-3">Optimization Mode:
          </div>
        </div>
      </div>
      <div className="ai_model_confidence_style">
      {selectedGroupAdaccounts.length?(aiGroupPlatforms1||[]).map((platform) => (platform?.adaccountDetail?.length>0)  && (<>
        <h4 className="campaigns_header fs-6 p-0 pt-4 ml-4 d-flex mb-4">
          {platform.type}
        </h4>
        <Accordion key={selectedGroup.id} className="p-4">
        {(platform.adaccountDetail||[]).map((detail,index) => (
            <div className="mb-2">
              <AdAccountAccordion data={detail} index={index} onOpen={(callback:any) => handleOpen({adAccountId:detail.id,platformId:detail.platformId,platformType:getPlatformName(platform.type)},detail.elementId,callback)}>
              <div className="p-4 d-flex flex-column pt-0">
                  <div className="border">
                    <AIGroupSettingForm data={activeAdAccount[detail.elementId]} handleChanage={handleChanage} adAccountId={detail.id} elementId={detail.elementId} metricList={metricList||[]} platform={platform.type} currency={currency} platformId={platform.id} />
                    {platform.type==="Google Search Ad" && <div className="profitability">
                      <h4 className="profitability_header fs-6 p-0 pt-4 ml-4 d-flex">
                        Profitability Index
                        <div className="position-relative tooltip-container">
                          <i ref={target}>
                            <img className="ml-2" src={info} alt="info_style" />
                          </i>
                          {campaignInfoTooltip()}
                        </div>
                      </h4>
                      <p className="campaign_sub_heading">
                        Define metrices and weight of metrics for ROI and quality factor
                      </p>
                      <hr />
                      <h4 className="profitability_header fs-6 p-0 pt-4 ml-4 d-flex">
                        ROI Factor
                        <div className="position-relative tooltip-container">
                          <i ref={target}>
                            <img className="ml-2" src={info} alt="info_style" />
                          </i>
                          {campaignInfoTooltip()}
                        </div>
                      </h4>
                      <p className="metric_sub_heading">Sum of all the weights should be 100</p>
                      <div className="profitability_roi_metric">
                        {!ROIMetrics.length ? (
                          <button className="btn-primary btn-add-more" onClick={() => addMetric(true)}>
                            Click to add metric <span className="btn-plus">+</span>
                          </button>
                        ) : (
                          <Metrics
                            setMetricData={setROIMetrics}
                            metricsData={ROIMetrics}
                            metricList={metricsList}
                            isROI={true}
                            updateMetrics={updateMetrics}
                            addMetric={addMetric}
                            sumError={ROIError}
                            setWeightError={setWeightError}
                          />
                        )}
                      </div>
                      <hr />
                      <h4 className="profitability_header fs-6 p-0 pt-4 ml-4 d-flex">
                        Quality Factor
                        <div className="position-relative tooltip-container">
                          <i ref={target}>
                            <img className="ml-2" src={info} alt="info_style" />
                          </i>
                          {campaignInfoTooltip()}
                        </div>
                      </h4>
                      <p className="metric_sub_heading">Sum of all the weights should be 100</p>
                      <div className="profitability_roi_metric">
                        {!QFMetrics.length ? (
                          <button className="btn-primary btn-add-more" onClick={() => addMetric(false)}>
                            Click to add metric <span className="btn-plus">+</span>
                          </button>
                        ) : (
                          <Metrics
                            setMetricData={setQFMetrics}
                            metricsData={QFMetrics}
                            metricList={metricsList}
                            isROI={false}
                            updateMetrics={updateMetrics}
                            addMetric={addMetric}
                            sumError={QFError}
                            setWeightError={setWeightError}
                          />
                        )}
                      </div>
                      <hr />
                      <h4 className="profitability_header fs-6 p-0 pt-4 ml-4 d-flex">
                        ROI factor and quality factor weight distribution
                      </h4>
                      <div className="slider-rc">
                        <p>ROI Factor</p>
                        <SliderWithLabel setROIFactor={setROIFactor} ROIFactor={ROIFactor} />
                        <p>Quality Factor</p>
                      </div>
                    </div>}
                    <div className="d-flex justify-content-between px-3 align-items-center">
                      { ! platform.type.includes("Facebook") ? <label className="text-md">
                          Campaign
                          <div className="tooltip-container">
                              <i ref={target}>
                              <img className="ml-2" src={info} alt="info_style" />
                              </i>
                              <div className="custom-tooltip-layout tooltip">
                                  <div className="tootltip-wrapper">
                                      <div className="tooltip-header">
                                      Campaign
                                      </div>
                                      <span> Campaign</span>
                                  </div>
                              </div>
                          </div>
                      </label>: <label className="text-md">
                          Adset
                          <div className="tooltip-container">
                              <i ref={target}>
                              <img className="ml-2" src={info} alt="info_style" />
                              </i>
                              <div className="custom-tooltip-layout tooltip">
                                  <div className="tootltip-wrapper">
                                      <div className="tooltip-header">
                                      Adset
                                      </div>
                                      <span> Adset</span>
                                  </div>
                              </div>
                          </div>
                      </label>
                      } 
                      {platform.type.includes("Facebook") && <ButtonUI className="view-ads" onClick={()=>handleAdsView(detail?.id,detail?.elementId)}>View in Ads Manager</ButtonUI>}                     
                    </div>
              
              <div className="adset-table m-3">
                      {platform.type==="Facebook Ad" ?<Table className="w-100 table-striped border rounded">
                        <thead className="">
                          <th className="text-md rounded">S.No.</th>
                          <th className="text-md">Ad Set ID</th>
                          <th className="text-md">Ad Set Name</th>
                        </thead>
                        <tbody>
                        {adsets && (adsets[detail.elementId]||[]).map((adsetDetail:any,index:number) => 
                          (        
                          <tr>
                            <td className="text-md">{index+1}</td>
                            <td className="text-md">{adsetDetail.adsetId}</td>
                            <td className="text-md">{adsetDetail.name}</td>
                          </tr>))}
                         
                        </tbody></Table>
                      :<Table className="w-100 table-striped border rounded">
                        <thead className="">
                          <th className="text-md rounded">S.No.</th>
                          <th className="text-md">Campaign ID</th>
                          <th className="text-md">Campaign Name</th>
                        </thead>
                        <tbody>
                        {campaigns && (campaigns[detail.elementId]||[]).map((adsetDetail:any,index:number) => 
                          (           
                          <tr>
                            <td className="text-md">{index+1}</td>
                            <td className="text-md">{adsetDetail.campaignId}</td>
                            <td className="text-md">{adsetDetail.name}</td>
                          </tr>))}
                        </tbody>
                      </Table>}
                    </div>
                    <div className="campaign-footer">
                        <button className="btn-apply" onClick={() => validateAllInput(platform) && submitResult(detail.elementId,platform
                          .type.includes("Google")?true:false,{ ad_account_id: detail.id, ad_account_name: detail.name, ad_set_ids:(adsets[detail.elementId] || []).map((adset:any) => adset.adsetId), campaign_ids: (campaigns[detail.elementId] || []).map((camp:any)=> camp.campaignId)})}>
                          Apply
                        </button>
                    </div>
                    </div>
                    </div>
                    
              </AdAccountAccordion>
            </div>
          ))}
        </Accordion>
      </>)):(adaccountLoading?<Loader/>:<NoData/>)}
    </div>
    </div>
  </>);
};


const SliderWithLabel: React.FC<{
  setROIFactor: (number:any) => {};
  ROIFactor: number;
}> = ({ setROIFactor, ROIFactor }) => {
  const [value, setValue] = React.useState(50);

  useEffect(() => {
    setValue(ROIFactor);
  }, [ROIFactor]);

  return (
    <div className="slider-container">
      <div className="slider-percent-label">
        <p style={{ left: `${value * 2.5}px` }}>{`${value}%`}</p>
        <p style={{ left: `${(100 + value) * 2.5}px` }}>{`${100 - value}%`}</p>
      </div>
      <Slider
        value={ROIFactor}
        defaultValue={ROIFactor}
        showValue={false}
        fillTrack={false}
        trackLength={500}
        trackThickness={10}
        handlerWidth={18}
        handlerHeight={18}
        markers={0}
        onChange={(value:any) => {
          setROIFactor(value);
        }}
        valueRenderer={(value:any) => `${value}%`}
      />
    </div>
  );
};

export default AIGroupSettings;

// @ts-nocheck
import moment from "moment/moment";
import React from "react";
import "./index.scss";

const PhaseTimeline: React.FC<{ phaseData: any }> = ({ phaseData }) => {
  let isEmpty = 0;
  for (const key in phaseData) {
    isEmpty += phaseData[key].length;
  }
  return (
    <div className="phase-timeline">
      {isEmpty > 0 ? (
        <>
          {Object.keys(phaseData).map((key) => {
            if (Object.keys(phaseData[key]).length > 0) {
              return (
                <div className="phase-timeline--container">
                  <div className="phase-timeline--title">{phaseData[key][0]?.phaseName}</div>
                  {phaseData[key]
                    .sort(function compare(a, b) {
                      const dateA = new Date(a.start_date);
                      const dateB = new Date(b.start_date);
                      return dateA - dateB;
                    })
                    .map((phase: any, index: number) => (
                      <>
                        <div className="phase-timeline--date">
                          Start date :
                          <span className="phase-date">
                            {phase?.start_date
                              ? ` ${moment(phase?.start_date).format("DD-MMM-YYYY")}`
                              : " --/--/----"}
                          </span>
                          - End date :
                          <span className="phase-date">
                            {phase?.end_date
                              ? ` ${moment(phase?.end_date).format("DD-MMM-YYYY")}`
                              : " --/--/----"}
                          </span>
                        </div>
                        <div
                          className={`phase-timeline--status ${
                            phase?.status === 2
                              ? "status__complete"
                              : phase?.status === 1 && "status__running"
                          }`}
                        >
                          {phase?.status === 2
                            ? "Complete"
                            : phase?.status === 1
                            ? "Running"
                            : "Not Started"}
                        </div>
                      </>
                    ))}
                </div>
              );
            }
          })}
        </>
      ) : (
        <div className="phase-timeline--no-data">No Data present</div>
      )}
    </div>
  );
};

export default PhaseTimeline;

import styled from "styled-components";

interface Props {
  height?: number;
}

export const StyledContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 13px;
  margin-bottom: 8px;
  height: ${(props) => props.height}px;
  border-radius: 10px;
`;
export const StyledMiddleContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 13px;
  // margin-bottom: 8px;
  height: calc(100vh - ${(props) => props.height}px);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;
export const StyledBottomContainer = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
  // border-radius: 0px 0px 10px 10px;
  // position: absolute;
  // bottom: 0px;
  // left: 0px;
  // right: 0px;
  padding: 12px 32px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const StyledTitle = styled.p`
  // font-weight: 600;
  font-size: 22px;
  font-family: "Lato";
  font-style: normal;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #000000;
`;
export const StyledTitleDM = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;
export const StyledButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .button-base-mui {
    padding: 11px 24px;
    border-radius: 10px;
    text-transform: none;
    font-size: 14px;
  }
  .cancel-btn {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #0869fb;
  }
`;

export const StyledVariantTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
`;
export const StyledGoBackBtn = styled.div`
  .goBack {
    padding: 12px 24px;
    width: 112px;
    background: #0869fb;
    border-radius: 12px;
    font-family: "Inter";
  }
`;

import { useState } from "react";
import { useSelector } from "react-redux";
import CreativeAIListingHeader from "../../../../CreativeAIListing/CreativeAIListingHeader";
import CustomizedDialogs from "../../../../shared/Modal/CustomizedDialogs";
import ActivityLog from "../ActivityLog/ActivityLog";
import { StyledModalHeightFix } from "../ActivityLog/CreativeRotation/styled";
import AiGroupListItem from "./AiGroupListItem/AiGroupListItem";
import { StyledCreativeAIListingWrapper } from "./styled";

const CreativeAIListingV2: React.FC = () => {
  const { selectedAiGroupObject } = useSelector(
    (state: any) => state.aiGroupReducer
  );

  const [showActivityLog, setShowActivityLog] = useState(false);

  return (
    <>
      <CreativeAIListingHeader />
      <StyledCreativeAIListingWrapper
        sx={{
          height: "91vh",
          borderRadius: "10px",
          overflow: "scroll",
          boxShadow: "unset",
        }}
      >
        <AiGroupListItem setShowActivityLog={setShowActivityLog} />
      </StyledCreativeAIListingWrapper>
      <CustomizedDialogs
        maxWidth="xl"
        show={showActivityLog}
        handleClose={() => setShowActivityLog(false)}
        type="modal"
        title={`Activity Log: ${selectedAiGroupObject?.name}`}
        headerHeight="70px"
        titleSize={20}
        footer={false}
        // fullScreen={true}
      >
        <StyledModalHeightFix>
          <ActivityLog />
        </StyledModalHeightFix>
      </CustomizedDialogs>
    </>
  );
};

export default CreativeAIListingV2;

import styled from "styled-components";

export const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 5px;
  overflow: hidden;
`;

export const StyledTabPanelMainDiv = styled.div`
  margin-top: 24px;
  padding-top: 4px;
  padding-bottom: 10px;
  overflow: scroll;
  height: 100%;
  width: 100%;
`;

export const StyledOptionsContainer = styled.article`
  position: relative;
  width: 80%;
  left: 10%;
`;

export const StyledIntroText = styled.p`
  font-family: "Lato";
  font-style: normal;
  // font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 3px;
`;

export const StyledButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 28px;
`;

export const StyledPreviewAndAdSettings = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

export const StyledShowMore = styled.a`
  margin-left: 5px;
  font-size: 15px;
  font-family: "Lato";
  font-style: normal;
  // font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #0869fb !important;
  border-bottom: 1px solid #0869fb;
  cursor: pointer;
`;

import React, { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { createCluster, setClusterDetails, setClusterKeywords, setClusterLoader, setKeyWordsFile } from "../../store/actions/cluster";
import { setModal } from "../../store/actions/form";
import { ClusterState } from "../../store/types/cluster";
import { FormState } from "../../store/types/form";
import { initialState } from "../../store/types/auth";
import FileUpload from "../../ui/FileUpload/FileUpload";
import { downloadFile } from "../../utils/commonFunctions";
import ModalContainer from "../Modal/ModalContainer";
import ModalWrapper from "../Modal/ModalWrapper";
import StepwiseFormContainer from "../StepwiseForm/StepwiseFormContainer";
import StepwiseFormWrapper from "../StepwiseForm/StepwiseFormWrapper";
import "./index.scss";
const KeywordsTemplate = require("../../assets/file/template.csv");

const UploadKeywords: React.FC = (): JSX.Element => {
  const { user,accountIdVal  } = useSelector((state: { auth:initialState }) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const clusterState = useSelector((state: { cluster: ClusterState }) => state.cluster);
  const { keyWordsFile, clusterLoader } = clusterState;
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { modal } = formState;
  // const [clusterRange, setClusterRange] = useState("0");

  const handleSubmit = useCallback(() => {
    if(keyWordsFile && accountIdVal) {
      dispatch(setClusterLoader(true));
      dispatch(setClusterKeywords([]));
      dispatch(createCluster(
        {accountID:accountIdVal,file:keyWordsFile,user:user},
        (response:any,error: boolean) => {
          if (!error) {
            dispatch(setClusterDetails(response.message));
            dispatch(setClusterLoader(false));
            history.push(`/googlesearchai/keywords-clustering${history.location.search}`)
          } else {
            dispatch(setModal({ key: "errorUploadFile", title: "Error In Uploaded File" }));
          }
        }
      ));
    }
  }, [accountIdVal, history, keyWordsFile, user]);

  const handleClose = useCallback(() => {
    dispatch(setModal({ key: "closeCluster", title: "Close cluster creator" }));
  },[]);
  
  const handleFileUpload = useCallback((files:any,isWrongType?:boolean) => {
    if(!isWrongType) {
      dispatch(setKeyWordsFile(files[0]));
    } else {
      dispatch(setModal({ key: "wrongFileType", title: "Wrong File Type" }));
    }
  },[])

  return (
    <>
      <StepwiseFormWrapper>
        <StepwiseFormContainer loading={clusterLoader} steps={["Keyword Clustering","Create Ad & Publish"]} handleSubmit={handleSubmit} handleClose={handleClose}>
          <div className="d-flex flex-column p-3 bg-white message-container flex-grow-1">
            <div className="upload-wrap">
              <FileUpload accept=".csv,.xlsx" value={keyWordsFile} onChange={handleFileUpload} />
              <a
                className="sample-file text-right mt-1"
                href={KeywordsTemplate.default}
                download="Keyword upload template - Google Search AI"
              >
                Download Template
              </a>
            </div>
          </div>
        </StepwiseFormContainer>
      </StepwiseFormWrapper>
      {modal && modal.key === "closeCluster" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Close cluster creator"}
            formDisable={false}
            submitText={"Close and Exit"}
            handleSubmit={() => {
              history.push(`/googlesearchai${history.location.search}`);
              dispatch(setModal(null));
            }}
            handleClose={() => {
              dispatch(setModal(null));
            }}
          >
            <p>
              All data in the current cluster flow will be deleted. Are you sure you want to close
              the flow?
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal && modal.key === "errorUploadFile" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Error In Uploaded File"}
            formDisable={false}
            submitText={"Close and Exit"}
            handleSubmit={() => dispatch(setModal(null))}
            width={"25vw"}
            handleClose={() => dispatch(setModal(null))}
          >
            <p className="text-lg">
              Error while reading the uploaded keyword file, please update the format of the file as
              per the{" "}
              <a className="text-decoration-none" href={KeywordsTemplate.default}>
                reference template
              </a>{" "}
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal && modal.key === "wrongFileType" && (
        <ModalWrapper>
          <ModalContainer
            title={modal?.title || "Wrong File Type"}
            formDisable={false}
            submitText={"Close and Exit"}
            handleSubmit={() => dispatch(setModal(null))}
            width={"25vw"}
            handleClose={() => dispatch(setModal(null))}
          >
            <p className="text-lg">
              Please select .csv or .xlsx file only with keywords and monthly average search columns.
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {/* {form?.modal && form?.modal.key === "defineCluster" && (
        <ModalWrapper>
          <ModalContainer
            title={form?.modal?.title || "Define cluster sensitivity"}
            formDisable={false}
            submitText={"Generate Clusters"}
            handleSubmit={handleModalSubmit}
            handleClose={() => dispatch(setModal(null))}
          >
            <p className="mt-2">Cluster Sensitivity</p>
            <div className="range-slider-wrapper d-flex justify-content-between mb-5">
              {rangeData && (
                <div className="pr-5 mt-4 pt-2 w-100">
                  <RangeSlider
                    data={rangeData}
                    name={"clusterSize"}
                    min={"0"}
                    max={"2"}
                    onChange={(e) => onRangeChange(e.target.value)}
                  />
                </div>
              )}
              <div className="cluster-size flex-1 mt-2 px-4">
                {clusterRange && clusterRange === "0" && <ClusterLowIcon />}
                {clusterRange && clusterRange === "1" && <ClusterLowIcon />}
                {clusterRange && clusterRange === "2" && <ClusterLowIcon />}
                <p className="text-sm pt-1 mb-0 color-light">Cluster Size</p>
              </div>
            </div>
          </ModalContainer>
        </ModalWrapper>
      )} */}
    </>
  )
};

export default UploadKeywords;

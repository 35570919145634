import React, { Fragment } from "react";
import CustomizedDialogs from "../../../shared/Modal/CustomizedDialogs";
import CustomizedButton from "../../../shared/Button/CustomizedButton";
import {
  StyledReviewModalFooter,
  StyledVariantItemPara,
  StyledReviewSpan,
  StyledReviewBtn,
} from "./styled";
import CustomizedSwitch from "../../../shared/Switch/CustomizedSwitch";
import CustomizedTable from "../../../shared/Table/CustomizedTable";
import { reviewVariantTableHead } from "../../../../utils/constants/creativeAI";
import { StyledTableCell, StyledTableRow } from "../../../shared/Table/styled";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CreateVariantsState } from "../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Button } from "../../../shared";
import { publishVariant } from "../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialState } from "../../../../store/types/auth";
import { textTruncateMore } from "../utils/text.utils";
import TooltipWithInfo from "../shared/TooltipWithInfo/TooltipWithInfo";

const ReviewModal: React.FC<{
  show: boolean;
  onHide: any;
}> = ({ show, onHide }) => {
  // All State

  const { ads, totalSelectedVariant, isPublishingVariant } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const auth = useSelector((state: { auth: initialState }) => state.auth);

  const dispatch = useDispatch();
  const {
    selectedAiGroupObject,
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);

  const onPublish = () => {
    dispatch(
      publishVariant({
        variantIds: totalSelectedVariant,
        user: auth.user,
        adAccountId,
      })
    );
    onHide("close");
  };

  return (
    <CustomizedDialogs
      show={show || isPublishingVariant}
      handleClose={() => onHide("close")}
      type="ConfirmDialog"
      footer={false}
      maxWidth="md"
    >
      <div>
        <CustomizedTable
          tableHeadValues={reviewVariantTableHead}
          tableWidth={600}
          tableType="evenColoredTable"
        >
          {ads?.map((item: any, index: any) => {
            const addToBePublish = item?.draftVariants
              ? (item?.variantList
                  ? item?.variantList?.filter((element: any) => element.checked)
                      ?.length
                  : 0) +
                "/" +
                item?.draftVariants
              : "--";
            return (
              <Fragment key={index}>
                <StyledTableRow>
                  {/* Will Need to discuss for condition base styling */}
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="left"
                    title={item?.name}
                  >
                    {textTruncateMore(item?.name, 30)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {addToBePublish}
                  </StyledTableCell>
                </StyledTableRow>
              </Fragment>
            );
          })}
        </CustomizedTable>

        <StyledReviewModalFooter>
          <div>
            <StyledVariantItemPara
              color="#7A7A7A"
              fontSize={14}
              fontWeight={500}
              fontFamily="Inter"
            >
              Creative Rotation
              <TooltipWithInfo
                top={"-20px"}
                info="Creative Rotation will be enabled/disabled for all the ad sets in the selected AI Group."
              />
            </StyledVariantItemPara>
            <StyledReviewSpan className="d-flex justify-content-start align-item-center">
              <CustomizedSwitch checked={selectedAiGroupObject?.toggle} />

              <StyledVariantItemPara
                color="#000000"
                fontSize={12}
                fontWeight={400}
                fontFamily="Lato"
              >
                (AI group level)
              </StyledVariantItemPara>
            </StyledReviewSpan>
          </div>
          <StyledReviewBtn>
            <CustomizedButton
              buttonText="Close"
              buttonClass="close-button"
              variant="outlined"
              onClick={() => onHide("close")}
            />
            <CustomizedButton
              buttonText={
                isPublishingVariant ? (
                  <Box width="60px" height="22px">
                    <CircularProgress
                      size={25}
                      sx={{
                        color: "white",
                      }}
                      thickness={5}
                    />
                  </Box>
                ) : (
                  `Publish (${totalSelectedVariant.length})`
                )
              }
              buttonClass="publish-button"
              variant="contained"
              disabled={totalSelectedVariant.length === 0}
              onClick={() => onPublish()}
            />

            {/* <Button
              buttonVariant="contained"
              disabled={totalSelectedVariant.length === 0}
              onClick={onPublish}
              // class="publish-button"
            >
              {isPublishingVariant ? (
                <Box width="60px" height="22px">
                  <CircularProgress
                    size={25}
                    sx={{
                      color: "white",
                    }}
                    thickness={5}
                  />
                </Box>
              ) : (
                ` Publish ${totalSelectedVariant.length}`
              )}
            </Button> */}
          </StyledReviewBtn>
        </StyledReviewModalFooter>
      </div>
    </CustomizedDialogs>
  );
};

export default ReviewModal;

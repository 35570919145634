import React, { useEffect, useState } from "react";
import { sub } from "date-fns";
import { Dropdown, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import info from "../../assets/svg/info.svg";
import DownloadIcon from "../../assets/svg/DownloadIcon.svg";
import ComingSoon from "../../assets/svg/ComingSoon.svg";
import { ReactComponent as Timer } from "../../assets/svg/timer.svg";
import { ReactComponent as DropDownArrow } from "../../assets/svg/dropdownarrow.svg";
import {
  disableCreativeRotation,
  enableCreativeRotation,
  getActivityLogs,
  getCreativeRotationToggle,
  getPlatformAdaccounts,
} from "../../store/actions/form";
import { AdAccountState } from "../../store/types/adaccount";
import { initialState } from "../../store/types/auth";
import ButtonUI from "../../ui/ButtonUI/ButtonUI";
import DateRangePickerUI from "../../ui/DateRangePicker/DateRangePicker";
import Switch from "../../ui/Switch/Switch";
import { updateSearchParams } from "../../utils/commonFunctions";
import Loader from "../common/Loader";
import ModalContainer from "../Modal/ModalContainer";
import "./index.scss";
import moment from "moment";
import { ActionImpactState } from "../../store/types/actionImpact";
import NoData from "../common/NoData";
import { mixPanelAdEvent } from "../../utils/mixpanel";
const CreativeAIListingHeader = () => {
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { groups, platforms, selectedBrandAdaccounts, selectedBrand } =
    useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const [facebookAdAccount, setFacebookAdAccount] = React.useState<any>([]);
  const [activityLogs, setActivityLogs] = React.useState<any>([]);
  const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
  const [elemetToggle, setElementToggle] = React.useState<any>({});
  const [selectedAccount, setSelectedAccount] = React.useState<any>({});
  const [selectedObj, setselectedObj] = React.useState<any>(null);
  const [platformGroups, setPlatformGroups] = React.useState<any>([]);
  const [facebookId, setFacebookId] = React.useState<any>(null);
  const [logNumber, setLogNumber] = useState(-1);
  const [pageCount, setPageCount] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<any>([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [platformGroupsData, setPlatformGroupData] = React.useState<any>([]);
  const [dateSelection, setDateSelection] = React.useState([
    {
      endDate: new Date(),
      startDate: sub(new Date(), { days: 6 }),
      key: "selection",
    },
  ]);
  type dateType = {
    start: any;
    end: any;
  };
  const [date, setDate] = React.useState<dateType>({
    start: moment(dateSelection[0].startDate).format("YYYY-MM-DD"),
    end: moment(dateSelection[0].endDate).format("YYYY-MM-DD"),
  });

  const history = useHistory();
  const actionImpact = useSelector(
    (state: { actionImpact: ActionImpactState }) => state.actionImpact
  );
  const { allPlatformLinkClicks } = actionImpact;
  const onDateRangeChange = (value: any) => {
    return {
      start: moment(value.start).format("YYYY-MM-DD"),
      end: moment(value.end).format("YYYY-MM-DD"),
    };
  };
  const activitylogsApi = (num: any, download: boolean) => {
    setActivityLogs([]);
    dispatch(
      getActivityLogs(
        {
          elementId: selectedObj.elementId,
          original_ad_account_id: selectedAccount.id,
          start_date: date.start,
          end_date: date.end,
          download: download,
          page: num,
          page_size: 50,
          user: auth.user,
        },
        (response: any, error: boolean) => {
          if (!error) {
            setActivityLogs(response?.data?.data?.results);
          } else {
            setActivityLogs([]);
          }
        }
      )
    );
  };
  const resetLogsData = () => {
    setActivityLogs([]);
    setPageCount(0);
    setCurrentPage(0);
    setPages([]);
  };
  const loadPage = (str: any) => {
    if (str === "next") {
      setCurrentPage((currentPage) => currentPage + 1);
      activitylogsApi(currentPage + 1, false);
    }
    if (str === "prev") {
      setCurrentPage((currentPage) => currentPage - 1);
      activitylogsApi(currentPage - 1, false);
    }
    if (str !== "next" && str !== "prev") {
      setCurrentPage(str);
      activitylogsApi(str, false);
    }
  };
  const onDateSelect = () => {
    setDate(
      onDateRangeChange({
        start: dateSelection[0].startDate,
        end: dateSelection[0].endDate,
      })
    );
    let temp = onDateRangeChange({
      start: dateSelection[0].startDate,
      end: dateSelection[0].endDate,
    });
    setShowCalendar(false);
    resetLogsData();
    dispatch(
      getActivityLogs(
        {
          elementId: selectedObj.elementId,
          original_ad_account_id: selectedAccount.id,
          start_date: temp.start,
          end_date: temp.end,
          download: false,
          page: 1,
          page_size: 50,
          user: auth.user,
        },
        (response: any, error: boolean) => {
          if (!error) {
            setActivityLogs(response?.data?.data?.results);
            setCurrentPage(1);
            setPageCount(response?.data?.data?.pages);
            const page = Array.from(
              { length: response?.data?.data?.pages },
              (v, k) => k + 1
            );
            setPages([...page]);
          } else {
            setActivityLogs(response?.data?.data?.results);
            setPageCount(0);
            setPages([]);
          }
        }
      )
    );
  };

  useEffect(() => {
    const facebookID = platforms.find(
      (f: any) => f.type.toLowerCase() == "FACEBOOK AD".toLowerCase()
    );
    setFacebookId(facebookID?.id);
    setFacebookAdAccount(
      selectedBrandAdaccounts.filter((a: any) => a.platformId == facebookID?.id)
    );
  }, [platforms, platforms.length, selectedBrandAdaccounts]);
  useEffect(() => {
    if (facebookAdAccount.length > 0) {
      setSelectedAccount(facebookAdAccount[0]);
      dispatch(
        getPlatformAdaccounts(
          {
            adaccountId: facebookAdAccount[0]?.id,
            platformId: facebookId,
            user: auth.user,
          },
          (response: any, error: any) => {
            if (!error) {
              setPlatformGroups(response.data);
            }
          }
        )
      );
    }
    setDate(
      onDateRangeChange({
        start: dateSelection[0].startDate,
        end: dateSelection[0].endDate,
      })
    );
  }, [facebookAdAccount]);
  useEffect(() => {
    setLoading(true);
    setPlatformGroupData([]);
    if (platformGroups.length > 0) {
      Promise.all(
        platformGroups.map((obj: any) => {
          return new Promise((res) => {
            dispatch(
              getCreativeRotationToggle(
                { elementId: obj?.elementId, user: auth.user },
                (response: any, error: any) => {
                  if (!error) {
                    res({ ...obj, toggle: response.data });
                  } else {
                    res({ ...obj, toggle: false });
                  }
                }
              )
            );
          });
        })
      )
        .then((data: any) => {
          setPlatformGroupData(data);
        })
        .catch((error) => {
          console.log(error);
          setPlatformGroupData(platformGroups);
          setLoading(false);
        });
    }
  }, [platformGroups?.length, platformGroups]);

  useEffect(() => {
    dispatch({
      type: "SET_SELECTED_ADACCOUNT_AND_PLATFORM",
      payload: selectedAccount,
    });
  }, [selectedAccount]);

  function downloadLogs(url: any) {
    const a = document.createElement("a");
    a.href = url;
    a.download = `activity_logs.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }



  return (
    <div className="flex-row w-100 pb-2 navigation">
      <div className="navigation--container">
        <div className="d-flex flex-row flex-wrap">
          <div className="flex-center navigation-dropdown mb-2">
            <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header--creativeai initial-style">
              AdAccount
            </h4>
            <span className="w-auto">
              <div>
                <Dropdown className="hoverable position-relative navigation-dropdown-btn">
                  <Dropdown.Toggle>
                    <div className="text-dark text-left text-truncate creativeai">
                      {facebookAdAccount?.length > 0 ? (
                        <>
                          Facebook{" "}
                          <span className="account-name-creativeai">
                            ({selectedAccount?.adAccount})
                          </span>
                        </>
                      ) : (
                        ` No Adaccount Found`
                      )}
                    </div>
                    <span className="dropdown_arrow">
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      disabled={true}
                      className="text-lg"
                      style={{ fontSize: "0.9rem !important" }}
                    >
                      {" "}
                      FACEBOOK AD{" "}
                    </Dropdown.Item>
                    {facebookAdAccount?.length > 0 &&
                      facebookAdAccount?.map((adAcc: any) => {
                        return (
                          <>
                            <Dropdown.Item className={"py-0"}>
                              <label
                                className="flex-center ml-2"
                                onClick={(e) => {
                                  setPlatformGroups([]);
                                  // const adacc = adAcc?.find((g: any) => g.adAccountId ===  data.adAccountId);
                                  dispatch(
                                    getPlatformAdaccounts(
                                      {
                                        adaccountId: adAcc.id,
                                        platformId: facebookId,
                                        user: auth.user,
                                      },
                                      (response: any, error: any) => {
                                        if (!error) {
                                          setPlatformGroups(response?.data);
                                        }
                                      }
                                    )
                                  );
                                  // dispatch(setSelectedAdaccounts(adAcc));
                                  setSelectedAccount(adAcc);
                                  updateSearchParams(
                                    "account_id",
                                    adAcc.adAccountId as any
                                  );
                                  mixPanelAdEvent("Ad Account Selected", {
                                    module_name: "Creative AI",
                                    brand_name: selectedBrand?.name,
                                    ad_account_name: adAcc?.adAccount,
                                    ad_account_id: adAcc?.id,
                                  });
                                }}
                              >
                                <input
                                  type="radio"
                                  name="paltform-nav"
                                  value={adAcc?.selectedElementId}
                                  checked={
                                    selectedAccount?.id === adAcc.adAccountId
                                  }
                                  defaultChecked={
                                    selectedAccount?.id ===
                                    facebookAdAccount[0].adAccountId
                                  }
                                />
                                <div className="radio-label text-md ml-2 creativeai">
                                  {adAcc?.adAccount}
                                  <br />
                                  <div className="text-md account-id-creativeai">
                                    {adAcc?.adAccountId}
                                  </div>
                                </div>
                              </label>
                            </Dropdown.Item>
                          </>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreativeAIListingHeader;

import React from "react";
import { ReactComponent as DropDownArrow } from "../../assets/svg/dropdownarrow.svg";

const Select: React.FC<{
  defaultDisableOption?: any;
  name?: string;
  options: any;
  disable?: boolean;
  defaultSelected?: any;
  onChange?: (value: any) => void;
}> = ({ defaultDisableOption, name, options, disable, defaultSelected, onChange }) => {
  return (
    <select className="form-control form-select text-md" name={name} disabled={disable} onChange={onChange}>
      <span className="dropdown_arrow">
        <DropDownArrow />
      </span>
      {defaultDisableOption && (
        <option disabled selected>
          {defaultDisableOption}{" "}
        </option>
      )}
      {options.map((value: any) => {
        return (
          <option selected={defaultSelected === value.value} value={value.value}>
            {value.name}
          </option>
        );
      })}
    </select>
  );
};

export default Select;

import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import ActionImpactAnalysis from "../../Pages/ActionImpactAnalysis";
import AIModelConfidence from "../../Pages/AIModelConfidence";
import AllPlatform from "../../Pages/AllPlatform/AllPlatform";
import Campaigns from "../../Pages/Campaigns";
import CreativeAI from "../../Pages/CreativeAI/Facebook/Facebook";
import TargetingAI from "../../Pages/TargetingAI";
import { setTargetingLinkClicks } from "../../store/actions/actionImpact";
import { getAllBrands, getGroups, setActiveBrand, setBrandLoading, setGroupId, setGroups, setGroupsLoading, setPlatform, setSelectedAdSet, setSelectedGroup, getAllPlatforms, setActivePlatform, setSelectedCampaign, setSelectedAdaccounts, setSelectedBrandAdaccounts, getBrandAdAccounts, setSelectedGroupAdaccounts, setAdAccountLoading, getGroupAdAccounts, getCalculatorData, setSelectedElementId, setSelectedTargetingAdaccount, setSelectedGSAIAdaccount, setSelectedBrandGSAIAdaccounts, setGSAIPlatform, setSelectedGroupCampaign } from "../../store/actions/adaccount";
import MontageAds from "../../Pages/MontageAds";
import LaunchCampaigns from "../../Pages/LaunchCampaigns";
import { setLinkClicks } from "../../store/actions/actionImpact";
import { ActionImpactState } from "../../store/types/actionImpact";
import { initialState } from "../../store/types/auth";
import { CampaignState } from "../../store/types/campaign";
import ButtonUI from "../../ui/ButtonUI/ButtonUI";
import CheckBox from "../../ui/CheckBox/CheckBox";
import DropdownUI from "../../ui/Dropdown/Dropdown";
import { getSearchParams, updateSearchParams } from "../../utils/commonFunctions";
import AIGroupSettings from "../AIGroupSettings/AIGroupSettings";
import Loader from "../common/Loader";
import CreateAdAndPublish from "../CreateAdAndPublish/CreateAdAndPublish";
import DateRangeFilter from "../DateRange";
import UserDetails from "../UserDetails/UserDetails";
import "./index.scss";
import { AdAccountState, GET_SAVINGCALCULATOR_DATA } from "../../store/types/adaccount";
import { FACEBOOK_PLATFORM_NAME, GOOGLE_SEARCH_PLATFORM_NAME, GOOGLE_SHOPPING_PLATFORM_NAME } from "../../utils/constants";
import { getGoogleOnBoarding, getTargetingAI, setGoogleOnboarding, setGoogleOnBoardingLoading, setTargetingAI, setTargetingAILoading } from "../../store/actions/form";
import { FormState } from "../../store/types/form";
import Radio from "../../ui/Radio/Radio";
import { ClusterState } from "../../store/types/cluster";
import { getAccountDetails } from "../../store/actions/auth";
import SavingCalculator from "../SavingCalculator/SavingCalculator";
import { setChartData, setChartOption, setChartTabs, setMetricCumulative } from "../../store/actions/campaign";
import GoogleSearchAI from "../../Pages/GoogleSearchAI/GoogleSearchAI";
import UploadKeywords from "../UploadKeywords/UploadKeywords";
import KeywordsClustering from "../KeywordsClustering/KeywordsClustering";
import OutlineScreen from "../../Pages/OutlineScreen/OutlineScreen";
import NavigationDropDownItem from "./NavigationDropDownItem";
import { Col, Row } from "react-bootstrap";
import Editor from "../CreativeAI/Facebook/CreateVariants/Editor/Editor";
import CreateVariants from "../../Pages/CreativeAI/Facebook/CreateVariants/VariantsLayout";
import { getModuleName, mixPanelAdEvent } from "../../utils/mixpanel";
import CreativeAIListingV2 from "../CreativeAI/Facebook/Main/AiGroupListing/CreativeAIListingV2";
import BrandSetting from "../CreativeAI/AdminControl/BrandSetting";

const Navigation: React.FC<unknown> = () => {
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAdSet, setIsAdSet] = useState(false);
  const [showPlatformDropdown, setShowPlatformDropdown] = useState(false);
  const [showAdAccDropdown, setShowAdAccDropdown] = useState(false);
  const [showAdSetDropdown, setShowAdSetDropdown] = useState(false);
  const [showCampaignDropdown, setShowCampaignDropdown] = useState(false);
  const [showCalenderDropdown, setShowCalenderDropdown] = useState(true);
  const [selectedAds, setSelectedAds] = useState<any>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<any>([]);
  const dispatch = useDispatch();
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { groups, selectedGroup, platforms, selectedPlatform, selectedGroupAdaccounts, selectedAdAccount, selectedBrand, groupId, selectedBrandAdaccounts, selectedBrandGSAIAdaccounts, selectedElementId, groupsLoading, selecteGroupAdset, selecteGroupCamp, selectedTargetingAdaccount, selectedGSAIAdaccount, selectedBrandTargetingAdaccounts, adaccountLoading } = adaccount;
  const actionImpact = useSelector((state: { actionImpact: ActionImpactState }) => state.actionImpact);
  const { targetingLinkClicks } = actionImpact;
  const campaign = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { spinner } = campaign;
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { savingCalculator: savingCalculatorData } = useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const { targetingAI, googleOnboarding } = formState;
  const clusterState = useSelector((state: { cluster: ClusterState }) => state.cluster);
  const {gsaiDropdownDisabled} = clusterState;
  const [isGSAIDropdownVisible, setIsGSAIDropdownVisible] = useState(false);
  const [isAllPlatformDropDownVisible, setIsAllPlatformDropDownVisible] = useState(false);
  const [isTargetAIDropDownVisible, setIsTargetAIDropDownVisible] = useState(false);
  const [isDropdownGroupSelected, setIsDropdownGroupSelected] = useState(false);

  const onDateRangeChange = (value: any) => {
    dispatch(setTargetingLinkClicks(value));
  }

  const elementID = selectedAdAccount?.elementId;
  const allPlatformCheck = history.location.pathname.includes("actionImpactAnalysis") || history.location.pathname === "/";

  useEffect(() => {
    allPlatformCheck && groupId && dispatch(getCalculatorData({ elementID: groupId, platformType: "aigroup" }))
  }, [groupId, allPlatformCheck])

  useEffect(() => {
    !allPlatformCheck && elementID && dispatch(getCalculatorData({ elementID: elementID, platformType: "element" }))
  }, [selectedAdAccount?.elementId, allPlatformCheck])

  useEffect(() => {
    dispatch(getAllPlatforms(
      { user: authState.user },
      (response: any, error: boolean) => {
        if (!error) {
          const platformData = response.data;
          platformData.map((platform: any) => {
            return platform.name = platform.type;
          });
          dispatch(setPlatform(platformData));
        }
      }
    ))
  }, [])

  useEffect(() => {
    dispatch(setBrandLoading(true));
    dispatch(getAllBrands(
      { user: authState.user },
      (response: any, error: boolean) => {
        if (!error && response?.data?.length > 0) {
          const { data } = response;
          const brand: any = data.find((g: any) => g.id === getSearchParams("brand_id")) || data[0];
          dispatch(setActiveBrand(brand));
          dispatch(setBrandLoading(false));
        }
      }
    ))
  }, [])

  useEffect(() => {
    dispatch(setAdAccountLoading(true));
    dispatch(getBrandAdAccounts({ brandId: selectedBrand.id},
      (adaccRes:any, adaccError: boolean) => {
        if (!adaccError && adaccRes.data.length > 0) {
          adaccRes.data.map((adcc: any) => {
            adcc.name = adcc.adAccountId;
            adcc.id = adcc.adAccountId;
          });
          dispatch(setSelectedBrandAdaccounts(adaccRes.data));
          dispatch(setAdAccountLoading(false));
        } else {
          console.log("getAdAccounts ->",adaccError);
          dispatch(setAdAccountLoading(false));
        }
      }
    ))
  }, [selectedBrand])

  useEffect(() => {
    if (selectedBrand && platforms.length && selectedBrandAdaccounts.length) {
      const selectedPlatform: any = platforms.find((platform: any) => platform.type.toLowerCase() === GOOGLE_SEARCH_PLATFORM_NAME.toLowerCase());
      const gsaiAdaccountsList = selectedBrandAdaccounts.filter((adAcc: any) => adAcc.platformId == selectedPlatform?.id);
      dispatch(setGSAIPlatform(selectedPlatform));
      dispatch(setSelectedBrandGSAIAdaccounts(gsaiAdaccountsList));
      if (!selectedGSAIAdaccount?.id) {
        dispatch(setSelectedGSAIAdaccount(gsaiAdaccountsList[0]));
      }
    }
  }, [selectedBrand, platforms, selectedBrandAdaccounts])

  useEffect(() => {
    if (history.location.pathname === "/targeting-ai") {
      dispatch(setTargetingAILoading(true));
      dispatch(getTargetingAI(
        { brandId: selectedBrand.id, user: authState.user },
        (response: any, error: boolean) => {
          dispatch(setTargetingAILoading(false));
          if (!error) {
            dispatch(setTargetingAI(response.data.taiOnBoarding && response.data.taiOnBoarding === true));
          }
        }
      ))
    }
  }, [selectedBrand])


  useEffect(() => {
    //get google onboarding
    dispatch(setGoogleOnBoardingLoading(true));
    dispatch(getGoogleOnBoarding(
      { brandId: selectedBrand.id, user: authState.user },
      (response: any, error: boolean) => {
        dispatch(setGoogleOnBoardingLoading(false));
        if (response?.success) {
          if (response.data) {
            dispatch(setGoogleOnboarding(!!response.data.googleOnBoarding));
          }
        }
      }
    ));
  }, [googleOnboarding]);

  useEffect(() => {
    dispatch(setGroupsLoading(true));
    dispatch(getGroups({ brandId: selectedBrand.id, user: authState.user },
      (grpResponse: any, grpError: boolean) => {
        dispatch(setGroupsLoading(false));
        if (!grpError) {
          const groups = [...grpResponse.data];
          dispatch(setGroups(groups));
          const group: any = groups.find((g: any) => g.id === getSearchParams("group_id")) || groups[0];
          dispatch(setSelectedGroup(group));
          dispatch(setGroupId(group.id));
        } else {
          console.log(grpError);
        }
      }
    ));
  }, [selectedBrand])

  useEffect(() => {
    if (selectedGroup?.id) {
      dispatch(setAdAccountLoading(true));
      dispatch(getGroupAdAccounts(
        { groupId: selectedGroup.id },
        (response: any, error: boolean) => {
          if (!error && response.data.length > 0) {
            const data = [...response.data];
            data.map((addata: any, index: number) => {
              data[index].name = addata.name || "NA";
              data[index].id = addata.adAccountId;
            });
            let selectedAdAcc = data.find((addData: any) => selectedElementId ? addData.elementId === selectedElementId : addData.adAccountId === getSearchParams("account_id")) || data[0];
            updateSearchParams("account_id", selectedAdAcc.adAccountId);
            const platform: any = platforms.find((g: any) => g.id === selectedAdAcc.platformId);
            dispatch(setActivePlatform(platform));
            dispatch(setSelectedAdaccounts(selectedAdAcc));
            dispatch(setSelectedElementId(selectedAdAcc.elementId));
            dispatch(setSelectedGroupAdaccounts(data));
            dispatch(setAdAccountLoading(false));
            if (isDropdownGroupSelected) {
              mixPanelAdEvent('AI Group Selected', {
                'module_name': getModuleName(history.location.pathname),
                'brand_name': selectedBrand?.name,
                'ai_group_name': selectedGroup?.name,
                'ai_group_id': selectedGroup?.id,
                'ad_account_id': selectedAdAcc.id,
                'ad_account_name': selectedAdAcc.name,
              });
              setIsDropdownGroupSelected(false);
            }
            
          } else if (isDropdownGroupSelected) {
            mixPanelAdEvent('AI Group Selected', {
              'module_name': getModuleName(history.location.pathname),
              'brand_name': selectedBrand?.name,
              'ai_group_name': selectedGroup?.name,
              'ai_group_id': selectedGroup?.id,
              'ad_account_id': null,
              'ad_account_name': null,
            });
            dispatch(setSelectedGroupAdaccounts([]));
            dispatch(setSelectedAdaccounts({}));
            dispatch(setAdAccountLoading(false));
            setIsDropdownGroupSelected(false);
          } else  {
            dispatch(setSelectedGroupAdaccounts([]));
            dispatch(setSelectedAdaccounts({}));
            console.log("getGroupAdAccounts ->", error);
            dispatch(setAdAccountLoading(false));
          }

        }
      ));
      dispatch(setSelectedGroupCampaign([]));
    }   
  }, [selectedGroup])


  useEffect(() => {
    if (selectedGroup && selecteGroupAdset && selecteGroupAdset.length > 0) {
      setSelectedAds(selecteGroupAdset?.map((d: any) => d?.adsetId));
      dispatch(setSelectedAdSet(selecteGroupAdset?.map((d: any) => d?.adsetId) as any));
    }
  }, [selecteGroupAdset])

  useEffect(() => {
    if (selecteGroupAdset?.length > 0) {
      setIsAdSet(true);
    } else {
      setIsAdSet(false);
    }
  }, [selecteGroupAdset?.length > 0]);

  //campaigns
  useEffect(() => {
    setSelectedCampaigns(selecteGroupCamp?.map((d: any) => d?.campaignId));
    dispatch(setSelectedCampaign(selecteGroupCamp?.map((d: any) => d?.campaignId)));
  }, [selecteGroupCamp]);


  useEffect(() => {
    if (selectedGroupAdaccounts.length > 0) {
      platforms.map((platform: any) => {
        platform.adaccountDetail = [];
        selectedGroupAdaccounts.map((adacc: any) => {
          if (platform.id === adacc.platformId && !(platform.adaccountDetail.some((g: any) => g.adAccountId === adacc.adAccountId))) {
            platform.adaccountDetail.push(adacc);
          }
        });
      });
      dispatch(setPlatform([...platforms]));
    } else {
      platforms.map((platform: any) => {
        platform.adaccountDetail = [];
      });
      dispatch(setPlatform([...platforms]));
    }
    dispatch(setPlatform([...platforms]));
  }, [selectedGroupAdaccounts, history.location])

  useEffect(() => {
    const val = history.location.pathname === "/targeting-ai";
    setShowCalenderDropdown(val);
    const unlisten = history.listen((location, action) => {
      const val = location.pathname === "/targeting-ai";
      setShowCalenderDropdown(val);
    });

    return () => {
      if (unlisten) unlisten();
    };
  }, []);

  const noDropdownRoutes = React.useMemo(
    () => [
      "/targeting-ai",
      "/googlesearchai",
      "/googlesearchai/upload-keywords",
      "/googlesearchai/keywords-clustering",
      "/googlesearchai/create-ad-and-publish",
      "/googlesearchai-dashboard",
      "/user-details",
      "/creative-ai",
      "/montage-ads",
      "/launch-campaigns",
      "/outline-screen",
      "/googleSearchTargetAi",
      "/creative-ai/variants/cohort",
      "/creative-ai/variants/create",
      "/brand-setting"
    ],
    []
  );

  const noAdAccDropdownRoutes = React.useMemo(
    () => [
      "/",
      "/user-details",
      "/aiModelConfidence",
      "/group-settings",
      "/singlePlatform",
      "/actionImpactAnalysis",
      // "/creative-ai",
      "/montage-ads",
      "/outline-screen",
    ],
    []
  );

  const noPlatformDropdownRoutes = React.useMemo(
    () => [
      "/",
      "/targeting-ai",
      "/googlesearchai",
      "/googlesearchai/upload-keywords",
      "/googlesearchai/keywords-clustering",
      "/googlesearchai/create-ad-and-publish",
      "/googlesearchai-dashboard",
      "/user-details",
      "/aiModelConfidence",
      "/group-settings",
      "/actionImpactAnalysis",
      "/creative-ai",
      "/montage-ads",
      "/launch-campaigns",
      "/outline-screen",
      "/googleSearchTargetAi",
      "/creative-ai/variants/cohort",
      "/creative-ai/variants/create",
      "/brand-setting"
    ],
    []
  );

  const noCampaignDropdownRoutes = React.useMemo(
    () => [
      "/",
      "/targeting-ai",
      "/googlesearchai",
      "/googlesearchai/upload-keywords",
      "/googlesearchai/keywords-clustering",
      "/googlesearchai/create-ad-and-publish",
      "/googlesearchai-dashboard",
      "/user-details",
      "/aiModelConfidence",
      "/group-settings",
      "/actionImpactAnalysis",
      "/creative-ai",
      "/montage-ads",
      "/launch-campaigns",
      "/outline-screen",
      "/googleSearchTargetAi",
      "/creative-ai/variants/cohort",
      "/creative-ai/variants/create",
      "/brand-setting"
    ],
    []
  );

  const noAdsetsDropdownRoutes = React.useMemo(
    () => [
      "/",
      "/targeting-ai",
      "/googlesearchai",
      "/googlesearchai/upload-keywords",
      "/googlesearchai/keywords-clustering",
      "/googlesearchai/create-ad-and-publish",
      "/googlesearchai-dashboard",
      "/user-details",
      "/aiModelConfidence",
      "/group-settings",
      "/actionImpactAnalysis",
      "/creative-ai",
      "/montage-ads",
      "/launch-campaigns",
      "/outline-screen",
      "/googleSearchTargetAi",
      "/creative-ai/variants/cohort",
      "/creative-ai/variants/create",
      "/brand-setting"
    ],
    []
  );

  const getDropDownRoutes = () => {
    if (!noDropdownRoutes.includes(history.location.pathname)) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
    if (!noAdAccDropdownRoutes.includes(history.location.pathname)) {
      setShowAdAccDropdown(true);
    } else {
      setShowAdAccDropdown(false);
    }
    if (!noPlatformDropdownRoutes.includes(history.location.pathname)) {
      setShowPlatformDropdown(true);
    } else {
      setShowPlatformDropdown(false);
    }
    if (!noCampaignDropdownRoutes.includes(history.location.pathname)) {
      setShowCampaignDropdown(true);
    } else {
      setShowCampaignDropdown(false);
    }
    if (!noAdsetsDropdownRoutes.includes(history.location.pathname)) {
      setShowAdSetDropdown(true);
    } else {
      setShowAdSetDropdown(false);
    }
  }

  useEffect(() => {
    getDropDownRoutes();
    history.listen((loc) => {
      getDropDownRoutes();
    });
  }, [history, history.location]);

  const getPageTitle = () => {
    let path = history.location.pathname;
    if (path.includes("/googlesearchai")) return "Google Search AI";
    switch (path) {
      case "/targeting-ai":
        return "Targeting AI";
      case "/user-details":
        return "User Details";
      default:
        return "";
    }
  };

  const handleAllCheckbox = (type: "adset" | "campaign") => {
    if (type === "adset") {
      let AdsId = selecteGroupAdset.map((a: any) => a.adsetId);
      if (selectedAds.length === 0) {
        setSelectedAds(AdsId);
      } else if (selectedAds.length === selecteGroupAdset.length) {
        setSelectedAds([]);
      } else {
        const unSelectedAdIds = AdsId.filter((element: any) => !selectedAds.includes(element));
        setSelectedAds((ads: any) => [...ads, ...unSelectedAdIds]);
      }
    } else {
      let campIds = selecteGroupCamp.map((a: any) => a.campaignId);
      if (selectedCampaigns.length === 0) {
        setSelectedCampaigns(campIds);
      } else if (selectedCampaigns.length === selecteGroupCamp.length) {
        setSelectedCampaigns([]);
      } else {
        const unSelectedCampIds = campIds.filter((element: any) => !selectedCampaigns.includes(element));
        setSelectedCampaigns((camp: any) => [...camp, ...unSelectedCampIds]);
      }
    }

  };

  const handleCheckbox = (element: any, type: "adset" | "campaign") => {
    if (type === "adset") {
      const adIds = selectedAds.find((a: any) => a === element.adsetId);
      if (selectedAds.length === 0) {
        setSelectedAds([element.adsetId]);
      } else if (adIds) {
        setSelectedAds((ads: any) => ads.filter((a: any) => a !== element.adsetId));
      } else {
        setSelectedAds((ads: any) => [...ads, element.adsetId]);
      }
    } else {
      const campIds = selectedCampaigns.find((a: any) => a === element.campaignId);
      if (selectedCampaigns.length === 0) {
        setSelectedCampaigns([element.campaignId]);
      } else if (campIds) {
        setSelectedCampaigns((camp: any) => camp.filter((a: any) => a !== element.campaignId));
      } else {
        setSelectedCampaigns((camp: any) => [...camp, element.campaignId]);
      }
    }

  };

  useEffect(() => {
    if (selectedAdAccount?.id) {
      dispatch(getAccountDetails());
    } else {
      dispatch(setActivePlatform({}));
    }
  }, [selectedAdAccount]);

  return (
    <>
      <div className={`flex-row w-100 py-2 navigation  ${(history.location.pathname === "/creative-ai" ||history.location.pathname === "/montage-ads"||history.location.pathname==="/outline-screen") && 'no-navigation'}`}>
        <div className="navigation--container">
          <div className={`d-flex flex-row flex-wrap  ${!(history.location.pathname === "/creative-ai" ||history.location.pathname === "/montage-ads"||history.location.pathname==="/outline-screen") && "navigation_dropdown_content justify-content-between"}`}>   
            <div className="flex-center navigation-dropdown ">
            <Row className="dropdown-row">
             {showDropdown &&<Col lg={4} className="d-flex align-items-center">
              <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                AI Group
              </h4>
              </Col>}
              {showDropdown && (
                <Col lg={8}>
                <span className="w-auto">
                  <div>
                  <DropdownUI
                      className="dropdown-ui"
                      isDropDownVisible={isAllPlatformDropDownVisible}
                      setIsDropDownVisible={setIsAllPlatformDropDownVisible}
                      toggelButton={
                        <ButtonUI className="btn secondary group-btn">
                          <div className="btn-container">
                            <div className="text-dark text-left text-truncate">
                              {groupsLoading ? (
                                <Loader
                                  height="20px"
                                  animation="grow"
                                  size="sm"
                                />
                              ) : selectedGroup ? (
                                selectedGroup.name
                              ) : (
                                "No Groups Found"
                              )}
                            </div>
                            {selectedGroup?.name && (
                              <span className="dropdown_arrow">
                                <svg
                                  width="12"
                                  height="8"
                                  viewBox="0 0 12 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        </ButtonUI>
                      }
                    >
                      {groups && (
                        <NavigationDropDownItem
                          data={groups}
                          onSelect={(id: any) => {
                            if (id !== groupId) {
                              const group = groups.find(
                                (g: any) => g.id === id
                              );
                              dispatch(setSelectedGroup(group as any));
                              dispatch(setGroupId(id as any));
                              updateSearchParams("group_id", id as any);
                              if (history.location.pathname === "/singlePlatform") {
                                setIsDropdownGroupSelected(true);
                              } else  {
                                mixPanelAdEvent('AI Group Selected', {
                                  'module_name': getModuleName(history.location.pathname),
                                  'brand_name': selectedBrand?.name,
                                  'ai_group_name': group?.name,
                                  'ai_group_id': group?.id,
                                  'ad_account_id': 'ALL',
                                  'ad_account_name': 'ALL',
                                });
                              }
                              
                            }
                          }}
                          setVisible={setIsAllPlatformDropDownVisible}
                        />
                      )}
                    </DropdownUI>
                  </div>
                </span>
               </Col>
              )}
              </Row>
            </div>
            
            
            {showAdAccDropdown && (history.location.pathname === "/targeting-ai" && targetingAI) && (<div className="flex-center navigation-dropdown ">
            <Row className="dropdown-row">
                    <Col lg={4} className="d-flex align-items-center">
              <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                {`AdAccounts`}
              </h4>
              </Col>
                    <Col lg={8}>
              <span className="w-auto">
                <div>
                <DropdownUI  
                        className="dropdown-ui"
                        isDropDownVisible={isTargetAIDropDownVisible}
                        setIsDropDownVisible={setIsTargetAIDropDownVisible}
                        toggelButton={
                          <ButtonUI className="btn secondary group-btn">
                            <div className="btn-container">
                              <div className="text-dark text-left text-truncate">
                                {selectedTargetingAdaccount ? (
                                  (selectedTargetingAdaccount as any)
                                    .adAccountId
                                ) : adaccountLoading ? (
                                  <Loader
                                    height="20px"
                                    animation="grow"
                                    size="sm"
                                  />
                                ) : (
                                  `No adaccount Present`
                                )}
                              </div>
                              <span className="dropdown_arrow">
                                <svg
                                  width="12"
                                  height="8"
                                  viewBox="0 0 12 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </div>
                          </ButtonUI>
                        }
                      >
                        {selectedBrandTargetingAdaccounts && (
                          <NavigationDropDownItem
                            data={selectedBrandTargetingAdaccounts}
                            onSelect={(id: number) => {
                              const adacc = selectedBrandTargetingAdaccounts.find(
                                (g: any) => g.id === id
                              );
                              mixPanelAdEvent('Ad Account Selected', {
                                'module_name': 'Targeting AI',
                                'brand_name': selectedBrand?.name,
                                'ad_account_name': adacc?.adAccount,
                                'ad_account_id': adacc?.id,
                                'platform_name': 'Facebook',
                              });
                              dispatch(setSelectedTargetingAdaccount(adacc));
                            }}
                            setVisible={setIsTargetAIDropDownVisible}
                          />
                        )}
                      </DropdownUI>
                </div>
              </span>
              </Col>
                  </Row>
            </div>)}



            {showAdAccDropdown && ((history.location.pathname.includes("/googlesearchai")||history.location.pathname==="/googleSearchTargetAi") && googleOnboarding) && (<div className="flex-center navigation-dropdown ">
            <Row className="dropdown-row">
                  <Col lg={4} className="d-flex align-items-center">
              <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                {`AdAccounts`}
              </h4>
              </Col>
                  <Col lg={8}>
              <span className="w-auto">
                <div>
                <DropdownUI
                        isDropDownVisible={isGSAIDropdownVisible}
                        setIsDropDownVisible={setIsGSAIDropdownVisible}
                        className="dropdown-ui"
                        disabled={gsaiDropdownDisabled}
                        toggelButton={
                          <ButtonUI className="btn secondary group-btn" disabled={gsaiDropdownDisabled}>
                            <div className="btn-container">
                              <div className="text-dark text-left text-truncate">
                                {selectedGSAIAdaccount ? (
                                  (selectedGSAIAdaccount as any).adAccountId
                                ) : adaccountLoading ? (
                                  <Loader
                                    height="20px"
                                    animation="grow"
                                    size="sm"
                                  />
                                ) : (
                                  `No adaccount Present`
                                )}
                              </div>
                              <span className="dropdown_arrow">
                                <svg
                                  width="12"
                                  height="8"
                                  viewBox="0 0 12 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </div>
                          </ButtonUI>
                        }
                      >
                        {selectedBrandGSAIAdaccounts && (
                          <NavigationDropDownItem
                            data={selectedBrandGSAIAdaccounts}
                            onSelect={(id: number) => {
                              const adacc = selectedBrandGSAIAdaccounts.find(
                                (g: any) => g.id === id
                              );
                              dispatch(setSelectedGSAIAdaccount(adacc));
                              mixPanelAdEvent('Ad Account Selected', {
                                'module_name': 'Google Search AI',
                                'brand_name': selectedBrand?.name,
                                'ad_account_name': adacc?.adAccount,
                                'ad_account_id': adacc?.id,
                                'platform_name': 'Google',
                              });
                            }}
                            setVisible={setIsGSAIDropdownVisible}
                          />
                        )}
                      </DropdownUI>
                </div>
              </span>
              </Col>
                </Row>
            </div>)}

      

        {showPlatformDropdown && platforms && platforms.length > 0 && (<div className="flex-center navigation-dropdown ">
          
        <Row className="dropdown-row">
              <Col lg={4} className="d-flex align-items-center">
          <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
            {`Platforms`}
          </h4>
          </Col>
              <Col lg={8}>
          {showPlatformDropdown && (
            <span className="w-auto">
              <div>

                <Dropdown className="hoverable position-relative navigation-dropdown-btn">
                    <Dropdown.Toggle>
                      <div className="text-dark text-left text-truncate">
                      {(selectedAdAccount && selectedAdAccount.adAccountId) ||` No Adaccount Found`}
                      </div>
                      <span className="dropdown_arrow">
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {platforms.map((platform:any) => {
                        if (platform.adaccountDetail?.length) {
                          return (
                            <>
                        <Dropdown.Item disabled={true} className="text-lg" style={{fontSize: "0.9rem !important"}} > {platform.name} </Dropdown.Item>
                        {platform.adaccountDetail.map((data:any) => {
                        return (
                          <Dropdown.Item className={'py-0'}>
                          <label className="flex-center ml-2" onClick={(e) =>{
                              const adacc = platform.adaccountDetail.find((g: any) => g.adAccountId ===  data.adAccountId);
                              dispatch(setActivePlatform(platform));
                              dispatch(setSelectedAdaccounts(adacc));
                              dispatch(setSelectedElementId(data.elementId));   
                              updateSearchParams("account_id", adacc.adAccountId as any);
                              mixPanelAdEvent('Ad Account Selected', {
                                'module_name': getModuleName(history.location.pathname),
                                'brand_name': selectedBrand?.name,
                                'ad_account_name': adacc?.name,
                                'ad_account_id': adacc?.adAccountId,
                                'platform_name': platform.name,
                              });
                            }}  >
                            <input type="radio" name="paltform-nav" value={data.selectedElementId} checked={selectedElementId === data.elementId} defaultChecked={selectedElementId === data.elementId} /> 
                            <div className="radio-label text-md ml-2">
                                {data.adAccountId}
                              </div>
                            </label>
                          </Dropdown.Item>
                          )
                        })}
                        </>)
                        }
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
              </div>
            </span>
          )}
            </Col>
            </Row>
        </div>)}

            <div className="flex-center navigation-dropdown ">
              {(!groupsLoading && selectedPlatform && selectedPlatform as any)?.name === FACEBOOK_PLATFORM_NAME && showAdSetDropdown && isAdSet && (
                <>
                 <Row className="dropdown-row">
                      <Col lg={4} className="d-flex align-items-center">
                  <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                    Ad Sets
                  </h4> </Col>
                      <Col lg={8}>
                  <span className="w-auto">
                    <div>
                      <Dropdown className="hoverable position-relative navigation-dropdown-btn">
                        <Dropdown.Toggle>
                          <div className="text-dark text-left text-truncate">
                            {selectedAds?.length > 0
                              ? `${selectedAds?.length} ad set selected`
                              : "AD Sets"}
                          </div>
                          <span className="dropdown_arrow">
                            <svg
                              width="12"
                              height="8"
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            className="navigation-adset-option adset-all"
                          >
                            <CheckBox
                              className="navigation-adset-checkbox"
                              onChange={() => handleAllCheckbox("adset")}
                              value={
                                selectedAds?.length ===
                                selecteGroupAdset?.length
                              }
                            />
                            All
                          </Dropdown.Item>
                          {selecteGroupAdset?.map(
                            (element: any, index: number) => (
                              <Dropdown.Item
                                disabled={spinner}
                                className="navigation-adset-option"
                                title={element.name}
                                eventKey={index}
                                key={element.adsetId}
                                style={{ backgroundColor: "#E9E9E9" }}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <CheckBox
                                  className="navigation-adset-checkbox"
                                  onChange={() => {
                                    handleCheckbox(element, "adset");
                                  }}
                                  value={selectedAds?.includes(element.adsetId)}
                                />
                                {element.adsetId} - {element.name}
                              </Dropdown.Item>
                            )
                          )}
                          <Dropdown.Item
                            onClick={(e: any) => {
                              // on change of SelectedAdSet API is called from ActionImpactAnalysis
                              if (selectedAds.length) {
                                dispatch(setSelectedAdSet(selectedAds));
                              } else {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            className="navigation-adset-option adset-apply"
                          >
                            <button
                              disabled={!selectedAds?.length}
                              className="btn primary apply-btn"
                            >
                              Apply
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </span>
                  </Col>
                    </Row>
                </>
              )}
            </div>

    
            <div className="flex-center navigation-dropdown ">
              {selectedPlatform?.name && (selectedPlatform.name.toLowerCase().includes("google") || selectedPlatform.name.toLowerCase().includes("linkedin")) && showCampaignDropdown && (
                <> <Row className="dropdown-row">
                <Col lg={4} className="d-flex align-items-center">
                  <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
                    Campaign
                  </h4>
                  </Col>
                      <Col lg={8}>
                  <span className="w-auto">
                    <div>
                      <Dropdown className="hoverable position-relative navigation-dropdown-btn">
                        <Dropdown.Toggle>
                          <div className="text-dark text-left text-truncate">
                            {(selectedCampaigns)?.length > 0
                              ? `${(selectedCampaigns)?.length} Campaign selected`
                              : "Campaign"}
                          </div>
                          <span className="dropdown_arrow">
                            <svg
                              width="12"
                              height="8"
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            className="navigation-adset-option adset-all"
                          >
                            <CheckBox
                              className="navigation-adset-checkbox"
                              onChange={() => handleAllCheckbox("campaign")}
                              value={
                                (selectedCampaigns)?.length ===
                                selecteGroupCamp?.length
                              }
                            />
                            All
                          </Dropdown.Item>
                          {selecteGroupCamp?.map(
                            (element: any, index: number) => (
                              <Dropdown.Item
                                disabled={spinner}
                                className="navigation-adset-option"
                                title={element.name}
                                eventKey={index}
                                key={element.campaignId}
                                style={{ backgroundColor: "#E9E9E9" }}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <CheckBox
                                  className="navigation-adset-checkbox"
                                  onChange={() => {
                                    handleCheckbox(element, "campaign");
                                  }}
                                  value={selectedCampaigns?.includes(element.campaignId)}
                                />
                                {element.campaignId} - {element.name}
                              </Dropdown.Item>
                            )
                          )}
                          <Dropdown.Item
                            onClick={(e: any) => {
                              // on change of SelectedAdSet API is called from ActionImpactAnalysis
                              if ((selectedCampaigns).length) {
                                dispatch(setSelectedCampaign(selectedCampaigns));
                                mixPanelAdEvent('Campaign Selected', {
                                  'module_name': 'Action Impact Analysis - Single platform',
                                  'brand_name': selectedBrand?.name,
                                  'ad_account_name': selectedAdAccount?.name,
                                  'ad_account_id': selectedAdAccount?.id,
                                  'campaign_id': selectedCampaigns,
                                  'campaign_name': selecteGroupCamp?.filter((camp:any)=> selectedCampaigns.includes(camp.campaignId)).map((selectedCamp:any) => selectedCamp.name ? selectedCamp.name : 'NA'),
                                });
                              } else {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            className="navigation-adset-option adset-apply"
                          >
                            <button
                              disabled={!(selectedCampaigns)?.length}
                              className="btn primary apply-btn"
                            >
                              Apply
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </span>
                  </Col>
                    </Row>
                </>
              )}
            </div>
          </div>

          {history.location.pathname === "/" ? <SavingCalculator module="actionImpactAnalysis" data={savingCalculatorData} />
            : ["actionImpactAnalysis", "singlePlatform"].map((value, index) => {
              if (history.location.pathname.includes(value)) {
                return <SavingCalculator module={value} data={savingCalculatorData} />
              }
            })}

          {showCalenderDropdown && (
            <span className="w-auto m-0 p-0">
              <div>
                <DateRangeFilter
                  defaultSelection={targetingLinkClicks}
                  callback={onDateRangeChange}
                />
              </div>
            </span>
          )}
        </div>
      </div>
      <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
        <Switch>
          <Route exact path="/" component={AllPlatform} />
          <Route path="/actionImpactAnalysis" component={AllPlatform} />
          <Route path="/singlePlatform" component={ActionImpactAnalysis} />
          <Route path="/group-settings" component={AIGroupSettings} />
          <Route path="/creative-ai" exact component={CreativeAI} />
          <Route path="/montage-ads" component={MontageAds} />
          <Route path="/campaigns" component={Campaigns} />
          <Route path="/aiModelConfidence" component={AIModelConfidence} />
          <Route path="/targeting-ai" component={TargetingAI} />
          <Route path="/user-details" component={UserDetails} />
          <Route path="/googlesearchai" exact component={GoogleSearchAI} />
          <Route path="/googleSearchTargetAi" exact component={GoogleSearchAI} />
          <Route path="/montage-ads" component={MontageAds} />
          <Route path="/launch-campaigns" component={LaunchCampaigns} />
          <Route path="/outline-screen" component={OutlineScreen} />
          <Route path="/googlesearchai" exact component={GoogleSearchAI} />
          <Route path="/googlesearchai/upload-keywords" exact component={UploadKeywords} />
          <Route path="/googlesearchai/keywords-clustering" exact component={KeywordsClustering} />
          <Route
            path="/googlesearchai/create-ad-and-publish"
            exact
            component={CreateAdAndPublish}
          />
          <Route path="/brand-setting" exact component={BrandSetting} />
          {/* Just for testing will remove in final merge */}
          <Route
            path="/creative-ai/variants/cohort"
            exact
            component={CreateVariants}
          />
          <Route
            path="/creative-ai/variants/create"     
            exact    
            component={CreateVariants}
          />
        </Switch>
      </div>
    </>
  );
};

export default Navigation;
import moment from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { Accordion, Dropdown, Table } from "react-bootstrap";
import { ReactComponent as ArrowUp } from "../assets/svg/arrowup.svg";
import { ReactComponent as EditIcon } from "../assets/svg/edit.svg";
import { ReactComponent as WarningIcon } from "../assets/svg/warning.svg";
import info from "../assets/svg/smallinfo.svg";
import CheckBox from "../ui/CheckBox/CheckBox";
import { getBarChartData, getCurrencySymbol, getFormatedCPR } from "../utils/commonFunctions";
import { FACEBOOK_PLATFORM_NAME } from "../utils/constants";
import BarChart from "./BarChart";
import ChartLabel from "./ChartLabel";
import Loader from "./common/Loader";
import MiniTable from "./common/MiniTable/MiniTable";
import NoData from "./common/NoData";
import Form from "./Form/Form";
import GroupAccordion from "./GroupAccordion";
import ModalContainer from "./Modal/ModalContainer";
import ModalWrapper from "./Modal/ModalWrapper";
import RecommendationAccordion from "./RecommendationAccordion";
import { CommonState } from "../store/types/common";
import { useDispatch, useSelector } from "react-redux";
import { getFormData, getOnBoarding, setFormLoading, setForms, setIsOnboarding, setModal, setTargetingForm, storeFormData, updateFormData, updateOnBoarding } from "../store/actions/form";
import { FormState } from "../store/types/form";
import { actionRecommendations, getCPRDetails, getCPRRecommendations, getIndustry, getRecommendationDetails, getRecommendations, setBarChartData, setChartLoading, setCPRDetails, setCPRGraphDetails, setCPRRecommendation, setCPRRecommendationLoading, setGroupRecommendationDetailLoading, setGroupRecommendationLoading, setIndustryType, setRecommendationDetails, setRecommendations } from "../store/actions/common";
import { ActionImpactState } from "../store/types/actionImpact";
import { initialState } from "../store/types/auth";
import { AdAccountState } from "../store/types/adaccount";
import { getElementId, setSelectedBrandAdaccounts, setTargetingGroups, setSelectedTargetingAdaccount, getAdsetDetails, getAdAccountDetails, setSelectedBrandTargetingAdaccounts } from "../store/actions/adaccount";
import { getLearningPhase, setAccountDetails } from "../store/actions/auth";
import { dateRangeOptions, defaultTargetingFormValues, TargetingFormValidationFields } from "../utils/constants/targetingAI";
import { mixPanelAdEvent } from "../utils/mixpanel";

const GroupsListing: React.FC = (): JSX.Element => {
  const [isAllChecked, setIsAllChecked] = React.useState<string | number>(-1);
  const [formDisable, setFormDisable] = React.useState(true);
  const [checked, setChecked] = React.useState<string[]>([]);
  const [rejectLoading, setRejectLoading] = React.useState<boolean>(false);
  const target = useRef(null);
  const dispatch = useDispatch();
  const actionImpact = useSelector((state: { actionImpact: ActionImpactState }) => state.actionImpact);
  const { targetingLinkClicks } = actionImpact;
  const auth = useSelector((state: { auth:initialState }) => state.auth);
  const handleClose = () => {
    dispatch(setModal(null));
    dispatch(setTargetingForm(defaultTargetingFormValues));
  };
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { groups, selectedBrand, platforms, groupsLoading, selectedBrandAdaccounts, targetingGroups, selectedTargetingAdaccount, platformId } = adaccount;
  const common = useSelector((state: { common: CommonState }) => state.common);
const { industryType, recommendations, recommendationDetails, cprDetails, cprGraphDetails, cprRecommendation, cprRecommendationLoading, chartLoading, groupRecommendationLoading, groupRecommendationDetailLoading } = common;
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { forms, targetingForm, formLoading, modal } = formState;

  const handleChecked = useCallback(
    (value, group, cohort_name,selectedTargetingAdaccount) => {
      if (checked.includes(value)) {
        setChecked((c) => c.filter((v) => v !== value));
        mixPanelAdEvent('Checkbox_un_tick_cohort_targeting_ai', {
          'module_name': 'Targeting AI',
          'brand_name': selectedBrand?.name,
          'ad_account_id': selectedTargetingAdaccount?.id,
          'ad_account_name': selectedTargetingAdaccount?.adAccount,
          'cohort_id': value,
          'cohort_name': cohort_name,
          'ai_group_name': group.name,
          'ai_group_id': group.id,
          'platform_name': 'Facebook'
        });
      } else {
        setChecked((c) => [...c, value]);
        mixPanelAdEvent('Checkbox_tick_cohort_targeting_ai', {
          'module_name': 'Targeting AI',
          'brand_name': selectedBrand?.name,
          'ad_account_id': selectedTargetingAdaccount?.id,
          'ad_account_name': selectedTargetingAdaccount?.adAccount,
          'cohort_id': value,
          'cohort_name': cohort_name,
          'ai_group_name': group.name,
          'ai_group_id': group.id,
          'platform_name': 'Facebook'
        });
      }
    },
    [checked]
  );

  useEffect(() => {
    let groupCprDetails = {};
    if (targetingGroups?.length > 0) {
      targetingGroups.forEach((group: any, index: number) => {
        if (
          groups && group.targtingElementID &&
          (!cprDetails ||
            !(cprDetails && cprDetails[group.targtingElementID]))
        ) {
          dispatch(getCPRDetails({ elementId: group.targtingElementID, start_date: moment(targetingLinkClicks?.start).format("YYYY-MM-DD"),
          end_date: moment(targetingLinkClicks?.end).format("YYYY-MM-DD"),  user: auth?.user },
              (response: any, error: boolean) => {
              if (!error) {
                groupCprDetails = {
                  ...groupCprDetails,
                  [group.targtingElementID]: getFormatedCPR(response.data)
                }
                dispatch(setCPRDetails({
                  ...cprDetails,
                  ...groupCprDetails
                }));
              } else {
                console.log(error);
              }
            }
          ));
        }
      });
    } 
  }, [targetingGroups, targetingLinkClicks]);

  const formValidation = (data: any) => {
    for (let index = 0; index < TargetingFormValidationFields.length; index++) {
      if (
        !(TargetingFormValidationFields[index] in data) ||
        !data[TargetingFormValidationFields[index]]
      ) {
        return false;
      }
    }
    return true;
  };

  const getStatusText = (val: null | boolean, status: string) => {
    switch (val) {
      case null:
        return "Recommended";
      case false:
        return "Rejected";
      case true: {
        if (status && status.toLowerCase() === "success") return "Published";
        return "Processing";
      }
    }
    return "";
  };
  
  const handleSubmit = () => {
    dispatch(setFormLoading(true));
    const elementId = modal.props ? modal.props["elementId"] : "";
    let formDetails = targetingForm;
    if (formDetails && formDetails?.modeOfApplication === "Manual") {
      formDetails = { ...formDetails, audienceRange: "All", relevanceRange: "All" };
    }
    if (
      elementId &&
      targetingForm &&
      auth &&
      forms &&
      forms[elementId]
    ) {
      if (modal.key === "adset" || modal.key === "switchAuto") {
        
        dispatch(updateFormData(
          {elementId: elementId, formId: forms[elementId].id, user: auth.user, params: formDetails},
          (response: any, error: boolean) => {
            dispatch(setFormLoading(false));
            if (!error) {
              dispatch(setForms({
                ...forms,
                [elementId]: formDetails
              }));
            } else {
              // console.log("UpdateForm->",error);
            }
          }
        ));
        dispatch(setModal(null));
      }
    } else if (targetingForm && auth) {
      dispatch(storeFormData(
        {elementId: elementId, user: auth.user, params: formDetails},
        (response: any, error: boolean) => {
          dispatch(setFormLoading(false));
          if (!error) {
            dispatch(updateOnBoarding(
              {brandId: selectedBrand.id,accountId: selectedTargetingAdaccount.id, user:auth.user,enable:true},
              (response: any, error: boolean) => {
                if (!error) {
                  dispatch(setIsOnboarding(true));
                }
              }
            ));
            if (!elementId) {
              getFormDetails();
            } else {
              dispatch(setForms({
                ...forms,
                [elementId]: response.data
              }));
            }
          } else {
            // console.log("StoreForm->",error);
          }
        }));
        dispatch(setModal(null));
    }
    mixPanelAdEvent(elementId ? 'TAI Form Updated' : 'Fills_onbaording_form_targeting_AI', {
      'module_name': 'Targeting AI',
      'brand_name': formDetails?.brandName,
      'recommendation_frequency': formDetails?.recommendationFrequency,
      'mode_of_application': formDetails?.modeOfApplication,
      'reference_ad_set': formDetails?.referenceAdsetId,
      'first_time': forms[elementId] ?  false : true,
      'ad_account_id': selectedTargetingAdaccount?.id,
      'ad_account_name': selectedTargetingAdaccount?.adAccount,
      'platform_name': 'Facebook',
      'ai_group_name': elementId ? targetingGroups.find((grp:any) => grp.targtingElementID === elementId).name : "",
      'ai_group_id': elementId? targetingGroups.find((grp:any) => grp.targtingElementID === elementId).id: "",
    });
    dispatch(setTargetingForm(defaultTargetingFormValues));

  };

  const getWarningDetail = (targtingElementID: any) => {
    const refId = forms && (forms[targtingElementID] as any)?.referenceAdsetId;
    if (formLoading || groupsLoading || !targtingElementID) return "";
    if (!forms || (forms && !forms[targtingElementID]))
      return "Targeting AI could not be activated due to some missing details.";
    if (!refId)
      return "You haven't selected any reference Ad set.";
    return "";
  };

  const onInputChange = (key: string, value: string | number | string[]) => {
    if (targetingForm) {
      if (key === "industryType") {
        let newTarget: any = targetingForm;
        newTarget.industryType = value;
        let industryIndex = industryType.findIndex((val: any) => val.value === value);
        newTarget.industrySubtype = industryType[industryIndex].subIndustries[0].value;
        dispatch(setTargetingForm(newTarget));
      } else {
        dispatch(setTargetingForm({ ...targetingForm, [key]: value }));
      }
    }
  };

  useEffect(() => {
    if (groups.length > 0 && selectedTargetingAdaccount?.id) {
      let tempGrp:any = [];
      new Promise((resolve:any, reject: any) => {
        Promise.all(groups.map((grp:any) => {
          let adAcc:any = grp.adAccounts.filter((adAcc:any)=> adAcc.adAccountId === selectedTargetingAdaccount.adAccountId);
          if (adAcc.length > 0) {
            dispatch(getAdsetDetails({ group_id: grp.id, user: auth?.user }, (adsetRes: any, adsetErr: boolean) => {
              if (!adsetErr && adsetRes.data.length > 0) {
                let newAdSets = adsetRes.data.filter((adset:any) => adset.adAccountId === selectedTargetingAdaccount.id)
                grp.adsets = newAdSets;
              }  else {
                console.log(adsetErr);
              }
            }));
            grp.targtingElementID = adAcc[0].elementId;
            tempGrp.push(grp);
          }
        }))
        resolve(dispatch(setTargetingGroups(tempGrp)));
      })
    }
  }, [groups?.length,selectedTargetingAdaccount])

  useEffect(() => {
    getFormDetails();
  }, [targetingGroups && targetingGroups.length,platforms,selectedTargetingAdaccount]);

  const getFormDetails = () => {
    if (targetingGroups && targetingGroups.length > 0 && selectedTargetingAdaccount) {
      let tempForms:any = {};
      dispatch(setFormLoading(true));

      Promise.all(
        targetingGroups.map((group: any) => {
          return new Promise((resolve) => {
            dispatch(getFormData(
              { elementId: group.targtingElementID, user: auth.user },
              (response: any, error: boolean) => {
                dispatch(setFormLoading(false));
              if (!error && response.data.id) {
                tempForms[group.targtingElementID] = response.data
                resolve(tempForms);
              } else {
                resolve(tempForms)
                console.log(error);
              }
            }))
          })
        }
      )).then((result) => {
        // console.log("New data", result);
        dispatch(setForms(result[0]))
      })
    }
  }

  useEffect(() => {
    if (auth) {
        dispatch(getIndustry({ user: auth.user },
          (response: any, error: boolean) => {
            if (!error) { 
              const industriesData: any[] = [];
              let subIndustriesData: any[] = [];
              response.data.industry_types.forEach((element: any, index: number) => {
                element.industry_subtypes.forEach((subelement: any, subindex: number) => {
                  subIndustriesData.push({ name: subelement.name, value: subelement.id.toString() });
                });
                industriesData.push({
                  name: element.name,
                  value: element.id.toString(),
                  subIndustries: subIndustriesData,
                });
                subIndustriesData = [];
              });
              dispatch(setIndustryType(industriesData));
            } else {
              console.log(error);
            }
          }
        )
      );
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (auth && selectedBrand && selectedTargetingAdaccount?.id) {
      dispatch(getOnBoarding(
        {brandId: selectedBrand.id,accountId: selectedTargetingAdaccount.id,user:auth.user},
        (response: any, error: boolean) => {
          if (!error) {
            if (!response.data || response.data.targetingAi !== true) {
              dispatch(setModal({ key: "groupForm" }));
            } else {
              dispatch(setModal(null));
              dispatch(setIsOnboarding(response.data.targetingAi));
            }
          }
        }
      ));
    }
    dispatch(getLearningPhase());
  }, [selectedBrand,selectedTargetingAdaccount])

  useEffect(() => {
    if (selectedBrandAdaccounts.length > 0 && platforms?.length > 0) {
      let newAds:any = [];
      const selectedPlatform:any = platforms.find((platform:any) => platform.type.toLowerCase() === FACEBOOK_PLATFORM_NAME.toLowerCase());
      if (selectedPlatform?.id) {
        selectedBrandAdaccounts.map((row:any)=> {
          if (row.platformId === selectedPlatform.id) {
            newAds.push(row);
          }
        });
        if(!selectedTargetingAdaccount?.id){
          dispatch(setSelectedTargetingAdaccount(newAds[0]));
        }
      }
      
      dispatch(setSelectedBrandTargetingAdaccounts(newAds));
    }
  }, [selectedBrandAdaccounts.length])

  useEffect(() => {
    if (selectedTargetingAdaccount?.id) {
      dispatch(getAdAccountDetails(
        {adaccountId: selectedTargetingAdaccount.id, platformType: "facebook"},
        (response:any, error: boolean) => {
          if ( !error && response.data) {
            dispatch(setAccountDetails(response.data));
          } else {
            dispatch(setAccountDetails({}));
          }
        } 
      ))
    }
  },[selectedTargetingAdaccount])

  useEffect(() => {
    if (targetingForm && formValidation(targetingForm)) {
      setFormDisable(false);
    } else {
      setFormDisable(true);
    }
  }, [
    targetingForm,
    modal?.props?.elementId,
  ]);

  useEffect(() => {
    if(modal?.key === "report"){
      handleRangeChange(cprGraphDetails.range||7);
      fetchRecommendations(cprGraphDetails.range||7)
    }
  }, [modal?.key]);

  const handleOpenGroup = (elementId: string, callback: any) => {
    if (!auth) return;
    if (!(recommendations && recommendations[elementId])) {
      dispatch(setGroupRecommendationLoading(true));
      dispatch(getRecommendations({ elementId: elementId, user: auth?.user },
        (response: any, error: boolean) => {
          callback(false);
          if (!error) {
            dispatch(setRecommendations({
              ...recommendations,
              [elementId]: response.data
            }));
            dispatch(setGroupRecommendationLoading(false));
          } else {
            console.log(error);
            dispatch(setGroupRecommendationLoading(false));
          }
        }
      ));
    }
  };

  const handleOpenRecommendation = (elementId: string, recommendationId: string, callback: any) => {
    if (!auth) return;
    if (
      !(recommendationDetails && recommendationDetails[recommendationId])
    ) {
      dispatch(setGroupRecommendationDetailLoading(true));
      dispatch(getRecommendationDetails({ elementId: elementId, recommendation_id: recommendationId, user: auth?.user },
        (response: any, error: boolean) => {
          if (!error) {
            dispatch(setRecommendationDetails({
              ...recommendationDetails,
              [recommendationId]: response.data
                ? response.data.map((d: any) => ({ ...d, data: JSON.parse(d.data) }))
                : null,
            }))
            dispatch(setGroupRecommendationDetailLoading(false));
          } else {
            console.log(error);
            dispatch(setGroupRecommendationDetailLoading(false));
          }
        }
      ));
    }
  };

  const handleAction = (elementId: any, action: "approve" | "deny", callback?: Function) => {
    let params: any = {
      "recommendationIds" : checked
    };

    dispatch(actionRecommendations({ elementId: elementId.targtingElementID, recommendation_ids: params, user: auth?.user, recommendation_action: action },
      (response: any, error: boolean) => {
        if (!error) {
          const keys = Object.keys(recommendationDetails);
          let newRecommendationDetails = recommendationDetails;
          let selectedData:any = [];
          keys.forEach((key) => {
            newRecommendationDetails[key].forEach((dataKey: any, dataIndex: number) => {

                if (checked.includes(dataKey.id)) {
                  
                  newRecommendationDetails[key][dataIndex] = {
                    ...newRecommendationDetails[key][dataIndex],
                    approved: action === "approve" ? true : false,
                  };
                  selectedData = {...selectedData ,[dataKey.id]: newRecommendationDetails[key][dataIndex].data['cohort_name']};
                }
              });
            })
          dispatch(setRecommendationDetails({ ...newRecommendationDetails }));
          setChecked([]);
          mixPanelAdEvent( action === "approve" ? 'TAI Cohorts Approved': "Reject_cohorts_click_targeting_ai", {
            'module_name': 'Targeting AI',
            'brand_name': selectedBrand?.name,
            'ad_account_id': selectedTargetingAdaccount?.id,
            'ad_account_name': selectedTargetingAdaccount?.adAccount,
            'ai_group_id': elementId?.id,
            'ai_group_name': elementId?.name,
            'cohorts': selectedData,
            'platform_name': 'Facebook'
          });
          callback && callback();
        } else {
          callback && callback();
          console.log(error);
        }
      }
    ));
  };

  const getAllDisabled = (recommendationId: string) => {
    const rows: any[] = recommendationDetails
      ? recommendationDetails[recommendationId]
      : [];
    return !!rows.find((row: any) => row.approved !== null);
  };

  const getDiffInCpr = (index: number) => {
    const key = `${moment()
      .subtract(cprGraphDetails?.range, "days")
      .format("YYYY-MM-DD")}-${moment().format("YYYY-MM-DD")}`;
    const graphDetails = cprGraphDetails?.data[`${key}`];
    const cpr = cprRecommendation?.data[index]?.data?.find(
      (f: any) => f.type === "CPR"
    );

    if (cpr && graphDetails) {
      const cprOther = graphDetails["cpr_other"];
      const cprTargeting = cpr;
      const diffCPR = parseFloat(
        (parseFloat(cprOther?.value || 0) - parseFloat(cprTargeting?.value || 0)).toFixed(2)
      );
      const diffInPercCPR = (diffCPR / parseFloat(cprOther?.value || 1)) * 100;

      return (
        <span>
          {diffCPR && cprTargeting?.value && cprOther?.value
            ? `${getCurrencySymbol(auth?.accountDetails?.currency) || ""}${Math.abs(diffCPR)}`
            : "-"}
          {diffInPercCPR !== 0 && cprTargeting?.value && cprOther?.value && (
            <span
              className="text-sm ml-2"
              style={{ color: diffInPercCPR > 0 ? "#89B84F" : "#E84B47" }}
            >
              <ArrowUp
                style={{ transform: diffInPercCPR < 0 ? "" : "rotate(180deg)" }}
                fill={diffInPercCPR > 0 ? "#89B84F" : "#E84B47"}
              />{" "}
              {parseFloat(`${diffInPercCPR}`).toFixed(2)}%
            </span>
          )}
        </span>
      );
    }
    return "-";
  };

  const fetchRecommendations = useCallback((range: string) => {
    if (modal.props.elementId) {
      dispatch(setCPRRecommendationLoading(true));
      dispatch(getCPRRecommendations({ elementId: modal.props.elementId, user: auth?.user, 
      start_date: moment().subtract(range, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD") },
        (response: any, error: boolean) => {
          if (!error) {
            
            const keys = Object.keys(response.data);
            const data: any[] = [];
            keys.forEach((key) => {
              if (key !== "labels") {
                data.push(
                 {
                    adsetId: key,
                    data: (response.data[key].data?.metrics && response.data[key].data?.metrics[0]?.columns.filter((a:any) => a.type).sort((a:any,b:any) => (a.type === "CPR") ? 1 : ((b.type  === "CPR") ? -1 : 0))) || [],
                    name: response.data[key].name,
                    recommendation_details: JSON.parse(response.data[key].recommendation_details),
                  }
                );
              }
            });
            dispatch(setCPRRecommendation({ labels: response.data?.labels?.filter((a:any) => a.type).sort((a:any,b:any) => (a.type === "CPR") ? 1 : ((b.type  === "CPR") ? -1 : 0)) || [], data }))
            dispatch(setCPRRecommendationLoading(false));
          } else {
            console.log(error);
            dispatch(setCPRRecommendationLoading(false));
          }
        }
      ));
    }
  },[dispatch, modal?.props?.elementId]);

  const handleSetModal = (val: any | null) => {
    dispatch(setModal(val));
  }

  const handleRangeChange = useCallback((range) => {
    dispatch(setCPRGraphDetails({
      ...cprGraphDetails,
      range,
    }));

    if(cprGraphDetails.range !== range)
    fetchRecommendations(range);

    let promises = [];
    for (let i = 0; i <= range / 7; i++) {
      const start_date = moment()
        .subtract(i === 0 ? range : `${i * 7}`, "days")
        .format("YYYY-MM-DD");
      const end_date = (
        i === 1 || i === 0 ? moment() : moment().subtract(`${(i - 1) * 7}`, "days")
      ).format("YYYY-MM-DD");
      const key = `${start_date}-${end_date}`;
      if (
        (!cprGraphDetails?.data ||
          !(key in (cprGraphDetails || {})?.data)) &&
          modal?.props &&
          modal.props.elementId
      ) {
        dispatch(setChartLoading(true));
        promises.push(new Promise((res) => {
          dispatch(getCPRDetails({ elementId: modal.props.elementId, start_date, end_date,  user: auth?.user },
              (response: any, error: boolean) => {
              if (!error) {
                res({ [key]: getFormatedCPR(response.data) });
              } else {
                res(null);
              }
            }
          ));
        }))
      }
    }
    let tempCPRGraphDetails = {...cprGraphDetails, range};
    dispatch(setChartLoading(true))
    Promise.all(promises).then((entries) => {
      entries.forEach((entry:any) => {
        tempCPRGraphDetails = {
          ...tempCPRGraphDetails,
          data: { ...tempCPRGraphDetails.data, ...entry }
        };
      })
      dispatch(setCPRGraphDetails(tempCPRGraphDetails));
      dispatch(setBarChartData(getBarChartData(tempCPRGraphDetails)));
      dispatch(setChartLoading(false))
    }).catch(() => dispatch(setChartLoading(false)));
    
  }, [auth?.user, cprGraphDetails, dispatch, fetchRecommendations, modal?.props]);

  return (
    <div
      className="d-flex flex-column p-3 bg-white flex-grow-1 box-shadow rounded"
      style={{ height: "20px" }}
    >
      { <></>}
      { formLoading ? (<div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100">
        <Loader />
      </div>) :
      <Accordion className="overflow-scroll h-100">
        {targetingGroups && targetingGroups.length > 0 ? targetingGroups.map((group: any, index: number) => {
          if (group.targtingElementID) {
            return <div key={group.targtingElementID} className="mb-2">
              <GroupAccordion
                data={group}
                cprData={cprDetails && cprDetails[group.targtingElementID]}
                index={index}
                formDetails={
                  forms &&
                  (forms[group.targtingElementID])
                }
                setModal={handleSetModal}
                onOpen={(callback: any) => handleOpenGroup(group.targtingElementID, callback)}
              >
                { groupRecommendationLoading ?
                  (<Loader />)
                :
                recommendations &&
                  recommendations[group.targtingElementID] &&
                  recommendations[group.targtingElementID].length > 0 && (
                    <Accordion className="mt-4">
                      {(recommendations
                        ? recommendations[group.targtingElementID] || []
                        : []
                      ).map((recommendation: any, recommendIndex: number) => (
                        <RecommendationAccordion
                          key={recommendation.id}
                          index={recommendations[group.targtingElementID].length - 1 - recommendIndex}
                          groupData={group}
                          data={recommendation}
                          accordionKey={`${index}${recommendIndex}`}
                          onOpen={(callback: any) =>
                            handleOpenRecommendation(group.targtingElementID, recommendation.id, callback)
                          }
                        >
                          {groupRecommendationDetailLoading ?
                           ( <Loader />)
                          : recommendationDetails &&
                            recommendationDetails[recommendation.id] &&
                            recommendationDetails[recommendation.id].length > 0 && (
                              <>
                                <Table className="overflow-scroll" style={{ maxHeight: "100px" }}>
                                  <thead className="border-bottom z-index-10">
                                    <tr>
                                      <th className="border-0" style={{ width: "3%" }}>
                                        <CheckBox
                                          disabled={getAllDisabled(recommendation.id)}
                                          value={isAllChecked === recommendation.id}
                                          onChange={(value) => {
                                            if (value) {
                                              setIsAllChecked(recommendation.id);
                                              setChecked(
                                                recommendationDetails[
                                                  recommendation.id
                                                ].map((data: any) => data.id)
                                              );
                                            } else {
                                              setIsAllChecked(-1);
                                              setChecked([]);
                                            }
                                          }}
                                        />
                                      </th>
                                      <th className="t_header10 border-0">
                                        List of Cohorts
                                        <div className="tooltip-container">
                                          <i ref={target}>
                                            <img className="ml-2" src={info} alt="info_style" />
                                          </i>
                                          <div
                                            data-container="body"
                                            className="custom-tooltip-layout tooltip"
                                          >
                                            <div className="tootltip-wrapper">
                                              This refers to the specific target groups with related
                                              behavior and interests within your target segment that
                                              are most likely to engage and convert for your brand.
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      <th className="t_header10 border-0" style={{ width: "15%" }}>
                                        Potential Audience size
                                        <div className="tooltip-container">
                                          <i ref={target}>
                                            <img className="ml-2" src={info} alt="info_style" />
                                          </i>
                                          <div className="custom-tooltip-layout tooltip">
                                            <div className="tootltip-wrapper">
                                              <span>
                                                This is an estimated size of the audience that fits
                                                your targeting criteria. It is calculated using:
                                                <br />
                                                1. The cohort details.
                                                <br />
                                                2. The reference Ad set, for each AI Group, selected
                                                at the time of Targeting AI setup.{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      <th className="t_header10 border-0">
                                        Relevance Score
                                        <div className="tooltip-container">
                                          <i ref={target}>
                                            <img className="ml-2" src={info} alt="info_style" />
                                          </i>
                                          <div className="custom-tooltip-layout tooltip">
                                            <div className="tootltip-wrapper">
                                              <div className="tooltip-header">Relevance Score</div>A
                                              number between 0 and 1, that defines how relevant the
                                              keyword is. This metric shows the ideal target audience
                                              based on all three objectives: conversion, consideration
                                              and awareness. The higher the number, the higher the
                                              relevance of the keyword.
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      <th className="t_header10 border-0">
                                        Awareness Score
                                        <div className="tooltip-container">
                                          <i ref={target}>
                                            <img className="ml-2" src={info} alt="info_style" />
                                          </i>
                                          <div className="custom-tooltip-layout tooltip">
                                            <div className="tootltip-wrapper">
                                              <div className="tooltip-header">Awareness Score</div>A
                                              number between 0 and 1, that defines how relevant the
                                              keyword is for the ideal target audience.This metric is
                                              based on the overall performance for audiences with
                                              awareness objectives. The higher the number, the higher
                                              the relevance of the keyword.
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      <th className="t_header10 border-0">
                                        Consideration Score
                                        <div className="tooltip-container">
                                          <i ref={target}>
                                            <img className="ml-2" src={info} alt="info_style" />
                                          </i>
                                          <div className="custom-tooltip-layout tooltip">
                                            <div className="tootltip-wrapper">
                                              <div className="tooltip-header">
                                                Consideration Score
                                              </div>
                                              A number between 0 and 1, that defines how relevant the
                                              keyword is to the ideal target audience. This metric
                                              shows the performance for audiences with consideration
                                              objectives. The higher the number, the higher the
                                              relevance of the keyword.
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      <th className="t_header10 border-0">
                                        Conversion Score
                                        <div className="tooltip-container">
                                          <i ref={target}>
                                            <img className="ml-2" src={info} alt="info_style" />
                                          </i>
                                          <div className="custom-tooltip-layout tooltip">
                                            <div className="tootltip-wrapper">
                                              <div className="tooltip-header">Conversion Score</div>A
                                              number between 0 and 1, that defines how relevant the
                                              keyword is to the ideal target audience. This metric is
                                              based on the overall performance for audiences with
                                              conversion objectives. The higher the number, the higher
                                              the relevance of the keyword.
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      <th className="t_header10 border-0">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(recommendationDetails
                                      ? recommendationDetails[recommendation.id] || []
                                      : []
                                    ).map((row: any, index: number) => (
                                      <tr key={row.id}>
                                        <td className="text-capitalize">
                                          <CheckBox
                                            disabled={row.approved !== null}
                                            value={
                                              checked.includes(row.id) ||
                                              isAllChecked === recommendation.id
                                            }
                                            onChange={(value) => {
                                              if (value && checked.length - 1 === recommendation.id) {
                                                setIsAllChecked(recommendation.id);
                                              } else {
                                                setIsAllChecked(-1);
                                              }
                                              handleChecked(row.id, group, row.data["cohort_name"],selectedTargetingAdaccount);
                                            }}
                                          />
                                        </td>
                                        <td className="align-items-center text-capitalize">
                                          <div className="tooltip-container">
                                            <p>
                                              <span className="font-weight-bold">
                                                {row.data["cohort_name"] || ""}:
                                              </span>
                                              <span>
                                                {" "}
                                                {(row.data["targetings"] || "").slice(0, 40)}
                                                {"..."}
                                              </span>
                                            </p>
                                            <div className="custom-tooltip-layout tooltip">
                                              <div className="tootltip-wrapper">
                                                {row.data["cohort_name"] && (
                                                  <div>
                                                    <span className="tooltip-header">
                                                      Cohort Name:{" "}
                                                    </span>
                                                    <span>{row.data["cohort_name"]}</span>
                                                  </div>
                                                )}
                                                {row.data["targetings"] && (
                                                  <div>
                                                    <span className="tooltip-header">
                                                      Interests:{" "}
                                                    </span>
                                                    <span>{row.data["targetings"]}</span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="text-capitalize">
                                          {row.data["audience_size"] || ""}
                                        </td>
                                        <td className="text-capitalize">
                                          {row.data["relevance"] ? parseFloat(row.data["relevance"]).toFixed(2) : `-`}
                                        </td>
                                        <td className="text-capitalize">
                                          {row.data["awareness"] || ""}
                                        </td>
                                        <td className="text-capitalize">
                                          {row.data["consideration"] || ""}
                                        </td>
                                        <td className="text-capitalize">
                                          {row.data["conversion"] || ""}
                                        </td>
                                        <td className="text-capitalize">
                                          {getStatusText(row.approved, row.status) || ""}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                {(checked.length > 0 || isAllChecked !== -1) && (
                                  <div className="d-flex p-2 position-sticky bottom-0 bg-white align-items-center justify-content-end">
                                    <button
                                      className="btn secondary"
                                      disabled={rejectLoading}
                                      onClick={() => {
                                        setRejectLoading(true);
                                        handleAction(group, "deny", () => setRejectLoading(false));
                                      }}
                                    >
                                      Reject {isAllChecked !== -1 ? "All" : checked.length} Cohorts
                                    </button>
                                    <button
                                      className="btn success"
                                      onClick={() => {
                                        dispatch(setModal({
                                          key: "confirmation",
                                          props: { elementId: group },
                                        }));
                                      }}
                                    >
                                      Approve {isAllChecked !== -1 ? "All" : checked.length} Cohorts
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                        </RecommendationAccordion>
                      ))}
                    </Accordion>
                  )}
              </GroupAccordion>
              {getWarningDetail(group.targtingElementID) && (
                <div className="warning p-2 pl-3">
                  <WarningIcon className="ml-2 mr-1" />
                  {getWarningDetail(group.targtingElementID)} Click on <EditIcon /> to complete.
                </div>
              )}
            </div>
          }
        }): <>
        <NoData />
        </>}
        {modal && (
          <ModalWrapper>
            {modal.key === "groupForm" && (
              <ModalContainer
                title={modal.title || "Targeting AI Setup"}
                formDisable={formDisable}
                submitText={"Next"}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                tagline={"New targeting recommendation will use these setting."}
                height="80vh"
              >
                <Form
                  formType={"form"}
                  onInputChange={onInputChange}
                  modalProps={modal.props}
                />
                {targetingGroups?.length > 0 && (
                  <MiniTable
                    type={"group"}
                    onInputChange={onInputChange}
                    modalProps={modal.props}
                    data={targetingGroups || []}
                    inputKey="elementIds"
                  />
                )}
              </ModalContainer>
            )}
            {modal.key === "adset" && (
              <ModalContainer
                title={modal.title || "Targeting AI Setup"}
                formDisable={formDisable}
                submitText={"Update"}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                tagline={"New targeting recommendation will use these setting."}
                height="80vh"
              >
                <Form
                  formType={"form"}
                  onInputChange={onInputChange}
                  modalProps={modal.props}
                />
                <MiniTable
                  type={"adset"}
                  onInputChange={onInputChange}
                  modalProps={modal.props}
                  data={modal?.props.adsets || []}
                  inputKey="referenceAdsetId"
                />
              </ModalContainer>
            )}
            {modal.key === "confirmation" && (
              <ModalContainer
                title={"Confirmation"}
                submitText={"Confirm"}
                handleClose={handleClose}
                handleSubmit={(setLoader) => {
                  setLoader(true);
                  handleAction(
                    modal.props ? modal.props["elementId"] : "",
                    "approve",
                    () => {
                      setLoader(false);
                      setChecked([]);
                      dispatch(setModal(null));
                    }
                  );
                }}
                tagline={""}
              >
                <p>
                  A duplicate of your reference Ad set, including inactive ads, will be created to
                  run your approved cohorts. All other setup details like age, gender, placements,
                  audiences etc will be the same.
                </p>
              </ModalContainer>
            )}
            {modal.key === "switchAuto" && (
              <ModalContainer
                title={modal?.title}
                submitText={"Confirm"}
                handleSubmit={handleSubmit}
                handleClose={() => {
                  modal.handleCancel && modal.handleCancel();
                  handleClose();
                }}
                tagline={"New targeting recommendation will use these setting."}
              >
                <Form
                  formType={"switchauto"}
                  onInputChange={onInputChange}
                  modalProps={modal.props}
                />
              </ModalContainer>
            )}
            {modal.key === "report" && (
              <ModalContainer
                title={modal?.title}
                handleClose={() => {
                  dispatch(setBarChartData([]));
                  dispatch(setCPRGraphDetails({ range: 7, term: "days", data: null }));
                  dispatch(setCPRRecommendation(null));
                  handleClose();
                }}
                width="80vw"
                height="80vh"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-md" style={{ fontWeight: 700 }}>
                    Week on week Comparission
                  </span>
                  <Dropdown
                    onSelect={(target: any) => {
                      handleRangeChange(target)
                    }}
                    className="hoverable position-relative mb-2"
                  >
                    <Dropdown.Toggle>
                      <div className="text-dark text-left text-truncate">
                        Last {cprGraphDetails.range / 7} Week
                        {cprGraphDetails.range / 7 > 1 ? "s" : ""}
                      </div>
                      <span className="dropdown_arrow">
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {dateRangeOptions.map((element: any, index: number) => (
                        <Dropdown.Item
                          eventKey={(index + 1) * 7}
                          key={element.id}
                          style={{ backgroundColor: "#E9E9E9" }}
                          onClick={() => {}}
                        >
                          {element.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="bg-white w-100 border rounded pt-2 overflow-hidden h-50 d-flex position-relative">
                  {chartLoading ? (
                    <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100">
                      <Loader />
                    </div>
                  ) : (<>
                  <ChartLabel
                    data={[
                      { name: "Targeting AI ad sets", color: "#5641B8" },
                      { name: "Others ad sets", color: "#66CACC" },
                    ]}
                  />
                  <BarChart label={modal?.props?.cprLabel?.label} /> </>)}
                </div>
                <div
                  className="bg-white w-100 border rounded mt-2 overflow-scroll position-relative"
                  style={{ height: "40%" }}
                >
                  {cprRecommendationLoading && (
                    <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100">
                      <Loader />
                    </div>
                  )}
                  <span className="text-md px-2 mt-2" style={{ fontWeight: 700 }}>
                    Targeting AI ad sets
                  </span>
                  <Table className="w-100" style={{ maxHeight: "100px" }}>
                    <thead className="border-0 z-index-10">
                      <tr>
                        <th className="fw-bold t_header10" style={{ width: "10%" }}>
                          <span>Ad Set Name</span>
                        </th>
                        <th className="t_header10">Ad Set Id</th>
                        <th className="t_header10">Targeting cohort</th>
                        {cprRecommendation?.labels.map((head: any) =>
                        head.type && (
                          <th className="t_header10">
                            {head.label}
                          </th>
                        ))}
                        {modal?.props?.cprLabel && (
                          <>
                            <th className="t_header10">
                              {modal?.props?.cprLabel?.label}
                              <p className="tableData">
                                (Other ad sets
                                <div className="tooltip-container">
                                  <i ref={target}>
                                    <img className="ml-2" src={info} alt="info_style" />
                                  </i>
                                  <div className="custom-tooltip-layout tooltip">
                                    <div className="tootltip-wrapper">
                                      {`This refers to the cumulative average ${modal?.props?.cprLabel?.label} for all Ad Sets in AI Groups not published by the Targeting AI.`}
                                    </div>
                                  </div>
                                </div>
                                )
                              </p>
                            </th>
                            <th className="t_header10" style={{ width: "20%" }}>
                              Difference in {modal?.props?.cprLabel?.label}
                              <div className="tooltip-container">
                                <i ref={target}>
                                  <img className="ml-2" src={info} alt="info_style" />
                                </i>
                                <div className="custom-tooltip-layout tooltip">
                                  <div className="tootltip-wrapper">
                                    This refers to the change in{" "}
                                    {modal?.props?.cprLabel?.label}. It is calculated using
                                    the following formula: % Change in{" "}
                                    {modal?.props?.cprLabel?.label} = (((
                                    {modal?.props?.cprLabel?.label} for Other Ad sets) - (
                                    {modal?.props?.cprLabel?.label} for Ad sets under
                                    Targeting AI))/{modal?.props?.cprLabel?.label} for Other
                                    Ad sets))*100.
                                  </div>
                                </div>
                              </div>
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!cprRecommendationLoading &&
                        (cprRecommendation?.data || []).map(
                          (recommendation: any, index: number) => (
                            <tr key={index}>
                              <td style={{ width: "6%" }}>{recommendation.name}</td>
                              <td>{recommendation.adsetId}</td>
                              <td>
                                <div className="tooltip-container">
                                  <span className="font-weight-bold">
                                    {recommendation.recommendation_details["cohort_name"] || ""}:
                                  </span>
                                  <span>
                                    {" "}
                                    {(
                                      recommendation.recommendation_details["targetings"] || ""
                                    ).slice(0, 40)}
                                    {"..."}
                                  </span>
                                  <div className="custom-tooltip-layout tooltip">
                                    <div className="tootltip-wrapper">
                                      {recommendation.recommendation_details["cohort_name"] && (
                                        <div>
                                          <span className="tooltip-header">Cohort Name: </span>
                                          <span>
                                            {recommendation.recommendation_details["cohort_name"]}
                                          </span>
                                        </div>
                                      )}
                                      {recommendation.recommendation_details["targetings"] && (
                                        <div>
                                          <span className="tooltip-header">Interests: </span>
                                          <span>
                                            {recommendation.recommendation_details["targetings"]}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {cprRecommendation?.labels?.length > 0 && cprRecommendation?.labels?.filter((lab:any) => lab.type).map(
                                (data: any, dataIndex:number) => 
                                (
                                  <td>{cprRecommendation?.data[index]?.data[dataIndex]?.value ? parseFloat(cprRecommendation?.data[index]?.data[dataIndex].value).toFixed(2) : `-`}</td>
                                  )
                                  )}
                              {/* cprRecommendation?.data[index]?.data */}
                              <td>
                                {cprGraphDetails?.data?.[
                                  `${moment()
                                    .subtract(cprGraphDetails?.range, "days")
                                    .format("YYYY-MM-DD")}-${moment().format("YYYY-MM-DD")}`
                                ]?.cpr_other?.value?.toFixed(2)}
                              </td>
                              <td>
                                {cprGraphDetails?.data !== null && getDiffInCpr(index)}
                              </td>
                            </tr>
                          )
                        )}
                      {!cprRecommendationLoading &&
                        cprRecommendation?.data.length === 0 && (
                          <tr>
                            <td colSpan={8} className="borderless-table">
                              <NoData />
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                </div>
              </ModalContainer>
            )}
          </ModalWrapper>
        )}
      </Accordion>}
    </div>
  );
};

export default React.memo(GroupsListing);

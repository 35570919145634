export const mapFactors = (items: any[]) => {
  const ROIResponse =
    items &&
    items
      .filter((item) => item.type === "roi")
      .map((item) => {
        return {
          id: item.id,
          metric: item.name,
          weight: item.weight,
          filter: item.filter,
          error: false,
          errorMessage: "",
        };
      });

  const QFResponse =
    items &&
    items
      .filter((item) => item.type === "quality")
      .map((item) => {
        return {
          id: item.id,
          metric: item.name,
          weight: item.weight,
          filter: item.filter,
          error: false,
          errorMessage: "",
        };
      });

  return {
    ROIResponse,
    QFResponse,
  };
};

export const mapFactorToResponse = (ROIMetrics: any[], QFMetrics: any[], ROIFactor: number) => {
  const ROIResponse =
    ROIMetrics &&
    ROIMetrics.map((item) => {
      return {
        name: item.metric,
        filter: item.filter,
        weight: item.weight,
        type: "roi",
      };
    });

  const QFResponse =
    QFMetrics &&
    QFMetrics.map((item) => {
      return {
        name: item.metric,
        filter: item.filter,
        weight: item.weight,
        type: "quality",
      };
    });

  return {
    metrics: [...ROIResponse, ...QFResponse],
    weight_distribuition: {
      roi: ROIFactor,
      quality: 100 - ROIFactor,
    },
  };
};

export const mapStoryPayload = (activeAdAccount: any,elementId:string) => {
  return {
    fake_story_in: {
      "target_cpr": parseInt(activeAdAccount[elementId]?.target_cpr,10),
      "target_result": parseInt(activeAdAccount[elementId]?.target_result,10),
      "cross_budget_max": parseFloat(activeAdAccount[elementId]?.cross_budget_max),
      "cross_budget_min": parseFloat(activeAdAccount[elementId]?.cross_budget_min),
      "result_metric_key":activeAdAccount[elementId]?.result_metric_key
    }
  }
};

export const mapStoryResponse = (data: any) => {
  const activeAdAccount = {
    cross_budget_max: data.cross_budget_max ? data.cross_budget_max : 0,
    cross_budget_min: data.cross_budget_min ? data.cross_budget_min : 0,
    target_cpr: data.target_cpr ? data.target_cpr : "",
    target_result: data.target_result ? data.target_result : "",
    result_metric_key:data.result_metric_key?data.result_metric_key:"",
    id:data.id?data.id:""
  }
  return activeAdAccount;
}
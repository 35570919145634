import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfigurationState,
  updateConfigureState,
} from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  CreateVariantsState,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Button, CheckBox } from "../../../../../shared";
import { validateLeverSelection } from "../../../utils/filterLevers.utils";
import { getFormValues } from "../../../utils/form.utils";
import {
  StyledFooterDiv,
  StyledRightElement,
  StyledTextVariant1,
} from "./styled";

interface ConfigureFooterProps {
  setIsConfiguring: any;
  onGenerate: (data: any, setIsConfiguring: any) => void;
  handleSubmit: any;
  getValues: any;
  isUpdateAISettingChecked: boolean;
  onUpdateAISettingCheckClick: any;
  watch: any;
}

export default function ConfigureFooter({
  setIsConfiguring,
  handleSubmit,
  onGenerate,
  getValues,
  isUpdateAISettingChecked,
  onUpdateAISettingCheckClick,
  watch,
}: ConfigureFooterProps) {
  const {
    isGeneratingMetaData,
    isUpdatingAIgroup,
    changedFieldList,
    configureMetaData,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const dispatch = useDispatch();
  const { CREATIVE_ELEMENT_AI } = useSelector((state: any) => ({
    CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi.data,
  }));

  const onBackClick = () => {
    setIsConfiguring(false);
    dispatch(setConfigurationState(false));
    dispatch(updateConfigureState(getValues()));
    const data = configureMetaData ?? getFormValues(CREATIVE_ELEMENT_AI, true);
    for (const changedField of changedFieldList) {
      let changedValue = data;
      changedField.split(".").forEach((field: string) => {
        changedValue = changedValue[field];
      });
      if (changedValue !== getValues(changedField)) {
        dispatch({
          type: UPDATE_RECOMMENDATION_SELECTION_STATE,
          payload: {
            isConfigureChanged: true,
          },
        });
        break;
      }
    }
  };
  const [disableFlag, setDisableFlag] = useState(false);
  let watchAllFields = watch();

  useEffect(() => {
    setDisableFlag(validateLeverSelection(getValues()));
  }, [watchAllFields]);

  return (
    <StyledFooterDiv>
      <Button
        buttonVariant="outlined"
        onClick={onBackClick}
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "Inter",
        }}
      >
        Back
      </Button>
      <StyledRightElement>
        <CheckBox
          checked={isUpdateAISettingChecked}
          size="small"
          onClick={onUpdateAISettingCheckClick}
        />
        <StyledTextVariant1>
          Update these settings for the AI Group
        </StyledTextVariant1>
        <Button
          type="submit"
          buttonVariant="contained"
          disabled={disableFlag}
          onClick={handleSubmit((data: any) =>
            onGenerate(data, setIsConfiguring)
          )}
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            fontFamily: "Inter",
          }}
        >
          {isGeneratingMetaData || isUpdatingAIgroup ? (
            <Box width="90px" height="25px">
              <CircularProgress
                size={25}
                sx={{
                  color: "white",
                }}
                thickness={5}
              />
            </Box>
          ) : (
            "Generate"
          )}
        </Button>
      </StyledRightElement>
    </StyledFooterDiv>
  );
}

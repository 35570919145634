import React, { useCallback } from "react";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import CheckBox from "../../../ui/CheckBox/CheckBox";
import InputLabel from "../../../ui/InputLabel/InputLabel";
import ModalContainer from "../../Modal/ModalContainer";
import ModalWrapper from "../../Modal/ModalWrapper";
import "./index.scss";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClusterState } from "../../../store/types/cluster";
import { initialState } from "../../../store/types/auth";
import {
  getDescription,
  getHeadline,
  setCreateAdDesc,
  setCreateAdHeadline,
  setCreateDescError,
  setCreateHeadlineError,
  setCreativeReplacement,
  setDescLoader,
  setGenerateDescriptionTimeStamp,
  setGenerateHeadlineTimestamp,
  setHeaderLoader,
  setTargetKeyword,
  setTimer,
} from "../../../store/actions/cluster";
import Switch from "../../../ui/Switch/Switch";
import { AdAccountState } from "../../../store/types/adaccount";
import { maxTimeLimit } from "../../../utils/constants/GSAI";
import Selection from './Selection'

const AdSection: React.FC = (): JSX.Element => {
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const timerRef = useRef<any>(null);
  const [headlinePriority, setHeadlinePriority] = React.useState<number>(0);
  const [descPriority, setDescPriority] = React.useState<number>(0);
  const [modal, setModal] = React.useState<any>({
    open: false,
    title: "",
    status: "",
    data: [],
  });
  const maxHeadlines = 15; //maximum headlines allowed to lock
  const maxDesc = 4; //maximum descriptions allowed to lock
  const [deleteId, setDeleteId] = React.useState({
    type: "",
    id: -1,
  });
  const [replaceHeadlineDetails, setReplaceHeadlineDetails] = React.useState({
    toBePublished:[],
    toBeReplacement:[],
    published:[]
  })

  const [replaceDescriptionDetails, setReplaceDescriptionDetails] = React.useState({
    toBePublished:[],
    toBeReplacement:[],
    published:[]
  })
  const dispatch = useDispatch();
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const {
    headlineID,
    descriptionID,
    clusterDetails,
    timer,
    createAdHeadline,
    createAdDesc,
    creativeReplacement,
    targetKeyword,
    headerLoader,
    descLoader,
    clusterTable,
    loadHeadlineDesc,
    generateHeadlineTimestamp,
    generateDescriptionTimestamp
  } = clusterState;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    selectedGSAIAdaccount,
  } = adaccount;

  React.useEffect(() => {
    if (
      (headlineID !== null && !clusterTable[targetKeyword.activeIndex].ad) ||
      (headlineID !== null &&
        clusterTable[targetKeyword.activeIndex].ad &&
        loadHeadlineDesc.headline)
    ) {
      dispatch(setHeaderLoader(true));
      getHeadlineData(
        selectedGSAIAdaccount?.id,
        headlineID,
        clusterDetails.cluster_req_id,
        auth?.user,
        createAdHeadline,
        generateHeadlineTimestamp
      );
    }
  }, [headlineID]);

  React.useEffect(() => {
    if (
      (descriptionID !== null && !clusterTable[targetKeyword.activeIndex].ad) ||
      (descriptionID !== null &&
        clusterTable[targetKeyword.activeIndex].ad &&
        loadHeadlineDesc.description)
    ) {
      dispatch(setDescLoader(true));
      getDescData(
        selectedGSAIAdaccount?.id,
        descriptionID,
        clusterDetails.cluster_req_id,
        auth?.user,
        createAdDesc,
        generateDescriptionTimestamp
      );
    }
  }, [descriptionID]);


  React.useEffect(() => {
    const crConst = {
      creative_replacement: false,
      headline: [
        {
          selected: false,
          label: "Best Performance",
          performance: "BEST",
          interval: "",
        },
        {
          selected: false,
          label: "Good Performance",
          performance: "GOOD",
          interval: "",
        },
        {
          selected: false,
          label: "Low Performance",
          performance: "LOW",
          interval: "",
        },
      ],
      description: [
        {
          selected: false,
          label: "Best Performance",
          performance: "BEST",
          interval: "",
        },
        {
          selected: false,
          label: "Good Performance",
          performance: "GOOD",
          interval: "",
        },
        {
          selected: false,
          label: "Low Performance",
          performance: "LOW",
          interval: "",
        },
      ],
    };
    if (clusterTable[targetKeyword.activeIndex].ad) {
      let { Headlines, Descriptions } = clusterTable[targetKeyword.activeIndex];
      let cluster = clusterTable[targetKeyword.activeIndex];
      if (!cluster.headlineIntervals && !cluster.descriptionIntervals) {
        cluster = clusterTable[targetKeyword.activeIndex]?.ad;
      }
      const { headlineIntervals, descriptionIntervals } = cluster;

      if (!Headlines && !Descriptions && cluster.responsiveSearchAd) {
        Headlines = cluster.responsiveSearchAd.headlines;
        Descriptions = cluster.responsiveSearchAd.descriptions;
      }

      let cr: any = {};
      cr.creative_replacement =
        clusterTable[targetKeyword.activeIndex].ad.creative_replacement;
      cr.headline = crConst.headline.map((headline: any, index: number) => {
        let found = (headlineIntervals || []).find(
          (h: any) => h.performance == headline.performance
        );
        if (found) {
          headline.interval = found.interval;
          headline.selected = true;
        }
        return headline;
      });
      cr.description = crConst.description.map(
        (description: any, index: number) => {
          let found = (descriptionIntervals || []).find(
            (d: any) => d.performance == description.performance
          );
          if (found) {
            description.interval = found.interval;
            description.selected = true;
          }
          return description;
        }
      );
      //console.log(cr)
      dispatch(setCreativeReplacement(cr));
      dispatch(
        setCreateAdHeadline(
          (Headlines || []).map((headline: any, index: number) => {
            return {
              id: index,
              value: headline.text ? headline.text : headline.headline,
              selected: true,
              isUploaded: true,
              isLocked: headline.isPublished || headline.isLocked,
              isReplaced:headline.isReplaced,
              isPublished: headline.isPublished,
              performance: headline.performance,
              pinningPositionId:
                headline.pinnedField == "UNSPECIFIED"
                  ? 0
                  : headline.pinnedField.split("_")[1],
              label: "",
            };
          })
        )
      );

      dispatch(
        setCreateAdDesc(
          (Descriptions || []).map((description: any, index: number) => {
            return {
              id: index,
              value: description.text
                ? description.text
                : description.description,
              selected: true,
              isLocked: description.isPublished || description.isLocked,
              isPublished: description.isPublished,
              isUploaded: true,
              isReplaced:description.isReplaced,
              performance: description.performance,
              pinningPositionId:
                description.pinnedField == "UNSPECIFIED"
                  ? 0
                  : description.pinnedField.split("_")[1],
              label: "",
            };
          })
        )
      );
      if (clusterTable[targetKeyword.activeIndex].ad.finalUrls) {
        targetKeyword.finalURL =
          clusterTable[targetKeyword.activeIndex].ad.finalUrls[0];
      }

      setTargetKeyword({ ...targetKeyword });
    }
  }, []);

  const getHeadlineData = useCallback(
    (
      accountID: any,
      headlineId: string,
      clusterID: string,
      user: any,
      createAdHeadlineData: any,
      generateHeadlineTimestamp:any
    ) => {
      dispatch(
        getHeadline(
          {
            accountID: accountID,
            headlineId: headlineId,
            clusterID: clusterID,
            user: user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              if(response.data.status.toLowerCase()==="failed")
                {
                  dispatch(setCreateHeadlineError(true));
                  dispatch(setGenerateHeadlineTimestamp(null));
                  dispatch(setHeaderLoader(false)); 
                }
              else if (response.data.status !== "PENDING") {
                const { generatedHeadline } = response.data;
                let details = JSON.parse(generatedHeadline);
                const headlines: any =
                  createAdHeadlineData && createAdHeadlineData.length > 1
                    ? createAdHeadlineData
                    : [];
                const lastIndex = headlines.length > 0 ? headlines.length : 0;
                details.headlines.forEach((headline: any, index: number) => {
                  headlines.push({
                    id: lastIndex + index,
                    value: headline.output,
                    selected: false,
                    isPublished: false,
                    pinningPositionId: 0,
                    label: headline.label.replace(/\b\w/g, (l: any) =>
                      l.toUpperCase()
                    ),
                  });
                });
                dispatch(setCreateHeadlineError(false)); 
                dispatch(setCreateAdHeadline(headlines));
                dispatch(setHeaderLoader(false));
              } else if((+ new Date())-generateHeadlineTimestamp>maxTimeLimit) { 
                 // checking time limit exceeded error
                dispatch(setCreateHeadlineError(true));
                dispatch(setGenerateHeadlineTimestamp(null)); 
                dispatch(setHeaderLoader(false));
              } else {
                let t = setTimeout(() => {
                  if (headlineId !== null) {
                    getHeadlineData(
                      accountID,
                      headlineId,
                      clusterID,
                      user,
                      createAdHeadlineData,
                      generateHeadlineTimestamp
                    );
                  }
                }, 8000);
                dispatch(setTimer(t));
              }
            }
            else{
              dispatch(setCreateHeadlineError(true));
              dispatch(setGenerateHeadlineTimestamp(null)); 
              dispatch(setHeaderLoader(false));
            }
          }
        )
      );
    },
    []
  );

  const getDescData = useCallback(
    (
      accountID: any,
      descriptionId: string,
      clusterID: string,
      user: any,
      createAdDescData: any,
      generateDescriptionTimestamp:any
    ) => {
      dispatch(
        getDescription(
          {
            accountID: accountID,
            descriptionId: descriptionId,
            clusterID: clusterID,
            user: user,
          },
          (response: any, error: boolean) => {
            if (!error) {
                if(response.data.status.toLowerCase()==="failed")
                {
                  dispatch(setCreateDescError(true));
                  dispatch(setGenerateDescriptionTimeStamp(null));
                  dispatch(setDescLoader(false)); 
                }
                else if (response.data.status !== "PENDING") {
                const { generatedDescription } = response.data;
                let details = JSON.parse(generatedDescription);
                const description: any =
                  createAdDescData && createAdDescData.length > 1
                    ? createAdDescData
                    : [];
                const lastIndex =
                  description.length > 0 ? description.length : 0;
                details.description.forEach((desc: any, index: number) => {
                  description.push({
                    id: lastIndex + index,
                    value: desc.output,
                    selected: false,
                    isPublished: false,
                    pinningPositionId: 0,
                  });
                });
                dispatch(setCreateDescError(false));
                dispatch(setCreateAdDesc(description));
                dispatch(setDescLoader(false));
              } else if((+ new Date())-generateDescriptionTimestamp>maxTimeLimit) { 
                // checking time limit exceeded error
                dispatch(setCreateDescError(true));
                dispatch(setGenerateDescriptionTimeStamp(null));
                dispatch(setDescLoader(false)); 
              } else {
                setTimer(
                  setTimeout(() => {
                    if (descriptionId !== null) {
                      getDescData(
                        accountID,
                        descriptionId,
                        clusterID,
                        user,
                        createAdDescData,
                        generateDescriptionTimestamp
                      );
                    }
                  }, 8000)
                );
              }
            }
            else{
              dispatch(setCreateDescError(true));
              dispatch(setGenerateDescriptionTimeStamp(null));
              dispatch(setDescLoader(false)); 
            }
          }
        )
      );
    },
    []
  );

  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        dispatch(setTimer(null));
      }
    },
    []
  );

  const closeDeleteModal = () => {
    setDeleteId({
      type: "",
      id: -1,
    });
  };

  // Delete headline/description
  const handleDelete = () => {
    if (deleteId.type === "headline") {
      const createAdData = createAdHeadline.filter(
        (h: any) => h.id !== deleteId.id
      );
      dispatch(setCreateAdHeadline(createAdData));
    } else {
      const createAdData = createAdDesc.filter(
        (h: any) => h.id !== deleteId.id
      );
      dispatch(setCreateAdDesc(createAdData));
    }
    closeDeleteModal();
  };

  const setLock = (id: number, type: string) => {
    if (type === "headline") {
      const hIndex = createAdHeadline.findIndex((h: any) => h.id == id);
      if (
        !createAdHeadline[hIndex].isLocked &&
        createAdHeadline.filter((d: any) => d.isPublished || d.isLocked).length < maxHeadlines
      ) {
        createAdHeadline[hIndex].isLocked = true;
        createAdHeadline[hIndex].selected = true;
      } else {
        createAdHeadline[hIndex].isLocked = false;
      }
      dispatch(setCreateAdHeadline([...createAdHeadline]));
    } else {
      const hIndex = createAdDesc.findIndex((h: any) => h.id == id);
      if (
        !createAdDesc[hIndex].isLocked &&
        createAdDesc.filter((d: any) => d.isPublished || d.isLocked).length < maxDesc
      ) {
        createAdDesc[hIndex].isLocked = true;
        createAdDesc[hIndex].selected = true;
      } else {
        createAdDesc[hIndex].isLocked = false;
      }
      dispatch(setCreateAdDesc([...createAdDesc]));
    }
  };

  const setPinningPosition = (
    id: number,
    type: string,
    pinningPosition: number
  ) => {
    if (type === "headline") {
      const hIndex = createAdHeadline.findIndex((h: any) => h.id == id);
      createAdHeadline[hIndex].pinningPositionId = pinningPosition;
      dispatch(setCreateAdHeadline(createAdHeadline));
    } else {
      const hIndex = createAdDesc.findIndex((h: any) => h.id == id);
      createAdDesc[hIndex].pinningPositionId = pinningPosition;
      dispatch(setCreateAdDesc(createAdDesc));
    }
  };

  useEffect(() => {
   const toBePublished:any=[];
   const toBeReplacement:any=[];
   const published:any=[];
   createAdHeadline?.forEach((element:any) => {
     if (element.isPublished && element.isLocked) {
       published.push(element);
     }
     else if (element.isLocked) {
       toBePublished.push(element);
     }
     else if (element.selected) {
       toBeReplacement.push(element);
     }
   });
   setReplaceHeadlineDetails({
    toBePublished,
    toBeReplacement,
    published
   });
  }, [createAdHeadline])

  useEffect(() => {
    const toBePublished:any=[];
    const toBeReplacement:any=[];
    const published:any=[];
    createAdDesc?.forEach((element:any) => {
      if (element.isPublished && element.isLocked) {
        published.push(element);
      }
      else if (element.isLocked) {
        toBePublished.push(element);
      }
      else if (element.selected) {
        toBeReplacement.push(element);
      }
    });
    setReplaceDescriptionDetails({
     toBePublished,
     toBeReplacement,
     published
    });
   }, [createAdDesc])
  
   

  return (
    <div className="create-ad">
      {/* Title */}
      <div className="create-ad--title">
        With responsive search ads, you enter multiple headlines and
        descriptions and Google combines them into ads
      </div>

      {/* Final URL */}
      <div className="create-ad-selection--heading">
        Final URL
        <span className="create-ad--icon-container">
          <Info
            style={{
              height: 16,
              width: 16,
              marginLeft: 8,
            }}
          />
          <div className="create-ad-heading--popup">The Final URL is the URL that people reach after clicking your ad. It should match what your ad promotes.</div>
        </span>
      </div>
      <div className="flex">
        <InputLabel
          placeholder={"https://www.example.com"}
          onInputChange={(e) => {
            let newTKeywords = targetKeyword;
            newTKeywords[e.target.name] = e.target.value;
            dispatch(setTargetKeyword(newTKeywords));
          }}
          name={"finalURL"}
          value={targetKeyword.finalURL}
        />
      </div>

      {/* Headline */}

      <Selection
        header="headline"
        data={createAdHeadline}
        loader={headerLoader}
        setLock={setLock}
        limit={30}
        popupInfo="Headlines appear at the top of your ad and can be up to 30 characters. Your headlines
            and other parts of your ad (including extensions) may appear in different configurations
            based on the device that a potential customer is using, or if Google Ads predicts that
            it may improve your performance. You can also delete headlines that you think don’t
            match the user’s intent."
        setDeleteId={setDeleteId}
        headlinePriority={headlinePriority}
        setHeadlinePriority={setHeadlinePriority}
        totalLength={maxHeadlines}
      />

      {/* Description */}
      <Selection
        header="description"
        data={createAdDesc}
        loader={descLoader}
        setLock={setLock}
        limit={90}
        popupInfo="Your ad’s description appears below the display URL and can be up to 90 characters.
        Your descriptions and other parts of your ad (including extensions) may show in different configurations based on the device that a potential customer is using, or when Google Ads predicts that it may improve your performance."
        setDeleteId={setDeleteId}
        descPriority={descPriority}
        setDescPriority={setDescPriority}
        totalLength={maxDesc}
      />

      {/* Footer */}
      <div className="create-ad--footer">
        <b>NOTE:</b> The url parameters and extensions in this ad will be
        applied as per the reference ad selected while defining ad group for
        respective cluster. To update these settings please visit the Google ads
        manager.
      </div>

      {/* Delete modal */}
      {deleteId.id > -1 && (
        <ModalWrapper>
          <ModalContainer
            title="Delete Keyword"
            submitText="Delete"
            handleSubmit={handleDelete}
            handleClose={closeDeleteModal}
          >
            <p>Are you sure you want to delete this keyword ?</p>
          </ModalContainer>
        </ModalWrapper>
      )}

      <div className="create-ad-selection--heading">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%" }}
        >
          <div className="flex">
            Creative Replace
            <span className="create-ad--icon-container">
              <Info
                style={{
                  height: 16,
                  width: 16,
                  marginLeft: 8,
                }}
              />
              <div className="create-ad-heading--popup">
                Turn this on to automatically replace headlines and descriptions
                based on the performance score assigned to them by google.
              </div>
            </span>
          </div>
          <div>
            <Switch
              initialValue={false}
              onChange={(e) => {
                creativeReplacement.creative_replacement = e;
                dispatch(setCreativeReplacement(creativeReplacement));
              }}
              value={creativeReplacement?.creative_replacement}
            ></Switch>
          </div>
        </div>
      </div>
      {creativeReplacement.creative_replacement ? (
        <>
          <div className="create-ad-selection--heading">
            Replace Headline
            <span className="create-ad--icon-container">
              <Info
                style={{
                  height: 16,
                  width: 16,
                  marginLeft: 8,
                }}
              />
              <div className="create-ad-heading--popup">
                Based on the performance score assigned to the headlines by
                Google, you can choose which performance categories you want to
                replace and input the number of days after which you want the
                replacement to occur. Headlines in the replacement list will be
                used for replacement.
              </div>
            </span>
          </div>
          <div className="create-ad-selection--body">
            <div className="sub-heading mb-2">
              Which type of headlines do you want to replace based on their
              performance from Google?
            </div>
            <div className="options--container">
              {creativeReplacement.headline.map((val: any, index: number) => {
                return (
                  <div className="option mb-3" key={index}>
                    <CheckBox
                      onChange={(value) => {
                        creativeReplacement.headline[index].selected = value;
                        dispatch(setCreativeReplacement(creativeReplacement));
                      }}
                      value={val.selected}
                    ></CheckBox>
                    <span
                      className={`replace-section ${
                        !val.selected ? " inactive" : ""
                      }`}
                    >
                      {val.label} - Replace in
                      <input
                        type="number"
                        min="0"
                        className="days mr-2 ml-2"
                        value={val.interval}
                        onChange={(e) => {
                          creativeReplacement.headline[index].interval =
                            e.target.value;
                          dispatch(setCreativeReplacement(creativeReplacement));
                        }}
                        placeholder="-"
                      />
                      days
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="links--container">
              <a
                className={replaceHeadlineDetails.toBePublished.length?"link mr-2":"link inactive mr-2"}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setModal({
                    open: true,
                    title: "Headlines to be published",
                    status: "toPublish",
                    data: replaceHeadlineDetails.toBePublished,
                  });
                }}
              >
                Headlines to be published
              </a>
              <a
              className={replaceHeadlineDetails.toBeReplacement.length?"link mr-2":"link inactive mr-2"}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setModal({
                    open: true,
                    title: "Headlines to be used for Replacement",
                    status: "refresh",
                    data: replaceHeadlineDetails.toBeReplacement,
                  });
                }}
              >
                Headlines to be used for Replacement
              </a>
              <a
               className={replaceHeadlineDetails.published.length?"link mr-2":"link inactive mr-2"}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setModal({
                    open: true,
                    title: "Published Headlines",
                    status: "published",
                    data: replaceHeadlineDetails.published,
                  });
                }}
              >
                Published Headlines
              </a>
            </div>
            {modal.open && (
              <ModalWrapper>
                <ModalContainer
                  title={modal.title}
                  handleClose={() => {
                    setModal({
                      open: false,
                      title: "",
                      status: "",
                      data: [],
                    });
                  }}
                >
                  <table className="table table-bordered selected-rows">
                    <thead>
                      <tr>
                        <th className="p-3">Headlines</th>
                        <th className="p-3">Pinning Position</th>
                        {modal.status == "published" ? (
                          <>
                            <th className="p-3">Performance</th>
                            <th className="p-3">Status</th>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {modal.data.map((element:any)=>{
                       return <tr>
                          <td>
                            {element.value}
                          </td>
                          <td>
                          {element.pinningPositionId === 0
                                  ? "None"
                                  : `Position ${element.pinningPositionId}`}
                          </td>
                          {modal.status === "published" &&(
                            <>
                              <td>
                              {element.performance?? "NA"}
                            </td>
                            <td>
                              {element.isPublished? "Current": "Removed"}
                            </td>
                            </>
                          ) }
                        </tr>
                      })}
                    </tbody>
                  </table>
                </ModalContainer>
              </ModalWrapper>
            )}
          </div>
          <div className="create-ad-selection--heading">
            Replace Descriptions
            <span className="create-ad--icon-container">
              <Info
                style={{
                  height: 16,
                  width: 16,
                  marginLeft: 8,
                }}
              />
              <div className="create-ad-heading--popup">
                Based on the performance score assigned to the descriptions by
                Google, you can choose which performance categories you want to
                replace and input the number of days after which you want the
                replacement to occur. Descriptions in the replacement list will
                be used for replacement.
              </div>
            </span>
          </div>
          <div className="create-ad-selection--body">
            <div className="sub-heading mb-2">
              Which type of descriptions do you want to replace based on their
              performance from Google?
            </div>
            {creativeReplacement.description.map((val: any, index: number) => {
              return (
                <div className="option mb-3" key={index}>
                  <CheckBox
                    onChange={(value) => {
                      creativeReplacement.description[index].selected = value;
                      dispatch(setCreativeReplacement(creativeReplacement));
                    }}
                    value={val.selected}
                  ></CheckBox>
                  <span
                    className={`replace-section ${
                      !val.selected ? " inactive" : ""
                    }`}
                  >
                    {val.label} - Replace in
                    <input
                      type="number"
                      min="0"
                      className="days mr-2 ml-2"
                      value={val.interval}
                      onChange={(e) => {
                        creativeReplacement.description[index].interval =
                          e.target.value;
                        dispatch(setCreativeReplacement(creativeReplacement));
                      }}
                      placeholder="-"
                    />
                    days
                  </span>
                </div>
              );
            })} 
            <div className="links--container">
              <a
                className={replaceDescriptionDetails.toBePublished.length?"link mr-2":"link inactive mr-2"}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setModal({
                    open: true,
                    title: "Descriptions to be published",
                    status: "toPublish",
                    data: replaceDescriptionDetails.toBePublished,
                  });
                }}
              >
                Descriptions to be published
              </a>
              <a
                className={replaceDescriptionDetails.toBeReplacement.length?"link mr-2":"link inactive mr-2"}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setModal({
                    open: true,
                    title: "Descriptions to be used for Replacement",
                    status: "refresh",
                    data: replaceDescriptionDetails.toBeReplacement,
                  });
                }}
              >
                Descriptions to be used for Replacement
              </a>
              <a
                className={replaceDescriptionDetails.published.length?"link mr-2":"link inactive mr-2"}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setModal({
                    open: true,
                    title: "Published Descriptions",
                    status: "published",
                    data: replaceDescriptionDetails.published,
                  });
                }}
              >
                Published Descriptions
              </a>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default AdSection;

import { SetLinkClicks, SET_LINK_CLICKS, SetAllPlatformRecommendation, SET_ALL_PLATFORM_RECOMMENDATION, SetAllPlatformspinner, SET_ALL_PLATFORM_SPINNER, GetAllPlatfromRecommendation, GET_ALL_PLATFROM_RECOMMENDATION, LinkClicks, SetAllPlatformLinkClicks, SET_ALL_PLATFORM_LINK_CLICKS, SetTargetingLinkClicks, SET_TARGETING_LINK_CLICKS, SET_ALL_PLATFORM_GRAPH_SPINNER, SET_ALL_PLATFORM_PIE_SPINNER, SET_ALL_PLATFORM_CHART_DATA, SET_ALL_PLATFORM_CHART_OPTION, SET_ALL_PLATFORM_CHART_CORDS_DATA, ChartData, SetAllPlatformGraphSpinner, SetAllPlatformPieSpinner, SetAllPlatformChartOption, SetAllPlatformChartData, SetAllPlatformChartCordsData, SetAllPlatformAdaccountDetails, SET_ALL_PLATFORM_ADACCOUNT_DETAILS, SET_CURRENCY_IDENTIFIER, SetCurrencyIdentifier, GetCurrencyDetails, GET_CURRENCY_DETAILS } from "../types/actionImpact";

export const setLinkClicks = (payload: any): SetLinkClicks => ({
  type: SET_LINK_CLICKS,
  payload,
});

export const setAllPlatformLinkClicks = (payload: any): SetAllPlatformLinkClicks => ({
  type: SET_ALL_PLATFORM_LINK_CLICKS,
  payload,
});

export const setTargetingLinkClicks = (payload: any): SetTargetingLinkClicks => ({
  type: SET_TARGETING_LINK_CLICKS,
  payload,
});

export const setAllPlatformRecommendation = (payload: any): SetAllPlatformRecommendation => ({
  type: SET_ALL_PLATFORM_RECOMMENDATION,
  payload,
});

export const setAllPlatformspinner = (payload: any): SetAllPlatformspinner => ({
  type: SET_ALL_PLATFORM_SPINNER,
  payload,
});


export const setAllPlatformGraphSpinner = (payload: boolean): SetAllPlatformGraphSpinner => ({
  type: SET_ALL_PLATFORM_GRAPH_SPINNER,
  payload,
});

export const setAllPlatformPieSpinner = (payload: boolean): SetAllPlatformPieSpinner => ({
  type: SET_ALL_PLATFORM_PIE_SPINNER,
  payload,
});

export const setAllPlatformChartData = (payload: ChartData): SetAllPlatformChartData => ({
  type: SET_ALL_PLATFORM_CHART_DATA,
  payload,
});

export const setAllPlatformChartOption = (payload: any[]): SetAllPlatformChartOption => ({
  type: SET_ALL_PLATFORM_CHART_OPTION,
  payload,
});

export const setAllPlatformChartCordsData = (payload: ChartData): SetAllPlatformChartCordsData => ({
  type: SET_ALL_PLATFORM_CHART_CORDS_DATA,
  payload,
});

export const setAllPlatformAdaccountDetails = (payload: any[]): SetAllPlatformAdaccountDetails => ({
  type: SET_ALL_PLATFORM_ADACCOUNT_DETAILS,
  payload,
});

export const setCurrencyIdentifier = (payload: any[]): SetCurrencyIdentifier => ({
  type: SET_CURRENCY_IDENTIFIER,
  payload,
});

export const getAllPlatfromRecommendation = (
  payload: { groupId: string, dates: LinkClicks, user: any, download: boolean },
  callback: Function
): GetAllPlatfromRecommendation => ({
  type: GET_ALL_PLATFROM_RECOMMENDATION,
  payload,
  callback,
});

export const getCurrencyDetails = (
  payload: { currencyName: string; },
  callback: Function
): GetCurrencyDetails => ({
  type: GET_CURRENCY_DETAILS,
  payload,
  callback,
});
import { base64ToUtf, getLocalStorageData, getSearchParams } from "../../utils/commonFunctions";
import { user_session_key } from "../../utils/constants";
import {
    initialState, SET_PHASE, SET_USER, SET_ALLOW_REJECT_RECOMMENDATION,
    SET_LEARNING_PHASE, SET_ACCOUNT_ID, SET_ACTIVE_PHASE, SET_ACCOUNT_DETAILS,
    SetAccountDetails, SetActivePhase, SetAllowRejectRecommendation, SetLearningPhase, SetPhase,
    SetUser, SetAccountId, SetSession, SetIsRefreshTokenExpired, SET_IS_REFRESH_TOKEN_EXPIRED, SET_SESSION, RefreashToken
} from "../types/auth";

const getUserDetails = () => {
    const userHash = getSearchParams("user");
    if (userHash) {
        const decodedUserDetails = base64ToUtf(userHash);
        const userData = decodedUserDetails ? decodedUserDetails.split(":") : ["", ""];
        return {
            username: userData[0],
            password: userData[1]
        }
    }

    return null;
}

const initailState: initialState = {
    user: getUserDetails(),
    phase: null,
    activePhase: -1,
    accountIdVal: getSearchParams("account_id"),
    learningPhase: false,
    allowRejectRecommendation: false,
    accountDetails: null,
    session: getLocalStorageData(user_session_key),
    isRefreshTokenExpired: false
}


export default function authReducer(state = initailState, action: SetAccountDetails | SetActivePhase |
    SetAllowRejectRecommendation | SetLearningPhase | SetPhase | SetUser | SetAccountId | SetSession | SetIsRefreshTokenExpired | any) {
    switch (action.type) {
        case SET_USER: return {
            ...state,
            user: action.payload
        }
        case SET_PHASE: return {
            ...state,
            phase: action.payload
        }
        case SET_ACTIVE_PHASE: return {
            ...state,
            activePhase: action.payload
        }
        case SET_ACCOUNT_ID: return {
            ...state,
            accountIdVal: action.payload
        }
        case SET_ACCOUNT_DETAILS: return {
            ...state,
            accountDetails: action.payload
        }
        case SET_LEARNING_PHASE: return {
            ...state,
            learningPhase: action.payload
        }
        case SET_ALLOW_REJECT_RECOMMENDATION: return {
            ...state,
            allowRejectRecommendation: action.payload
        }
        case SET_IS_REFRESH_TOKEN_EXPIRED: return {
            ...state,
            isRefreshTokenExpired: action.payload
        }
        case SET_SESSION: return {
            ...state,
            session: action.payload
        }
        default: return state;
    }
}
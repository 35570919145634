export const SET_GROUPS = "SET_GROUPS";
export const SET_GROUPS_LOADING = "SET_GROUPS_LOADING";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
export const SET_GROUP_ID = "SET_GROUP_ID";
export const SET_SELECTED_AD_SET = "SET_SELECTED_AD_SET";
export const SET_ACTIVE_BRAND = "SET_ACTIVE_BRAND";
export const SET_BRAND_LOADING = "SET_BRAND_LOADING";
export const SET_PLATFORM = "SET_PLATFORM";
export const SET_ACTIVE_PLATFORM = "SET_ACTIVE_PLATFORM";
export const SET_GSAI_PLATFORM = "SET_GSAI_PLATFORM";
export const GET_GROUPS = "GET_GROUPS";
export const GET_ADSET_DETAILS = "GET_ADSET_DETAILS";
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
export const GET_ALL_PLATFORMS = "GET_ALL_PLATFORMS";
export const GET_SAVINGCALCULATOR_DATA = "GET_SAVINGCALCULATOR_DATA";
export const SET_SAVINGCALCULATOR_DATA = "SET_SAVINGCALCULATOR_DATA";
export const SET_SELECTED_CAMPAIGN = "SET_SELECTED_CAMPAIGN";
export const GET_ELEMENT_ID = "GET_ELEMENT_ID";
export const GET_CAMPAIGN_DETAILS = "GET_CAMPAIGN_DETAILS";
export const SET_SELECTED_BRAND_ADACCOUNTS = "SET_SELECTED_BRAND_ADACCOUNTS";
export const SET_SELECTED_BRAND_GSAI_ADACCOUNTS = "SET_SELECTED_BRAND_GSAI_ADACCOUNTS";
export const SET_SELECTED_BRAND_TARGETING_ADACCOUNTS = "SET_SELECTED_BRAND_TARGETING_ADACCOUNTS";
export const SET_SELECTED_ADACCOUNT = "SET_SELECTED_ADACCOUNT";
export const GET_BRAND_ADACCOUNTS = "GET_BRAND_ADACCOUNTS";
export const GET_GROUP_ADACCOUNTS = "GET_GROUP_ADACCOUNTS";
export const SET_SELECTED_GROUP_ADACCOUNTS = "SET_SELECTED_GROUP_ADACCOUNTS";
export const GET_ADACCOUNT_DETAIL = "GET_ADACCOUNT_DETAIL";
export const SET_PLATFORM_ID = "SET_PLATFORM_ID";
export const SET_TARGETING_GROUPS = "SET_TARGETING_GROUPS";
export const SET_ADACCOUNT_LOADING = "SET_ADACCOUNT_LOADING";
export const SET_SINGLE_PLATFORM_ADACCOUNTS = "SET_SINGLE_PLATFORM_ADACCOUNTS";
export const SET_SELECTED_ELEMENTID = "SET_SELECTED_ELEMENTID";
export const SET_SELECTED_GROUP_CAMPAIGN = "SET_SELECTED_GROUP_CAMPAIGN";
export const SET_SELECTED_GROUP_ADSET = "SET_SELECTED_GROUP_ADSET";
export const SET_SELECTED_TARGETING_ADACCOUNT = "SET_SELECTED_TARGETING_ADACCOUNT";
export const SET_SELECTED_GSAI_ADACCOUNT = "SET_SELECTED_GSAI_ADACCOUNT";

// State
export type AdAccountState = {
  groups: groups[],
  groupsLoading: boolean,
  selectedGroup: groups,
  groupId: string,
  selectedAdSet: [],
  selectedBrand: brand,
  brandLoading: boolean,
  platforms: platform[],
  selectedPlatform: platform,
  selectedGSAIPlatform: platform,
  selectedCampaign: any,
  selectedBrandAdaccounts: any[],
  selectedBrandGSAIAdaccounts: any[],
  selectedBrandTargetingAdaccounts: any[],
  selectedAdAccount: any,
  selectedGroupAdaccounts: any[],
  platformId: any,
  targetingGroups: any[],
  adaccountLoading: boolean,
  singlePlatformAdaccounts: any,
  savingCalculator: any,
  adAccounts: any,
  selectedElementId: string,
  selecteGroupCamp: [],
  selecteGroupAdset: [],
  selectedTargetingAdaccount: any,
  selectedGSAIAdaccount: any
}

export type groups = {
  adaccount_id: string;
  adsetdetail: any[];
  adsets: string[];
  campaigns: any[];
  color: string;
  created_at: string;
  enabled: boolean;
  execution_budget: null;
  id: string;
  isterminated: boolean;
  name: string;
  platform: string;
  quality: null;
  roi: null;
  story_id: null;
  updated_at: string;
  targtingElementID?: string;
  googleSearchAdsCount?: any;
};

export type brand = {
  id: string,
  name: string,
  agencyId: string,
  createdAt: string,
  updatedAt: string,
  agreementType: string,
  enabled: boolean,
  creativeAiOnboarding:boolean,
}

export type platform = {
  id: string;
  type: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type adset = {
  id: string;
  name: string;
};

export type campaign = {
  id: string;
  name: string;
  adAccountId: string;
  aiGroupId: string;
  campaignId: string;
  elementId: string;
  platformId: string;
};

export type SetGroups = {
  type: typeof SET_GROUPS;
  payload: any;
};

export type SetGroupsLoading = {
  type: typeof SET_GROUPS_LOADING;
  payload: boolean;
};

export type SetSelectedGroup = {
  type: typeof SET_SELECTED_GROUP;
  payload: groups;
};

export type SetGroupId = {
  type: typeof SET_GROUP_ID;
  payload: string;
};

export type SetSelectedAdSet = {
  type: typeof SET_SELECTED_AD_SET;
  payload: [];
};

export type SetActiveBrand = {
  type: typeof SET_ACTIVE_BRAND;
  payload: any;
};

export type SetBrandLoading = {
  type: typeof SET_BRAND_LOADING;
  payload: boolean;
};

export type SetPlatform = {
  type: typeof SET_PLATFORM;
  payload: any;
};

export type SetActivePlatform = {
  type: typeof SET_ACTIVE_PLATFORM;
  payload: any;
};

export type SetGSAIPlatform = {
  type: typeof SET_GSAI_PLATFORM;
  payload: any;
};

export type SetSelectedCampaign = {
  type: typeof SET_SELECTED_CAMPAIGN;
  payload: [];
};

export type SetSelectedBrandAdaccounts = {
  type: typeof SET_SELECTED_BRAND_ADACCOUNTS;
  payload: [];
};

export type SetSelectedBrandGSAIAdaccounts = {
  type: typeof SET_SELECTED_BRAND_GSAI_ADACCOUNTS;
  payload: [];
};

export type SetSelectedBrandTargetingAdaccounts = {
  type: typeof SET_SELECTED_BRAND_TARGETING_ADACCOUNTS;
  payload: [];
};

export type SetSelectedAdaccounts = {
  type: typeof SET_SELECTED_ADACCOUNT;
  payload: [];
};

export type SetSelectedGroupAdaccounts = {
  type: typeof SET_SELECTED_GROUP_ADACCOUNTS;
  payload: [];
};

export type SetPlatformId = {
  type: typeof SET_PLATFORM_ID;
  payload: [];
};

export type SetTargetingGroups = {
  type: typeof SET_TARGETING_GROUPS;
  payload: [];
};

export type SetAdAccountLoading = {
  type: typeof SET_ADACCOUNT_LOADING;
  payload: [];
};

export type SetSinglePlatformAdaccounts = {
  type: typeof SET_SINGLE_PLATFORM_ADACCOUNTS,
  payload: [],
};

export type SetSelectedElementId = {
  type: typeof SET_SELECTED_ELEMENTID,
  payload: [],
};

export type SetSelectedGroupCampaign = {
  type: typeof SET_SELECTED_GROUP_CAMPAIGN,
  payload: [],
};

export type SetSelectedGroupAdset = {
  type: typeof SET_SELECTED_GROUP_ADSET,
  payload: [],
};

export type SetSelectedTargetingAdaccount = {
  type: typeof SET_SELECTED_TARGETING_ADACCOUNT,
  payload: [],
};

export type SetSelectedGSAIAdaccount = {
  type: typeof SET_SELECTED_GSAI_ADACCOUNT,
  payload: [],
};

// API
export type GetGroups = {
  type: typeof GET_GROUPS;
  payload: {
    brandId: string;
    user: any;
  };
  callback?: Function;
};

export type GetAdsetDetails = {
  type: typeof GET_ADSET_DETAILS;
  payload: {
    group_id: string;
    user: any;
  };
  callback?: Function;
};

export type GetCampaignDetails = {
  type: typeof GET_CAMPAIGN_DETAILS;
  payload: {
    group_id: string;
    user: any;
  };
  callback?: Function;
};

export type GetAllBrands = {
  type: typeof GET_ALL_BRANDS;
  payload: {
    user: any;
  };
  callback?: Function;
};

export type GetAllPlatforms = {
  type: typeof GET_ALL_PLATFORMS;
  payload: {
    user: any;
  };
  callback?: Function;
};

//saving calculator type
export type GetSavingCalculatorData = {
  type: typeof GET_SAVINGCALCULATOR_DATA;
  payload: any;
  callback?: Function;
};
export type SetSavingCalculatorData = {
  type: typeof SET_SAVINGCALCULATOR_DATA;
  payload: any;
};

export type GetElementId = {
  type: typeof GET_ELEMENT_ID;
  payload: {
    adAccountId: string;
    platformId: string;
    aiGroupId: string;
    user: any;
  };
  callback?: Function;
};

export type GetBrandAdAccounts = {
  type: typeof GET_BRAND_ADACCOUNTS;
  payload: {
    brandId: string;
  };
  callback?: Function;
};

export type GetGroupAdAccounts = {
  type: typeof GET_GROUP_ADACCOUNTS;
  payload: {
    groupId: string;
  };
  callback?: Function;
};

export type GetAdAccountDetails = {
  type: typeof GET_ADACCOUNT_DETAIL;
  payload: {
    adaccountId: string;
    platformType: string;
  };
  callback?: Function;
};

import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { ItemType } from "../Editor.types";
import useThrottledOnScroll from "./useThrottledOnScroll";

const useScrollSpy = ({
  items = [],
  target = window,
}: {
  items: ItemType[];
  target?: any;
}) => {
  const itemsWithNodeRef = useRef([]);
  useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items) as any;
  }, [items]);

  const [activeState, setActiveState] = useState<string | null>(null);
  const clickedRef = React.useRef(false);
  const unsetClickedRef = React.useRef<any>(null);

  const findActiveIndex = useCallback(() => {
    if (clickedRef.current) return;
    let active;
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      const item: ItemType = itemsWithNodeRef.current[i];
      const top =
        item?.node.offsetTop - document.getElementById("panel")!.offsetTop - 4;
      const refTop =
        document.getElementById("panel")!.scrollTop -
        document.getElementById("panel")!.offsetTop -
        4;

      if (refTop >= top / 2) {
        active = item;
        break;
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, items]);

  useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 100);

  const handleClick = (hash: string) => () => {
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1500);

    if (activeState !== hash) {
      setActiveState(hash);
      document.getElementById(hash)!.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  React.useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    []
  );

  return { activeState, handleClick };
};

const getItemsClient = (items: ItemType[]) =>
  items.map(({ hash }) => ({ hash, node: document.getElementById(hash) }));
export default useScrollSpy;

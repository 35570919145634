import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CreativeRotation from "./CreativeRotation/CreativeRotation";
import CreativeVariants from "./CreativeVariants/CreativeVariants";
import { DateRangePicker } from "react-date-range";
import { useSelector } from "react-redux";
import { StyledTabsContainer } from "../AiGroupListing/styled";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ marginTop: "22px" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ActivityLog: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { selectedAiGroup } = useSelector((state: any) => state.aiGroupReducer);
  return (
    <Box sx={{ width: "100%" }}>
      <StyledTabsContainer>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
        >
          <Tab
            className="text-capitalize"
            label="Creative Rotation"
            {...a11yProps(0)}
          />
          <Tab
            className="text-capitalize"
            label="Creative Variants"
            {...a11yProps(1)}
          />
        </Tabs>
      </StyledTabsContainer>
      <div>
        <TabPanel value={value} index={0}>
          <CreativeRotation />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CreativeVariants />
        </TabPanel>
      </div>
    </Box>
  );
};

export default ActivityLog;

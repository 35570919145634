import { DisableCreativeRotation, DISABLE_CREATIVE_ROTATION, EnableCreativeRotation, ENABLE_CREATIVE_ROTATION, GetActivityLogs, GetAgreement, GetBrandData, GetClientPDF, GetCreativeAI, GetCreativeRotationToggle, GetFormData, GetGoogleOnBoarding, GetOnBoarding, GetPlatformAdaccounts, GetTargetingAI, GET_ACTIVITY_LOGS, GET_AGREEMENT, GET_BRAND_DATA, GET_CLIENT_PDF, GET_CREATIVE_AI, GET_CREATIVE_ROTATION_TOGGLE, GET_FORMS, GET_GOOGLE_ONBOARDING, GET_ONBOARDING, GET_PLATFORM_ADACCOUNTS, GET_TARGETING_AI, SetCreativeAI, SetCreativeAILoading, SetCreativeRotationToggle, SetFormLoading, SetForms, SetFormsLoading, SetGoogleOnboarding, SetGoogleOnBoardingLoading, SetIsOnboarding, SetModal, SetOnBoardingLoading, SetPdfFile, SetTargetingAI, SetTargetingAILoading, SetTargetingForm, SET_CREATIVEAI_LOADING, SET_CREATIVE_AI, SET_CREATIVE_ROTATION_TOGGLE, SET_FORMS, SET_FORMS_LOADING, SET_FORM_LOADING, SET_GOOGLE_ONBOARDING, SET_GOOGLE_ONBOARDING_LOADING, SET_MODAL, SET_ONBOARDING, SET_ONBOARDING_LOADING, SET_PDF_FILE, SET_TARGETING_AI, SET_TARGETING_FORM, SET_TARGETING_LOADING, StoreFormData, STORE_FORM, TargetingForm, UpdateCreativeAI, UpdateFormData, UpdateGoogleOnBoarding, UpdateOnBoarding, UpdateTargetingAI, UpdateUserDetails, UPDATE_CREATIVE_AI, UPDATE_FORMS, UPDATE_GOOGLE_ONBOARDING, UPDATE_ONBOARDING, UPDATE_TARGETING_AI, UPDATE_USER_DETAILS } from "./../types/form";

export const setForms = (payload: any): SetForms => ({
    type: SET_FORMS,
    payload,
});

export const setFormsLoading = (payload: boolean): SetFormsLoading => ({
    type: SET_FORMS_LOADING,
    payload,
});

export const getFormData = (
    payload: { elementId: any, user: any },
    callback: Function
): GetFormData => ({
    type: GET_FORMS,
    payload,
    callback,
});

export const storeFormData = (
    payload: { user: any, params: any, elementId?: any },
    callback: Function
): StoreFormData => ({
    type: STORE_FORM,
    payload,
    callback,
});

export const updateFormData = (
    payload: { formId: string, elementId: string, user: any, params: any },
    callback: Function
): UpdateFormData => ({
    type: UPDATE_FORMS,
    payload,
    callback,
});

export const getOnBoarding = (
    payload: { brandId: string, accountId: string, user: any },
    callback: Function
): GetOnBoarding => ({
    type: GET_ONBOARDING,
    payload,
    callback,
});

export const getGoogleOnBoarding = (
    payload: { brandId: string, user: any },
    callback: Function
): GetGoogleOnBoarding => ({
    type: GET_GOOGLE_ONBOARDING,
    payload,
    callback,
});

export const updateOnBoarding = (
    payload: { brandId: string, accountId: string, user: any, enable: boolean },
    callback: Function
): UpdateOnBoarding => ({
    type: UPDATE_ONBOARDING,
    payload,
    callback,
});

export const updateGoogleOnBoarding = (
    payload: { brandId: string, user: any, enable: boolean },
    callback: Function
): UpdateGoogleOnBoarding => ({
    type: UPDATE_GOOGLE_ONBOARDING,
    payload,
    callback,
});

export const setTargetingForm = (payload: TargetingForm): SetTargetingForm => ({
    type: SET_TARGETING_FORM,
    payload,
});

export const setIsOnboarding = (payload: boolean): SetIsOnboarding => ({
    type: SET_ONBOARDING,
    payload,
});

export const getTargetingAI = (
    payload: { brandId: string, user: any },
    callback: Function
): GetTargetingAI => ({
    type: GET_TARGETING_AI,
    payload,
    callback,
});

export const getCreativeAI = (
    payload: { brandId: string, user: any },
    callback: Function
): GetCreativeAI => ({
    type: GET_CREATIVE_AI,
    payload,
    callback,
});

export const updateTargetingAI = (
    payload: { brandId: string, user: any, enable: boolean },
    callback: Function
): UpdateTargetingAI => ({
    type: UPDATE_TARGETING_AI,
    payload,
    callback,
});

export const updateCreativeAI = (
    payload: { brandId: string, user: any, enable: boolean },
    callback: Function
): UpdateCreativeAI => ({
    type: UPDATE_CREATIVE_AI,
    payload,
    callback,
});

export const getBrandData = (
    payload: { brandId:string, user: any},
    callback: Function
): GetBrandData => ({
    type: GET_BRAND_DATA,
    payload,
    callback
});

export const getPlatformAdaccounts = (
    payload: { adaccountId:string, platformId:string, user: any},
    callback: Function
): GetPlatformAdaccounts => ({
    type: GET_PLATFORM_ADACCOUNTS,
    payload,
    callback
});

export const enableCreativeRotation = (
    payload: { elementId:string, user: any},
    callback: Function
): EnableCreativeRotation => ({
    type: ENABLE_CREATIVE_ROTATION,
    payload,
    callback
})

export const disableCreativeRotation = (
    payload: { elementId:string, user: any},
    callback: Function
): DisableCreativeRotation => ({
    type: DISABLE_CREATIVE_ROTATION,
    payload,
    callback
})

export const getCreativeRotationToggle = (
    payload: { elementId:string, user: any},
    callback: Function
): GetCreativeRotationToggle => ({
    type: GET_CREATIVE_ROTATION_TOGGLE,
    payload,
    callback
})

export const getActivityLogs = (
    payload: { elementId:any, original_ad_account_id:any,start_date:any,end_date:any,download:boolean,page:Number,page_size:Number,user:any},
    callback: Function
): GetActivityLogs => ({
    type: GET_ACTIVITY_LOGS,
    payload,
    callback
})

export const getClientPDF = (
    payload: { brandId: string, user: any, userType: any },
    callback: Function
): GetClientPDF => ({
    type: GET_CLIENT_PDF,
    payload,
    callback,
});

export const getAgreement = (
    payload: { brandId: any, user: any },
    callback: Function
): GetAgreement => ({
    type: GET_AGREEMENT,
    payload,
    callback,
});

export const updateUserDetails = (
    payload: { brandId: string, user: any, param: any },
    callback: Function
): UpdateUserDetails => ({
    type: UPDATE_USER_DETAILS,
    payload,
    callback,
});

export const setPdfFile = (payload: any): SetPdfFile => ({
    type: SET_PDF_FILE,
    payload,
});

export const setCreativeRotationToggle = (payload: any): SetCreativeRotationToggle => ({
    type: SET_CREATIVE_ROTATION_TOGGLE,
    payload,
});

export const setTargetingAI = (payload: boolean): SetTargetingAI => ({
    type: SET_TARGETING_AI,
    payload,
});

export const setCreativeAI = (payload: boolean): SetCreativeAI => ({
    type: SET_CREATIVE_AI,
    payload,
});

export const setGoogleOnboarding = (payload: boolean): SetGoogleOnboarding => ({
    type: SET_GOOGLE_ONBOARDING,
    payload,
});

export const setModal = (payload: any): SetModal => ({
    type: SET_MODAL,
    payload,
});

export const setFormLoading = (payload: boolean): SetFormLoading => ({
    type: SET_FORM_LOADING,
    payload,
});

export const setTargetingAILoading = (payload: boolean): SetTargetingAILoading => ({
    type: SET_TARGETING_LOADING,
    payload,
});

export const setCreativeAILoading = (payload: boolean): SetCreativeAILoading => ({
    type: SET_CREATIVEAI_LOADING,
    payload,
});

export const setOnBoardingLoading = (payload: boolean): SetOnBoardingLoading => ({
    type: SET_ONBOARDING_LOADING,
    payload,
});

export const setGoogleOnBoardingLoading = (payload: boolean): SetGoogleOnBoardingLoading => ({
    type: SET_GOOGLE_ONBOARDING_LOADING,
    payload,
});
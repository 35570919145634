import Select from "@mui/material/Select";
import { styled as MUIStyled } from "@mui/material/styles";
import styled from "styled-components";
import { SelectProps } from "./Select";

export const StyledSelect = MUIStyled(Select)`
border: ${(props: SelectProps) =>
  props.isBorder ? `1px solid ${props.borderColor ?? "#E5E5E5"}` : "white"};
  background: ${(props: SelectProps) =>
    props.backgroundColor ?? "rgba(242, 242, 242, 0.5)"};
  border-radius: 8px;
  height: ${(props: SelectProps) => props.height ?? "43px"};
.MuiSelect-standard {
  border-radius: 8px;
  padding: 12px 16px;
}
`;

export const StyledSelectContainer = styled.div`
  margin-bottom: 20px;
  height: 43;
`;

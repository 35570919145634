import { Box, CircularProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChangedFieldList } from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { getBusinessUintById } from "../../../../../../store/actions/CreativeAI/Facebook/Main/Main";
import { SelectFormControl, InputFormControl } from "../../../../../shared";
import GenerationTypeComponent from "../../../shared/GenerationTypeComponent/GenerationTypeComponent";
import { APIGenerationType } from "../../../shared/SharedTypes";
import { filterVariantData } from "../../../utils/filterLevers.utils";
import { getBUFormValues, getFormValues } from "../../../utils/form.utils";

interface ConfigureFieldsProps {
  control: any;
  getValues: any;
  setValue: any;
  reset: any;
  watch: any;
  errors: any;
  setError: any;
  clearErrors: any;
}

export default function ConfigureFields({
  control,
  getValues,
  setValue,
  reset,
  watch,
  errors,
  setError,
  clearErrors,
}: ConfigureFieldsProps) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const {
    LEVERS,
    CREATIVE_ELEMENT_AI,
    BUSINESS_UNIT_List,
    BUSINESS_UNIT_BY_ID_DATA,
    adAdSetFieldList,
  } = useSelector((state: any) => ({
    LEVERS: state.MainReducer.defaultLevers,
    CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi.data,
    BUSINESS_UNIT_List: state.MainReducer.AllBusinessUnitList,
    BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
    adAdSetFieldList: state.MainReducer.adAdSetFieldList,
  }));
  const data: APIGenerationType[] = useMemo(
    () => filterVariantData(LEVERS, ["user", "ad", "adset", "campaign"]),
    [LEVERS]
  );
  const { configureMetaData, changedFieldList } = useSelector(
    (state: { createVariantReducer: any }) => state.createVariantReducer
  );
  const businessUnitID = watch("businessUnitId");

  useEffect(() => {
    if (CREATIVE_ELEMENT_AI && BUSINESS_UNIT_List) {
      const data =
        configureMetaData ?? getFormValues(CREATIVE_ELEMENT_AI, true);
      reset(data);
      watch();
      setShow(true);
    }
  }, [CREATIVE_ELEMENT_AI, BUSINESS_UNIT_List]);

  const businessUnits = useMemo(
    () =>
      BUSINESS_UNIT_List.map((bu: any) => ({
        id: bu.id,
        name: bu.businessUnit,
      })),
    [BUSINESS_UNIT_List]
  );

  useEffect(() => {
    if (businessUnitID) {
      setShow(false);
      dispatch(
        getBusinessUintById({
          businessUnitId: businessUnitID,
        })
      );
    }
  }, [businessUnitID]);

  useEffect(() => {
    if (BUSINESS_UNIT_BY_ID_DATA) {
      const data =
        configureMetaData ?? getFormValues(CREATIVE_ELEMENT_AI, true);
      if (
        data?.businessUnitId !== businessUnitID &&
        businessUnitID !== undefined
      ) {
        reset(getBUFormValues(BUSINESS_UNIT_BY_ID_DATA, false, true));
        for (let item in adAdSetFieldList) {
          setValue(item, adAdSetFieldList[item]);
        }
      } else {
        reset(data);
      }
      watch();
      setShow(true);
    }
  }, [BUSINESS_UNIT_BY_ID_DATA, CREATIVE_ELEMENT_AI]);

  const configureHelperFunction = (registeredName: string) => {
    if (!changedFieldList.includes(registeredName)) {
      dispatch(setChangedFieldList([...changedFieldList, registeredName]));
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      alignItems="start"
      display="flex"
      flexDirection="column"
      className="drawer-panel"
    >
      {show ? (
        <>
          <Box display="flex" width="100%">
            <Box width="50%" pr="12px">
              <SelectFormControl
                options={businessUnits}
                field="name"
                valueKey="id"
                tooltipText="Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit."
                placeholder="Select Business Unit"
                required
                fullWidth
                control={control}
                registeredName="businessUnitId"
                label="Business Unit"
                height="40px"
                sx={{
                  fontSize: "12px",
                }}
                helperFunction={configureHelperFunction}
              />
            </Box>
            <Box width="50%" pl="12px">
              <InputFormControl
                label="Display Brand Name"
                tooltipText="Display brand name refers to how you want the brand name to be displayed in the ad copy."
                disableUnderline
                control={control}
                registeredName="brandName"
                required
                fullWidth
                placeholder="Enter Business Unit"
                sx={{
                  height: "40px",
                  fontSize: "12px",
                }}
                helperFunction={configureHelperFunction}
              />
            </Box>
          </Box>
          <Box width="50%" pt="10px">
            <InputFormControl
              label="Interest Keyword"
              tooltipText="Interest keyword refers to the crux of the ad generations. It indicates what the ad should deliver based on the product/service category and what the ad generations should be based on."
              disableUnderline
              required
              fullWidth
              control={control}
              registeredName="interestKeyword"
              placeholder="Enter Interest Keyword"
              sx={{
                height: "40px",
                fontSize: "12px",
              }}
              helperFunction={configureHelperFunction}
            />
          </Box>
          {data.map((item) => (
            <GenerationTypeComponent
              item={item}
              key={item.generation_type}
              control={control}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              helperFunction={configureHelperFunction}
            />
          ))}
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="80%"
        >
          <CircularProgress thickness={5} />
        </Box>
      )}
    </Box>
  );
}

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { StyledSelect, StyledSelectContainer } from "./Select.styled";
import { SelectProps as MUISelectProps } from "@mui/material/Select";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledErrorMessage } from "../../Typography/Heading/styled";

export interface SelectProps extends MUISelectProps {
  label?: string;
  backgroundColor?: string;
  isBorder?: boolean;
  borderColor?: string;
  tooltipText?: string;
  options?: Array<string | number>;
  height?: string;
}
//refactoring needed

export function Select({
  label,
  required,
  tooltipText,
  value,
  options,
  placeholder,
  error,
  ...props
}: SelectProps) {
  return (
    <StyledSelectContainer>
      {label && (
        <InputLabel
          required={required}
          label={label}
          tooltipText={tooltipText}
        />
      )}
      <StyledSelect
        value={value}
        variant="standard"
        disableUnderline
        displayEmpty
        renderValue={(value: any) => {
          if (!value) {
            return <em>{placeholder}</em>;
          }
          return value;
        }}
        {...props}
      >
        {options &&
          options.map((element) => (
            <MenuItem value={element}>{element}</MenuItem>
          ))}
      </StyledSelect>
      {error && (
        <StyledErrorMessage>This field is required *</StyledErrorMessage>
      )}
    </StyledSelectContainer>
  );
}

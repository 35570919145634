//*******DO NOT REFER THIS FILE FOR SAGA IMPLEMENTATION*******//

import {
    FETCH_ACCOUNT_DETAILS,
    FETCH_ALLOW_REJECT_RECOMMENDATION, FETCH_LEARNING_PHASE, FETCH_PHASE,
    REFRESH_TOKEN,
    Session,
    SET_ACCOUNT_DETAILS, SET_ACTIVE_PHASE, SET_ALLOW_REJECT_RECOMMENDATION, SET_IS_REFRESH_TOKEN_EXPIRED, SET_LEARNING_PHASE,
    SET_PHASE,
    SET_SESSION
} from "../types/auth";

export const getPhase = () => ({
    type: FETCH_PHASE
})

export const getLearningPhase = () => ({
    type: FETCH_LEARNING_PHASE
})

export const setAccountDetails = (payload: any) => ({
    type: SET_ACCOUNT_DETAILS,
    payload
})

export const setPhase = (payload: any) => ({
    type: SET_PHASE,
    payload
})

export const setActivePhase = (payload: number) => ({
    type: SET_ACTIVE_PHASE,
    payload
})

export const setLearningPhase = (payload: any) => ({
    type: SET_LEARNING_PHASE,
    payload
})

export const setAllowRejectRecommendation = (payload: boolean) => ({
    type: SET_ALLOW_REJECT_RECOMMENDATION,
    payload
})

export const getAllowRejectRecommendation = () => ({
    type: FETCH_ALLOW_REJECT_RECOMMENDATION
})

export const getAccountDetails = (payload?: string)=>({
    type: FETCH_ACCOUNT_DETAILS,
    payload
})

export const setSession = (payload: Session | null) => ({
    type: SET_SESSION,
    payload
})

export const setIsRefreshTokenExpired = (payload: boolean) => ({
    type: SET_IS_REFRESH_TOKEN_EXPIRED,
    payload
})

export const refreshToken = (callback?: (session?: Session) => void) => ({
    type: REFRESH_TOKEN,
    callback
})
import styled from "styled-components";

export const StyledFooterDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 64px;
  padding: 12px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 100%; */
  .footer-btn {
    border-radius: 12px;
    font-weight: 600;
    /* need to find another way instead of putting !important */
    font-size: 16px !important;
    line-height: 24px;
  }
`;

export const StyledRightElement = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMiddleElement = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledPaginatedText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
`;

export const StyledTextVariant1 = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-right: 28px;
  color: #333333;
`;

import React from "react";
import "./index.scss";

const InputLabel: React.FC<{
  limit?: number;
  label?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  inputClassName?: string;
  labelClassName?: string;
  validationClassName?: string;
  onInputChange: (e: any) => void;
}> = ({
  value,
  name,
  placeholder,
  inputClassName,
  labelClassName,
  validationClassName,
  onInputChange,
  limit,
  label,
}) => {
  return (
    <div className="input-label-container">
      <input
        type="text"
        name={name}
        value={value}
        className={`input-label--input ${inputClassName}`}
        onChange={(e) => {
          onInputChange(e);
        }}
        maxLength={limit}
        placeholder={placeholder}
      />
      <div className="flex">
        {label && (<span className={`input-label--label ${labelClassName}`}>{label}</span>)}
        <span
          className={`input-label--validation ${
            limit === value?.length ? "limit-reached" : ""
          } ${validationClassName}`}
        >
          {limit && (value?.length === undefined ? 0 : value?.length) + `/`+ limit}
        </span>
      </div>
    </div>
  );
};

export default InputLabel;

import { cloneDeep } from "lodash";
import {
  AddUpdateVariantLoadingState,
  AddVarianButton,
  AddVariant,
  ADD_UPDATE_VARIANT_LOADING_STATE,
  ADD_VARIANT,
  ADD_VARIANT_BUTTON,
  CopyVariant,
  COPY_VARIANT,
  COPY_VARIANT_STATE,
  CreateVariantsState,
  DeleteVariantAPICall,
  DeleteVariantStateUpdate,
  DELETE_VARIANT_API_CALL,
  DELETE_VARIANT_STATE_UPDATE,
  GenerateVariantMatadata,
  GENERATE_VARIANT_METADATA,
  DiscardDraftVariant,
  DiscardDraftVariantState,
  DISCARD_DRAFT_VARIANT,
  DISCARD_DRAFT_VARIANT_STATE,
  GetAdsListValues,
  GET_ADS_LIST,
  PublishVariantState,
  PUBLISH_VARIANT_STATE,
  Is_Show_SaveVariant_Prompt,
  ResetState,
  RESET_STATE,
  SetAddVariantResponse,
  SetAdPreviewLink,
  SetAds,
  SetCreateSpec,
  SetRecommendationMetadata,
  SetSelectedAds,
  SetVariantList,
  SET_ADD_VARIANT_RESPONSE,
  SET_ADS,
  SET_AD_PREVIEW_LINK,
  SET_CREATE_SPEC,
  SET_RECOMMENDATION_LOADING,
  SET_RECOMMENDATION_METADATA,
  SET_VARIANT_LIST,
  SET__SELECTED_ADS,
  UpdateAds,
  UpdateCreateSpec,
  UpdateExtraField,
  UpdateRecommendationMetadata,
  UpdateRecommendationSelectionState,
  UpdateTotalVariantSelected,
  UpdateVariant,
  UpdateVariantState,
  UPDATE_ADS,
  UPDATE_CREATE_SPEC,
  UPDATE_EXTRA_FIELD,
  UPDATE_RECOMMENDATION_METADATA,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
  UPDATE_TOTAL_VARIANT_SELECTED,
  UPDATE_VARIANT,
  UPDATE_VARIANT_STATE,
  GENERATE_VARIANT_METADATA_STATE,
  IS_CONFIGURING_STATE,
  UPDATE_AI_GROUP,
  UPDATE_AI_GROUP_STATE,
  SET_UPDATE_AI_GROUP_PROMPT,
  GET_VARIANT_LIST,
  GET_CREATE_SPEC,
  GENERATE_VARIANT_METADATA_STATE_VARIANT,
  SET_RECOMMENDATION_METADATA_ERROR,
  SET_TIMESTAMP,
  UPDATE_CONFIGURE_STATE,
  GENERATE_AD_PREVIEW,
  GENERATE_AD_PREVIEW_VARIANT,
  GENERATE_AD_PREVIEW_VARIANT_STATE,
  SET_CHANGED_FIELD_LIST,
  GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE,
} from "../../../../types/CreativeAI/Facebook/CreateVariant/createVariant.types";

export const initialStateCreateVariant: CreateVariantsState | any = {
  adsList: [],
  variantList: [],
  recommendationMetaData: {},
  recommendationMetaDataTemplate: [],
  addVariantResponse: undefined,
  createSpec: undefined,
  ads: [],
  selectedAd: undefined,
  adPreviewLink: undefined,
  isUpdatingVariant: false,
  isDeletingVariant: false,
  isDiscardingDraft: false,
  fetchingAds: false,
  isUpdateAds: false,
  loadingRecommendation: true,
  isGeneratingPreview: false,
  extraFields: {
    isVariantEditing: undefined,
    selectedVariantIndex: undefined,
    showEditor: undefined,
  },
  isConfiguringMetaData: false,
  isGeneratingMetaData: false,
  addUpdateVariantLoading: false,
  totalSelectedVariant: [],
  isPublishingVariant: false,
  isUpdatingAIgroup: false,
  generateAdPreviewVariantLoading: false,
  recommendationSelectionState: {
    primary_text: "",
    headline: "",
    description: "",
    adPreviewLink: "",
    variantName: "",
    isChanged: false,
    isConfigureChanged: false,
  },
  generateMetaData: {},
  isShowSaveVariantPrompt: false,
  showUpdateAISuccessMsg: false,
  fetchingVariants: false,
  fetchingCreateSpec: false,
  executionIdVariant: undefined,
  generateRecommendationError: false,
  generateRecommendationTimestamp: undefined,
  configureMetaData: undefined,
  changedFieldList: [],
};

const createVarianReducer = (
  state = initialStateCreateVariant,
  action:
    | GetAdsListValues
    | SetVariantList
    | SetRecommendationMetadata
    | UpdateRecommendationMetadata
    | SetAddVariantResponse
    | SetCreateSpec
    | SetAds
    | SetSelectedAds
    | SetAdPreviewLink
    | DeleteVariantStateUpdate
    | UpdateExtraField
    | AddUpdateVariantLoadingState
    | UpdateRecommendationSelectionState
    | UpdateVariantState
    | CopyVariant
    | UpdateAds
    | UpdateTotalVariantSelected
    | PublishVariantState
    | GenerateVariantMatadata
    | ResetState
    | UpdateCreateSpec
    | UpdateVariant
    | DeleteVariantAPICall
    | AddVariant
    | DiscardDraftVariant
    | DiscardDraftVariantState
    | AddVarianButton
    | any
) => {
  switch (action.type) {
    case GET_ADS_LIST: {
      return {
        ...state,
        fetchingAds: true,
      };
    }
    case GET_VARIANT_LIST: {
      return {
        ...state,
        fetchingVariants: true,
      };
    }

    case SET_VARIANT_LIST: {
      return {
        ...state,
        variantList: action.payload.data,
        fetchingVariants: false,
      };
    }

    case SET_TIMESTAMP: {
      return {
        ...state,
        generateRecommendationTimestamp: +new Date(),
        generateRecommendationError: false,
      };
    }
    case SET_RECOMMENDATION_METADATA_ERROR: {
      return {
        ...state,
        generateRecommendationError: action.payload,
      };
    }

    case RESET_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_VARIANT: {
      return {
        ...state,
        isUpdatingVariant: true,
      };
    }

    case GENERATE_AD_PREVIEW: {
      return {
        ...state,
        isGeneratingPreview: true,
      };
    }
    case GENERATE_AD_PREVIEW_VARIANT_LOADING_STATE: {
      return {
        ...state,
        isGeneratingPreview: action.payload,
      };
    }

    case ADD_VARIANT: {
      return {
        ...state,
        isUpdatingVariant: true,
      };
    }

    case SET_RECOMMENDATION_LOADING: {
      return {
        ...state,
        loadingRecommendation: action.payload,
      };
    }
    case COPY_VARIANT: {
      return {
        ...state,
        addUpdateVariantLoading: true,
      };
    }

    case UPDATE_AI_GROUP: {
      return {
        ...state,
        isUpdatingAIgroup: true,
      };
    }

    case UPDATE_AI_GROUP_STATE: {
      return {
        ...state,
        isUpdatingAIgroup: false,
        showUpdateAISuccessMsg: action.payload,
      };
    }

    case SET_UPDATE_AI_GROUP_PROMPT: {
      return {
        ...state,
        showUpdateAISuccessMsg: action.payload,
      };
    }

    case SET_RECOMMENDATION_METADATA: {
      return {
        ...state,
        recommendationMetaDataTemplate: action.payload.data,
      };
    }
    case UPDATE_RECOMMENDATION_METADATA: {
      return {
        ...state,
        recommendationMetaData: action.payload.data,
        configureMetaData: action.payload?.configureMetaData,
        loadingRecommendation: false,
      };
    }
    case DISCARD_DRAFT_VARIANT: {
      return {
        ...state,
        isDiscardingDraft: true,
      };
    }

    case DISCARD_DRAFT_VARIANT_STATE: {
      return {
        ...state,
        isDiscardingDraft: action.payload.data,
      };
    }

    case UPDATE_CONFIGURE_STATE: {
      return {
        ...state,
        configureMetaData: action.payload,
      };
    }

    case SET_ADD_VARIANT_RESPONSE: {
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === state.selectedAd.id
      );
      ads[index].draftVariants = ads[index].draftVariants + 1;
      const variantList = state.variantList.filter(
        (element: any) => element.id !== null
      );
      return {
        ...state,
        variantList: [...variantList, action.payload],
        isUpdatingVariant: false,
        ads,
        recommendationSelectionState: {
          ...state.recommendationSelectionState,
          isChanged: false,
          isConfigureChanged: false,
        },
      };
    }
    case GET_CREATE_SPEC: {
      return {
        ...state,
        fetchingCreateSpec: true,
      };
    }
    case SET_CREATE_SPEC: {
      let createSpec = action.payload;
      if (createSpec?.object_story_spec?.video_data) {
        let video_data = createSpec?.object_story_spec?.video_data;
        delete video_data?.image_hash;
        createSpec = {
          ...createSpec,
          object_story_spec: {
            ...createSpec.object_story_spec,
            video_data,
          },
        };
      }
      return {
        ...state,
        // createSpec: action.payload?.adcreatives?.data[0],
        createSpec: createSpec,
        fetchingCreateSpec: false,
      };
    }
    case ADD_VARIANT_BUTTON: {
      let selectedVariantIndex = state.extraFields.selectedVariantIndex;
      selectedVariantIndex = state.variantList.length;
      return {
        ...state,
        variantList: [...state.variantList, { id: null }],
        // executionIdVariant: state.generateMetaData?.executionId,
        extraFields: {
          ...state.extraFields,
          selectedVariantIndex,
          showEditor: true,
        },
      };
    }
    case SET_ADS: {
      return {
        ...state,
        ads: action.payload,
        selectedAd: cloneDeep(action.payload[0]),
        fetchingAds: false,
      };
    }
    case UPDATE_ADS: {
      return {
        ...state,
        ads: action.payload,
      };
    }
    case SET__SELECTED_ADS: {
      return {
        ...state,
        selectedAd: cloneDeep(action.payload),
      };
    }
    case SET_AD_PREVIEW_LINK: {
      return {
        ...state,
        adPreviewLink: action.payload,
      };
    }
    case DELETE_VARIANT_STATE_UPDATE: {
      let initState = {};
      let totalSelectedVariant = state.totalSelectedVariant;
      if (totalSelectedVariant.length) {
        totalSelectedVariant = totalSelectedVariant.filter(
          (item: any) => item !== action.payload.id
        );
      }
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === state.selectedAd.id
      );
      ads[index].draftVariants = ads[index].draftVariants - 1;
      const newVariantList = state.variantList.filter(
        (element: any) => element.id !== action.payload.id
      );
      let selectedVariantIndex = state?.extraFields?.selectedVariantIndex;
      let showEditor = state?.extraFields?.showEditor;
      if (
        state?.extraFields?.selectedVariantIndex !== undefined &&
        newVariantList.length === state?.extraFields?.selectedVariantIndex
      ) {
        selectedVariantIndex = newVariantList.length
          ? state.extraFields.selectedVariantIndex - 1
          : undefined;
      }
      showEditor = state?.extraFields?.showEditor && !!newVariantList.length;
      if (!showEditor) {
        initState = {
          loadingRecommendation: true,
          isConfiguringMetaData: false,
          extraFields: {
            isVariantEditing: undefined,
            selectedVariantIndex: undefined,
            showEditor: undefined,
          },
          generateRecommendationError: false,
          generateRecommendationTimestamp: undefined,
          configureMetaData: undefined,
          recommendationMetaData: {},
          recommendationSelectionState: {
            primary_text: "",
            headline: "",
            description: "",
            adPreviewLink: "",
            variantName: "",
            isChanged: false,
            isConfigureChanged: false,
          },
        };
      }

      return {
        ...state,
        variantList: newVariantList,
        isDeletingVariant: false,
        totalSelectedVariant,
        recommendationMetaData:
          selectedVariantIndex !== undefined
            ? newVariantList[selectedVariantIndex].payload
                ?.recommendationMetaData
            : undefined,
        isConfiguringMetaData: showEditor ? state.isConfiguringMetaData : false,
        extraFields: {
          ...state?.extraFields,
          selectedVariantIndex,
          showEditor,
        },
        ads,
        ...initState,
      };
    }
    case UPDATE_EXTRA_FIELD: {
      return {
        ...state,
        extraFields: action.payload,
      };
    }
    case DELETE_VARIANT_API_CALL: {
      return {
        ...state,
        isDeletingVariant: true,
      };
    }
    case ADD_UPDATE_VARIANT_LOADING_STATE: {
      return {
        ...state,
        addUpdateVariantLoading: action.payload,
      };
    }
    case UPDATE_RECOMMENDATION_SELECTION_STATE: {
      return {
        ...state,
        recommendationSelectionState: {
          ...state.recommendationSelectionState,
          ...action.payload,
        },
      };
    }
    case COPY_VARIANT_STATE: {
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === state.selectedAd.id
      );
      ads[index].draftVariants = ads[index].draftVariants + 1;
      const variantList = [...state.variantList, action.payload];
      let selectedVariantIndex = variantList.length - 1;

      return {
        ...state,
        variantList,
        addUpdateVariantLoading: false,
        ads,
        extraFields: {
          ...state.extraFields,
          selectedVariantIndex,
          isVariantEditing: false,
        },
      };
    }
    case UPDATE_VARIANT_STATE: {
      const variantList = [...state.variantList];
      variantList[action.payload.index] = action.payload.updatedValues;
      return {
        ...state,
        variantList,
        isConfiguringMetaData: false,
        isUpdatingVariant: false,
        recommendationSelectionState: {
          ...state.recommendationSelectionState,
          isChanged: false,
          isConfigureChanged: false,
        },
      };
    }

    case UPDATE_TOTAL_VARIANT_SELECTED: {
      const id = action.payload;
      let totalSelectedVariant = [...state.totalSelectedVariant];
      if (totalSelectedVariant.includes(id)) {
        totalSelectedVariant = totalSelectedVariant.filter(
          (element: string) => element !== id
        );
      } else {
        totalSelectedVariant.push(id);
      }
      return {
        ...state,
        totalSelectedVariant,
      };
    }
    case PUBLISH_VARIANT_STATE: {
      return {
        ...state,
        isPublishingVariant: action.payload,
        totalSelectedVariant: [],
      };
    }
    case UPDATE_CREATE_SPEC: {
      return {
        ...state,
        createSpec: action.payload,
      };
    }
    case GENERATE_VARIANT_METADATA: {
      return {
        ...state,
        isGeneratingMetaData: true,
      };
    }
    case GENERATE_VARIANT_METADATA_STATE: {
      let ads = state.ads;
      const index = ads.findIndex(
        (item: any) => item.id === state.selectedAd.id
      );

      ads[index] = { ...ads[index], executionId: action.payload?.executionId };
      return {
        ...state,
        generateMetaData: action.payload,
        isGeneratingMetaData: false,
        executionIdVariant: action.payload?.executionId,
        ads,
      };
    }

    case GENERATE_VARIANT_METADATA_STATE_VARIANT: {
      return {
        ...state,
        isGeneratingMetaData: false,
        executionIdVariant: action.payload?.executionId,
      };
    }
    case IS_CONFIGURING_STATE: {
      return {
        ...state,
        isConfiguringMetaData: action.payload,
      };
    }
    case Is_Show_SaveVariant_Prompt: {
      return {
        ...state,
        isShowSaveVariantPrompt: action.payload,
      };
    }

    case GENERATE_AD_PREVIEW_VARIANT: {
      return {
        ...state,
        generateAdPreviewVariantLoading: true,
      };
    }

    case GENERATE_AD_PREVIEW_VARIANT_STATE: {
      return {
        ...state,
        generateAdPreviewVariantLoading: false,
      };
    }
    case SET_CHANGED_FIELD_LIST: {
      return {
        ...state,
        changedFieldList: action.payload,
      };
    }

    default:
      return state;
  }
};
export default createVarianReducer;

import React, { useEffect, useState } from "react";
import TextArea from "../../../../../shared/Input/TextArea/TextArea";
import { StyledMainContainer } from "./styled";
import { ReactComponent as Save } from "../../../../../../assets/svg/save.svg";
import { ReactComponent as Edit } from "../../../../../../assets/svg/edit_.svg";
import { IconButton, Radio } from "@mui/material";
import { CreateVariantsState } from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { useSelector } from "react-redux";

interface EditableTextRadioProps {
  onSave?: (index: number, value: string) => void;
  onEdit?: () => void;
  defaultValue?: string;
  isSelected?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  value?: string;
  radioValue?: string | number;
  hash: string;
  index: number;
}

export default function EditableTextRadio({
  onEdit,
  onSave,
  defaultValue,
  isSelected,
  value,
  radioValue,
  hash,
  index,
}: EditableTextRadioProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputState, setInputState] = useState("");
  const { recommendationMetaData } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );

  const onChange = (event: any) => {
    setInputState(event.target.value);
  };

  useEffect(() => {
    setInputState(recommendationMetaData[hash].data[index]);
  }, [recommendationMetaData]);

  return (
    <StyledMainContainer id={hash + index}>
      <Radio
        size="small"
        className="mr-1"
        value={radioValue}
        checked={isSelected}
      />
      <TextArea
        defaultValue={defaultValue}
        value={inputState}
        onChange={onChange}
        readOnly={!isEditing}
        isBorder={isEditing || isSelected}
        disableUnderline
        backgroundColor={
          isEditing ? "white" : isSelected ? "#FBFCFF" : undefined
        }
        borderColor={isEditing ? "#E5E5E5" : isSelected ? "#0869FB" : undefined}
        sx={{ fontSize: "14px", fontWeight: 400, fontFamily: "Inter" }}
      />
      <IconButton
        color="primary"
        aria-label="save"
        className="ml-2"
        onClick={() => {
          if (isEditing) {
            setIsEditing(false);
            onSave && onSave(index, inputState);
          } else {
            setIsEditing(true);
            onEdit && onEdit();
          }
        }}
      >
        {isEditing ? <Save /> : <Edit />}
      </IconButton>
    </StyledMainContainer>
  );
}

import { combineReducers } from "redux";
import common from "./common";
import actionImpact from "./actionImpact";
import campaign from "./campaign";
import form from "./form";
import cluster from "./cluster";
import auth from "./auth";
import adaccount from "./adaccount";
import creativeAi from "./creativeAi";
import montageAds from "./montageAds";
import aiGroupReducer from "./CreativeAI/Facebook/AiGroupListing/aiGroupReducer";
import businessUnitsReducer from "./CreativeAI/Facebook/BusinessUnits/businessUnitsReducer";
import activityLogsReducer from "./CreativeAI/Facebook/ActivityLogs/activityLogsReducer";
import createVariantReducer from "./CreativeAI/Facebook/CreateVariant/createVariantReducer";
import selectCohortReducer from "./CreativeAI/Facebook/SelectCohot/selectCohortReducer";
import MainReducer from "./CreativeAI/Facebook/Main/MainReducer";
import userInfoReducer from "./CreativeAI/Facebook/UserInfo/userInfoReducer";

const rootReducer = combineReducers({
  common,
  adaccount,
  form,
  cluster,
  actionImpact,
  campaign,
  auth,
  creativeAi,
  montageAds,
  selectCohortReducer,
  createVariantReducer,
  aiGroupReducer,
  businessUnitsReducer,
  activityLogsReducer,
  MainReducer,
  userInfoReducer
});

export default rootReducer;

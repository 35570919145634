import { LinkClicks } from './actionImpact';
export const SET_SPINNER = 'SET_SPINNER';
export const SET_SETTING_POST_SPINNER = 'SET_SETTING_POST_SPINNER';
export const SET_CAMPAIGNS_DETAILS = 'SET_CAMPAIGNS_DETAILS';
export const SET_CAMPAIGNS_FACTORS = 'SET_CAMPAIGNS_FACTORS';
export const SET_METRIC_LIST = 'SET_METRIC_LIST';
export const SET_METRIC_CUMULATIVE = 'SET_METRIC_CUMULATIVE';
export const SET_MODEL_CONFIDENCE_SCORE = 'SET_MODEL_CONFIDENCE_SCORE';
export const SET_RECOMMENDATION = 'SET_RECOMMENDATION';
export const SET_CHART_TABS = 'SET_CHART_TABS';
export const SET_CHART_DATA = 'SET_CHART_DATA';
export const SET_CHART_OPTION = 'SET_CHART_OPTION';
export const SET_SELECTED_OPTION = 'SET_SELECTED_OPTION';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_CHART_CORDS_DATA = 'SET_CHART_CORDS_DATA';
export const SET_PHASE_TIMELINE = 'SET_PHASE_TIMELINE';

export const GET_CAMPAIGNS_DETAILS = 'GET_CAMPAIGNS_DETAILS';
export const GET_PHASE_TIMELINE = 'GET_PHASE_TIMELINE';
export const GET_CAMPAIGNS_FACTORS = 'GET_CAMPAIGNS_FACTORS';
export const GET_METRICS_LIST = 'GET_METRICS_LIST';
export const POST_CAMPAIGN_FACTORS = 'POST_CAMPAIGN_FACTORS';
export const DELETE_CAMPAIGN_FACTORS = 'DELETE_CAMPAIGN_FACTORS';
export const GET_MODEL_CONFIDENCE_SCORE = 'GET_MODEL_CONFIDENCE_SCORE';
export const GET_GROUP_METRICS = 'GET_GROUP_METRICS';
export const GET_CAMPAIGN_METRICS = 'GET_CAMPAIGN_METRICS';
export const GET_METRICS_CUMULATIVE = 'GET_METRICS_CUMULATIVE';
export const UPDATE_METRIC = 'UPDATE_METRIC';
export const GET_RECOMMENDATION = 'GET_RECOMMENDATION';
export const REJECT_RECOMMENDATION = 'REJECT_RECOMMENDATION';
export const GET_STORY = 'GET_STORY';
export const POST_STORY = 'POST_STORY';
export const PUT_STORY = 'PUT_STORY';
export const GET_ALL_PLATFORM_CUMMULATIVE_DATA = 'GET_ALL_PLATFORM_CUMMULATIVE_DATA';
export const GET_ALL_PLATFORM_METRICS_DATA = 'GET_ALL_PLATFORM_METRICS_DATA';
export const GET_OPTIMIZATION_EVENT="GET_OPTIMIZATION_EVENT"

export type CampaignState = {
    spinner: boolean,
    settingPostspinner: boolean,
    campaignsDetails: CampaignDetail[],
    campaignsFactors: CampaignFactors,
    metricList: Metrics[],
    metricCumulative: any,
    modelConfidenceScore: ModelConfidenceScore[],
    recommendation: any,
    chartTabs: Metrics[],
    chartData: ChartData,
    chartOption: any[],
    selectedOption: any,
    rejectRecommendation: any,
    success: boolean,
    chartCordsData: ChartData,
    phaseTimeline: PhaseTimelines,
}

export type CampaignDetail = {
    id: string,
    name: string
}

export type CampaignFactors = {
    metrics: CampaignMetrics[],
    weight_distribuition: {
        roi: null,
        quality: null
    }
}

export type CampaignMetrics = {
    id?: string,
    name: string,
    type: string,
    weight: string
}

export type Metrics = {
    attribution_window: string,
    created_at: string,
    filter: string,
    group_id: string,
    id: string,
    inverse: boolean,
    isTaiMetric: boolean,
    key: null,
    label: string,
    metric_type: null,
    showCurrency: boolean,
    sub_filter: string,
    taiMetricType: string,
    uiVisible: boolean,
    updated_at: string,
    isSelected: boolean
}

export type ModelConfidenceScore = {
    created_at: string,
    group_id: string,
    id: string,
    name: string,
    percentage: string,
    updated_at: string,
}

export type ChartData = {
    labels: string[],
    datasets: any,
}

export type PhaseTimelines = {
    phase1?: PhaseTimeLine[] | [],
    phase2?: PhaseTimeLine[] | [],
    phase3?: PhaseTimeLine[] | [],
    phase4?: PhaseTimeLine[] | [],
    phase5?: PhaseTimeLine[] | [],
}

export type PhaseTimeLine = {
    adaccount_id: string,
    created_at: string,
    end_date: null | string,
    id: string,
    name: string,
    phaseName: string,
    start_date: string,
    status: boolean,
    updated_at: string,
}

// State
export type SetSpinner = {
    type: typeof SET_SPINNER;
    payload: boolean;
}

export type SetSettingPostspinner = {
    type: typeof SET_SETTING_POST_SPINNER;
    payload: boolean;
}

export type SetCampaignsDetails = {
    type: typeof SET_CAMPAIGNS_DETAILS;
    payload: CampaignDetail[];
}

export type SetCampaignsFactors = {
    type: typeof SET_CAMPAIGNS_FACTORS;
    payload: CampaignFactors;
}

export type SetMetricList = {
    type: typeof SET_METRIC_LIST;
    payload: Metrics[];
}

export type SetMetricCumulative = {
    type: typeof SET_METRIC_CUMULATIVE;
    payload: any;
}

export type SetModelConfidenceScore = {
    type: typeof SET_MODEL_CONFIDENCE_SCORE;
    payload: ModelConfidenceScore[];
}

export type SetRecommendation = {
    type: typeof SET_RECOMMENDATION;
    payload: any;
}

export type SetChartTabs = {
    type: typeof SET_CHART_TABS;
    payload: Metrics[];
}

export type SetChartData = {
    type: typeof SET_CHART_DATA;
    payload: ChartData;
}

export type SetChartOption = {
    type: typeof SET_CHART_OPTION;
    payload: any[];
}

export type SetSelectedOption = {
    type: typeof SET_SELECTED_OPTION;
    payload: any;
}

export type SetSuccess = {
    type: typeof SET_SUCCESS;
    payload: boolean;
}

export type SetChartCordsData = {
    type: typeof SET_CHART_CORDS_DATA;
    payload: ChartData;
}

export type SetPhaseTimeline = {
    type: typeof SET_PHASE_TIMELINE;
    payload: PhaseTimelines;
}

// API
export type GetCampaignsDetails = {
    type: typeof GET_CAMPAIGNS_DETAILS;
    payload: {
        groupId: string;
        user: any;
    };
    callback?: Function;
};

export type GetPhaseTimeline = {
    type: typeof GET_PHASE_TIMELINE;
    payload: {
        brandId: string;
        user: any;
    };
    callback?: Function;
};

export type GetCampaignFactors = {
    type: typeof GET_CAMPAIGNS_FACTORS;
    payload: {
        elementId: string;
        user: any;
    };
    callback?: Function;
};

export type GetMetricsList = {
    type: typeof GET_METRICS_LIST;
    payload: {
        elementId: string;
        user: any;
    };
    callback?: Function;
};

export type PostCampaignFactors = {
    type: typeof POST_CAMPAIGN_FACTORS;
    payload: {
        elementId: string;
        user: any;
        payload: any;
    };
    callback?: Function;
};

export type DeleteCampaignFactors = {
    type: typeof DELETE_CAMPAIGN_FACTORS;
    payload: {
        factorId: string;
        user: any;
    };
    callback?: Function;
};

export type GetModelConfidenceScore = {
    type: typeof GET_MODEL_CONFIDENCE_SCORE;
    payload: {
        elementId: string;
        user: any;
    };
    callback?: Function;
};

export type GetGroupMetrics = {
    type: typeof GET_GROUP_METRICS;
    payload: {
        elementId: string;
        user: any;
    };
    callback?: Function;
};

export type GetCampaignMetrics = {
    type: typeof GET_CAMPAIGN_METRICS;
    payload: {
        elementId: string;
        dates: LinkClicks;
        user: any;
        selectedAdSet: any;
        selectedCampaign: any;
    };
    callback?: Function;
};

export type GetMetricsCumulative = {
    type: typeof GET_METRICS_CUMULATIVE;
    payload: {
        elementId: string,
        dates: LinkClicks,
        user: any,
        selectedAdSet: any,
        selectedCampaign: any
    };
    callback?: Function;
};

export type UpdateMetric = {
    type: typeof UPDATE_METRIC;
    payload: {
        metricId: number;
        user: any;
        payload: any;
    };
    callback?: Function;
};

export type GetRecommendation = {
    type: typeof GET_RECOMMENDATION;
    payload: {
        elementId: string;
        dates: LinkClicks;
        user: any;
        activeHeader: string
    };
    callback?: Function;
};

export type RejectRecommendation = {
    type: typeof REJECT_RECOMMENDATION;
    payload: {
        id: string;
        concept_type: "campaign" | "adset"
        user: any,
    };
    callback?: Function;
};

export type GetStoryData = {
    type: typeof GET_STORY;
    payload: {
        elementId: string;
        user: any;
    };
    callback?: Function;
};

export type PostStoryData = {
    type: typeof POST_STORY;
    payload: {
        elementId: string;
        user: any;
        payload: any
    };
    callback?: Function;
};
export type PutStoryData = {
    type: typeof PUT_STORY;
    payload: {
        elementId: string;
        user: any;
        payload: any
    };
    callback?: Function;
};
export type GetAllPlatformCummulativeData = {
    type: typeof GET_ALL_PLATFORM_CUMMULATIVE_DATA;
    payload: {
        groupId: string;
        dates: LinkClicks,
        user: any;
    };
    callback?: Function;
};

export type GetAllPlatformMetricsData = {
    type: typeof GET_ALL_PLATFORM_METRICS_DATA;
    payload: {
        groupId: string;
        dates: LinkClicks,
        user: any;
    };
    callback?: Function;
};


export type GetOptimizationEventDetails = {
    type: typeof GET_OPTIMIZATION_EVENT;
    payload: {
        adAccountId: string;
        platformId: any;
        user: any;
    };
    callback?: Function;
};

export const googleAIGroupMetrics = [
    {
      name: "engagements",
      value: "gdn_primary_engagements",
    },
    {
      name: "video_views",
      value: "gdn_primary_video_views",
    },
    {
      name: "video_impressions",
      value: "gdn_primary_video_impressions",
    },
    {
      name: "cost_micros",
      value: "gdn_calculated_story_3597_spends",
    },
    {
      name: "impressions",
      value: "gdn_primary_impressions",
    },
    {
      name: "clicks",
      value: "gdn_primary_clicks",
    },
    {
      name: "cost_micros",
      value: "gdn_primary_cost",
    },
    {
      name: "conversions",
      value: "gdn_primary_conversions",
    },
    {
      name: "ctr",
      value: "gdn_primary_ctr",
    },
    {
      name: "average_cpc",
      value: "gdn_primary_cpc",
    },
    {
      name: "average_cpm",
      value: "gdn_primary_cpm",
    },
    {
      name: "roas_perc",
      value: "gdn_primary_return_on_ad_spend_(roas)",
    },
    {
      name: "mcf:totalConversionValue",
      value: "gdn_primary_total_conversion_value",
    },
    {
      name: "all_conversions",
      value: "gdn_primary_all_conversions",
    },
    {
      name: "cost_per_conversion",
      value: "gdn_primary_cost_per_conversion",
    },
    {
      name: "interactions",
      value: "gdn_primary_interactions",
    },
  ];
  
  export const fbAIGroupMetrics = [
    {
      "name": "Mobile App Subscribe (IM Purchase)",
      "value": "fb_primary_actions_app_custom_event_fb_mobile_subscribe"
    },
    {
      "name": "sell-car/form-2",
      "value": "fb_primary_actions_offsite_conversion_custom_178122467490376"
    },
    {
      "name": "On-facebook Lead",
      "value": "fb_primary_actions_onsite_conversion_lead_grouped"
    },
    {
      "name": "Instamart First Order",
      "value": "fb_primary_app_custom_event_instamart_first_order"
    },
    {
      "name": "MBA 2022 Paid",
      "value": "fb_primary_actions_offsite_conversion_custom_1354645305006955"
    },
    {
      "name": "FB_LP_Conversion",
      "value": "fb_primary_actions_offsite_conversion_custom_1868695869960621"
    },
    {
      "name": "Book Online",
      "value": "fb_primary_actions_offsite_conversion_custom_811738646383743"
    },
    {
      "name": "Estimated Ad Recall Lift",
      "value": "fb_primary_estimated_ad_recallers"
    },
    {
      "name": "Unique adds to cart",
      "value": "fb_primary_unique_actions_offsite_conversion_fb_pixel_add_to_cart"
    },
    {
      "name": "Mobile App Purchase",
      "value": "fb_primary_actions_app_custom_event_fb_mobile_purchase"
    },
    {
      "name": "testing QA",
      "value": "TESTING QA"
    },
    {
      "name": "Website Leads",
      "value": "fb_primary_actions_offsite_conversion_fb_pixel_lead"
    },
    {
      "name": "Install Now",
      "value": "fb_primary_actions_offsite_conversion_custom_1501611433557964"
    },
    {
      "name": "Mobile App Feature Unlock",
      "value": "fb_primary_actions_app_custom_event_fb_mobile_achievement_unlocked"
    },
    {
      "name": "Mobile App Content Views",
      "value": "fb_primary_app_custom_event_fb_mobile_content_view"
    },
    {
      "name": "Website purchases conversion value (Revenue)",
      "value": "fb_primary_action_values_offsite_conversion_fb_pixel_purchase"
    },
    {
      "name": "VSS_demo_booking",
      "value": "fb_primary_actions_offsite_conversion_custom_923412375127792"
    },
    {
      "name": "Initiated Checkouts",
      "value": "fb_primary_omni_initiated_checkout"
    },
    {
      "name": "Lead Open Account",
      "value": "fb_primary_offsite_conversion_fb_pixel_custom_LeadOpenAccount"
    },
    {
      "name": "FIN_EQMS_Subscribe",
      "value": "fb_primary_actions_offsite_conversion_custom_308167220657998"
    },
    {
      "name": "FlipkartButton",
      "value": "conversions.offsite_conversion.fb_pixel_custom.FlipkartButton"
    },
    {
      "name": "RegSuccess",
      "value": "fb_primary_actions_offsite_conversion_custom_641125736595647"
    },
    {
      "name": "ROAS",
      "value": "fb_derived_roas"
    },
    {
      "name": "Purchases(Mobile and Website)",
      "value": "fb_primary_actions_omni_purchase"
    },
    {
      "name": "all_reg",
      "value": "fb_primary_actions_offsite_conversion_custom_1559748320843780"
    },
    {
      "name": "Landing Page Views",
      "value": "fb_primary_actions_landing_page_view"
    },
    {
      "name": "GP LM Hotels Custom Purchase RM",
      "value": "fb_primary_actions_offsite_conversion_custom_238117361243902"
    },
    {
      "name": "Website View Content",
      "value": "fb_primary_actions_offsite_conversion_fb_pixel_view_content"
    },
    {
      "name": "Website schedule",
      "value": "fb_primary_actions_offsite_conversion_fb_pixel_custom_website_schedule"
    },
    {
      "name": "SB Initiated",
      "value": "fb_primary_actions_offsite_conversion_custom_612362812457036"
    },
    {
      "name": "AmazonButton",
      "value": "conversions.offsite_conversion.fb_pixel_custom.AmazonButton"
    },
    {
      "name": "Mobile App Add to Cart",
      "value": "fb_primary_actions_app_custom_event_fb_mobile_add_to_cart"
    },
    {
      "name": "Paid Sign Up",
      "value": "fb_primary_actions_offsite_conversion_custom_2605289753087332"
    },
    {
      "name": "Mobile App Installs",
      "value": "fb_primary_actions_mobile_app_install"
    },
    {
      "name": "Post Engagement",
      "value": "fb_primary_actions_post_engagement"
    },
    {
      "name": "Link Clicks",
      "value": "fb_primary_actions_link_click"
    },
    {
      "name": "Conversions Website Donate",
      "value": "fb_primary_conversions_donate_website"
    },
    {
      "name": "Eligible",
      "value": "fb_primary_offsite_conversion_fb_pixel_custom_eligible_lead"
    },
    {
      "name": "Thank You Page",
      "value": "fb_primary_actions_offsite_conversion_custom_207913951221940"
    },
    {
      "name": "Lead",
      "value": "fb_primary_actions_lead"
    },
    {
      "name": "Registration Completed",
      "value": "fb_primary_actions_omni_complete_registration"
    },
    {
      "name": "test for creating metrics",
      "value": "fb_global_configure_story_link"
    },
    {
      "name": "Website completed registration",
      "value": "fb_primary_actions_offsite_conversion_fb_pixel_complete_registration"
    },
    {
      "name": "Reach",
      "value": "fb_primary_reach"
    },
    {
      "name": "Thruplays",
      "value": "fb_primary_video_thruplay_watched_actions"
    },
    {
      "name": "Website Purchase",
      "value": "fb_primary_actions_offsite_conversion_fb_pixel_purchase"
    },
    {
      "name": "Page Likes",
      "value": "fb_primary_actions_like"
    },
    {
      "name": "CGC Lead 2022",
      "value": "fb_primary_actions_offsite_conversion_custom_634131251025540"
    },
    {
      "name": "Mobile App Payment Details",
      "value": "fb_primary_actions_app_custom_event_fb_mobile_add_payment_info"
    },
    {
      "name": "Mobile App Registrations",
      "value": "fb_primary_actions_app_custom_event_fb_mobile_complete_registration"
    },
    {
      "name": "Mobile App Tutorial Completion",
      "value": "fb_primary_actions_app_custom_event_fb_mobile_tutorial_completion"
    },
    {
      "name": "Alopecia Thank you",
      "value": "fb_primary_actions_offsite_conversion_custom_2251342018345774"
    },
    {
      "name": "Website Donate",
      "value": "fb_primary_actions_offsite_conversion_custom_425274205075833"
    },
    {
      "name": "Aiquire Custom Conversion 2",
      "value": "fb_primary_actions_offsite_conversion_custom_2479027085686019"
    },
    {
      "name": "App Installs",
      "value": "fb_primary_actions_omni_app_install"
    },
    {
      "name": "Add to Cart",
      "value": "fb_primary_actions_offsite_conversion_fb_pixel_add_to_cart"
    },
    {
      "name": "Impressions",
      "value": "fb_primary_impressions"
    },
    {
      "name": "Adds to Wishlist",
      "value": "fb_primary_actions_app_custom_event_fb_mobile_add_to_wishlist"
    },
    {
      "name": "testing",
      "value": "testing"
    },
    {
      "name": "Payment Successful",
      "value": "fb_primary_actions_offsite_conversion_custom_879636732749784"
    },
    {
      "name": "post_engagement",
      "value": "fb_primary_actions_post_engagement"
    },
    {
      "name": "reach",
      "value": "fb_primary_reach"
    },
    {
      "name": "impressions",
      "value": "fb_primary_impressions"
    },
    {
      "name": "spend",
      "value": "fb_primary_spend"
    },
    {
      "name": "clicks",
      "value": "fb_primary_actions_link_click"
    },
    {
      "name": "frequency",
      "value": "fb_derived_frequency"
    }
  ];
  
  export const linkedinGroupMetrics = [
    {
      "value": "li_primary_actions_action_clicks",
      "name": "Action Clicks"
    },
    {
      "value": "li_primary_actions_ad_unit_clicks",
      "name": "Ad Unit Clicks"
    },
    {
      "value": "li_primary_actions_approximate_unique_impressions",
      "name": "Approximate Unique Impressions"
    },
    {
      "value": "li_primary_actions_card_clicks",
      "name": "Card Clicks"
    },
    {
      "value": "li_primary_actions_card_impressions",
      "name": "Card Impressions"
    },
    {
      "value": "li_primary_actions_clicks",
      "name": "Clicks"
    },
    {
      "value": "li_primary_actions_comment_likes",
      "name": "Comment Likes"
    },
    {
      "value": "li_primary_actions_comments",
      "name": "Comments"
    },
    {
      "value": "li_primary_actions_company_page_clicks",
      "name": "Company Page Clicks"
    },
    {
      "value": "li_primary_actions_external_website_conversions",
      "name": "External Website Conversions "
    },
    {
      "value": "li_primary_actions_external_website_post_click_conversions",
      "name": "External Website Post Click Conversions "
    },
    {
      "value": "li_primary_actions_external_website_post_view_conversions",
      "name": "External Website Post View Conversions "
    },
    {
      "value": "li_primary_actions_follows",
      "name": "Follows "
    },
    {
      "value": "li_primary_actions_full_screen_plays",
      "name": "Full Screen Plays "
    },
    {
      "value": "li_primary_actions_impressions",
      "name": "Impressions "
    },
    {
      "value": "li_primary_actions_landing_page_clicks",
      "name": "Landing Page Clicks "
    },
    {
      "value": "li_primary_actions_lead_generation_mail_contact_info_shares",
      "name": "Lead Generation Mail Contact Info Shares "
    },
    {
      "value": "li_primary_actions_lead_generation_mail_interested_clicks",
      "name": "Lead Generation Mail Interested Clicks "
    },
    {
      "value": "li_primary_actions_likes",
      "name": "Likes "
    },
    {
      "value": "li_primary_actions_one_click_lead_form_opens",
      "name": "One Click Lead Form Opens "
    },
    {
      "value": "li_primary_actions_one_click_leads",
      "name": "One Click Leads "
    },
    {
      "value": "li_primary_actions_opens",
      "name": "Opens "
    },
    {
      "value": "li_primary_actions_other_engagements",
      "name": "Other Engagements "
    },
    {
      "value": "li_primary_actions_reactions",
      "name": "Reactions "
    },
    {
      "value": "li_primary_actions_sends",
      "name": "Sends "
    },
    {
      "value": "li_primary_actions_shares",
      "name": "Shares "
    },
    {
      "value": "li_primary_actions_text_url_clicks",
      "name": "Text Url Clicks "
    },
    {
      "value": "li_primary_actions_total_engagements",
      "name": "Total Engagements "
    },
    {
      "value": "li_primary_actions_video_completions",
      "name": "Video Completions "
    },
    {
      "value": "li_primary_actions_video_first_quartile_completions",
      "name": "Video First Quartile Completions "
    },
    {
      "value": "li_primary_actions_video_midpoint_completions",
      "name": "Video Midpoint Completions "
    },
    {
      "value": "li_primary_actions_video_starts",
      "name": "Video Starts "
    },
    {
      "value": "li_primary_actions_video_third_quartile_completions",
      "name": "Video Third Quartile Completions "
    },
    {
      "value": "li_primary_actions_video_views",
      "name": "Video Views "
    },
    {
      "value": "li_primary_actions_viral_card_clicks",
      "name": "Viral Card Clicks "
    },
    {
      "value": "li_primary_actions_viral_card_impressions",
      "name": "Viral Card Impressions "
    },
    {
      "value": "li_primary_actions_viral_clicks",
      "name": "Viral Clicks "
    },
    {
      "value": "li_primary_actions_viral_comment_likes",
      "name": "Viral Comment Likes "
    },
    {
      "value": "li_primary_actions_viral_comments",
      "name": "Viral Comments "
    },
    {
      "value": "li_primary_actions_viral_company_page_clicks",
      "name": "Viral Company Page Clicks "
    },
    {
      "value": "li_primary_actions_viral_external_website_conversions",
      "name": "Viral External Website Conversions "
    },
    {
      "value": "li_primary_actions_viral_external_website_post_click_conversions",
      "name": "Viral External Website Post Click Conversions "
    },
    {
      "value": "li_primary_actions_viral_external_website_post_view_conversions",
      "name": "Viral External Website Post View Conversions "
    },
    {
      "value": "li_primary_actions_viral_follows",
      "name": "Viral Follows "
    },
    {
      "value": "li_primary_actions_viral_full_screen_plays",
      "name": "Viral Full Screen Plays "
    },
    {
      "value": "li_primary_actions_viral_impressions",
      "name": "Viral Impressions "
    },
    {
      "value": "li_primary_actions_viral_landing_page_clicks",
      "name": "Viral Landing Page Clicks "
    },
    {
      "value": "li_primary_actions_viral_likes",
      "name": "Viral Likes "
    },
    {
      "value": "li_primary_actions_viral_one_click_lead_form_opens",
      "name": "Viral One Click Lead Form Opens "
    },
    {
      "value": "li_primary_actions_viral_one_click_leads",
      "name": "Viral One Click Leads "
    },
    {
      "value": "li_primary_actions_viral_other_engagements",
      "name": "Viral Other Engagements "
    },
    {
      "value": "li_primary_actions_viral_reactions",
      "name": "Viral Reactions "
    },
    {
      "value": "li_primary_actions_viral_shares",
      "name": "Viral Shares "
    },
    {
      "value": "li_primary_actions_viral_total_engagements",
      "name": "Viral Total Engagements "
    },
    {
      "value": "li_primary_actions_viral_video_completions",
      "name": "Viral Video Completions "
    },
    {
      "value": "li_primary_actions_viral_video_first_quartile_completions",
      "name": "Viral Video First Quartile Completions "
    },
    {
      "value": "li_primary_actions_viral_video_midpoint_completions",
      "name": "Viral Video Midpoint Completions "
    },
    {
      "value": "li_primary_actions_viral_video_starts",
      "name": "Viral Video Starts "
    },
    {
      "value": "li_primary_actions_viral_video_third_quartile_completions",
      "name": "Viral Video Third Quartile Completions "
    },
    {
      "value": "li_primary_actions_viral_video_views",
      "name": "Viral Video Views "
    }
  ];
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import {
  StyledActivityLogContainer,
  StyledAiGroupContainer,
  StyledCreateVariantContainer,
  StyledCreativeAIListItem,
  StyledCreativeRotationContainer,
  StyledRecommendationContainer,
  StyledSettingButton,
  StyledAiGroupName,
} from "./styled";
import ActivityLog from "../../../../../../assets/svg/ActivityLog.svg";
import SettingIcon from "../../../../../../assets/svg/Setting.svg";
import CreateVariantModal from "../../CreateVariantModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_AIGROUP_LIST } from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariantType";
import Loader from "../../../../../common/Loader";
import { initialState } from "../../../../../../store/types/auth";
import CreativeAIToggle from "../../../../../CreativeAIListing/CreativeAIToggle";
import { getCreativeRotationToggle } from "../../../../../../store/actions/form";
import { StyledOverFlow } from "../../../CreateVariants/styled";
import { textTruncateMore } from "../../../utils/text.utils";

const AiGroupListItem: React.FC<{ setShowActivityLog: any }> = ({
  setShowActivityLog,
}) => {
  const { CREATIVE_ELEMENT_AI } = useSelector((state: any) => ({
    CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
  }));

  const { data, loading } = useSelector(
    (state: any) => state.aiGroupReducer.aiGroups
  );
  const { selectedAiGroupObject } = useSelector(
    (state: any) => state.aiGroupReducer
  );
  const { selectedAdAccount } = useSelector(
    (state: any) => state.aiGroupReducer
  );

  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { adAccountId } = selectedAdAccount;
  const { platformId: platformID } = selectedAdAccount;

  const [showUnitForm, setShowUniteForm] = useState(false);
  const [selectedAiGroup, setSelectedAiGroup] = useState("");
  const [variantModalType, setVariantModalType] = useState("");
  const [statusLoading, setStatusLoading] = useState<boolean>(false);

  const [platformGroups, setPlatformGroups] = useState<any>([]);
  const [platformGroupsData, setPlatformGroupData] = useState<any>([]);
  const [modalBtnLoader, setModalBtnLoader] = useState<any>(false);
  const [currentCreateVarientID, setCurrentVariantID] = useState("");
  const [currentSettingID, setSettingID] = useState("");

  const handelToggleModal = (aiGroup: any, modalType: string, index: any) => {
    setModalBtnLoader(true);
    setCurrentVariantID(index);
    setSettingID(index);

    if (!showUnitForm) {
      dispatch({
        type: "GET_ELEMENT_CREATIVE_AI",
        payload: { elementId: aiGroup.elementId },
      });
    }
    setSelectedAiGroup(aiGroup.elementId);
    setVariantModalType(modalType);
    setShowUniteForm(!showUnitForm);
    dispatch({
      type: "SELECTED_AIGROUP_OBJECT",
      payload: aiGroup,
    });
    dispatch({
      type: "SET_SELECTED_AIGROUP",
      payload: aiGroup.elementId,
    });
  };

  const dispatch = useDispatch();

  const handleToggleActivityLogModal = (aiGroup: any) => {
    dispatch({
      type: "SET_SELECTED_AIGROUP",
      payload: aiGroup.elementId,
    });
    dispatch({
      type: "SELECTED_AIGROUP_OBJECT",
      payload: aiGroup,
    });
    setShowActivityLog(true);
  };

  useEffect(() => {
    dispatch({ type: "GENERATE_LEVERS" });
  }, []);

  useEffect(() => {
    if (adAccountId && platformID) {
      dispatch({
        type: GET_AIGROUP_LIST,
        payload: { adAccountId, platformID },
      });
    }
  }, [adAccountId, platformID]);

  useEffect(() => {
    setPlatformGroups(data);
  }, [data]);

  const [elementToggleValues, setElementToggleValues] = useState<any>({});

  useEffect(() => {
    let temp = selectedAiGroupObject;
    if (Object.keys(elementToggleValues).includes(temp.elementId)) {
      temp.toggle = elementToggleValues[temp.elementId];
    }
    dispatch({ type: "SELECTED_AIGROUP_OBJECT", payload: temp });
  }, [elementToggleValues, selectedAiGroupObject]);

  useEffect(() => {
    if (CREATIVE_ELEMENT_AI.data !== undefined) {
      setModalBtnLoader(false);
    }
  }, [showUnitForm, CREATIVE_ELEMENT_AI.data]);

  useEffect(() => {
    setStatusLoading(true);
    if (platformGroups?.length > 0) {
      Promise.all(
        platformGroups.map((obj: any) => {
          return new Promise((res) => {
            dispatch(
              getCreativeRotationToggle(
                { elementId: obj?.elementId, user: auth.user },
                (response: any, error: any) => {
                  if (!error) {
                    res({ ...obj, toggle: response.data });
                  } else {
                    res({ ...obj, toggle: false });
                  }
                }
              )
            );
          });
        })
      )
        .then((data: any) => {
          setPlatformGroupData(data);
          setStatusLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setPlatformGroupData(platformGroups);
          setStatusLoading(false);
        });
    }
  }, [platformGroups?.length, platformGroups]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {statusLoading ? (
        <Loader />
      ) : (
        <StyledOverFlow>
          {platformGroupsData.length === 0 && (
            <div className="text-center fs-3 text-secondary font-weight-bold">
              <p className="mb-0">No data found</p>
            </div>
          )}
          {platformGroupsData?.map((aiGroup: any, index: any) => (
            <StyledCreativeAIListItem key={index}>
              <StyledAiGroupContainer>
                <StyledAiGroupName
                  className="title"
                  reccomendations={aiGroup?.variantsRecommended}
                  title={aiGroup.name}
                >
                  {textTruncateMore(aiGroup.name, 16)}
                </StyledAiGroupName>
                <Box className="description">
                  {aiGroup.variantsRecommended
                    ? "Creative variation recommended"
                    : ""}
                </Box>
              </StyledAiGroupContainer>
              <StyledRecommendationContainer>
                <Box className="title">Recommendation</Box>
                <Box className="description">
                  Publish More Creative Variants In{" "}
                  {textTruncateMore(
                    `${
                      aiGroup.adsetsRecommendedForVariants
                        ? aiGroup.adsetsRecommendedForVariants
                        : 0
                    }/${aiGroup.totalAdsets} Ad Sets`,
                    50
                  )}
                </Box>
              </StyledRecommendationContainer>
              <StyledCreateVariantContainer>
                <Button
                  className="createVarientBtn"
                  variant="text"
                  size="medium"
                  onClick={() =>
                    handelToggleModal(aiGroup, "createVariant", index)
                  }
                >
                  {modalBtnLoader &&
                  variantModalType == "createVariant" &&
                  currentCreateVarientID === index ? (
                    <span>
                      <CircularProgress
                        style={{ color: "#0869fb" }}
                        size={15}
                      />
                    </span>
                  ) : (
                    "Create Variant"
                  )}
                </Button>
                <StyledSettingButton
                  onClick={() => handelToggleModal(aiGroup, "Setting", index)}
                >
                  {modalBtnLoader &&
                  variantModalType === "Setting" &&
                  currentSettingID === index ? (
                    <CircularProgress style={{ color: "#71747A" }} size={15} />
                  ) : (
                    <img src={SettingIcon} />
                  )}
                </StyledSettingButton>
              </StyledCreateVariantContainer>
              <StyledCreativeRotationContainer
                display="flex"
                flexDirection="column"
              >
                <Box className="title">Creative Rotation</Box>
                <Box className="settings" display="flex" flexDirection="row">
                  <CreativeAIToggle
                    elementToggleValues={elementToggleValues}
                    setElementToggleValues={setElementToggleValues}
                    data={aiGroup}
                  />
                  {/* <Box className="statusToggle">
                    <Box className="sub-title">Status</Box>
                    <CreativeAIToggle
                      elementToggleValues={elementToggleValues}
                      setElementToggleValues={setElementToggleValues}
                      data={aiGroup}
                    />
                    // <Switch
                    // onClick={() =>
                    //  handleStatus(aiGroup.elementId, aiGroup.isTerminated)
                    // }
                    defaultChecked={aiGroup.isTerminated}
                  />
                  </Box>
                  <Box
                    className="phase"
                    // display="flex"
                    flexDirection="column"
                    // textAlign="center"
                  >
                    <Box className="sub-title">Phase</Box>
                    <Box className="phaseDetail">
                      {aiGroup.phase ? aiGroup.phase : "-"}
                    </Box>
                  </Box> */}
                </Box>
              </StyledCreativeRotationContainer>
              <StyledActivityLogContainer>
                <IconButton
                  onClick={() => handleToggleActivityLogModal(aiGroup)}
                >
                  <img src={ActivityLog} />
                </IconButton>
              </StyledActivityLogContainer>
            </StyledCreativeAIListItem>
          ))}

          {showUnitForm && (
            <CreateVariantModal
              onHide={() => handelToggleModal("", "", null)}
              selectedAiGroup={selectedAiGroup}
              variantModalType={variantModalType}
              setShowUniteForm={setShowUniteForm}
            />
          )}
        </StyledOverFlow>
      )}
    </>
  );
};

export default AiGroupListItem;

import {
  SET_SETTING_POST_SPINNER, SET_CAMPAIGNS_DETAILS, SET_CAMPAIGNS_FACTORS, SET_METRIC_LIST,
  SET_METRIC_CUMULATIVE, SET_MODEL_CONFIDENCE_SCORE, SET_RECOMMENDATION, SET_CHART_TABS, SET_CHART_DATA,
  SET_CHART_OPTION, SET_SELECTED_OPTION, SET_SUCCESS, SET_CHART_CORDS_DATA, SET_PHASE_TIMELINE,
  SetRecommendation, SetCampaignsDetails, SetCampaignsFactors, SetChartCordsData, SetChartData, SetChartOption,
  SetChartTabs, SetMetricCumulative, SetMetricList, SetModelConfidenceScore, SetPhaseTimeline, SetSelectedOption,
  SetSettingPostspinner, SetSpinner, SetSuccess, SET_SPINNER
} from './../types/campaign';

const initialState = {
  spinner: false,
  settingPostspinner: false,
  campaignsDetails: [],
  campaignsFactors: {},
  metricList: [],
  metricCumulative: [],
  modelConfidenceScore: [],
  recommendation: null,
  chartTabs: [],
  chartData: {},
  chartOption: [],
  selectedOption: null,
  success: false,
  chartCordsData: {},
  phaseTimeline: {},
};

const campaignReducer = (state = initialState, action: SetSpinner | SetSettingPostspinner | SetCampaignsDetails |
  SetCampaignsFactors | SetMetricList | SetMetricCumulative | SetModelConfidenceScore | SetRecommendation |
  SetChartTabs | SetChartData | SetChartOption | SetSelectedOption | SetSuccess | SetChartCordsData | SetPhaseTimeline
) => {
  switch (action.type) {
    case SET_SPINNER: { return { ...state, spinner: action.payload } }
    case SET_SETTING_POST_SPINNER: { return { ...state, settingPostspinner: action.payload } }
    case SET_CAMPAIGNS_DETAILS: { return { ...state, campaignsDetails: action.payload } }
    case SET_CAMPAIGNS_FACTORS: { return { ...state, campaignsFactors: action.payload } }
    case SET_METRIC_LIST: { return { ...state, metricList: action.payload } }
    case SET_METRIC_CUMULATIVE: { return { ...state, metricCumulative: action.payload } }
    case SET_MODEL_CONFIDENCE_SCORE: { return { ...state, modelConfidenceScore: action.payload } }
    case SET_RECOMMENDATION: { return { ...state, recommendation: action.payload } }
    case SET_CHART_TABS: { return { ...state, chartTabs: action.payload } }
    case SET_CHART_DATA: { return { ...state, chartData: action.payload } }
    case SET_CHART_OPTION: { return { ...state, chartOption: action.payload } }
    case SET_SELECTED_OPTION: { return { ...state, selectedOption: action.payload } }
    case SET_SUCCESS: { return { ...state, success: action.payload } }
    case SET_CHART_CORDS_DATA: { return { ...state, chartCordsData: action.payload } }
    case SET_PHASE_TIMELINE: { return { ...state, phaseTimeline: action.payload } }

    default: return state;
  }
};

export default campaignReducer;
import React from "react";
import { Box } from "@mui/material";
import CustomizedDialogs from "./CustomizedDialogs";
import { DialogTitle } from "./styled";
import { Button } from "../Button/Button";

interface AlertDialogProps {
  show: boolean;
  onClose?: () => void;
  onComplete?: () => void;
  title?: string;
  confirmText?: string;
}

export function AlertDialog({
  show,
  onClose,
  onComplete,
  title,
  confirmText,
}: AlertDialogProps) {
  return (
    <CustomizedDialogs
      show={show}
      handleClose={onClose}
      type="ConfirmDialog"
      fullScreen={false}
      maxWidth="sm"
    >
      <Box
        width="350px"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <DialogTitle className="mb-0">{title}</DialogTitle>
        <Box
          display="flex"
          justifyContent="center"
          mt="20px"
          alignItems="center"
        >
          <Button
            buttonVariant="contained"
            className="button-base-mui"
            onClick={onClose}
            style={{
              width: "70px",
            }}
          >
            {confirmText ?? "Ok"}
          </Button>
        </Box>
      </Box>
    </CustomizedDialogs>
  );
}

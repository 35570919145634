import { InputProps } from "@mui/material";
import { TextInput } from "../Input/TextInput";

interface TextAreaProps extends InputProps {
  isBorder?: boolean;
  backgroundColor?: string;
  borderColor?: string;
}

export default function TextArea({ isBorder, ...props }: TextAreaProps) {
  return (
    <TextInput multiline minRows={3} fullWidth {...props} isBorder={isBorder} />
  );
}

import InputText from "../../ui/InputText/InputText";

const RadioInputGuideline: React.FC<{handleDo: (value: any,key:string) => void; handleChange: (value: any) => void;guideline:any;emptyGuideline:any;guidelines?:any;}> = ({
    handleDo,
    handleChange,
    guideline,
    emptyGuideline,
    guidelines
  }) => {

    return(
        <>
        <div className="modal-add-guidelines">
                        <input className="radio-input"
                          type="radio"
                          id="do"
                          name="DO"
                          value="DO"
                          onChange={()=>handleDo(true,"shouldDo")}
                          defaultChecked={guideline.shouldDo}
                          
                        />
                          <label htmlFor="DO"> Do's</label> {" "}
                        <input className="radio-input"
                          type="radio"
                          id="dont"
                          name="DO"
                          value="DONT"
                          onChange={(e) => handleDo(false,"shouldDo")}
                          defaultChecked={!guideline.shouldDo}

                        />
                          <label htmlFor="DONT">Don't</label>
                        <br />
                        <label>Guideline*</label>
                        <InputText className="add"
                        name="add"
                        value={guideline?.text}
                        onChange={(e) =>{handleChange(e)}}
                        required={true}
                        maxlength="999" />
                        <span>
                          {emptyGuideline && (
                            <small className="guideline-error">*This field is required</small>
                          )}
                        </span>
                      </div></>
    )
  }
  export default RadioInputGuideline
import React, {useEffect, useRef, useState} from "react";
import InputText from "../../../ui/InputText/InputText";
import info from '../../../assets/svg/smallinfo.svg';
import Select from "../../../ui/Select/Select";
import { fbAIGroupMetrics, googleAIGroupMetrics, linkedinGroupMetrics } from "../../../utils/constants/metrics";  
import { getCurrencySymbol, getPlatformName } from "../../../utils/commonFunctions";
import { getOptimizationEventDetails } from "../../../store/actions/campaign";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../../store/types/auth";


const AIGroupSettingForm: React.FC<{
    data:any; 
    handleChanage: (key:string,value:any,elementId:string) => void;
    adAccountId: string;
    elementId:string;
    metricList?: any;
    platform:string,
    currency: any;
    platformId: any
}> = ({data,handleChanage,adAccountId, elementId,metricList,platform,currency,platformId}) => {

    const dispatch = useDispatch();

    const authState = useSelector((state: { auth:initialState }) => state.auth);

    const { user } = authState;

    const target = useRef(null);
    const [optimisationEventList, setOptimisationEventList] = useState([]);
    const [optimizationEventOption,setOptimizationEventOption]=useState([]);

    
    const options = React.useMemo(
        () => {
        const platformName = getPlatformName(platform);
        if (platformName === "Google") return googleAIGroupMetrics;
        if (platformName === "Facebook") return fbAIGroupMetrics;
        return linkedinGroupMetrics
        }, [platform]);

    const defaultDisableOption = React.useMemo(
        () => {
        const platformName = getPlatformName(platform);
        if (platformName === "Google") return googleAIGroupMetrics.find((m:any)=>m.value === data?.result_metric_key)?.name ;
        if (platformName === "Facebook") return fbAIGroupMetrics.find((m:any)=>m.value === data?.result_metric_key)?.name;
        return linkedinGroupMetrics.find((m:any)=>m.value === data?.result_metric_key)?.name
    }, [platform, data?.result_metric_key]);

    useEffect(() => {
        if (metricList && metricList.length > 0) {
            metricList.map((list:any,index:number) => {
                list.name = list.label;
                list.value = list.label;
            });
            setOptimisationEventList(metricList);
        }
    }, [metricList]);

    const uptoDecimal = (value:number) => {
        return (Math.round(value * 100) / 100);
    }

    useEffect(() => {
        dispatch(  getOptimizationEventDetails(
            { adAccountId: adAccountId,platformId:platformId,user},
            (response: any, error: boolean) => {
              if (!error) {
                  const temp:any=[];
                  response.data.map((item:any)=>{
                      temp.push({name:item.metricName,value:item.metricName})
                  })               
                setOptimizationEventOption(temp)
              } else {
                console.log(error);
              }
            }
          ))
    },[adAccountId,platformId ])

    return (<>
        <div className="d-flex px-3 pt-3">
            <div key={`min_budget`} className="form-group pr-3">
            <label className="text-md">
                Minimum Budget *
                <div className="tooltip-container">
                    <i ref={target}>
                    <img className="ml-2" src={info} alt="info_style" />
                    </i>
                    <div className="custom-tooltip-layout tooltip">
                        <div className="tootltip-wrapper">
                            <div className="tooltip-header">
                            Minimum Budget
                            </div>
                            <span> Minimum Budget</span>
                        </div>
                    </div>
                </div>
            </label>
            <div className="currency-input">
                <InputText  className="currency-symbol" name="currency" value={getCurrencySymbol(currency[elementId])} disable={true}></InputText>
                <InputText name={`min_budget`} value={data ?  data?.cross_budget_min : 0} onChange={(e) => handleChanage("cross_budget_min",e.target.value,elementId)}/>
            </div>
            
            </div>
            
            <div key={`max_budget`} className="form-group">
            <label className="text-md">
                Maximum Budget (Optional)
                <div className="tooltip-container">
                    <i ref={target}>
                    <img className="ml-2" src={info} alt="info_style" />
                    </i>
                    <div className="custom-tooltip-layout tooltip">
                        <div className="tootltip-wrapper">
                            <div className="tooltip-header">
                            Maximum Budget
                            </div>
                            <span> Maximum Budget</span>
                        </div>
                    </div>
                </div>
            </label>
            <div className="currency-input">
                <InputText  className="currency-symbol" name="currency" value={getCurrencySymbol(currency[elementId])} disable={true}></InputText>
                <InputText name={`max_budget`} step={"any"} pattern={"^\d*(\.\d{0,2})?$"} value={data && data?.cross_budget_max} onChange={(e) => handleChanage("cross_budget_max",uptoDecimal(e.target.value),elementId)} type={`number`}/>
            </div>
            
            </div>
        </div>
        <>
        <div key={`optimisationEvents`} className="form-group px-3 w-25">
            <label className="text-md">
                Optimisation Events
                <div className="tooltip-container">
                    <i ref={target}>
                    <img className="ml-2" src={info} alt="info_style" />
                    </i>
                    <div className="custom-tooltip-layout tooltip">
                        <div className="tootltip-wrapper">
                            <div className="tooltip-header">
                            Optimisation Events
                            </div>
                            <span> Optimisation Events</span>
                        </div>
                    </div>
                </div>
            </label>
            
        <Select name="optimisationEvents"
        options={options}  
        defaultDisableOption={data && defaultDisableOption}
        onChange={(e) => handleChanage("result_metric_key", e.target.value,elementId)} />
        </div>
        
        <div key={`target_cpr`} className="form-group px-3 w-25">
            
            <label className="text-md">
                Target Cost Per Optimisation Event
                <div className="tooltip-container">
                    <i ref={target}>
                    <img className="ml-2" src={info} alt="info_style" />
                    </i>
                    <div className="custom-tooltip-layout tooltip">
                        <div className="tootltip-wrapper">
                            <div className="tooltip-header">
                            Target Cost Per Optimisation Event
                            </div>
                            <span> Target Cost Per Optimisation Event</span>
                        </div>
                    </div>
                </div>
            </label>
             <div className="currency-input">
                <InputText  className="currency-symbol" name="currency" value={getCurrencySymbol(currency[elementId])} disable={true}></InputText>
                <InputText name={`target_cpr`} value={data && data.target_cpr} onChange={(e) => handleChanage("target_cpr",e.target.value,elementId)}/>
            </div>
         </div>
        <div key={`target_result`} className="form-group px-3 w-25">
            <label className="text-md">
                Target  Daily Results (Optimisation Event)
                <div className="tooltip-container">
                    <i ref={target}>
                    <img className="ml-2" src={info} alt="info_style" />
                    </i>
                    <div className="custom-tooltip-layout tooltip">
                        <div className="tootltip-wrapper">
                            <div className="tooltip-header">
                            Target  Daily Results (Optimisation Event)
                            </div>
                            <span> Target  Daily Results (Optimisation Event)</span>
                        </div>
                    </div>
                </div>
            </label>
            <div className="currency-input">
                {/* <InputText  className="currency-symbol" name="currency" value={getCurrencySymbol(accountDetails?.currency)} disable={true}></InputText> */}
                <InputText name={`target_result`} value={data && data.target_result} onChange={(e) => handleChanage("target_result",e.target.value,elementId)}/>
            </div>
            
        </div></>
    </>);
}

export default AIGroupSettingForm;
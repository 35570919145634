import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../../../../HttpService/HttpService";
import { BASE_URL } from "../../../../../utils/path";
import {
  AddElementCReativeAI,
  ADD_ELEMEMT_CREATIVE_AI,
  GenerateLevers,
  GENERATE_LEVERS,
  GetBusinessUnitWithId,
  GetElementCreativeAi,
  GET_AI_GROUP_LIST,
  GET_ALL_BUSINESS_UNIT_LIST,
  GET_BUSINESS_UNIT_WITH_ID,
  GET_ELEMENT_CREATIVE_AI,
  NAVIGATE_VARIANT,
  SetAiGroupListing,
  SetAllBusinessUnitList,
  SetElementCreativeAi,
  SetGenerateLevers,
  SET_AI_GROUP_LIST,
  SET_ALL_BUSINESS_UNIT_LIST,
  SET_BUSINESS_UNIT_WITH_ID,
  SET_ELEMENT_CREATIVE_AI,
  SET_GENERATE_LEVERS,
} from "../../../../types/CreativeAI/Facebook/Main/Main";

function* fetchBusinessUnitWithId(action: GetBusinessUnitWithId): any {
  let {
    payload: { businessUnitId },
  } = action;
  try {
    const url = `/v1/api/business-unit/${businessUnitId}`;
    const response = yield call(HttpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put({
        type: SET_BUSINESS_UNIT_WITH_ID,
        payload: response.data?.data,
      });
    } else {
      console.log("Business Unit  error");
    }
  } catch (error: any) {
    console.log("Business Unit error", error);
  }
}
function* fetchElementCreativeAi(action: GetElementCreativeAi): any {
  let {
    payload: { elementId },
  } = action;
  try {
    const url = `/v1/api/element-creative-ai/${elementId}`;
    const response = yield call(HttpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put({
        type: SET_ELEMENT_CREATIVE_AI,
        payload: response.data,
      });
    } else {
      console.log("Element Creative  error");
    }
  } catch (error: any) {
    console.log("Element Creative", error);
  }
}
function* handelAddElementCreativeAi(action: AddElementCReativeAI): any {
  let {
    payload: { user, elementData },
  } = action;
  try {
    const url = `/v1/api/element-creative-ai`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      url,
      user,
      elementData
    );
    if (response.data.success) {
      if (response.data.data.successRecord.length !== 0) {
        yield put({
          type: NAVIGATE_VARIANT,
          payload: true,
        });
      }
    } else {
      console.log("Element Creative  error");
      yield put({
        type: NAVIGATE_VARIANT,
        payload: false,
      });
    }
  } catch (error: any) {
    console.log("Element Creative", error);
    yield put({
      type: NAVIGATE_VARIANT,
      payload: false,
    });
  }
}

function* fetchAiGroupList(action: SetAiGroupListing): any {
  let {
    payload: { adAccountId, platformId },
  } = action;
  try {
    const url = `/v1/api/element-creative-ai/${adAccountId}/${platformId}/element`;
    const response = yield call(HttpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put({
        type: SET_AI_GROUP_LIST,
        payload: response.data?.data,
      });
    } else {
      console.log("AI Group List  error");
    }
  } catch (error: any) {
    console.log("AI Group List erroe", error);
  }
}
function* fetchDefaultLevers(action: SetGenerateLevers): any {
  try {
    const url = `/v1/api/variant/get-default-levers`;
    const response = yield call(HttpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put({
        type: SET_GENERATE_LEVERS,
        payload: response.data?.data,
      });
    } else {
      console.log("Levers  error");
    }
  } catch (error: any) {
    console.log("Levers error", error);
  }
}
function* fetchAllBusinessUnitList(action: SetAllBusinessUnitList): any {
  const { brandId } = action.payload;
  try {
    const url = `/v1/api/business-unit?limit=100&brandId=${brandId}`;
    const response = yield call(HttpService.get, BASE_URL, url);
    if (response.data.success) {
      yield put({
        type: SET_ALL_BUSINESS_UNIT_LIST,
        payload: response.data?.data?.rows,
      });
    } else {
      console.log("ALl Business Unit  error");
    }
  } catch (error: any) {
    console.log("ALl Business Unit error", error);
  }
}

export function* MainWatcher() {
  yield takeLatest(GET_BUSINESS_UNIT_WITH_ID, fetchBusinessUnitWithId);
  yield takeLatest(GET_ELEMENT_CREATIVE_AI, fetchElementCreativeAi);
  yield takeLatest(ADD_ELEMEMT_CREATIVE_AI, handelAddElementCreativeAi);
  yield takeLatest(GET_AI_GROUP_LIST, fetchAiGroupList);
  yield takeLatest(GENERATE_LEVERS, fetchDefaultLevers);
  yield takeLatest(GET_ALL_BUSINESS_UNIT_LIST, fetchAllBusinessUnitList);
}

import React, { useRef, useMemo, useState } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { ReactComponent as DownIcon } from '../../../assets/svg/down.svg';
import Loader from "../../common/Loader";

const AdAccountAccordion:React.FC<{ 
    data:any; 
    index:number; 
    children?:any; 
    isAdvance?: boolean;
    onOpen: (callback: any) => void;
}> = ({ data, index, children, isAdvance , onOpen}):JSX.Element => {
    const [loading, setLoading] = useState(false);
    const decoratedOnClick = useAccordionButton(index.toString());
    const count = useRef(1);

    const handleOpen = (e:any) => {
        if (count.current % 2 !== 0) {
            onOpen(setLoading);
        }count.current += 1;
        decoratedOnClick(e);
    }
    
    return <div className="rounded box-shadow">
        <div className="w-100 d-flex justify-content-between align-items-center p-3 px-4 rounded bg-white" onClick={handleOpen}>
            <div className="d-flex flex-column">
                {isAdvance ? (<span className="fw-bold text-md mb-1">Advanced Configuration <span className="text-muted font-italic">(coming soon)</span></span>)
                : (
                    <>
                <span className="fw-bold text-md mb-1">{(data.name!==`NA`)?data.name:data.id}</span>
                <span className="text-sm mb-1 text-muted">{data.id}</span>
                </>)}
            </div>
            <div className="cursor-pointer" role="button">
                <DownIcon />
            </div>
        </div>
        <Accordion.Collapse eventKey={index.toString()}>
            {loading ? (
                <div className="w-100 h-25">
                <Loader />
                </div>
            ) :children}
        </Accordion.Collapse>
    </div>
}

export default AdAccountAccordion;
//@ts-nocheck
import React, { useEffect, useState } from "react";
import "./AllPlatformGraph.scss";
import { Pie, Line } from "react-chartjs-2";
import { AdAccountState } from "../../store/types/adaccount";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlatformMetricsData, setSelectedOption, getAllPlatformCummulativeData } from "../../store/actions/campaign";
import { ActionImpactState } from "../../store/types/actionImpact";
import { initialState } from "../../store/types/auth";
import { formatAllPlatformChartInfo, formatAllPlatformChartOptions, getAllPlatformLabels } from "../../utils/commonFunctions";
import { CampaignState } from "../../store/types/campaign";
import { colors, commonOptions } from "../../utils/constants/actionImpact";
import { calculateCurrencyValue, getAdaccountList } from "../../utils/currencyConverter";
import { getCurrencyDetails, setAllPlatformAdaccountDetails, setAllPlatformChartCordsData, setAllPlatformChartData, setAllPlatformChartOption, setAllPlatformGraphSpinner, setAllPlatformPieSpinner, setCurrencyIdentifier } from "../../store/actions/actionImpact";
import { getAdAccountDetails } from "../../store/actions/adaccount";

const AllPlatformGraph: React.FC = () => {

  const adaccount = useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const { selectedGroup, selectedGroupAdaccounts, platforms } = adaccount;
  const actionImpact = useSelector((state: { actionImpact: ActionImpactState }) => state.actionImpact);
  const { allPlatformLinkClicks, allPlatformChartData, allPlatformChartCordsData, allPlatformChartOption, allPlatformGraphSpinner, pieSpinner, allPlatformAdaccountDetails, currencyIdentifier } = actionImpact;
  const { user, ...authState } = useSelector((state: { auth: initialState }) => state.auth);
  const campaign = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { selectedOption} = campaign;
  const [pieChartData, setPieChartData] = useState<any>({});
  const [allPlatformMetricData, setAllPlatformMetricData] = useState<any>({});
  const [allPlatformPieChartData, setallPlatformPieChartData] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedGroup?.id) {
      dispatch(setAllPlatformChartCordsData([]));
      dispatch(setAllPlatformChartData([]));
      dispatch(setAllPlatformGraphSpinner(true));
      dispatch(getAllPlatformMetricsData(
        { groupId: selectedGroup.id, dates: allPlatformLinkClicks, user: user },
        (response: any, error: boolean) => {
          if (!error) {
            if (response.data && Object.keys(response.data).length > 0) {
              getListofAdaccounts(response.data, selectedGroupAdaccounts);
              setAllPlatformMetricData(response.data);
              dispatch(setAllPlatformChartOption(formatAllPlatformChartOptions(response.data)));
              dispatch(setAllPlatformGraphSpinner(false));
            } else {
              dispatch(setAllPlatformGraphSpinner(false));
            }
          } else {
            dispatch(setAllPlatformGraphSpinner(false));
          }
        }
      ));
      dispatch(setAllPlatformPieSpinner(true));
      dispatch(getAllPlatformCummulativeData(
        { groupId: selectedGroup.id, dates: allPlatformLinkClicks, user: user },
        (response: any, error: boolean) => {
          if (!error) {
            if (response.data && Object.keys(response.data).length > 0) {
              setallPlatformPieChartData(response.data);
            } else {
              dispatch(setAllPlatformPieSpinner(false));
            }
          } else {
            dispatch(setAllPlatformPieSpinner(false));
          }
        }
      ));
    }
  }, [selectedGroup, allPlatformLinkClicks, selectedGroupAdaccounts]);

  const getListofAdaccounts = (data:any, adaccounts: any) => {
    let adaccountList = getAdaccountList(adaccounts, data, platforms);
    Promise.all(adaccountList.map((adacc:any) => {
      return new Promise((res) => {
        dispatch(getAdAccountDetails(
          {adaccountId: adacc.id, platformType: adacc.platformType},
          (response: any, error: boolean) => {
            if (!error) {
              res({id : adacc.id, currency : response.data.currency ? response.data.currency.toLowerCase() : "usd"});
            }
          }
        ))
      })
    })).then((result) => {
      dispatch(setAllPlatformAdaccountDetails(result));
    });
  }

  useEffect(() => {
    getCurrencyValues(allPlatformAdaccountDetails);
  }, [allPlatformAdaccountDetails])
  
  const getCurrencyValues = (data: any) => {
    if(data.length > 0) {
      Promise.all(data.map((adacc:any) => {
        return new Promise((resolve) => {
          dispatch(getCurrencyDetails(
            {currencyName: adacc.currency},
            (response: any, error: boolean) => {
              if (!error) {
                resolve({id : adacc.id, value: response[adacc.currency].usd});
              }
            }
          ))
        })
      })).then((newresult) => {
        dispatch(setCurrencyIdentifier(newresult));
      });
    }
  }

  useEffect(() => {
    if (currencyIdentifier.length > 0) {
      dispatch(setAllPlatformChartData(formatAllPlatformChartInfo(allPlatformMetricData,allPlatformLinkClicks, selectedGroupAdaccounts, platforms, currencyIdentifier)));
      formatPieChartData(allPlatformPieChartData, currencyIdentifier);
    }
  }, [currencyIdentifier])
  
  
  const formatPieChartData = (data:any, currencyIdentifier:any) => {

    let labels:any = [];
    let datasets:any = [];
    labels = getAllPlatformLabels(data);
    let dataPoints:any = [];
    let calculatedData = calculateCurrencyValue(data, labels, platforms, selectedGroupAdaccounts, currencyIdentifier);
    labels = labels.sort();
    calculatedData = calculatedData.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    if (calculatedData.filter((res:any) => res.data > 0).length >= 1) {
      datasets = [{
        label: "Spend",
        data: calculatedData.map((a:any) => a.data),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      }];
      setPieChartData({labels:labels,datasets:datasets});
      dispatch(setAllPlatformPieSpinner(false));
    } else {
      setPieChartData({labels:[],datasets:[]});
      dispatch(setAllPlatformPieSpinner(false));
    }
  }
  
  useEffect(() => {
    if (allPlatformChartData) {
      const currency = "";
      let datasets = allPlatformChartData?.datasets?.map((d: any) => {
        return {
          ...d,
          data: d.data,
          backgroundColor: d?.backgroundColor,
          borderColor: d?.borderColor,
          currency
        };
      });
      dispatch(setAllPlatformChartCordsData({ ...allPlatformChartData, datasets }));
    } else {
      dispatch(setAllPlatformChartCordsData({ ...allPlatformChartData, datasets: [] }));
    }
  }, [allPlatformChartData]);

  useEffect(() => {
    if (allPlatformChartOption) {
      let options = allPlatformChartOption?.map((o: any, index: number) => ({
        ...o,
        position: "left",
        display: true,
        ticks: {
          display: index === 0 ? true : false,
          stepSize: 10,
          beginAtZero: true,
          max: 100,
          min: 0
        },
        gridLines: {
          display: index === 0 ? true : false,
        },
      }));
      dispatch(setSelectedOption({
        ...commonOptions,
        maintainAspectRatio: false,
        scales: {
          yAxes: options,
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
                unitStepSize: 1,
                displayFormats: {
                  day: "MM-DD",
                },
              },
            },
          ],
        },
        legend: { display: true, position: "bottom" },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label(tooltipItem: any, data: any) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              return ` ${label} : ${tooltipItem.yLabel.toFixed(2)}%`;
            },
          },
        },
      }));
    }
  }, [allPlatformChartOption]);

  const options = {
    legend: { display: true, position: "bottom" },
    maintainAspectRatio: false,
    datalabels: {
      display: true,
      color: "white",
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat((currentValue/total*100).toFixed(2));
          return percentage + '%';
        },
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        }
      }
    },
    responsive: true
  };

  return (
    <>
    {/* Handle Below Condition : (TypeError: Cannot read properties of undefined (reading 'getBasePixel')) */}
    {!(allPlatformGraphSpinner || pieSpinner) && allPlatformChartData?.datasets?.length > 0 && pieChartData?.datasets?.length > 0 ? 
    <>
      <div className="all-platform-graph-container">
        <div className="pie-chart-container">
          {!(allPlatformGraphSpinner || pieSpinner) && pieChartData?.datasets?.length > 0 &&  <Pie data={pieChartData} options={options} height={null} width={null} />}
        </div>
        { !(allPlatformGraphSpinner || pieSpinner) && allPlatformChartCordsData && selectedOption && allPlatformChartData?.datasets && allPlatformChartData?.datasets[0]?.data?.length > 0 && allPlatformChartData.datasets?.length === selectedOption?.scales?.yAxes?.length && 
         <div className="line-chart-container"> <Line data={allPlatformChartCordsData} options={selectedOption} /></div>}
      </div>
      { !(allPlatformGraphSpinner || pieSpinner) && allPlatformChartCordsData && selectedOption && allPlatformChartData?.datasets && allPlatformChartData?.datasets[0]?.data?.length > 0 && allPlatformChartData.datasets?.length === selectedOption?.scales?.yAxes?.length && <div className="ml-2 text-sm mt-2">
        {`*Spends accross all ad accounts have been converted to USD($)`}
      </div>}
      </>
      : !(allPlatformGraphSpinner || pieSpinner) && <div className="nodata">No Data Present</div>}
    </>
  );
};

export default AllPlatformGraph;
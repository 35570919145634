import React, { useMemo, useRef, useState } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowUp } from "../assets/svg/arrowup.svg";
import { ReactComponent as DownIcon } from "../assets/svg/down.svg";
import { ReactComponent as EditIcon } from "../assets/svg/edit.svg";
import { ReactComponent as ReportIcon } from "../assets/svg/report.svg";
import info from "../assets/svg/smallinfo.svg";
import { setFormLoading, setForms, setTargetingForm, updateFormData } from "../store/actions/form";
import { FormState } from "../store/types/form";
import { initialState } from "../store/types/auth";
import Switch from "../ui/Switch/Switch";
import { getCurrencySymbol } from "../utils/commonFunctions";
import { defaultTargetingFormValues } from "../utils/constants/targetingAI";
import Loader from "./common/Loader";
import { setCPRGraphDetails } from "../store/actions/common";
import { mixPanelAdEvent } from "../utils/mixpanel";
import { AdAccountState } from "../store/types/adaccount";

const GroupAccordion: React.FC<{
  data: any;
  index: number;
  children?: any;
  formDetails?: any;
  cprData?: any;
  setModal?: (val: any | null) => void;
  onOpen: (callback: any) => void;
}> = ({ data, cprData, index, children, setModal, onOpen, formDetails }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const decoratedOnClick = useAccordionButton(index.toString());
  const target = useRef(null);
  const dispatch = useDispatch();
  const { accountDetails, ...auth } = useSelector((state: { auth:initialState }) => state.auth);
  const count = useRef(1);
  const formState = useSelector((state: { form: FormState }) => state.form);
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedBrand, selectedTargetingAdaccount } = adaccount;
  const { forms } = formState;
  const isAuto: boolean = useMemo(
    () => formDetails && formDetails["modeOfApplication"] === "Auto",
    [formDetails]
  );
  const cprOther = useMemo(() => cprData && cprData["cpr_other"], [cprData]);
  const cprTargeting = useMemo(() => cprData && cprData["cpr_targeting"], [cprData]);
  const cprLabel = useMemo(
    () => cprData && cprData["labels"] && cprData["labels"].find((f: any) => f.type === "CPR"),
    [cprData]
  );
  const diffCPR: any = useMemo(
    () => parseFloat((parseFloat(cprOther?.value||0) - parseFloat(cprTargeting?.value||0)).toFixed(2)),
    [cprOther, cprTargeting]
  );
  const diffInPercCPR = useMemo(
    () => (diffCPR / parseFloat(cprOther?.value || 1)) * 100,
    [cprOther, diffCPR]
  );
  const onChange = (value: boolean) => {
    dispatch(setForms({
      ...forms,
      [data.targtingElementID]: {...forms[data.targtingElementID],modeOfApplication: value ? "Auto" : "Manual"}
    }));
    if (value) {
      setModal &&
        setModal({
          key: "switchAuto",
          title: `Switch to Auto: ${data?.name}`,
          props: { elementId: data.targtingElementID },
          handleCancel: () => {
            dispatch(setForms({
              ...forms,
              [data.targtingElementID]: {...forms[data.targtingElementID],modeOfApplication: "Manual"}
            }));
          },
        });
    } else {
      dispatch(setFormLoading(true));
      dispatch(updateFormData(
        {elementId: data.targtingElementID, formId: forms[data.targtingElementID].id , user: auth?.user, params: { ...formDetails, modeOfApplication: value ? "Auto" : "Manual", audienceRange: "All",relevanceRange: "All" }},
        (response: any, error: boolean) => {
          if (!error) {
            dispatch(setFormLoading(false));
            dispatch(setForms({
              ...forms,
              [data.targtingElementID]:  { ...formDetails, modeOfApplication: value ? "Auto" : "Manual", audienceRange: "All",relevanceRange: "All" }
            }));
          } else {
            // console.log("UpdateForm->",error);
          }
        }
      ));
    }
    mixPanelAdEvent( "Mode_of_application_toggle_auto", {
      'module_name': 'Targeting AI',
      'brand_name': selectedBrand?.name,
      'ad_account_id': selectedTargetingAdaccount?.id,
      'ad_account_name': selectedTargetingAdaccount?.adAccount,
      'ai_group_id': data?.id,
      'ai_group_name': data?.name,
      'tai_recommendations_toggle_on': value,
      'platform_name': 'Facebook'
    });
  };

  const handleOpen = (e: any) => {
    if (count.current % 2 !== 0) {
      onOpen(setLoading);
    }
    count.current += 1;
    decoratedOnClick(e);
  };

  const handleShowReport = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    mixPanelAdEvent('TAI Graph Viewed', {
      'module_name': 'Targeting AI',
      'brand_name': selectedBrand?.name,
      'ad_account_id': selectedTargetingAdaccount?.id,
      'ad_account_name': selectedTargetingAdaccount?.adAccount,
      'ai_group_id': data?.id,
      'ai_group_name': data?.name,
      'platform_name': 'Facebook'
    });
    setModal &&
      setModal({
        key: "report",
        title: `Targeting AI Performance : ${data?.name}`,
        props: { elementId: data.targtingElementID, cprLabel: cprLabel },
      });
    dispatch(setCPRGraphDetails({ range: 7, term: "days", data: null }));
  };

  return (
    <div className="border p-3 rounded">
      <div
        className="w-100 d-flex justify-content-between text-sm align-items-center"
        onClick={handleOpen}
      >
        <div className="d-flex flex-column" style={{ width: "20%" }}>
          <span title={data?.name} className="fw-bold text-lg mb-1 text-truncate">
            {data?.name}
          </span>
          <span style={{ color: "#89B84F" }}>Generated Recommendation</span>
        </div>

        <div className="d-flex flex-column align-items-between">
          <span className="text-xs mb-1">
            {cprLabel?.label} (Other ad sets
            <div className="tooltip-container">
              <i ref={target}>
                <img className="ml-2" src={info} alt="info_style" />
              </i>
              <div className="custom-tooltip-layout tooltip">
                <div className="tootltip-wrapper">
                  {`This refers to the cumulative average ${cprOther?.label} for all Ad Sets in AI Groups not published by the Targeting AI.`}
                </div>
              </div>
            </div>
            )
          </span>
          <span className="fw-bold text-lg">
            {cprOther?.value
              ? `${getCurrencySymbol(accountDetails?.currency) || ""}${parseFloat(
                  cprOther?.value
                ).toFixed(2)}`
              : "-"}
          </span>
        </div>
        <div className="d-flex flex-column align-items-between">
          <span className="text-xs mb-1">
            {cprLabel?.label} (Targeting AI ad sets
            <div className="tooltip-container">
              <i ref={target}>
                <img className="ml-2" src={info} alt="info_style" />
              </i>
              <div className="custom-tooltip-layout tooltip">
                <div className="tootltip-wrapper">
                  {`This is the cumulative average ${cprLabel?.label} for all Ad Sets, within AI Groups published by the Targeting AI.`}
                </div>
              </div>
            </div>
            )
          </span>
          <span className="fw-bold text-lg">
            {cprTargeting?.value
              ? `${getCurrencySymbol(accountDetails?.currency) || ""}${parseFloat(
                  cprTargeting?.value
                ).toFixed(2)}`
              : "-"}
          </span>
        </div>
        <div className="d-flex flex-column align-items-between">
          <span className="text-xs mb-1">
            Difference in {cprLabel?.label}
            <div className="tooltip-container">
              <i ref={target}>
                <img className="ml-2" src={info} alt="info_style" />
              </i>
              <div className="custom-tooltip-layout tooltip">
                <div className="tootltip-wrapper">
                  This refers to the change in {cprLabel?.label}. It is calculated using the
                  following formula: % Change in {cprLabel?.label} = ((({cprLabel?.label} for Other
                  Ad sets) - ({cprLabel?.label} for Ad sets under Targeting AI))/{cprLabel?.label}{" "}
                  for Other Ad sets)*100.
                </div>
              </div>
            </div>
          </span>
          <span className="fw-bold text-lg align-items-center d-flex">
            {diffCPR && cprTargeting?.value && cprOther?.value
              ? `${getCurrencySymbol(accountDetails?.currency) || ""}${Math.abs(diffCPR)}`
              : "-"}
            {diffInPercCPR !== 0 && cprTargeting?.value && cprOther?.value && (
              <span
                className="text-sm ml-2"
                style={{ color: diffInPercCPR > 0 ? "#89B84F" : "#E84B47" }}
              >
                <ArrowUp
                  style={{ transform: diffInPercCPR < 0 ? "" : "rotate(180deg)" }}
                  fill={diffInPercCPR > 0 ? "#89B84F" : "#E84B47"}
                />{" "}
                {parseFloat(`${diffInPercCPR}`).toFixed(2)}%
              </span>
            )}
          </span>
        </div>
        <div className="d-flex align-items-between">
          <span className="p-2 bg-light rounded" role="button" onClick={handleShowReport}>
            <ReportIcon />
          </span>
          <span
            className="p-2 bg-light rounded ml-2"
            role="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(setTargetingForm(defaultTargetingFormValues));
              setModal &&
                setModal({
                  key: "adset",
                  title: `Targeting AI Setup: ${data.name}`,
                  props: { elementId: data.targtingElementID, adsets: data.adsets },
                });
            }}
          >
            <EditIcon data-backdrop="static" />
          </span>
        </div>
        <div className="d-flex flex-column align-items-between">
          <span className="text-xs mb-1">Mode of application</span>
          <div className="d-flex align-items-center">
            <span>Manual</span>
            {formDetails ? (
              <span className="mx-2">
                <Switch initialValue={isAuto} value={isAuto} onChange={onChange} />
              </span>
            ) : (
              <span className="mx-2">
                <Switch initialValue={isAuto} value={isAuto} onChange={onChange} />
              </span>
            )}
            <span>Auto</span>
          </div>
        </div>
        <div className="cursor-pointer" role="button">
          <DownIcon />
        </div>
      </div>

      <Accordion.Collapse eventKey={index.toString()}>
        <>
          {loading && (
            <div className="w-100 h-25">
              <Loader />
            </div>
          )}
          {children}
          {!loading && !children && (
            <div
              className="w-100 text-md text-center font-weight-bold mt-auto d-flex align-items-center justify-content-center"
              style={{ height: "100px" }}
            >
              <span>No Reccomendations Found</span>
            </div>
          )}
        </>
      </Accordion.Collapse>
    </div>
  );
};

export default GroupAccordion;

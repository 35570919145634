import DataList from "../../ui/DataList/DataList";
import MultiSelectWithIcon from "../../ui/MultiSelectWithIcon/MultiSelectWithIcon";
import Select from "../../ui/Select/Select";
import SelectableCards from "../../ui/SelectableCards/SelectableCards";
import TargetSlider from "../../ui/TargetSlider/TargetSlider";
import { ReactComponent as CreatorTargeting } from "../../assets/svg/creatorTargeting.svg";
import { ReactComponent as AllGender } from "../../assets/svg/allGender.svg";
import { ReactComponent as FemaleGender } from "../../assets/svg/Female.svg";
import { ReactComponent as MaleGender } from "../../assets/svg/Male.svg";
import { MontageCampaigns} from "../../store/types/montageAds";
import info from "../../assets/svg/smallinfo.svg";
import React, {useRef} from "react";
import { languageOption, sliderMarks, interestsOptions } from "../../utils/constants/motageAds";



const MontageCreatorTargeting: React.FC<{handleChange:(value:any)=>void;montageAds:MontageCampaigns;setMontageAds:any; montageCountry:any;montageValidation:any;handleCreative:(value:any,name:any)=>void}>=({

    handleChange,
    montageAds,
    setMontageAds,
    montageCountry,
    montageValidation,
    handleCreative
})=>{
    const target = useRef(null);
    const [languageValue, setLanguageValue] = React.useState("");
    const [filterLanguageOption, setFilterLanguageOption] =React.useState<any>(languageOption);

      const handleSliderChange = (data: any, newValue: number | number[]) => {

        setMontageAds((montageAds: MontageCampaigns) => ({
          ...montageAds,
          targetAgeMax: data.targetAgeMax,
        }));
        setMontageAds((montageAds: MontageCampaigns) => ({
          ...montageAds,
          targetAgeMin: data.targetAgeMin,
        }));
      };

      const handelLanguageSelect = (value: any) => {
        setLanguageValue(value);
        const selectedLanguage = montageAds.language.length===0?[]:montageAds.language.split(",");
          selectedLanguage.push(value);
        const filteredLanguage = languageOption.filter((f) => !selectedLanguage.includes(f?.label));
        setFilterLanguageOption(filteredLanguage);
        setMontageAds((montageAds: MontageCampaigns) => ({
          ...montageAds,
          language: selectedLanguage.toString(),
        }));
      };

      const handelRemoveSelectedLanguage = async (index: any) => {
        const localSelectedLanguage = [...montageAds.language.split(",")];
        const data = { label: localSelectedLanguage.find((f: any, ind: any) => ind == index) };
        const res = [...filterLanguageOption, data];
        setFilterLanguageOption(res);
        localSelectedLanguage.splice(index, 1);
        setLanguageValue("");

        setMontageAds((montageAds: MontageCampaigns) => ({
          ...montageAds,
          language: localSelectedLanguage.toString(),
        }));
      };

      const onChange = (value: any, action: string) => {
        let localActiveStorage: any[];
        switch (action) {
          case "add":
            localActiveStorage = montageAds.interests.length===0?[value]:[...montageAds.interests.split(","), value];
            setMontageAds((montageAds: MontageCampaigns) => ({
              ...montageAds,
              interests: localActiveStorage.toString(),
            }));
            break;
          default:
            localActiveStorage = montageAds.interests.split(",").filter((b: any) => b != value);
            setMontageAds((montageAds: MontageCampaigns) => ({
              ...montageAds,
              interests: localActiveStorage.toString(),
            }));
            break;
        }
      };


    return(
        <div className="second-row setup">
          <div className="creator-targeting">
            <div className="setup-heading">
              <div className="heading-icon">
                <CreatorTargeting />
              </div>
              <h6>Creator Targeting</h6>
            </div>
          </div>
          <div className="creative-details">
            <div className="creative-layout">
              <div className="creative-heading">Target Gender*</div>

              <div className="layouts">
                <SelectableCards
                  content={[
                    { name: "All", cardIcon: <AllGender /> },
                    { name: "Male", cardIcon: <MaleGender /> },
                    { name: "Female", cardIcon: <FemaleGender /> },
                  ]}
                  cardStyle={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: "white",
                  }}
                  handelOnChange={(value)=>handleCreative(value,"targetGender")}
                  activeValue={montageAds.targetGender}
                />
              </div>
            </div>
            <div className="creative-layout">
              <div className="creative-heading">Target Age Range {montageAds.targetAgeMax>16 && `(Min: ${montageAds.targetAgeMin} Max: ${montageAds.targetAgeMax}) `}</div>
              <div className="slider">
                <TargetSlider
                  sliderMarks={sliderMarks}
                  handelOnChange={handleSliderChange}
                  sliderRange={[montageAds.targetAgeMin,montageAds.targetAgeMax]}
                  sliderRestriction={16}
                  valueLabelDisplay={"on"}
                />
              </div>
            </div>
            <div className="target">
              <div key={`country`} className="country category">
                <label className="text-md">
                  Country*
                  <div className="tooltip-container">
                    <i ref={target}>
                      <img className="ml-2" src={info} alt="info_style" />
                    </i>
                    <div className="custom-tooltip-layout tooltip">
                      <div className="tootltip-wrapper">
                        <div className="tooltip-header">Country</div>
                        <span>
                          {" "}
                          The geographical location or demographic target
                          audience for your product.
                        </span>
                      </div>
                    </div>
                  </div>
                </label>
                <Select name="country"
                options={montageCountry}
                onChange={(e) => handleChange(e)} />
              </div>

              <div key={`language`} className="language category">
                <label className="text-md">
                  Language*
                  <div className="tooltip-container">
                    <i ref={target}>
                      <img className="ml-2" src={info} alt="info_style" />
                    </i>
                    <div className="custom-tooltip-layout tooltip">
                      <div className="tootltip-wrapper">
                        <div className="tooltip-header">Language</div>
                        <span>
                          {" "}
                          The language for communicating with your target
                          audience. Currently, we support 4 languages - English,
                          Hindi, French and Spanish.
                        </span>
                      </div>
                    </div>
                  </div>
                </label>
                <div className="dataList">
                  <DataList
                    languageOption={filterLanguageOption}
                    currentValue={languageValue}
                    handelOnChange={handelLanguageSelect}
                    selectedValues={montageAds.language.length===0?[]:montageAds.language.split(",")}
                    removeItem={handelRemoveSelectedLanguage}
                  />
                </div>
                {montageValidation?.language && (
                  <span style={{ color: "red" }}>
                    *This field is required
                  </span>
                )}
              </div>
            </div>
            <div key={`interests`} className="interest category">
              <label className="text-md">
                Interests*
                <div className="tooltip-container">
                  <i ref={target}>
                    <img className="ml-2" src={info} alt="info_style" />
                  </i>
                  <div className="custom-tooltip-layout tooltip">
                    <div className="tootltip-wrapper">
                      <div className="tooltip-header">Interests</div>
                      <span>
                        {" "}
                        Key characteristics of your target audience that can help us match you with creators.
                      </span>
                    </div>
                  </div>
                </div>
              </label>
              <MultiSelectWithIcon
                labels={interestsOptions}
                handelOnChange={onChange}
                onActiveInterests={montageAds.interests.length===0?[]:montageAds.interests.split(",")}
              />
              {montageValidation?.interests && (
                  <span style={{ color: "red" }}>
                    <small>*This field is required</small>
                  </span>
                )}
            </div>
          </div>
        </div>
    )
}
export default MontageCreatorTargeting;
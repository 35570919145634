import React from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LaunchCampaigns: React.FC = (): JSX.Element => {
    return(
        <>
        <p className="montage-heading">Montage Ads</p>
        <div className="montage-campaign">
            <div className="launch-campaigns">
                <button className="campaign-btn1">
                    <small>+ Create Campaigns</small>
                </button>
                <button className="campaign-btn2">
                    <small><b> ? How UGC Works</b></small>
                </button>
            </div>

            <div>
                <ul className="campaign-ul">
                    
                    <li className="campaign-list">
                        <div className="campaign-name">
                            <img></img>
                            <span>Campaign Name</span>
                        </div>
                        <div className="campaign-details"> 
                            <span>LAUNCH DATE</span>
                            <small>Mar 29, 2021 12:30 pm</small>
                        </div>

                        <div className="campaign-details"> 
                            <span>NO. OF ASSETS </span>
                            <small>10</small>
                        </div>

                        <div className="campaign-details">
                            <span>VIDEO STYLE</span>
                            <small>Review</small>
                        </div>

                        <div className="campaign-details">
                            <span>PLATFORM USED</span>
                            <small>Facebook</small>
                        </div>

                        <div className="creative-library">
                            <button></button>
                            <button></button>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}
export default LaunchCampaigns
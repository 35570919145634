import { GetBrandAdAccounts, GetAdsetDetails, GetAllBrands, GetAllPlatforms, GetCampaignDetails, GetElementId, GetGroups, GET_ADSET_DETAILS, GET_ALL_BRANDS, GET_ALL_PLATFORMS, GET_CAMPAIGN_DETAILS, GET_ELEMENT_ID, GET_GROUPS, groups, SetActiveBrand, SetActivePlatform, SetBrandLoading, SetGroupId, SetGroups, SetGroupsLoading, SetPlatform, SetSelectedAdaccounts, SetSelectedAdSet, SetSelectedCampaign, SetSelectedGroup, SetSelectedBrandAdaccounts, SET_ACTIVE_BRAND, SET_ACTIVE_PLATFORM, SET_BRAND_LOADING, SET_GROUPS, SET_GROUPS_LOADING, SET_GROUP_ID, SET_PLATFORM, SET_SELECTED_ADACCOUNT, SET_SELECTED_AD_SET, SET_SELECTED_CAMPAIGN, SET_SELECTED_GROUP, SET_SELECTED_BRAND_ADACCOUNTS, GET_BRAND_ADACCOUNTS, GetGroupAdAccounts, GET_GROUP_ADACCOUNTS, SetSelectedGroupAdaccounts, SET_SELECTED_GROUP_ADACCOUNTS, GET_ADACCOUNT_DETAIL, GetAdAccountDetails, SetPlatformId, SET_PLATFORM_ID, SET_TARGETING_GROUPS, SetTargetingGroups, SetAdAccountLoading, SET_ADACCOUNT_LOADING, SET_SINGLE_PLATFORM_ADACCOUNTS, SetSinglePlatformAdaccounts, GET_SAVINGCALCULATOR_DATA, SetSelectedElementId, SET_SELECTED_ELEMENTID, SetSelectedGroupCampaign, SET_SELECTED_GROUP_CAMPAIGN, SetSelectedGroupAdset, SET_SELECTED_GROUP_ADSET, SetSelectedTargetingAdaccount, SET_SELECTED_TARGETING_ADACCOUNT, SET_SELECTED_GSAI_ADACCOUNT, SetSelectedGSAIAdaccount, SET_SELECTED_BRAND_GSAI_ADACCOUNTS, SetSelectedBrandGSAIAdaccounts, SET_GSAI_PLATFORM, SetGSAIPlatform, SET_SELECTED_BRAND_TARGETING_ADACCOUNTS, SetSelectedBrandTargetingAdaccounts } from "./../types/adaccount";

// State
export const setGroups = (payload: any): SetGroups => ({
  type: SET_GROUPS,
  payload,
});

export const setGroupsLoading = (payload: any): SetGroupsLoading => ({
  type: SET_GROUPS_LOADING,
  payload,
});

export const setSelectedGroup = (payload: groups): SetSelectedGroup => ({
  type: SET_SELECTED_GROUP,
  payload,
});

export const setGroupId = (payload: string): SetGroupId => ({
  type: SET_GROUP_ID,
  payload,
});

export const setSelectedAdSet = (payload: []): SetSelectedAdSet => ({
  type: SET_SELECTED_AD_SET,
  payload,
});

export const setActiveBrand = (payload: []): SetActiveBrand => ({
  type: SET_ACTIVE_BRAND,
  payload,
});

export const setBrandLoading = (payload: any): SetBrandLoading => ({
  type: SET_BRAND_LOADING,
  payload,
});

export const setPlatform = (payload: any): SetPlatform => ({
  type: SET_PLATFORM,
  payload,
});

export const setActivePlatform = (payload: any): SetActivePlatform => ({
  type: SET_ACTIVE_PLATFORM,
  payload,
});

export const setGSAIPlatform = (payload: any): SetGSAIPlatform => ({
  type: SET_GSAI_PLATFORM,
  payload,
});

export const setSelectedCampaign = (payload: any): SetSelectedCampaign => ({
  type: SET_SELECTED_CAMPAIGN,
  payload,
});

export const setSelectedBrandAdaccounts = (
  payload: any
): SetSelectedBrandAdaccounts => ({
  type: SET_SELECTED_BRAND_ADACCOUNTS,
  payload,
});

export const setSelectedBrandGSAIAdaccounts = (
  payload: any
): SetSelectedBrandGSAIAdaccounts => ({
  type: SET_SELECTED_BRAND_GSAI_ADACCOUNTS,
  payload,
});

export const setSelectedBrandTargetingAdaccounts = (
  payload: any
): SetSelectedBrandTargetingAdaccounts => ({
  type: SET_SELECTED_BRAND_TARGETING_ADACCOUNTS,
  payload,
});

export const setSelectedAdaccounts = (payload: any): SetSelectedAdaccounts => ({
  type: SET_SELECTED_ADACCOUNT,
  payload,
});

export const setSelectedGroupAdaccounts = (
  payload: any
): SetSelectedGroupAdaccounts => ({
  type: SET_SELECTED_GROUP_ADACCOUNTS,
  payload,
});

export const setPlatformId = (payload: any): SetPlatformId => ({
  type: SET_PLATFORM_ID,
  payload,
});

export const setTargetingGroups = (payload: any): SetTargetingGroups => ({
  type: SET_TARGETING_GROUPS,
  payload,
});

export const setAdAccountLoading = (payload: any): SetAdAccountLoading => ({
  type: SET_ADACCOUNT_LOADING,
  payload,
});

export const setSinglePlatformAdaccounts = (payload: any): SetSinglePlatformAdaccounts => ({
  type: SET_SINGLE_PLATFORM_ADACCOUNTS,
  payload,
});

export const setSelectedElementId = (payload: any): SetSelectedElementId => ({
  type: SET_SELECTED_ELEMENTID,
  payload,
});

export const setSelectedGroupCampaign = (payload: any): SetSelectedGroupCampaign => ({
  type: SET_SELECTED_GROUP_CAMPAIGN,
  payload,
});

export const setSelectedGroupAdset = (payload: any): SetSelectedGroupAdset => ({
  type: SET_SELECTED_GROUP_ADSET,
  payload,
});

export const setSelectedTargetingAdaccount = (payload: any): SetSelectedTargetingAdaccount => ({
  type: SET_SELECTED_TARGETING_ADACCOUNT,
  payload,
});

export const setSelectedGSAIAdaccount = (payload: any): SetSelectedGSAIAdaccount => ({
  type: SET_SELECTED_GSAI_ADACCOUNT,
  payload,
});

// API
export const getGroups = (
  payload: { brandId: string; user: any },
  callback: Function
): GetGroups => ({
  type: GET_GROUPS,
  payload,
  callback,
});

export const getAdsetDetails = (
  payload: { group_id: string; user: any },
  callback: Function
): GetAdsetDetails => ({
  type: GET_ADSET_DETAILS,
  payload,
  callback,
});

export const getCampaignDetails = (
  payload: { group_id: string; user: any },
  callback: Function
): GetCampaignDetails => ({
  type: GET_CAMPAIGN_DETAILS,
  payload,
  callback,
});

export const getAllBrands = (
  payload: { user: any },
  callback: Function
): GetAllBrands => ({
  type: GET_ALL_BRANDS,
  payload,
  callback,
});

export const getAllPlatforms = (
  payload: { user: any },
  callback: Function
): GetAllPlatforms => ({
  type: GET_ALL_PLATFORMS,
  payload,
  callback,
});

// saving calculator action
export const getCalculatorData = (payload: any) => ({
  type: GET_SAVINGCALCULATOR_DATA,
  payload,
});

export const getElementId = (
  payload: {
    adAccountId: string;
    aiGroupId: string;
    platformId: string;
    user: any;
  },
  callback: Function
): GetElementId => ({
  type: GET_ELEMENT_ID,
  payload,
  callback,
});

export const getBrandAdAccounts = (
  payload: { brandId: string },
  callback: Function
): GetBrandAdAccounts => ({
  type: GET_BRAND_ADACCOUNTS,
  payload,
  callback,
});

export const getGroupAdAccounts = (
  payload: { groupId: string },
  callback: Function
): GetGroupAdAccounts => ({
  type: GET_GROUP_ADACCOUNTS,
  payload,
  callback,
});

export const getAdAccountDetails = (
  payload: { adaccountId: string, platformType: string },
  callback: Function
): GetAdAccountDetails => ({
  type: GET_ADACCOUNT_DETAIL,
  payload,
  callback,
});

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CreateVariantsState } from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Button } from "../../../../../shared";
import ViewMoreModal from "../../../SelectCohort/ViewMoreModal";
import { textTruncateMore } from "../../../utils/text.utils";
import AdSetDetailsModal from "./AdSetDetailsModal";
import {
  StyledEditorHeaderContainer,
  StyledHeading,
  StyledHeadingContainer,
  StyledAnchor,
  StyledViewAdSetDetail,
} from "./styled";

export default function EditorHeader({ variantItem }: { variantItem: any }) {
  const [showModal, setShowModal] = useState(false);
  const { selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const [showDetailedTarget, setShowDetailedTarget] = useState(false);
  const toggleDetailedTargetModal = () => {
    setShowDetailedTarget(!showDetailedTarget);
  };
  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state.selectCohortReducer.cohortData,
      ADS_LIST: state.createVariantReducer.adsList,
    })
  );
  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);
  const adLink = `https://business.facebook.com/adsmanager/manage/ads?act=${adAccountId}&filter_set=SEARCH_BY_ADGROUP_IDS-STRING_SET%1EANY%1E[%22${selectedAd.id}%22]`;
  return (
    <>
      <StyledEditorHeaderContainer>
        <StyledHeadingContainer>
          <StyledHeading>
            Create Variant | Reference Ad Name:{" "}
            {textTruncateMore(selectedAd.name, 20)}
          </StyledHeading>
          <StyledAnchor href={adLink} target="_blank">
            (View Ad in Facebook)
          </StyledAnchor>
        </StyledHeadingContainer>
        <StyledViewAdSetDetail onClick={() => setShowModal(true)}>
          View Ad Set Details
        </StyledViewAdSetDetail>
      </StyledEditorHeaderContainer>
      {showModal && (
        <AdSetDetailsModal
          show={showModal}
          toggleDetailedTargetModal={toggleDetailedTargetModal}
          onHide={() => setShowModal(false)}
        />
      )}
      {showDetailedTarget && (
        <ViewMoreModal
          selectedAdSet={COHORT_DATA}
          show={showDetailedTarget}
          onHide={() => toggleDetailedTargetModal()}
        />
      )}
    </>
  );
}

import React from "react";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import App from "./App";
import "./scss/style.scss";
import { Provider } from "react-redux";
import store from "./store/store";
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_UNIQUE_CODE } from "./utils/path";
import WebFont from "webfontloader";

mixpanel.init(MIXPANEL_UNIQUE_CODE, { debug: true }); 

WebFont.load({
  google: {
    families: ["Inter", "Lato"],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
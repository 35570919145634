import React from "react";
import InputText from "../../ui/InputText/InputText";
import Radio from "../../ui/Radio/Radio";
import Select from "../../ui/Select/Select";
import { UserDetailsFormValidationFields } from "../../utils/constants/userForm";

type InputProps = {
    data:any,
    formKey:string;
    formData: any;
    disable?: boolean;
    handleChanage: (key:string,value:string | number) => void;
}

const UserDetailsFormInput:React.FC<InputProps> = ({data,formKey,formData,disable,handleChanage}) => {
    
    return (<>
        {data.type === "text" && data.show && (
            <div key={formKey} className={data.fullWidth ? "form-group flex-100" : "form-group flex-50"}>
                <label className="text-md form-title">{data.label}{UserDetailsFormValidationFields.includes(formKey) ? " *": "" }</label>
                <InputText name={formKey} value={formData[formKey] || ""} disable={disable} onChange={(e) => handleChanage(formKey,e.target.value)}/>
            </div>)
        }
        {data.type === "select" && data.show && (
            <div key={formKey} className={data.fullWidth ? "form-group flex-100" : "form-group flex-50"}>
                <label htmlFor="example2" className="text-md form-title">{data.label}</label>
                <Select disable={disable} name={formKey} defaultSelected={""} options={data.options} onChange={(e) => handleChanage(formKey,e.target.value)}/>
            </div>)
        }
        {data.type === "radio" && data.show &&   
            (<div key={formKey} className={data.fullWidth ? "form-group flex-100" : "form-group flex-50"}>
                <label className="text-md form-title">{data.label}</label>
                <Radio disable={disable} className="d-flex" name={formKey} data={data.options} defaultSelected={formData[formKey] || ""} onChange={(e) =>  handleChanage(formKey,e.target.value)} />
            </div>
            )
        }
    </>)
}

export default UserDetailsFormInput;

import styled from "styled-components";

export const StyledEditorHeaderContainer = styled.div`
  height: 60px;
  background: #ffffff;
  border: 1px solid #dadce0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
`;

export const StyledHeading = styled.div`
  font-family: "Lato";
  font-style: normal;
  // font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  max-lines: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 70%;
`;

export const StyledHeadingContainer = styled.div`
  display: flex;

  width: 70%;
`;

export const StyledAnchor = styled.a`
  margin-left: 5px;
  font-size: 15px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
`;
export const StyledViewAdSetDetail = styled.a`
  margin-left: 5px;
  font-size: 15px;
  font-family: "Lato";
  font-style: normal;
  // font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #0869fb !important;
  border-bottom: 1px solid #0869fb;
  cursor: pointer;
`;

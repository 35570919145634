import React from "react";

const InputText: React.FC<{
  value?: any;
  name?: string;
  placeholder?: string;
  className?: string;
  type?: string;
  onChange?: (value: any) => void;
  disable?: boolean;
  required?:boolean;
  maxlength?:any;
  step?:any;
  pattern?: any;
}> = ({ value, name, placeholder, className, onChange, type = "text", disable, required, maxlength, step, pattern}) => {
  return (
    <input
      name={name}
      type={type}
      value={value}
      className={`form-control form-control-md text-md ${className}`}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disable}
      required={required}
      maxLength={maxlength}
      step={step}
      pattern={pattern}
    />
  );
};

export default InputText;

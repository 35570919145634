/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import React, { useEffect, useRef } from "react";
import info from "../assets/svg/smallinfo.svg";
import Loader from '../components/common/Loader';
import NoData from '../components/common/NoData';
import { getModelText, setModelText } from "../store/actions/common";
import { useDispatch, useSelector } from "react-redux";
import { CommonState } from "../store/types/common";
import { getModelConfidenceScore, setModelConfidenceScore, setSpinner } from "../store/actions/campaign";
import { CampaignState } from "../store/types/campaign";
import { initialState } from "../store/types/auth";
import { AdAccountState } from "../store/types/adaccount";
import AdAccountAccordion from "../components/AIGroupSettings/AdAccountAccordion/AdAccountAccordion";
import { Accordion } from 'react-bootstrap';
import { useMemo } from "react";
import { mixPanelAdEvent } from "../utils/mixpanel";

const AIModelConfidence: React.FC<unknown> = () => {
  const { user,...authState } = useSelector((state: { auth:initialState }) => state.auth);
  const target = useRef(null);
  const dispatch = useDispatch();
  const [adAccountsList, setAdAccountsList] = React.useState({facebook:[],google:[]});
  const [modelConfidenceList, setModelConfidenceList] = React.useState({});
  const [loading,setLoading] = React.useState(false)
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { modelText } = common;
  const adAccount = useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const { selectedGroup, adaccountLoading, selectedGroupAdaccounts, platformId, platforms, selectedBrand } = adAccount;
  const campaign = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const { modelConfidenceScore } = campaign;

  useEffect(() => {
    mixPanelAdEvent('Page View - Dashboard', {
      'module_name': "AI model confidence",
      'brand_name': selectedBrand?.name,
    });
  }, []);

  const aiModalPlatforms = useMemo(() => {
    let data= platforms.sort((a:any,b:any) => {
      if(a.name>b.name){ return 1}
      if(a.name<b.name){ return -1}
      if(a.name==b.name){ return 0}
    })
    let capitalize = function (type: string){
      let arr = type.split(' ');
      arr.forEach((word,index)=>arr[index]=word.charAt(0)+word.slice(1).toLowerCase())
      return arr.join(' ')
    }
    
    data.forEach((platform:any,index:number)=>{data[index].type=capitalize(platform.type)});
    return data
  },[platforms])
  
  const handleOpen = ( elementId: string, callback:any ) => {
    setLoading(true)
    dispatch(getModelConfidenceScore({ elementId, user },
      (response: any, error: boolean) => {
        dispatch(setSpinner(false));
        if (!error) {
          dispatch(setModelConfidenceScore(response.data))
          setModelConfidenceList({...modelConfidenceList,[elementId]:response.data})
          setLoading(false)
        } else {
          dispatch(setModelConfidenceScore([]))
          console.log(error);
        }
      }
    ));
    dispatch(getModelText({ user },
      (response: any, error: boolean) => {
        if (!error) {
          dispatch(setModelText(response.data))
        } else {
          console.log(error);
        }
      }
    ));
  }

  const modelTooltip = (props) => (
    <div {...props} className="custom-tooltip-layout tooltip">
      <div className="tootltip-wrapper">
        <div className="tooltip-header">AI Model Confidence Score</div>
        This shows a percentage score, between 0-100, that determines the most suitable model for a
        particular AI Group. This is determined by 1. Existing structure of the campaigns 2. Cost
        and scale of the campaigns
      </div>
    </div>
  );

  const tooltip = (props, key) =>
    modelText.length > 0 ? (
      <div {...props} className="custom-tooltip-layout tooltip">
        <div className="tootltip-wrapper">{modelText.map((data:any)=> {if (data.id === key) {
          return data.description
        }})}</div>
      </div>
    ) : null;

  return (
    <div className="ai_model_confidence_style">
      {selectedGroupAdaccounts.length?(aiModalPlatforms||[]).map((platform) => (platform?.adaccountDetail?.length>0)  && (<>
        <h4 className="campaigns_header fs-6 p-0 pt-4 ml-4 d-flex mb-4">
          {platform.type} : AI Model Confidence Score
          <div className="position-relative tooltip-container">
            <i ref={target}>
              <img className="ml-2" src={info} alt="info_style" />
            </i>
            {modelTooltip()}
          </div>
        </h4>
        <Accordion className="p-4">
          {(platform.adaccountDetail||[]).map((detail,index) => (
            <div className="mb-2">
              <AdAccountAccordion data={detail} index={index} onOpen={(callback:any) => handleOpen(detail.elementId,callback)}>
              {modelConfidenceList ? (
                <div className="ai_model_confidence_content w-100 px-4 pb-4">
                  {modelConfidenceList[detail.elementId]?.map((element, index) => (
                    <div key={index} className="mb-2">
                      <div className="d-flex align-items-center">
                        <label className="lable_style mb-0" htmlFor="file">{element.name}</label>
                        <div className="position-relative tooltip-container">
                            <img className="ml-2" src={info} alt="info_style" />
                          {tooltip(null,element.aiModelId)}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <progress id="file" value="1" style={{ width: `${element.percentage}` }} />
                        {' '}
                        <span className="ml-2">{element.percentage}</span>
                      </div>
                    </div>
                  ))}
                  {modelConfidenceList[detail.elementId]?.length === 0 &&
                  <NoData/>}
    
                </div>
              ) : <Loader />}
              </AdAccountAccordion>
            </div>
          ))}
        </Accordion>
      </>)):(adaccountLoading?<Loader/>:<NoData/>)}
    </div>
  );
};

export default AIModelConfidence;

import { InputProps } from "@mui/material";
import { Controller } from "react-hook-form";
import InputLabel from "../../InputLabel/InputLabel";
import { StyledInputContainer, StyledTextInputWithLabel } from "./styled";
import { StyledErrorMessage } from "../../Typography/Heading/styled";

interface InputWithLabelProps extends InputProps {
  label?: string;
  required?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  isBorder?: boolean;
  tooltipText?: string;
  inputType?: "alpha" | "float" | "int" | undefined;
  control?: any;
  registeredName?: any;
  helperFunction?: (registeredName: string) => void;
}

export function InputFormControl({
  required,
  label,
  tooltipText,
  inputType = undefined,
  control,
  registeredName,
  inputProps,
  helperFunction,
  ...props
}: InputWithLabelProps) {
  // need to change to  the pattern basis
  const alphaInput = (event: any) => {
    if (event) {
      var char = String.fromCharCode(event.which);
      if (!/^[a-zA-Z ]+$/.test(char)) {
        event.preventDefault();
      }
    }
  };

  const floatInput = (event: any) => {
    if (event) {
      var char = String.fromCharCode(event.which);
      if (!/^\d*(\.\d{0,2})?$/.test(char)) {
        event.preventDefault();
      }
    }
  };

  const integerInput = (event: any) => {
    if (event) {
      var char = String.fromCharCode(event.which);
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    }
  };

  return (
    <Controller
      control={control}
      name={registeredName}
      rules={{
        required,
        ...inputProps,
      }}
      render={({
        field: { onChange, value },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <StyledInputContainer>
          {label && (
            <InputLabel
              required={required}
              label={label}
              tooltipText={tooltipText}
            />
          )}
          <StyledTextInputWithLabel
            onChange={(value) => {
              helperFunction && helperFunction(registeredName);
              onChange(value);
            }}
            value={value}
            onKeyPress={(event) =>
              inputType === "alpha"
                ? alphaInput(event)
                : inputType === "int"
                ? integerInput(event)
                : inputType === "float"
                ? floatInput(event)
                : null
            }
            {...props}
          />
          {error?.type === "required" && (
            <StyledErrorMessage>This field is required *</StyledErrorMessage>
          )}
          {error?.type === "maxLength" && (
            <StyledErrorMessage>{`Max length should <= ${
              inputProps && inputProps["maxLength"]
            }`}</StyledErrorMessage>
          )}
          {error?.type === "minLength" && (
            <StyledErrorMessage>{`Min length should >= ${
              inputProps && inputProps["minLength"]
            }`}</StyledErrorMessage>
          )}
          {error?.type === "min" && (
            <StyledErrorMessage>{`Min value should be >= ${
              inputProps && inputProps["min"]
            }`}</StyledErrorMessage>
          )}
          {error?.type === "max" && (
            <StyledErrorMessage>{`Max value should be <= ${
              inputProps && inputProps["max"]
            }`}</StyledErrorMessage>
          )}
        </StyledInputContainer>
      )}
    />
  );
}

import moment from "moment";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AdAccountState } from "../../store/types/adaccount";
import { mixPanelAdEvent } from "../../utils/mixpanel";
import "./index.scss";
const SavingCalculator = ({ data = null, module }: any) => {
  const adaccount = useSelector((state: { adaccount: AdAccountState}) => state.adaccount);
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  
    const secondVal = (dataInput:any)=>{
      let str= dataInput?.secondDataValue || ""
      if(str.length<=4){
        return (str.slice(0,str.length-1))
        
      }
      if(str.length<=6 && str.length>4){
        return (str.slice(0,str.length-4)+","+str.slice(str.length-4,str.length-1))
      }
      if(str.length===7){
        return (str.slice(0,3)+"."+str.slice(3,5)+"k")
      }
      if(str.length>7){
        return (str.slice(0,str.length-7)+"."+str.slice(str.length-7,str.length-5)+"m")
      }      
    }
  return (
    <div className="saving-calculator flex-center navigation-dropdown">
      <div className="heading">
        <div className="">Saving Calculator</div>
        <div className="date-range">
          <div> {data.startDate ? moment(data.startDate).format('ll') + "-" : ""} {data.startDate ? moment(data.endDate).format('ll') : ""}</div>
        </div>
      </div>
      <div className="savingpercentage">
        <div className="title">{data.firstTextFieldName}</div>
        <div className="detail">{module === "singlePlatform" ? data.firstDataValue : "-"}</div>
      </div>
      <div className="savingvalue">
        <div className="title">{data.secondTextFieldName} </div>
        <div className="detail">{data ? data.secondDataValue?.charAt(data.secondDataValue.length - 1) : ""} {data.secondDataValue ? secondVal(data) : ""}</div>
      </div>
      <div className="moreInfoLink">
        <a target="_blank" href={data.url} onClick={() => {
          if (data.url) {
            mixPanelAdEvent('Savings Calculator Viewed', {
              'module_name': `Action Impact Analysis - ${module === "singlePlatform" ? "Single platforms" : "All platforms"}`,
              'brand_name': selectedBrand?.name,
              'ai_group_name': selectedGroup?.name,
              'ai_group_id': selectedGroup?.id,
              'ad_account_id': `${module === "singlePlatform"  ? selectedAdAccount?.id || null : 'ALL'}`,
              'ad_account_name': `${module === "singlePlatform"  ? selectedAdAccount?.name || null : 'ALL'}`
            })
          } 
        }
          
        }>View in Details</a>
      </div>
    </div>
  );
};
export default SavingCalculator;
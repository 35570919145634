import { Box, Card } from "@mui/material";
import styled from "styled-components";

export interface AiGroupNameProps {
  reccomendations: boolean;
}

export const StyledCreativeAIListingWrapper = styled(Card)`
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 24px;
`;

export const StyledCreativeAIListItem = styled(Box)`
  // width: 100%;
  width: 100%;
  min-width: 1017px;
  margin: 16px 0px;
  border: 1px solid #dddddd;
  height: 120px;
  border-radius: 8px;
  padding: 36px 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const StyledAiGroupContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .description {
    min-width: 180px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #89b84f;
  }
`;

export const StyledAiGroupName = styled.div<AiGroupNameProps>`
  margin-bottom: ${(props) => (!props.reccomendations ? "0px" : "8px")};
  font-weight: 700;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  min-width: 120px;
  font-family: "Inter";
  font-style: normal;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  width: 216px;
`;

export const StyledRecommendationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 5px;
  min-width: 300px;

  .title {
    margin-bottom: 5px;
    width: 118px;
    height: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #7a7a7a;
  }
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    max-width: 305px;
    color: #333333;
    width: 307px;
    height: 20px;
    font-family: "Inter";
    line-height: 20px;
    text-transform: capitalize;
  }
`;

export const StyledCreateVariantContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  .createVarientBtn {
    text-transform: capitalize;
    background: #edf3fe;
    border-radius: 10px;
    color: #0869fb;
    font-size: 16px;
    padding: 11px 24px;
    width: 167px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;
export const StyledSettingButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-left: 12px;
  height: 40px;
  background: #eeeeee;
  border-radius: 6px;
  cursor: pointer;
`;
export const StyledCreativeRotationContainer = styled(Box)`
  // overflow: scroll;
  min-width: 160px;
  max-width: 160px;
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7a7a7a;
    font-family: "Inter";
    width: 116px;
    height: 24px;
    font-style: normal;
    display: flex;
  }
  .sub-title {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #7a7a7a;
    font-family: "Inter";
    margin-bottom: 6px;
    width: 31px;
    height: 12px;
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .settings {
    margin-top: 4px;
    .title {
      font-size: 14px;
    }
  }
  .statusToggle {
    border-right: 1px solid #e5e5e5;
    padding-right: 12px;
  }
  .phase {
    margin-left: 12px;
  }
  .phaseDetail {
    margin-top: 9px;
    font-weight: 500;
    font-size: 10px;
    width: 36px;
    height: 12px;
    font-family: "Inter";
    font-style: normal;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
`;
export const StyledActivityLogContainer = styled(Box)`
  width: 40px;
  height: 40px;
  background: #eeeeee;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

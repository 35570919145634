import React, { useEffect, useMemo, useState } from "react";
import {
  StyledVariantContainerCreateVariant,
  StyledAds,
  StyledHorizontalScrollMainCreativeCard,
  StyledAddVariant,
  StyledAddVariantCard,
  StyledAddVariantSpan,
  StyledPreview,
  StyledAddVariantCardDisabled,
  StyledMainWidth,
  StyledOverFlowNew,
  StyledNotSupportTextAVDIS,
  StyledMainWidthCV,
} from "./styled";

import AdSets from "../shared/AdSets/AdSets";
import ViewMoreModal from "../SelectCohort/ViewMoreModal";
import AdCard from "./AdCard/AdCard";
import AdPreview from "./AdPreview/AdPreview";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCohort } from "../../../../store/actions/CreativeAI/Facebook/SelectCohort/selectCohort";
import {
  addVarianButton,
  getAds,
  getVariants,
  resetState,
  setCreateSpec,
  setSelectedAd,
  setVariants,
  updateAds,
} from "../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import Editor from "./Editor/Editor";
import {
  CreateVariantsState,
  GENERATE_VARIANT_METADATA_STATE_VARIANT,
  SET_AD_PREVIEW_LINK,
} from "../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { Box, CircularProgress } from "@mui/material";
import { ReactComponent as CreateVariant } from "../../../../assets/svg/AdVariantSvg.svg";
import { ReactComponent as AdVariantDisableSvg } from "../../../../assets/svg/AdVariantDisableSvg.svg";
import { initialState } from "../../../../store/types/auth";
import { generateConfigurePayload, getFormValues } from "../utils/form.utils";
import { mapAdAdSetToElement } from "../utils/common.utils";
import { APIGenerationType } from "../shared/SharedTypes";
import { filterVariantData } from "../utils/filterLevers.utils";
import {
  SET_AD_ADSET_FIELD_LIST,
  SET_ELEMENT_CREATIVE_AI,
} from "../../../../store/types/CreativeAI/Facebook/Main/Main";

const CreateVariants: React.FC = (): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();

  // All Selectors
  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state?.selectCohortReducer?.cohortData,
      ADS_LIST: state?.createVariantReducer?.adsList,
    })
  );
  const { LEVERS } = useSelector((state: any) => ({
    LEVERS: state.MainReducer.defaultLevers,
  }));
  const data: APIGenerationType[] = useMemo(
    () => filterVariantData(LEVERS, ["user", "ad", "adset", "campaign"]),
    [LEVERS]
  );

  const auth = useSelector((state: { auth: initialState }) => state.auth);
  // All Selectors
  const { BUSINESS_UNIT_BY_ID_DATA, CREATIVE_ELEMENT_AI } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
      CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
    })
  );

  const {
    variantList,
    ads,
    selectedAd,
    createSpec,
    adPreviewLink,
    isPublishingVariant,
    extraFields: { showEditor },
    fetchingAds,
    fetchingVariants,
    fetchingCreateSpec,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);
  // All State
  const [showDetailedTarget, setShowDetailedTarget] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [selectedAdIndex, setSelectedAdIndex] = useState(0);
  const [errorInfo, setErrorInfo] = useState({
    error: true,
    errorText: "",
  });

  //  All Handling Function
  const toggleDetailedTargetModal = () => {
    setShowDetailedTarget(!showDetailedTarget);
  };

  const handelAdsSelect = (item: any, index: number) => {
    dispatch(setSelectedAd(item));
    setSelectedAdIndex(index);
  };

  const addVariantClick = () => {
    dispatch(addVarianButton({}));
  };

  useEffect(() => {
    if (COHORT_DATA?.adset && !isPublishingVariant) {
      dispatch(getAds({ adSetId: COHORT_DATA.adset.adsetId, adAccountId }));
    }
  }, [COHORT_DATA, isPublishingVariant]);

  useEffect(() => {
    if (selectedAd?.id) {
      setPreviewLoading(true);
      let initState: any = {
        loadingRecommendation: true,
        isConfiguringMetaData: false,
        executionIdVariant: undefined,
        extraFields: {
          isVariantEditing: undefined,
          selectedVariantIndex: undefined,
          showEditor: undefined,
        },
        generateRecommendationError: false,
        generateRecommendationTimestamp: undefined,
        configureMetaData: undefined,
        recommendationMetaData: {},
        recommendationSelectionState: {
          primary_text: "",
          headline: "",
          description: "",
          adPreviewLink: "",
          variantName: "",
          isChanged: false,
          isConfigureChanged: false,
        },
      };
      dispatch(resetState(initState));
      const createSpecAPI = selectedAd?.adcreatives?.data[0];
      if (ads[selectedAdIndex]?.variantList) {
        dispatch(setVariants({ data: ads[selectedAdIndex]?.variantList }));
      } else {
        dispatch(getVariants({ adId: selectedAd.id }));
      }
      dispatch(setCreateSpec(createSpecAPI));

      setErrorInfo(checkSupportedAdType(createSpecAPI));
      dispatch({
        type: SET_AD_PREVIEW_LINK,
        payload: selectedAd?.previews?.data[0]?.body,
      });

      const { newCreativeElementAI, adAdSetFieldList } = mapAdAdSetToElement({
        createSpec: createSpecAPI,
        targeting: COHORT_DATA?.facebookData?.targeting,
        creativeElementAI: CREATIVE_ELEMENT_AI.data,
        levers: data,
      });

      dispatch({
        type: SET_ELEMENT_CREATIVE_AI,
        payload: {
          data: newCreativeElementAI,
        },
      });
      dispatch({
        type: SET_AD_ADSET_FIELD_LIST,
        payload: adAdSetFieldList,
      });
      if (BUSINESS_UNIT_BY_ID_DATA && !selectedAd?.executionId) {
        dispatch({
          type: "GENERATE_VARIANT_METADATA",
          payload: {
            user: auth.user,
            data: generateConfigurePayload(
              {
                ...getFormValues(newCreativeElementAI, true),
                businessUnit: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
                businessUnitDetail:
                  BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
              },
              LEVERS
            ),
          },
        });
      } else {
        dispatch({
          type: GENERATE_VARIANT_METADATA_STATE_VARIANT,
          payload: {
            executionId: selectedAd?.executionId,
          },
        });
      }

      setPreviewLoading(false);
    }
  }, [selectedAd]);

  useEffect(() => {
    if (ads) {
      const localAds = [...ads];
      localAds[selectedAdIndex] = { ...localAds[selectedAdIndex], variantList };
      dispatch(updateAds(localAds));
    }
  }, [variantList]);

  const checkSupportedAdType = (createSpec: any) => {
    const { object_story_spec } = createSpec;
    if (object_story_spec) {
      const { link_data, video_data } = object_story_spec;
      if (link_data) {
        const { child_attachments, collection_thumbnails } = link_data;
        if (child_attachments || collection_thumbnails) {
          return {
            error: false,
            errorText:
              "Currently, we are only supporting Ads with Image/Video format",
          };
        } else {
          return { error: true, errorText: "" };
        }
      } else if (video_data) {
        const { child_attachments, collection_thumbnails } = video_data;
        if (child_attachments || collection_thumbnails) {
          return {
            error: false,
            errorText:
              "Currently, we are only supporting Ads with Image/Video format",
          };
        } else {
          return { error: true, errorText: "" };
        }
      } else {
        return {
          error: false,
          errorText:
            "Currently, we are only supporting Ads with Image/Video format",
        };
      }
    }

    return {
      error: false,
      errorText: "Currently, we are not supporting this Ad Type",
    };
  };

  return (
    <StyledOverFlowNew>
      {ads &&
      ads.length &&
      adPreviewLink &&
      !fetchingAds &&
      !fetchingVariants &&
      !fetchingCreateSpec ? (
        <StyledVariantContainerCreateVariant>
          <StyledMainWidth>
            <AdSets
              type="createVariant"
              item={COHORT_DATA}
              toggleViewMoreModal={() => toggleDetailedTargetModal()}
            />
          </StyledMainWidth>

          <StyledMainWidthCV>
            <Box display="flex" height="100%">
              <Box position="relative" height="100%">
                <StyledAds>
                  {ads.map((item: any, index: number) => {
                    return (
                      <div
                        key={item?.id}
                        // style={{
                        //   position:
                        //     item.id === selectedAd.id ? "relative" : "static",
                        // }}
                      >
                        <AdCard
                          adPreviewLink={adPreviewLink}
                          previewLoading={previewLoading}
                          item={item}
                          handelSelect={handelAdsSelect}
                          activeAd={selectedAd}
                          index={index}
                        />
                      </div>
                    );
                  })}
                </StyledAds>
              </Box>
              {!errorInfo.error ? (
                <Box
                  width="100%"
                  height="50vh"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <StyledAddVariantCardDisabled>
                    <StyledAddVariantSpan>
                      <AdVariantDisableSvg />
                    </StyledAddVariantSpan>
                  </StyledAddVariantCardDisabled>
                  <StyledNotSupportTextAVDIS>
                    {errorInfo.errorText}
                  </StyledNotSupportTextAVDIS>
                </Box>
              ) : variantList.length === 0 ? (
                <Box
                  width="100%"
                  height="50vh"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <StyledAddVariant onClick={addVariantClick}>
                    <StyledAddVariantCard>
                      <StyledAddVariantSpan>
                        <CreateVariant />
                      </StyledAddVariantSpan>
                    </StyledAddVariantCard>
                  </StyledAddVariant>
                </Box>
              ) : (
                <Box width="70%" display="flex" height="100%">
                  <Box width="76%">
                    <StyledPreview title="Press shift and move mouse scroll wheel to view more">
                      <StyledHorizontalScrollMainCreativeCard>
                        {variantList.length ? (
                          variantList?.map((item: any, ind: any) => {
                            return (
                              <div
                                style={{
                                  paddingLeft: "10px",
                                }}
                              >
                                <AdPreview index={ind} item={item} />
                              </div>
                            );
                          })
                        ) : (
                          <div />
                        )}
                      </StyledHorizontalScrollMainCreativeCard>
                    </StyledPreview>
                  </Box>
                  <StyledAddVariant>
                    <StyledAddVariantCard onClick={addVariantClick}>
                      <StyledAddVariantSpan>
                        <CreateVariant />
                      </StyledAddVariantSpan>
                    </StyledAddVariantCard>
                  </StyledAddVariant>
                </Box>
              )}
            </Box>
          </StyledMainWidthCV>
        </StyledVariantContainerCreateVariant>
      ) : (
        <Box
          width="100%"
          height="70vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress thickness={5} />
        </Box>
      )}
      {showDetailedTarget && (
        <ViewMoreModal
          selectedAdSet={COHORT_DATA}
          show={showDetailedTarget}
          onHide={() => toggleDetailedTargetModal()}
        />
      )}

      {showEditor && <Editor />}
    </StyledOverFlowNew>
  );
};

export default React.memo(CreateVariants);

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { ReactComponent as DropDownArrow } from "../../assets/svg/dropdownarrow.svg";
import Loader from "../../components/common/Loader";
import ButtonUI from "../ButtonUI/ButtonUI";
import CheckBox from "../CheckBox/CheckBox";
import HandleClickOutside from "../../components/common/HandleClickOutside";
import "./index.scss";

export type MultiSelectedItem = {
  name: string;
  value: string;
  isSelected: boolean;
  color?: string;
};

const MultiSelect: React.FC<{
  placeholder?: string;
  value: MultiSelectedItem[];
  className?: string;
  isSingleMetric?: boolean;
  onChange: (value: MultiSelectedItem[], item?: MultiSelectedItem) => void;
  loader?: boolean;
}> = ({ value, placeholder, isSingleMetric = false, onChange, children, className = "" , loader=false}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<MultiSelectedItem[]>(value);
  const selectedItem = useMemo(
    () => items.filter((item) => item.isSelected).map((item) => item.name),
    [items]
  );

  const handleChange = useCallback(
    (index: number) => {
      const newItems: MultiSelectedItem[] = items.map((item, itemIndex) => {
        if (itemIndex === index) {
          item.isSelected = !item.isSelected;
        }
        return item;
      });
      if (isSingleMetric && newItems.filter(a => a.isSelected === true).length > 6) {
        alert("Can not add more than 6 Metrics");
      } else {
        setItems(newItems);
        onChange(newItems, newItems[index]);
      }
    },
    [items, onChange]
  );

  useEffect(() => {
    setItems(value);
  }, [value]);

  const wrapperRef = useRef(null);
  HandleClickOutside(wrapperRef,[setOpen]);

  return (
    <div className={`multiselect ${className}`} ref={wrapperRef}>
      <div onClick={() => setOpen((v) => !v)}>
        {children || (
          <ButtonUI
            type="button"
            className="btn secondary d-flex align-items-center"
          >
            <span
              style={{ width: "auto", maxWidth: "150px" }}
              className="text-truncate"
            >
              {selectedItem?.join(",") || placeholder}
            </span>
            <DropDownArrow />
          </ButtonUI>
        )}
        </div>
        {open && (
        <div className="dropdown-menu" >
          {loader && <Loader />}
          {!loader && items.map((element, index) => (
            <div
              className="d-flex"
              key={element.value}
              onClick={() => {
                handleChange(index);
              }}
              style={{ cursor: "pointer" }}
            >
              <CheckBox
                style={{ color: element?.color }}
                value={element.isSelected}
              />{" "}
              <span className="text-truncate">{element.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;

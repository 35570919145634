import { IconButton } from "@mui/material";
import styled from "styled-components";

interface ActiveProp {
  active?: boolean;
}

export const StyledVariantItemParaTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #333333;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 0;
`;

export const StyledVariantItemParaADC = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  max-lines: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 15rem;
  font-family: "Inter";
  font-style: normal;
`;

export const StyledAdsListContainer = styled.div<ActiveProp>`
  background: #ffffff;
  position: relative;
  border: ${(props) =>
    props.active == false ? "1px solid #dddddd" : "1px solid #0869FB"};
  border-radius: 8px;
  // width: 95%;
  width: 307px;
  padding: 10px;
  margin-bottom: 10px;
  height: 100%;
  cursor: pointer;

  .pointer {
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: ${(props) =>
      props.active == false ? "1px solid #dddddd" : "1px solid #0869FB"};
    border-right: ${(props) =>
      props.active == false ? "1px solid #dddddd" : "1px solid #0869FB"};
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background: white;
    right: -6px;
    display: ${(props) => (props.active == false ? "none" : "block")};
  }

  .ads-list-text {
    display: flex;
    justify-content: flex-start;
  }
`;

export const StyledAdsImage = styled.img`
  // width: 78.91px;
  // height: 91px;
  width: 72.91px;
  height: 80px;
  border: 1px solid #e5e5e5;
`;
export const StyledAdsListContainerTitle = styled.div`
  margin-left: 10px;
  width: 59%;
`;
export const StyledAdsInDraft = styled.div`
  color: #7a7a7a;
  font-size: 10px;
  font-weight: 400;
  font-family: Inter;
`;
export const StyledAdsValues = styled.div`
  color: #333333;
  font-size: 10px;
  font-weight: bold;
  font-family: Inter;
`;

export const StyledAdsDrafFields = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledBackDrop = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999;
`;

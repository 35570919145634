import { SetMetricList, SET_METRIC_LIST, ChartData, GetCampaignsDetails, GET_CAMPAIGNS_DETAILS, GetPhaseTimeline, GET_PHASE_TIMELINE, GetCampaignFactors, GET_CAMPAIGNS_FACTORS, GetMetricsList, GET_METRICS_LIST, POST_CAMPAIGN_FACTORS, PostCampaignFactors, DeleteCampaignFactors, DELETE_CAMPAIGN_FACTORS, GetModelConfidenceScore, GET_MODEL_CONFIDENCE_SCORE, GetGroupMetrics, GET_GROUP_METRICS, GetCampaignMetrics, GET_CAMPAIGN_METRICS, GetMetricsCumulative, GET_METRICS_CUMULATIVE, UpdateMetric, UPDATE_METRIC, GetRecommendation, GET_RECOMMENDATION, RejectRecommendation, REJECT_RECOMMENDATION, SetRecommendation, SET_RECOMMENDATION, GetStoryData, GET_STORY, PostStoryData, POST_STORY, PutStoryData, PUT_STORY, GetAllPlatformCummulativeData, GetAllPlatformMetricsData, GET_ALL_PLATFORM_CUMMULATIVE_DATA, GET_ALL_PLATFORM_METRICS_DATA,GetOptimizationEventDetails } from './../types/campaign';
import {
  CampaignDetail, CampaignFactors, Metrics, SetCampaignsDetails, SetCampaignsFactors,
  SetSettingPostspinner, SetSpinner, SET_CAMPAIGNS_DETAILS, SET_CAMPAIGNS_FACTORS, SET_SETTING_POST_SPINNER,
  SET_SPINNER, SetMetricCumulative, SET_METRIC_CUMULATIVE, ModelConfidenceScore, SetModelConfidenceScore,
  SET_MODEL_CONFIDENCE_SCORE, SetChartTabs, SET_CHART_TABS, SetChartData, SET_CHART_DATA, SET_CHART_OPTION,
  SetChartOption, SET_SELECTED_OPTION, SetSelectedOption, SetSuccess, SET_SUCCESS, SetChartCordsData, SET_CHART_CORDS_DATA,
  PhaseTimelines, SetPhaseTimeline, SET_PHASE_TIMELINE,GET_OPTIMIZATION_EVENT
} from "../types/campaign";
import { LinkClicks } from '../types/actionImpact';


// State
export const setSpinner = (payload: boolean): SetSpinner => ({
  type: SET_SPINNER,
  payload,
});

export const setSettingPostspinner = (payload: boolean): SetSettingPostspinner => ({
  type: SET_SETTING_POST_SPINNER,
  payload,
});

export const setCampaignsDetails = (payload: CampaignDetail[]): SetCampaignsDetails => ({
  type: SET_CAMPAIGNS_DETAILS,
  payload,
});

export const setCampaignsFactors = (payload: CampaignFactors): SetCampaignsFactors => ({
  type: SET_CAMPAIGNS_FACTORS,
  payload,
});

export const setMetricList = (payload: Metrics[]): SetMetricList => ({
  type: SET_METRIC_LIST,
  payload,
});

export const setMetricCumulative = (payload: any): SetMetricCumulative => ({
  type: SET_METRIC_CUMULATIVE,
  payload,
});

export const setModelConfidenceScore = (payload: ModelConfidenceScore[]): SetModelConfidenceScore => ({
  type: SET_MODEL_CONFIDENCE_SCORE,
  payload,
});

export const setRecommendation = (payload: any): SetRecommendation => {
  return {
    type: SET_RECOMMENDATION,
    payload,
  }
};

export const setChartTabs = (payload: Metrics[]): SetChartTabs => ({
  type: SET_CHART_TABS,
  payload,
});

export const setChartData = (payload: ChartData): SetChartData => ({
  type: SET_CHART_DATA,
  payload,
});

export const setChartOption = (payload: any[]): SetChartOption => ({
  type: SET_CHART_OPTION,
  payload,
});

export const setSelectedOption = (payload: any): SetSelectedOption => ({
  type: SET_SELECTED_OPTION,
  payload,
});

export const setSuccess = (payload: boolean): SetSuccess => ({
  type: SET_SUCCESS,
  payload,
});

export const setChartCordsData = (payload: ChartData): SetChartCordsData => ({
  type: SET_CHART_CORDS_DATA,
  payload,
});

export const setPhaseTimeline = (payload: PhaseTimelines): SetPhaseTimeline => ({
  type: SET_PHASE_TIMELINE,
  payload,
});

// API
export const getCampaignsDetails = (
  payload: { groupId: string, user: any },
  callback: Function
): GetCampaignsDetails => ({
  type: GET_CAMPAIGNS_DETAILS,
  payload,
  callback,
});

export const getPhaseTimeline = (
  payload: { brandId: string, user: any },
  callback: Function
): GetPhaseTimeline => ({
  type: GET_PHASE_TIMELINE,
  payload,
  callback,
});

export const getCampaignsFactors = (
  payload: { elementId: string, user: any },
  callback: Function
): GetCampaignFactors => ({
  type: GET_CAMPAIGNS_FACTORS,
  payload,
  callback,
});

export const getMetricsList = (
  payload: { elementId: string, user: any },
  callback: Function
): GetMetricsList => ({
  type: GET_METRICS_LIST,
  payload,
  callback,
});


export const postCampaignFactors = (
  payload: { elementId: string, user: any, payload: any },
  callback: Function
): PostCampaignFactors => ({
  type: POST_CAMPAIGN_FACTORS,
  payload,
  callback,
});


export const deleteCampaignFactors = (
  payload: { factorId: string, user: any },
  callback: Function
): DeleteCampaignFactors => ({
  type: DELETE_CAMPAIGN_FACTORS,
  payload,
  callback,
});

export const getModelConfidenceScore = (
  payload: { elementId: string, user: any },
  callback: Function
): GetModelConfidenceScore => ({
  type: GET_MODEL_CONFIDENCE_SCORE,
  payload,
  callback,
});

export const getGroupMetrics = (
  payload: { elementId: string, user: any },
  callback: Function
): GetGroupMetrics => ({
  type: GET_GROUP_METRICS,
  payload,
  callback,
});

export const getCampaignMetrics = (
  payload: { elementId: string, dates: LinkClicks, user: any, selectedAdSet: any, selectedCampaign: any },
  callback: Function
): GetCampaignMetrics => ({
  type: GET_CAMPAIGN_METRICS,
  payload,
  callback,
});

export const getMetricsCumulative = (
  payload: { elementId: string, dates: LinkClicks, user: any, selectedAdSet: any, selectedCampaign: any },
  callback: Function
): GetMetricsCumulative => ({
  type: GET_METRICS_CUMULATIVE,
  payload,
  callback,
});

export const updateMetric = (
  payload: { metricId: number, user: any, payload: any },
  callback: Function
): UpdateMetric => ({
  type: UPDATE_METRIC,
  payload,
  callback,
});

export const getRecommendation = (
  payload: { elementId: string, dates: LinkClicks, user: any, activeHeader: string },
  callback: Function
): GetRecommendation => ({
  type: GET_RECOMMENDATION,
  payload,
  callback,
});

export const rejectRecommendation = (
  payload: { id: string, concept_type: "campaign" | "adset", user: any },
  callback: Function
): RejectRecommendation => ({
  type: REJECT_RECOMMENDATION,
  payload,
  callback,
});

export const getStoryData = (
  payload: { elementId: string, user: any },
  callback: Function
): GetStoryData => ({
  type: GET_STORY,
  payload,
  callback,
});

export const postStoryData = (
  payload: { elementId: string, user: any, payload: any },
  callback: Function
): PostStoryData => ({
  type: POST_STORY,
  payload,
  callback,
});

export const putStoryData = (
  payload: { elementId: string, user: any, payload: any },
  callback: Function
): PutStoryData => ({
  type: PUT_STORY,
  payload,
  callback,
});

export const getAllPlatformCummulativeData = (
  payload: { groupId: string, dates: LinkClicks, user: any },
  callback: Function
): GetAllPlatformCummulativeData => ({
  type: GET_ALL_PLATFORM_CUMMULATIVE_DATA,
  payload,
  callback,
});

export const getAllPlatformMetricsData = (
  payload: { groupId: string, dates: LinkClicks, user: any },
  callback: Function
): GetAllPlatformMetricsData => ({
  type: GET_ALL_PLATFORM_METRICS_DATA,
  payload,
  callback,
});

export const getOptimizationEventDetails = (
  payload: { adAccountId: any, platformId: any,user: any },
  callback: Function
): GetOptimizationEventDetails => ({
  type: GET_OPTIMIZATION_EVENT,
  payload,
  callback,
});

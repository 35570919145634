import moment from "moment";
import React, { useEffect } from "react";
import { ReactComponent as Timer } from "../../../assets/svg/timer.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getClusterLogs,
  getPublishClusters,
  setClusterLoader,
  setCreateDescError,
  setCreateHeadlineError,
  setEditCluster,
  setGenerateDescriptionTimeStamp,
  setGenerateHeadlineTimestamp,
  setGroupsLoader,
  setGSAIDropdownDisabled,
  setLoadHeadLineDesc,
  setPublishCluster,
  setSelectedTabIndex,
  setTargetKeyword,
  updateCreativeReplacement,
} from "../../../store/actions/cluster";
import { ClusterState } from "../../../store/types/cluster";
import { initialState } from "../../../store/types/auth";
import KeywordRecommendation from "../../../components/KeywordRecommendation/KeywordRecommendation";
import Tab from "../../../ui/Tab/Tab";
import Table from "../../../ui/Table/Table";
import TableElement from "../../../ui/Table/TableElement";
import Loader from "../../../components/common/Loader";
import ViewMore from "../../../components/common/ViewMore/ViewMore";
import Switch from "../../../ui/Switch/Switch";
import ButtonUI from "../../../ui/ButtonUI/ButtonUI";
import ModalWrapper from "../../../components/Modal/ModalWrapper";
import ModalContainer from "../../../components/Modal/ModalContainer";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../../../utils/commonFunctions";
import { AdAccountState } from "../../../store/types/adaccount";
import { getKeywordRecommendations } from "../../../store/actions/common";
import AdTableElement from "./AdTableElement";
import { clusterManagerTableHeaders as headers } from "../../../utils/constants/GSAI";
import { gSAILogsTableHeader as logsHeaders } from "../../../utils/constants/GSAI";
import { ReactComponent as Refresh } from "../svgs/refresh.svg";
import { mixPanelAdEvent } from "../../../utils/mixpanel";

const tabs = ["Keyword Recommendation", "Cluster Manager"];

const GoogleSearchAIDashboard = () => {
  const dispatch = useDispatch();
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const {
    clusterLoader,
    groupsLoader,
    publishCluster,
    selectedTabIndex,
    targetKeyword,
  } = clusterState;
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand, selectedGSAIAdaccount, selectedGSAIPlatform } =
    useSelector((state: { adaccount: AdAccountState }) => state.adaccount);
  const [groupsList, setGroupsList] = React.useState([]);
  const [confirmationModal, setConfirmationModal] = React.useState<any>({
    open: false,
    text: "",
    data: {},
    flag: false,
  });
  const [replacementLogs, setReplacementLogs] = React.useState([]);
  const [fetchingLogs, setFetchingLogs] = React.useState(false);
  const [isEmptyReplacementLogs, setEmptyReplacementLogs] =
    React.useState(false);
  const [showLogsTable, setShowLogsTable] = React.useState<boolean>(false);
  const [isGetClusterNetworkError, setIsGetClusterNetworkError] =
    React.useState(false);
  const showLogsTableModal = (cluster: any) => {
    setShowLogsTable(true);
    setFetchingLogs(true);
    dispatch(
      getClusterLogs(
        { publishClusterId: cluster.id, user: auth.user },
        (response: any, error: boolean) => {
          if (
            response.data &&
            (response.data.length > 0 || Object.keys(response.data).length > 0)
          ) {
            setFetchingLogs(false);
            setReplacementLogs(response.data);
          } else {
            setFetchingLogs(false);
            setEmptyReplacementLogs(true);
          }
        }
      )
    );
  };

  const handleModalSubmit = () => {
    const flag = confirmationModal.flag;
    const params = {
      publishClusterId: confirmationModal.data.id,
      creativeReplacement: flag,
    };
    dispatch(updateCreativeReplacement({ params, user: auth.user }, () => {}));
    handleModalClose();
  };

  const handleModalClose = () => {
    setConfirmationModal({
      open: false,
      text: "",
      data: {},
      flag: false,
    });
  };

  const onCreativeReplacementChange = (cluster: any, flag: boolean) => {
    setConfirmationModal({
      open: true,
      text: `Are you sure you want to turn ${
        flag ? "on" : "off"
      } Creative Replacement`,
      data: cluster,
      flag: flag,
    });
  };

  useEffect(() => {
    const i = getLocalStorageData("selectedTabIndex");
    if (i != null) {
      dispatch(setSelectedTabIndex(i));
    } else {
      setLocalStorageData("selectedTabIndex", selectedTabIndex);
      dispatch(setSelectedTabIndex(selectedTabIndex));
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    if (auth && selectedGSAIAdaccount) {
      dispatch(setGroupsLoader(true));
      dispatch(
        getKeywordRecommendations(
          { adAccountId: selectedGSAIAdaccount?.id, user: auth.user },
          (response: any, error: boolean) => {
            dispatch(setGroupsLoader(false));
            if (response.success) {
              setGroupsList(
                response.data.filter((group: any) => {
                  return (
                    group.platformId == selectedGSAIPlatform?.id &&
                    group.AiGroup?.brandId == selectedBrand?.id
                  );
                })
              );
            }
          }
        )
      );
    }
  }, [selectedGSAIAdaccount]);

  const callGetPublishCluster = () => {
    if (selectedGSAIAdaccount?.id) {
      dispatch(
        getPublishClusters(
          { accountID: selectedGSAIAdaccount?.id, user: auth.user },
          (response: any, error: boolean) => {
            if (!error) {
              const data = response.data;
              data.sort((a: any, b: any) =>
                b.createdAt.localeCompare(a.createdAt)
              );
              dispatch(setPublishCluster(data));
              setIsGetClusterNetworkError(false);
            } else {
              setIsGetClusterNetworkError(true);
            }
            dispatch(setClusterLoader(false));
          }
        )
      );
    } 
    dispatch(setClusterLoader(true));
  };

  useEffect(() => {
    callGetPublishCluster();
  }, [selectedGSAIAdaccount]);

  useEffect(() => {
    dispatch(setGSAIDropdownDisabled(false));
    dispatch(setCreateHeadlineError(false));
    dispatch(setGenerateHeadlineTimestamp(null));
    dispatch(setCreateDescError(false));
    dispatch(setEditCluster({ ...{ ext_id: null } }));
    dispatch(setGenerateDescriptionTimeStamp(null));
    let newTargetKeyword = { ...targetKeyword };
    newTargetKeyword.finalURL = "";
    newTargetKeyword.selectedReferenceDescription = undefined;
    newTargetKeyword.selectedReferenceHeadline = undefined;
    dispatch(setTargetKeyword(newTargetKeyword));
    dispatch(
      setLoadHeadLineDesc({ ...{ headline: false, description: false } })
    );
  }, []);

  const handleTabChange = (tabIndex: number) => {
    setLocalStorageData("selectedTabIndex", tabIndex);
    dispatch(setSelectedTabIndex(tabIndex));
  };

  const capitalizeFirstLetter = (value: string) =>
    value ? value.charAt(0).toUpperCase() + value.slice(1) : "";

  return (
    <>
      <Tab
        data={tabs}
        selectedTabIndex={selectedTabIndex}
        onClick={handleTabChange}
      ></Tab>
      <div
        className="d-flex flex-column m-3 bg-white message-container flex-grow-1 mt-2 overflow-auto"
        style={{ height: "calc(100vh - 185px)" }}
      >
        {selectedTabIndex === 0 ? (
          <>
            {groupsLoader ? (
              <Loader />
            ) : (
              <KeywordRecommendation data={groupsList}></KeywordRecommendation>
            )}
          </>
        ) : (
          <>
            {clusterLoader ? (
              <Loader />
            ) : isGetClusterNetworkError ? (
              <div className="text-center  publish-cluster-error">
                <div className="publish-cluster-child-error">
                  Network error, please try again{"   "}
                  <Refresh
                    className="refresh-icon"
                    onClick={() => {
                      callGetPublishCluster();
                    }}
                  />
                </div>
              </div>
            ) : (
              <Table headers={headers} stickyTop={true} topPosition={"0px"}>
                {(publishCluster || []).map((cluster: any, index: number) => (
                  <TableElement
                    type="tr"
                    key={index}
                    className="publish-cluster-tr position-relative"
                  >
                    <TableElement type="td">
                      {cluster.clusterLabel}
                    </TableElement>
                    <TableElement className="text-truncate" type="td">
                      <div
                        className="tooltip-container"
                        style={{ position: "unset" }}
                      >
                        {cluster?.clusterKeywords?.join(" , ")}
                        {cluster?.clusterKeywords && (
                          <ViewMore
                            popupTitle={"Keywords"}
                            popupData={cluster?.clusterKeywords?.join(" , ")}
                          />
                        )}
                      </div>
                    </TableElement>
                    <TableElement type="td">
                      {cluster?.adGroup?.update_name ? cluster.adGroup.update_name : cluster?.adGroupName}
                    </TableElement>
                    <TableElement type="td">
                      {cluster?.campaignName}
                    </TableElement>
                    <TableElement type="td">
                      {cluster?.adGroup?.update_name ? 'GS AI' : ' - '}
                    </TableElement>
                    <TableElement type="td">{cluster?.aiGroup}</TableElement>
                    <AdTableElement
                      cluster={cluster}
                      targetKeyword={targetKeyword}
                    />
                    <TableElement type="td">
                      {moment(cluster?.createdAt).format("DD MMM, YYYY")}
                    </TableElement>
                    <TableElement
                      type="td"
                      style={{
                        pointerEvents: cluster?.creativeReplacement
                          ? "auto"
                          : "none",
                      }}
                    >
                      <span>
                        <Switch
                          initialValue={false}
                          value={cluster?.creativeReplacement}
                          onChange={(e) => {
                            onCreativeReplacementChange(cluster, false);
                          }}
                        />
                      </span>
                    </TableElement>
                    <TableElement type="td">
                      <div>{cluster?.status}</div>
                      <div style={{ fontSize: "10px", whiteSpace: "nowrap" }}>
                        {cluster?.update_status
                          ? `EDIT CHANGES ${cluster?.update_status}`
                          : ""}
                      </div>
                    </TableElement>
                    <TableElement type="td">
                      <ButtonUI
                        onClick={() => {
                          showLogsTableModal(cluster);
                        }}
                      >
                        <Timer></Timer>
                      </ButtonUI>
                    </TableElement>
                  </TableElement>
                ))}
              </Table>
            )}
            {showLogsTable && (
              <ModalWrapper>
                <ModalContainer
                  title="Replacement logs table"
                  handleClose={() => {
                    setShowLogsTable(false);
                    setEmptyReplacementLogs(false);
                    setReplacementLogs([]);
                  }}
                >
                  {fetchingLogs ? (
                    <Loader />
                  ) : (
                    <Table
                      headers={logsHeaders}
                      stickyTop={true}
                      topPosition={"0px"}
                    >
                      {replacementLogs?.map((h: any, index: number) => {
                        return (
                          <TableElement
                            key={index}
                            type="tr"
                            className="publish-cluster-tr position-relative"
                          >
                            <TableElement type="td">
                              {moment(h.assetReplacementDate ?? "").format(
                                "DD MMM, YYYY"
                              )}
                            </TableElement>
                            <TableElement type="td">
                              {capitalizeFirstLetter(h.assetType)}
                            </TableElement>
                            <TableElement type="td">
                              {h.previousAsset ?? ""}
                            </TableElement>
                            <TableElement type="td">
                              {h.pinnedField == "UNSPECIFIED"
                                ? 0
                                : h.pinnedField.split("_")[1]}
                            </TableElement>
                            <TableElement type="td">
                              {h.performance ?? ""}
                            </TableElement>
                            <TableElement type="td">
                              {h.newAsset ?? ""}
                            </TableElement>
                          </TableElement>
                        );
                      })}
                      {isEmptyReplacementLogs && (
                        <TableElement
                          type="tr"
                          className="publish-cluster-tr position-relative"
                        >
                          <TableElement
                            type="td"
                            colSpan={6}
                            className="text-center"
                          >
                            No replacement logs found
                          </TableElement>
                        </TableElement>
                      )}
                    </Table>
                  )}
                </ModalContainer>
              </ModalWrapper>
            )}

            {confirmationModal.open && (
              <ModalWrapper>
                <ModalContainer
                  title="Confirmation"
                  submitText="Yes"
                  cancelText="Cancel"
                  handleSubmit={handleModalSubmit}
                  handleClose={handleModalClose}
                >
                  <p>{confirmationModal.text}</p>
                </ModalContainer>
              </ModalWrapper>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default GoogleSearchAIDashboard;

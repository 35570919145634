import React from "react";
import { Chart } from "react-charts";
import { useSelector } from "react-redux";
import { CommonState } from "../store/types/common";

const BarChart: React.FC<{ label?: string }> = ({ label }): JSX.Element => {
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { barChartData } = common;
  const [activeSeriesIndex, setActiveSeriesIndex] = React.useState(-1);
  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: "ordinal",
        position: "bottom",
        show: true,
      },
      {
        type: "linear",
        position: "left",
        show: true,
      },
    ],
    []
  );
  const secondaryAxis = React.useMemo(
    (): any[] => [
      {
        getValue: (datum: any) => datum.secondary,
        elementType: "bar",
      },
    ],
    []
  );

  const primaryAxis = React.useMemo(
    (): any => ({
      getValue: (datum: any) => datum.primary,
      elementType: "bar",
    }),
    []
  );
  const series = React.useMemo(
    () => ({
      showPoints: false,
      type: "bar",
    }),
    []
  );

  const cursor = React.useMemo(
    () => ({
      render: (props: any) => <span>{(props.formattedValue || "").toString()}</span>,
    }),
    []
  );

  const getSeriesStyle = React.useCallback(
    (series) => ({
      color: `#${series.index % 2 === 0 ? "5641B8" : "66CACC"}`,
      width: "34px",
      rx: "5px",
      ry: "5px",
      tranform: "translate(39px, 0)",
    }),
    []
  );

  const onFocus = React.useCallback(
    (focused) => setActiveSeriesIndex(focused ? focused.series.id : -1),
    [setActiveSeriesIndex]
  );

  return (
    <div className="h-75 w-50 m-auto">
      {label && <div className="text-sm font-weight-bold ml-2">{label}</div>}
      <Chart
        axes={axes}
        data={barChartData||[]}
        secondaryAxis={secondaryAxis}
        primaryAxis={primaryAxis}
        series={series}
        secondaryCursor={cursor}
        primaryCursor={cursor}
        getSeriesStyle={getSeriesStyle}
        onFocus={onFocus}
        tooltip
        className="p-2"
      />
    </div>
  );
};

export default BarChart;

import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import {
  createDescription,
  createHeadline,
  getArticleDescription,
  getArticleHeadline,
  setClusterTable,
  setCreateAdDesc,
  setCreateAdHeadline,
  setCreateDescError,
  setCreateDescParam,
  setCreateHeadlineError,
  setCreateHeadlineParam,
  setCreativeReplacement,
  setDescriptionID,
  setEditCluster,
  setGenerateDescriptionTimeStamp,
  setGenerateHeadlineTimestamp,
  setHeadlineID,
  setLoadHeadLineDesc,
  setSaveCluster,
  setTargetKeyword,
  updatePublishedCluster,
} from "../../../store/actions/cluster";
import { setModal } from "../../../store/actions/form";
import { ClusterState } from "../../../store/types/cluster";
import { FormState } from "../../../store/types/form";
import { initialState } from "../../../store/types/auth";
import ButtonUI from "../../../ui/ButtonUI/ButtonUI";
import ModalContainer from "../../Modal/ModalContainer";
import ModalWrapper from "../../Modal/ModalWrapper";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { AdAccountState } from "../../../store/types/adaccount";
import { mixPanelAdEvent } from "../../../utils/mixpanel";

export type DrawerFooterInterface = {
  setAdStep: (value: any) => void;
  adStep: number;
  setOpen: (value: boolean) => void;
};

const DrawerFooter: React.FC<DrawerFooterInterface> = ({
  setAdStep,
  adStep,
  setOpen,
}): JSX.Element => {
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [showConfirmationModal, setConfirmationModal] =
    React.useState<boolean>(false);
  const [confirmUpdateModal, setConfirmUpdateModal] =
    React.useState<boolean>(false);
  const [goBackConfirmationModal, setGoBackConfirmationModal] =
    React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<string[]>([]);
  const clusterState = useSelector(
    (state: { cluster: ClusterState }) => state.cluster
  );
  const [selectedGenerationType, setSelectedGenerationType] = React.useState();
  const {
    headlineID,
    descriptionID,
    clusterDetails,
    createAdHeadline,
    createAdDesc,
    targetKeyword,
    clusterTable,
    creativeReplacement,
    editCluster,
  } = clusterState;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    selectedBrand,
    selectedPlatform,
    selectedGSAIPlatform,
    selectedGSAIAdaccount,
    selectedAdAccount,
  } = adaccount;
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { modal } = formState;
  const history = useHistory();

  const checkError = () => {
    const selectedGeneration = targetKeyword.generationType.filter(
      (generation: any) => generation.selected
    );
    return {
      selectedGen: selectedGeneration.length,
    };
  };

  // useEffect(() => {
  //   if(editCluster.ext_id && targetKeyword.referenceHeadline && targetKeyword.referenceDescription){
  //     targetKeyword.referenceHeadline[0].selected = true;
  //     targetKeyword.referenceDescription[0].selected = true;
  //     targetKeyword.generationType[0].selected = true;
  //     dispatch(setTargetKeyword(targetKeyword));
  //     createHeadlineAndDesc();
  //   }
  // },[targetKeyword.referenceHeadline,targetKeyword.referenceDescription]);

  const headlinePramaBody = (headline: any) => {
    //console.log(targetKeyword);
    //console.log("referenceHeadline ",targetKeyword.referenceHeadline);
    let article = targetKeyword.referenceHeadline[targetKeyword.selectedReferenceHeadline];
    let generation_type = targetKeyword.generationType.filter((ele: any) => {
      return ele.selected;
    });
    return {
      article: article.value,
      interest_keyword: targetKeyword.title,
      example_list: headline.map((h: any) => {
        return {
          id: h.id,
          adaccount_id: h.adAccountId,
          article_headline: h.articleHeadline,
          interest_label: h.interestLabel,
          headline: h.headline,
          created_at: h.createdAt,
          updated_at: h.updatedAt,
        };
      }),
      generation_type: generation_type.map((gen: any) => {
        return gen.value;
      }),
      no_of_outputs: 5,
    };
  };

  const descriptionPramaBody = (description: any) => {
    let article = targetKeyword.referenceDescription[targetKeyword.selectedReferenceDescription];
    return {
      article: article.value,
      interest_keyword: targetKeyword.title,
      example_list: description.map((d: any) => {
        return {
          id: d.id,
          adaccount_id: d.adAccountId,
          article_description: d.articleDescription,
          interest_label: d.interestLabel,
          description: d.description,
          created_at: d.createdAt,
          updated_at: d.updatedAt,
        };
      }),
      no_of_outputs: 4,
    };
  };

  const createHeadlineAndDesc = () => {
    if (
      (clusterDetails && clusterDetails.url) ||
      (editCluster && editCluster.ext_id)
    ) {
      //either redirected from step 1 or from redirected from edit link
      dispatch(
        getArticleHeadline(
          {
            brandId: selectedBrand?.id,
            platformId: selectedGSAIPlatform?.id,
            accountID: selectedGSAIAdaccount?.id,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              let headlineParams = headlinePramaBody(response.data);
              dispatch(setCreateHeadlineParam(headlineParams));
              dispatch(
                createHeadline(
                  {
                    accountID: selectedGSAIAdaccount?.id,
                    params: headlineParams,
                    clusterID: clusterDetails.cluster_req_id,
                    user: auth?.user,
                  },
                  (response: any, error: boolean) => {
                    if (!error) {
                      dispatch(setGenerateHeadlineTimestamp( + new Date())); // generate timestamp
                      dispatch(setHeadlineID(response.data.id));
                    }
                    else{
                      dispatch(setCreateHeadlineError(true));
                      dispatch(setGenerateHeadlineTimestamp(null)); 
                    }
                  }
                )
              );
            }
          }
        )
      );
      dispatch(
        getArticleDescription(
          {
            brandId: selectedBrand?.id,
            platformId: selectedGSAIPlatform?.id,
            accountID: selectedGSAIAdaccount?.id,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              let descParam = descriptionPramaBody(response.data);
              dispatch(setCreateDescParam(descParam));
              dispatch(
                createDescription(
                  {
                    accountID: selectedGSAIAdaccount?.id,
                    params: descParam,
                    clusterID: clusterDetails.cluster_req_id,
                    user: auth?.user,
                  },
                  (response: any, error: boolean) => {
                    if (!error) {
                      dispatch(setGenerateDescriptionTimeStamp(+ new Date())); // generate timestamp
                      dispatch(setDescriptionID(response.data.id));
                    }
                    else {
                      dispatch(setCreateDescError(true));
                      dispatch(setGenerateDescriptionTimeStamp(null));
                    }
                  }
                )
              );
            }
          }
        )
      );
    }
  };

  const validateNext = () => {
    const selectedGeneration: any = checkError();
    if (
      selectedGeneration.selectedGen > 0 &&
      targetKeyword.selectedReferenceHeadline!==undefined &&
      targetKeyword.selectedReferenceDescription!==undefined
    ) {
      return true;
    }
    if (selectedGeneration.selectedGen === 0) {
      setShowError((c) => [
        ...c,
        "Minimum 1 generation type option should be selected to continue.",
      ]);
    }
    if (targetKeyword.selectedReferenceHeadline===undefined) {
      setShowError((c) => [
        ...c,
        "Minimum 1 Reference Headline option should be selected to continue.",
      ]);
    }
    if (targetKeyword.selectedReferenceDescription===undefined) {
      setShowError((c) => [
        ...c,
        "Minimum 1 Reference Description option should be selected to continue.",
      ]);
    }
  };

  const handleNextClick = () => {
    if (validateNext()) {
      dispatch(setHeadlineID(null));
      dispatch(setDescriptionID(null));
      createHeadlineAndDesc();
      setAdStep(1);
      setShowError([]);
      setSelectedGenerationType(cloneDeep(targetKeyword.generationType));
      mixPanelAdEvent( "Responsive_search_screen_next_click_gsai", {
        'brand_name': selectedBrand?.name,
        'target_interest_keywords': targetKeyword?.title,
        'reference_headlines': targetKeyword?.referenceHeadline[targetKeyword.selectedReferenceHeadline]?.value,
        'generation_type_selected': targetKeyword?.generationType?.map((gen:any) => {
          if (gen.selected) {
            return gen.label;
          }
        } ).filter((name:any) => name),
        'reference_descriptions':targetKeyword?.referenceDescription[targetKeyword.selectedReferenceDescription]?.value,
      });
    }
  };

  const handleCloseErrorModal = () => {
    setShowError([]);
  };

  const validateSaveUpdate = () => {
    const saveAdError = [];
    const lockedHeadline = createAdHeadline.filter((h: any) => h.isLocked);
    const lockedDesc = createAdDesc.filter((d: any) => d.isLocked);
    const blankHeadlines = creativeReplacement.headline.filter(
      (h: any, index: number) => h.selected && h.interval == ""
    );
    const blankDesc = creativeReplacement.description.filter(
      (h: any, index: number) => h.selected && h.interval == ""
    );

    const headlineSlots: any = {
      1: [],
      2: [],
      3: [],
    };

    let availableHeadlinePins: Array<number> = [];

    // 1. fill slots with respective headline pinned positions
    lockedHeadline.forEach((h: any, index: number) => {
      if (h.pinningPositionId > 0) {
        headlineSlots[h.pinningPositionId].push(h.pinningPositionId);
      } else {
        availableHeadlinePins.push(h.pinningPositionId);
      }
    });

    // 2. then use the unpinned positions to fill the empty headline slots
    for (let key in headlineSlots) {
      if (headlineSlots[key].length == 0 && availableHeadlinePins.length > 0) {
        headlineSlots[key].push(availableHeadlinePins.pop());
      } else if (
        headlineSlots[key].length == 0 &&
        availableHeadlinePins.length == 0
      ) {
        saveAdError.push(
          "You have not selected enough distinct pinning positions. Either unpin headlines or choose more pinning positions."
        );
        break;
      }
    }

    const descSlots: any = {
      1: [],
      2: [],
    };

    let availableDescPins: Array<number> = [];

    // 1. fill slots with respective description pinned positions
    lockedDesc.forEach((h: any, index: number) => {
      if (h.pinningPositionId > 0) {
        descSlots[h.pinningPositionId].push(h.pinningPositionId);
      } else {
        availableDescPins.push(h.pinningPositionId);
      }
    });

    // 2. then use the unpinned positions to fill the empty description slots
    for (let key in descSlots) {
      if (descSlots[key].length == 0 && availableDescPins.length > 0) {
        descSlots[key].push(availableDescPins.pop());
      } else if (descSlots[key].length == 0 && availableDescPins.length == 0) {
        saveAdError.push(
          "You have not selected enough distinct pinning positions. Either unpin descriptions or choose more pinning positions."
        );
        break;
      }
    }

    if (lockedHeadline.length < 3) {
      saveAdError.push("Minimum 3 heading should be locked to continue.");
    }
    if (lockedDesc.length < 2) {
      saveAdError.push("Minimum 2 description should be locked to continue.");
    }
    if (blankHeadlines.length > 0) {
      saveAdError.push(
        "Number of days can not be blank for headline replacement"
      );
    }
    if (blankDesc.length > 0) {
      saveAdError.push(
        "Number of days can not be blank for description replacement"
      );
    }
    if (!targetKeyword.finalURL) {
      saveAdError.push("Please enter Final URL.");
    }
    if (saveAdError.length === 0) {
      return true;
    } else {
      setShowError(saveAdError);
      setConfirmUpdateModal(false);
      return false;
    }
  };

  const handleSaveAdClick = () => {
    if (validateSaveUpdate()) {
      const selectedHeadline = createAdHeadline
        .filter((h: any) => h.selected)
        .map((head: any) => {
          return {
            text: head.value,
            pinnedField:
              head.pinningPositionId == 0
                ? "UNSPECIFIED"
                : `HEADLINE_${head.pinningPositionId}`,
            isPublished: head.isPublished,
            isLocked: head.isLocked
          };
        });

      const selectedDesc = createAdDesc
        .filter((d: any) => d.selected)
        .map((desc: any) => {
          return {
            text: desc.value,
            pinnedField:
              desc.pinningPositionId == 0
                ? "UNSPECIFIED"
                : `DESCRIPTION_${desc.pinningPositionId}`,
            isPublished: desc.isPublished,
            isLocked: desc.isLocked
          };
        });
      dispatch(setSaveCluster({ ext_id: 1 })); //set any value other than null
      dispatch(
        setLoadHeadLineDesc({
          ...{ headline: false, description: false },
        })
      );
      dispatch(
        setModal({
          key: "saveNewAd",
          title: "Save Ad",
          props: { selectedHeadline, selectedDesc },
        })
      );
      setConfirmationModal(true);
    }
  };

  const updatePublishedClusterHandler = (setLoading:any) => {
    if (validateSaveUpdate()) {
      let clusterSelected = clusterTable[0];
      let headlines = creativeReplacement.headline
        .filter((h: any, index: number) => {
          return h.selected;
        })
        .map((h: any, index: number) => {
          return {
            interval: h.interval,
            performance: h.performance,
          };
        });

      let descriptions = creativeReplacement.description
        .filter((h: any, index: number) => {
          return h.selected;
        })
        .map((h: any, index: number) => {
          return {
            interval: h.interval,
            performance: h.performance,
          };
        }); 

      const selectedHeadline = createAdHeadline
        .filter((h: any) => h.selected)
        .map((head: any) => {
          return {
            text: head.value,
            pinnedField:
              head.pinningPositionId == 0
                ? "UNSPECIFIED"
                : `HEADLINE_${head.pinningPositionId}`,
            isPublished: head.isLocked,
          };
        });

      const selectedDesc = createAdDesc
        .filter((d: any) => d.selected)
        .map((desc: any) => {
          return {
            text: desc.value,
            pinnedField:
              desc.pinningPositionId == 0
                ? "UNSPECIFIED"
                : `DESCRIPTION_${desc.pinningPositionId}`,
            isPublished: desc.isLocked,
          };
        });
      clusterSelected.publishClusterId = editCluster.ext_id;
      clusterSelected.ad = {
        ...clusterSelected.ad,
        responsiveSearchAd: {
          headlines: selectedHeadline,
          descriptions: selectedDesc,
        },
        headlineIntervals: headlines,
        descriptionIntervals: descriptions,
        creative_replacement: creativeReplacement.creative_replacement,
        finalUrls: [targetKeyword.finalURL],
      };

      clusterSelected.keywords = clusterSelected.keywords.map((k: any) => {
        return {
          match_type: k.matchType,
          text: k.text,
          negative: k.negative,
        };
      });
     
      clusterSelected.selectedReferenceHeadline = targetKeyword.selectedReferenceHeadline;
      clusterSelected.selectedReferenceDescription = targetKeyword.selectedReferenceDescription;
      clusterSelected.selectedGenerationType = selectedGenerationType;
      dispatch(
        updatePublishedCluster(
          {
            accountID: selectedGSAIAdaccount?.id,
            params: clusterSelected,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (response && !error) {
              setConfirmUpdateModal(false);
            }
            dispatch(setEditCluster({ ...{ ext_id: null } }));
            dispatch(setClusterTable([]));
            dispatch(setHeadlineID(null));
            dispatch(setDescriptionID(null));
            resetCreativeReplacement();
            clearTargetKeywordData();
            dispatch(setCreateAdHeadline(null));
            dispatch(setCreateAdDesc(null));
            dispatch(setCreateHeadlineError(false));
            dispatch(setGenerateHeadlineTimestamp(null)); 
            dispatch(setCreateDescError(false));
            dispatch(setGenerateDescriptionTimeStamp(null));
            dispatch(
              setLoadHeadLineDesc({ ...{ headline: false, description: false } })
            );
            setLoading(false);
            history.push(`/googlesearchai${history.location.search}`);
          }
        )
      );
    }
  };

  const resetCreativeReplacement = () => {
    const cr = {
      creative_replacement: false,
      headline: [
        {
          selected: false,
          label: "Best Performance",
          performance: "BEST",
          interval: "",
        },
        {
          selected: false,
          label: "Good Performance",
          performance: "GOOD",
          interval: "",
        },
        {
          selected: false,
          label: "Low Performance",
          performance: "LOW",
          interval: "",
        },
      ],
      description: [
        {
          selected: false,
          label: "Best Performance",
          performance: "BEST",
          interval: "",
        },
        {
          selected: false,
          label: "Good Performance",
          performance: "GOOD",
          interval: "",
        },
        {
          selected: false,
          label: "Low Performance",
          performance: "LOW",
          interval: "",
        },
      ],
    };
    dispatch(setCreativeReplacement(cr));
  };
  const clearTargetKeywordData = () => {
    targetKeyword.generationType = targetKeyword.generationType.map(
      (gt: any) => {
        gt.selected = true;
        return gt;
      }
    );

    targetKeyword.referenceHeadline = targetKeyword.referenceHeadline.map(
      (rh: any) => {
        rh.selected = false;
        return rh;
      }
    );

    targetKeyword.referenceDescription = targetKeyword.referenceDescription.map(
      (rh: any) => {
        rh.selected = false;
        return rh;
      }
    );
    let newTargetKeyword = { ...targetKeyword };
    newTargetKeyword.finalURL = "";
    newTargetKeyword.selectedReferenceDescription=undefined;
    newTargetKeyword.selectedReferenceHeadline= undefined;
    dispatch(setTargetKeyword(newTargetKeyword));
  };

  const handleSaveAd = () => {
    let creative_replacement = false;
    let headlines = creativeReplacement.headline
      .filter((h: any, index: number) => {
        return h.selected;
      })
      .map((h: any, index: number) => {
        return {
          interval: h.interval,
          performance: h.performance,
        };
      });

    let descriptions = creativeReplacement.description
      .filter((h: any, index: number) => {
        return h.selected;
      })
      .map((h: any, index: number) => {
        return {
          interval: h.interval,
          performance: h.performance,
        };
      });

    if (headlines.length == 0 && descriptions.length == 0) {
      creative_replacement = false;
    } else {
      creative_replacement = creativeReplacement.creative_replacement;
    }

    const ad = {
      type: "RESPONSIVE_SEARCH_AD",
      responsiveSearchAd: {
        headlines: modal.props.selectedHeadline,
        descriptions: modal.props.selectedDesc,
      },
      finalUrls: [targetKeyword.finalURL],
      name: targetKeyword.title + " ad",
      creative_replacement: creative_replacement,
      headlineIntervals: headlines,
      descriptionIntervals: descriptions,
    };
    
    const newCluster = clusterTable;
    newCluster[targetKeyword.activeIndex].ad = ad;
    newCluster[targetKeyword.activeIndex].selectedReferenceHeadline = targetKeyword.selectedReferenceHeadline;
    newCluster[targetKeyword.activeIndex].selectedReferenceDescription = targetKeyword.selectedReferenceDescription;
    newCluster[targetKeyword.activeIndex].selectedGenerationType = selectedGenerationType;
    mixPanelAdEvent('Responsive_ad_save_ad_click_gsai', {
      'headlines': modal?.props?.selectedHeadline,
      'descriptions': modal?.props?.selectedDesc,
      'locked_headlines': createAdHeadline?.filter((h:any) => h.isLocked).map((head:any)=> head.value),
      'locked_descriptions': createAdDesc?.filter((d:any) => d.isLocked).map((desc:any)=> desc.value),
      'pinned_headlines': createAdHeadline?.filter((h:any) => h.pinningPositionId > 0),
      'pinned_descriptions': createAdDesc?.filter((d:any) => d.pinningPositionId > 0),
      'creative_replace_toggle_on': creativeReplacement?.creative_replacement,
      'headline_best_performance_days': creativeReplacement?.headline[0]?.interval ?? 'NA',
      'headline_good_performance_days': creativeReplacement?.headline[1]?.interval ?? 'NA',
      'headline_bad_performance_days': creativeReplacement?.headline[2]?.interval ?? 'NA',
      'description_best_performance_days': creativeReplacement?.description[0]?.interval ?? 'NA',
      'description_good_performance_days': creativeReplacement?.description[1]?.interval ?? 'NA',
      'description_bad_performance_days': creativeReplacement?.description[2]?.interval ?? 'NA',
    });
    dispatch(setClusterTable(newCluster));
    dispatch(setCreateAdHeadline([]));
    dispatch(setCreateAdDesc([]));
    setConfirmationModal(false);
    resetCreativeReplacement();
    clearTargetKeywordData();
    setOpen(false);
  };

  return (
    <div className="createAd-drawer-footer">
      {adStep === 0 ? (
        //   First Screen
        <ButtonUI
          type="button"
          className="btn primary width-secondary footer-btn"
          onClick={handleNextClick}
        >
          Next
        </ButtonUI>
      ) : //   Second screen

      editCluster?.ext_id ? (
        <>
          <ButtonUI
            type="button"
            className="btn secondary width-secondary footer-btn"
            onClick={() => {
              //reset all the forms
              dispatch(setEditCluster({ ...{ ext_id: null } }));
              dispatch(setClusterTable([]));
              dispatch(setHeadlineID(null));
              dispatch(setDescriptionID(null));
              resetCreativeReplacement();
              clearTargetKeywordData();
              dispatch(setCreateAdHeadline(null));
              dispatch(setCreateAdDesc(null));
              history.push(`/googlesearchai${history.location.search}`);
            }}
          >
            Back
          </ButtonUI>
          <ButtonUI
            type="button"
            className="btn primary width-secondary footer-btn"
            onClick={() => {
              setConfirmUpdateModal(true);
            }}
          >
            Publish
          </ButtonUI>
        </>
      ) : (
        <>
          <ButtonUI
            type="button"
            className="btn secondary width-secondary footer-btn"
            onClick={() => {
              setGoBackConfirmationModal(true);
            }}
          >
            Back
          </ButtonUI>
          <ButtonUI
            type="button"
            className="btn primary width-secondary footer-btn"
            onClick={handleSaveAdClick}
          >
            Save Ad
          </ButtonUI>
        </>
      )}
      {/* Modal opens on error */}
      {showError.length > 0 && (
        <ModalWrapper>
          <ModalContainer
            title="Error"
            submitText="Ok"
            handleSubmit={handleCloseErrorModal}
            handleClose={handleCloseErrorModal}
          >
            {adStep === 0 ? (
              <ul className="create-ad-drawer-error">
                {showError.map((err: string, index: number) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              <ul className="create-ad-drawer-error">
                {showError.map((err: string, index: number) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            )}
          </ModalContainer>
        </ModalWrapper>
      )}

      {showConfirmationModal && (
        <ModalWrapper>
          <ModalContainer
            title="Save Ad"
            submitText="OK"
            handleSubmit={() => {
              handleSaveAd();
            }}
            handleClose={() => {
              setConfirmationModal(false);
            }}
          >
            <p>
              The ad will be saved and when published it will be published in
              active state.
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {confirmUpdateModal && (
        <ModalWrapper>
          <ModalContainer
            title="Update Ad"
            submitText="Yes"
            cancelText="Cancel"
            showLoader
            handleSubmit={(setLoading) => {
              setLoading(true);
              updatePublishedClusterHandler(setLoading);
            }}
            handleClose={() => {
              setConfirmUpdateModal(false);
            }}
          >
            <p>Are you sure you want to update this ad?</p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {goBackConfirmationModal && (
        <ModalWrapper>
          <ModalContainer
            title="Save Ad"
            submitText="OK"
            handleSubmit={() => {
              setAdStep(0);
              dispatch(setCreateAdHeadline([]));
              dispatch(setCreateAdDesc([]));
              setGoBackConfirmationModal(false);
              resetCreativeReplacement();
              clearTargetKeywordData();
              dispatch(setHeadlineID(null));
              dispatch(setDescriptionID(null));
            }}
            handleClose={() => {
              setGoBackConfirmationModal(false);
            }}
          >
            <p>Are you sure you want to leave? Your data will be removed.</p>
          </ModalContainer>
        </ModalWrapper>
      )}
    </div>
  );
};

export default DrawerFooter;

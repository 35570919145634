import React, { useEffect, useState } from "react";
import { StyledVariantTitle, StyledVariantContainer } from "./styled";
import ViewMoreModal from "./ViewMoreModal";
import AdSets from "../shared/AdSets/AdSets";
import { useDispatch, useSelector } from "react-redux";
import { getCohortList } from "../../../../store/actions/CreativeAI/Facebook/SelectCohort/selectCohort";
import { resetState } from "../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialStateCreateVariant } from "../../../../store/reducers/CreativeAI/Facebook/CreateVariant/createVariantReducer";
import { useHistory } from "react-router-dom";
import Loader from "../../../common/Loader";
import { StyledOverFlow } from "../CreateVariants/styled";
import { getBusinessUintById } from "../../../../store/actions/CreativeAI/Facebook/Main/Main";

const VariantsListing: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  // All Selectors
  const { COHORT_LIST, CREATIVE_ELEMENT_AI, count, cohortGetStart } =
    useSelector((state: { selectCohortReducer: any; MainReducer: any }) => ({
      COHORT_LIST: state.selectCohortReducer.cohortList,
      count: state.selectCohortReducer.cohortListCount,
      cohortGetStart: state.selectCohortReducer.cohortGetStart,
      BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
      CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
    }));

  const { selectedAiGroup } = useSelector((state: any) => state.aiGroupReducer);

  // All State
  const [showDetailedTarget, setShowDetailedTarget] = useState(false);
  const [selectedAdSet, setSelectedAdSet] = useState({});
  // All Handling Function
  const toggleDetailedTargetModal = (item: any) => {
    setShowDetailedTarget(!showDetailedTarget);
    setSelectedAdSet(item);
  };

  // url params

  const initialParams = {
    searchField: "",
    search: "",
    order: "",
    orderBy: "",
    limit: 5,
    page: 1,
    page_size: "",
    prevY: 0,
  };

  const paramsReducer = (state: any, action: any) => {
    switch (action.type) {
      case action.type:
        return { ...state, [action.type]: action.payload };
      default:
        return state;
    }
  };

  const [params, paramsDispatch] = React.useReducer(
    paramsReducer,
    initialParams
  );
  // url params

  useEffect(() => {
    dispatch({
      type: "RESET_COHORT_LIST",
      payload: { adsets: [], totalCount: 0 },
    });
  }, []);

  useEffect(() => {
    dispatch(resetState(initialStateCreateVariant));

    dispatch(
      getCohortList({
        elementId: selectedAiGroup,
        page: params.page,
        limit: params.limit,
      })
    );
  }, [params]);

  const [prevY, setPrevY] = useState(0);

  useEffect(() => {
    if (!selectedAiGroup) {
      history.push(`/creative-ai${history.location.search}`);
    }
  }, []);

  useEffect(() => {
    if (CREATIVE_ELEMENT_AI?.data?.businessUnitId) {
      dispatch(
        getBusinessUintById({
          businessUnitId: CREATIVE_ELEMENT_AI?.data?.businessUnitId,
        })
      );
    }
  }, [CREATIVE_ELEMENT_AI]);

  const handleScroll = (e: any) => {
    if (e.target.scrollHeight > prevY) {
      if (
        Math.ceil(e.target.scrollTop + e.target.offsetHeight + 2) >=
        e.target.scrollHeight
      ) {
        setPrevY(e.target.scrollHeight);
        if (cohortGetStart === false) {
          dispatch({
            type: "GET_COHORT_START",
            payload: true,
          });
          setTimeout(() => {
            paramsDispatch({ type: "page", payload: params.page + 1 });
          }, 1000);
        }
      }
    }
  };

  return (
    <StyledOverFlow>
      {COHORT_LIST && COHORT_LIST.length ? (
        <>
          <StyledVariantTitle>
            Select any cohort/ad-set to start creating creative variations for
            respective cohort{" "}
          </StyledVariantTitle>
          <StyledVariantContainer onScroll={(e) => handleScroll(e)}>
            {COHORT_LIST &&
              COHORT_LIST.length !== 0 &&
              COHORT_LIST?.map((item: any, ind: any, arr: any) => {
                return (
                  <div key={ind}>
                    <AdSets
                      toggleViewMoreModal={toggleDetailedTargetModal}
                      type="cohort"
                      item={item}
                    />

                    {cohortGetStart && count > arr.length && (
                      <div>{arr.length - 1 === ind && <Loader />}</div>
                    )}
                  </div>
                );
              })}
          </StyledVariantContainer>
        </>
      ) : (
        <StyledVariantTitle>
          <Loader />
        </StyledVariantTitle>
      )}

      {showDetailedTarget && (
        <ViewMoreModal
          selectedAdSet={selectedAdSet}
          show={showDetailedTarget}
          onHide={() => setShowDetailedTarget(!showDetailedTarget)}
        />
      )}
    </StyledOverFlow>
  );
};

export default VariantsListing;

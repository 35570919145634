import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  disableCreativeRotation,
  enableCreativeRotation,
  getCreativeRotationToggle,
} from "../../store/actions/form";
import { initialState } from "../../store/types/auth";
import Switch from "../../ui/Switch/Switch";
import "./index.scss";

const CreativeAIToggle: React.FC<{
  data: any;
  elementToggleValues?: any;
  setElementToggleValues?: any;
}> = ({ data, elementToggleValues, setElementToggleValues }) => {
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const dispatch = useDispatch();
  const [elemetToggle, setElementToggle] = useState<any>({});

  const [platformGroupsData, setPlatformGroupData] = React.useState<any>([]);

  const { data: aiGroups, loading } = useSelector(
    (state: any) => state.aiGroupReducer.aiGroups
  );
  const { selectedAiGroup } = useSelector((state: any) => state.aiGroupReducer);
  useEffect(() => {
    if (Object.keys(elemetToggle).length > 0) {
      if (elemetToggle[data.elementId]) {
        setElementToggleValues({
          ...elementToggleValues,
          [data.elementId]: true,
        });
      } else {
        setElementToggleValues({
          ...elementToggleValues,
          [data.elementId]: false,
        });
      }
    }
  }, [elemetToggle]);

  return (
    <>
      <Switch
        initialValue={data.toggle}
        onChange={(e) => {
          if (e) {
            data.elementId &&
              dispatch(
                enableCreativeRotation(
                  { elementId: data.elementId, user: auth.user },
                  (response: any, error: boolean) => {
                    if (!error) {
                      setElementToggle({
                        ...elemetToggle,
                        [data.elementId]: true,
                      });
                      dispatch(
                        getCreativeRotationToggle(
                          {
                            elementId: data.elementId,
                            user: auth.user,
                          },
                          (response: any, error: boolean) => {
                            if (!error) {
                            }
                          }
                        )
                      );
                    } else {
                      dispatch(
                        getCreativeRotationToggle(
                          {
                            elementId: data.elementId,
                            user: auth.user,
                          },
                          (response: any, error: boolean) => {
                            if (!error) {
                            }
                          }
                        )
                      );
                    }
                  }
                )
              );
          } else {
            data.elementId &&
              dispatch(
                disableCreativeRotation(
                  { elementId: data.elementId, user: auth.user },
                  (response: any, error: boolean) => {
                    if (!error) {
                      console.log("...elementToggle else", elemetToggle);

                      setElementToggle({
                        ...elemetToggle,
                        [data.elementId]: false,
                      });
                      dispatch(
                        getCreativeRotationToggle(
                          {
                            elementId: data.elementId,
                            user: auth.user,
                          },
                          (response: any, error: boolean) => {
                            if (!error) {
                            }
                          }
                        )
                      );
                    }
                    dispatch(
                      getCreativeRotationToggle(
                        {
                          elementId: data.elementId,
                          user: auth.user,
                        },
                        (response: any, error: boolean) => {
                          if (!error) {
                          }
                        }
                      )
                    );
                  }
                )
              );
          }
        }}
      />
    </>
  );
};

export default CreativeAIToggle;

import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../../common/Loader";
import { StyledHeading4 } from "../../../../shared/Typography/Heading/styled";
import {
  StyledAdVariantListCard,
  StyledAdVariantPreview,
  StyledFrame,
  StyledFrameHolder,
  StyledMainCreativeHead,
  StyledVariantItemPara,
} from "./styled";

const MainCreativeCard = ({
  adPreviewLink,
  selectedAd,
  previewLoading,
  item,
}: {
  adPreviewLink: keyof JSX.IntrinsicElements;
  selectedAd: any;
  previewLoading: boolean;
  item: any;
}) => {
  const [iframeSrc, setIframeSrc] = useState("");
  const [previewDelay, setPreviewDelay] = useState(false);

  useEffect(() => {
    var doc: any = new DOMParser().parseFromString(
      item?.previews?.data[0]?.body,
      "text/xml"
    );
    const iframSrc = doc?.firstChild.getAttribute("src");
    setIframeSrc(iframSrc);
  }, [item]);

  useEffect(() => {
    loadingFunction();
  }, []);

  const loadingFunction = () => {
    setTimeout(() => {
      setPreviewDelay(true);
    }, 2500);
  };

  return (
    <StyledAdVariantPreview>
      <StyledAdVariantListCard>
        <StyledMainCreativeHead>
          <span style={{ width: "100%" }}>
            <StyledHeading4 className="fw-bold">Main Creative</StyledHeading4>
            <StyledVariantItemPara>{item?.name}</StyledVariantItemPara>
          </span>
        </StyledMainCreativeHead>
        {previewLoading ? (
          <CircularProgress />
        ) : (
          <>
            <StyledFrameHolder>
              <iframe
                style={{
                  display: previewDelay ? "block" : "none",
                  width: "370px",
                  minHeight: "450px",
                }}
                src={iframeSrc}
              ></iframe>

              {!previewDelay && <Loader />}
            </StyledFrameHolder>
          </>
        )}
      </StyledAdVariantListCard>
    </StyledAdVariantPreview>
  );
};

export default React.memo(MainCreativeCard);
